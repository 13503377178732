import React, { Component } from "react"
//import { Grid, Row, Col } from "react-flexbox-grid"
//import ReferralForm from "containers/Referrals/Form"
//import ReferralItem from "components/Referrals/Item"
import { connect } from "react-redux"
//import { Redirect } from "react-router-dom"
//import { checkWrongStatus } from "utils/associateStatus"
// import Tooltip from "components/Tooltip"
import "./Referrals.scss"

const REFERRAL_LINK = process.env.REACT_APP_REFERRAL_LINK;

class Referrals extends Component {
  componentDidMount() {
    this.props.readReferrals().catch(() => {})
  }

  render() {
    //const { associate, referrals } = this.props
    //const { isThirdPartyLabor } = this.props.associate

    document.location.href = REFERRAL_LINK;

    return <></>;

    /*if (isThirdPartyLabor === "Y") {
      return <Redirect to="/app/assignments" />
    }

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <div className="referrals-content content">
        <Grid>
          <Row>
            <Col xs={12} md={8}>
              <h2>Referrals</h2>
              <span className="h3">Invite Your Friends</span>
              {//<Tooltip text="Tooltip text placeholder"}
              <p>
                Know someone who you think would be a great fit with Hireflex?
                Invite your friends to sign up and you&apos;ll get $100 once
                they work and have been paid for 80 hours.
              </p>
              <Row>
                <Col xs={12} md={4} last="md">
                  <div className="referrals-callout">
                    <div className="referrals-callout-title">EARN</div>
                    <div className="referrals-callout-amount">100</div>
                    <div className="referrals-callout-details">
                      For every referral
                      <br />
                      that works 80 hours
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={8} first="md">
                  <ReferralForm />
                </Col>
              </Row>
              <div className="referral-list">
                {referrals.map((referral, key) => (
                  <ReferralItem key={key} referral={referral} />
                ))}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )*/
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

export default connect(mapStateToProps)(Referrals)
