import React from "react"
import PropTypes from "prop-types"
import "./DollarAmount.scss"

const DollarAmount = props => {
  const float = parseFloat(props.amount)
  const parts = float.toFixed(2).split(".")
  const dollars = parts[0]
  const cents = parts[1]

  return (
    <span className="dollar-amount">
      {dollars}
      <span className="dollar-amount-cents">{cents}</span>
    </span>
  )
}

DollarAmount.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default DollarAmount
