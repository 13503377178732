import React, { Component } from "react"
// import PropTypes from 'prop-types'
import classNames from "classnames"
import { Field, reduxForm } from "redux-form"
import { errorAlert } from "utils/alerts"

import TextInput from "./TextInput"
import Button from "./Button"

import "./EditableField.scss"

class EditableField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.submitSucceeded && nextProps.submitSucceeded) {
      this.setState({ showForm: false })
    }
  }

  toggleForm = e => {
    this.props.reset()
    this.setState({ showForm: !this.state.showForm })
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      pristine,
      submitSucceeded,
      submitting,
      isThirdPartyLabor,
    } = this.props

    return (
      <div className="editable-field">
        <div className="label-mobile">{this.props.label}</div>
        <div
          className={classNames("preview-mode", {
            hidden: this.state.showForm,
          })}
        >
          <div className="label">{this.props.label}</div>
          <div className="preview">{this.props.preview}</div>
          <div className="controls">
            <Button
              btnClass="btn-edit"
              text="Edit"
              onClick={
                isThirdPartyLabor
                  ? () =>
                      errorAlert("Email Edit is not allowed for your account")
                  : this.toggleForm
              }
              succeeded={submitSucceeded}
            />
          </div>
        </div>
        <form
          className={classNames("form-mode", { hidden: !this.state.showForm })}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="input-box">
            <Field
              name={this.props.name}
              type={this.props.type}
              component={TextInput}
              label={this.props.label}
              placeholder={this.props.label}
            />
          </div>
          <div className="controls">
            <Button
              btnClass="btn btn-green btn-save"
              text="Save"
              disabled={pristine || submitting}
              submitting={submitting}
            />
            <a onClick={this.toggleForm} className="cancel-link">
              Cancel
            </a>
          </div>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
})(EditableField)
