import React, { Component } from "react"
import PortalWrapper from "components/PortalWrapper"
import { connect } from "react-redux"
import PointsSummary from "components/Modals/PointsSummary"
import Icon from "components/Icon"
import SvgIcon from "components/SvgIcon"
import "./Gamification.scss"

class Gamification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPointsSummary: false,
    }
  }

  toggleModal = () => {
    this.setState({ showPointsSummary: !this.state.showPointsSummary }, () => {
      if (this.state.showPointsSummary) {
        document.body.classList.add("modal-open")
      } else {
        document.body.classList.remove("modal-open")
      }
    })
  }

  nextLevelNeededPoints = (maxPoints, score) => {
    return Math.round(maxPoints - score + 1)
  }

  nextLevelBasePoints = maxPoints => {
    let basePoints = maxPoints + 1
    return basePoints
  }

  progressWidth = (nextLevelBasePoints, score) => {
    let width = (score / nextLevelBasePoints) * 100
    return width > 0 ? (width > 100 ? 100 : width) : 0
  }

  render() {
    const maxPoints =
      this.props.associate && this.props.associate.attributes.level.maxPoints
    const score = this.props.associate && this.props.associate.attributes.score
    const nextLevelBasePoints = this.nextLevelBasePoints(maxPoints)
    const nextLevelNeededPoints = this.nextLevelNeededPoints(maxPoints, score)
    const barWidth = this.progressWidth(nextLevelBasePoints, score)
    const trainings =
      this.props.associate && this.props.associate.attributes.trainings

    const progressWidthStyle = {
      width: barWidth + "%",
      backgroundColor: "#3877c2",
      borderRadius: "100px",
    }

    let badge = null
    let nextLevel = null

    const levelLabel =
      this.props.associate && this.props.associate.attributes.level.label

    if (levelLabel === "Entry") {
      badge = "ilus-badge-entry"
      nextLevel = "Qualified"
    } else if (levelLabel === "Qualified") {
      badge = "ilus-badge-journeyman"
      nextLevel = "Journeyman"
    } else if (levelLabel === "Journeyman") {
      badge = "ilus-badge-journeyman"
      nextLevel = "Expert"
    } else if (levelLabel === "Expert") {
      badge = "ilus-badge-expert"
    } else {
      badge = "ilus-badge-entry"
    }

    const associateLoading = this.props.associate.isLoading

    return (
      <div className="gamification">
        {!associateLoading && (
          <React.Fragment>
            <div className="gamification-header" onClick={this.toggleModal}>
              <div className="gamification-badge">
                <SvgIcon name={badge} title={levelLabel} />
              </div>

              <div className="gamification-label">
                <div className="gamification-label-wrapper">
                  <div>{levelLabel}</div>
                  <div className="gamificaton-status-wrapper">
                    <span className="gamificaton-status">Status</span>

                    <div
                      className="points-summary-mobile"
                      onClick={this.handleViewSummary}
                    >
                      <Icon name="caret-right" height="12" width="6" />
                    </div>
                  </div>
                </div>

                {levelLabel !== "Expert" && (
                  <div className="gamification-bar mobile">
                    {maxPoints && (
                      <div className="gamification-bar-outer">
                        <div style={progressWidthStyle} />
                      </div>
                    )}
                  </div>
                )}
                {maxPoints ? (
                  <React.Fragment>
                    <p className="level-up">
                      {nextLevelNeededPoints} to level up!
                    </p>
                    <p className="points-left">{`${Math.round(
                      score
                    )} / ${nextLevelBasePoints}`}</p>
                  </React.Fragment>
                ) : (
                  <p className="level-up">
                    You&apos;ve reached the top level!! We are working on
                    additional ways for you to continue to level up, so keep up
                    the great work.
                  </p>
                )}
              </div>
            </div>

            <div className="gamification-progress">
              <div className="gamification-stats">
                <div className="gamification-points">
                  <h5>Points</h5>
                  <span className="gamification-numbers">
                    {Math.round(score)}
                  </span>
                </div>

                {maxPoints && (
                  <div className="gamification-next-level">
                    <h5>Next Level</h5>
                    <span className="gamification-numbers">
                      {nextLevelBasePoints}
                    </span>
                  </div>
                )}
              </div>

              {maxPoints ? (
                <React.Fragment>
                  <div className="gamification-bar dektop">
                    <div className="gamification-bar-outer">
                      <div style={progressWidthStyle} />
                    </div>
                  </div>
                  <p>
                    {nextLevelNeededPoints} points to reach {nextLevel}!
                  </p>
                </React.Fragment>
              ) : (
                <p>
                  You&apos;ve reached the top level!! We are working on
                  additional ways for you to continue to level up, so keep up
                  the great work.
                </p>
              )}
            </div>

            <div className="gamification-training">
              <h5>Training</h5>
              <div className="gamification-training-badges">
                {trainings && trainings.length > 0 ? (
                  trainings.map(t => (
                    <span key={t.id} className="badge">
                      {t.name}
                    </span>
                  ))
                ) : (
                  <p>There is no training</p>
                )}
              </div>
            </div>

            <div className="gamification-summary">
              <button
                className="btn-view points-summary"
                onClick={this.toggleModal}
              >
                View Points Summary
              </button>
            </div>
            {this.state.showPointsSummary && (
              <PortalWrapper>
                <PointsSummary closeModal={this.toggleModal} />
              </PortalWrapper>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate,
    pointsSummary: state.pointsSummary,
  }
}

export default connect(mapStateToProps)(Gamification)
