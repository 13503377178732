import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Field } from "redux-form"
import { readList } from "actions"

import Select from "../Form/Select"

class StateSelectRedux extends Component {
  componentDidMount() {
    this.props.readList("states").catch(() => {})
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={Select}
        disabled={this.props.disabled}
        onChange={this.props.handleChange}
        options={this.props.states || []}
        isMulti={this.props.isMulti}
        limit={this.props.limit}
        labelKey="name"
        valueKey="id"
        labelText={this.props.placeholder}
        placeholder={this.props.placeholder}
        {...{
          menuPlacement: this.props.menuPlacement,
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    states: state.lists.states,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readList: bindActionCreators(readList, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateSelectRedux)
