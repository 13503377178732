import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  errorMessage: "",
  contents: {},
}

export default function application(state = defaultState, action) {
  switch (action.type) {
    case types.CREATE_APPLICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        contents: action.response?.data || {},
      }
    case types.CREATE_APPLICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action?.error?.data?.message || "",
      }
    case types.READ_APPLICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        contents: {},
      }
    case types.READ_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        contents: action.response?.data || {},
      }
    case types.READ_APPLICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action?.error?.data?.message || "",
      }
    case types.UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case types.UPDATE_APPLICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CLEAR_APPLICATION:
      return defaultState
    default:
      return state
  }
}
