import React, { Component } from "react"
import Button from "components/Form/Button"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"

export default class IdentificationWarning extends Component {
  render() {
    const { modalClose } = this.props

    return (
      <Modal className="lofted modal alert" modalClose={modalClose}>
        <div className="modal-header">
          <a className="modal-close" onClick={modalClose}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <h3>Attention</h3>
        </div>
        <div className="modal-body">
          <p>
            Entered “Full Legal Name” differs from the name provided in the “Personal Information”. Please make sure that entered name is correct. Failure to do so may result in a failed background check.
          </p>
        </div>
        <div className="modal-footer">
          <Button
            btnClass="btn-green btn-apply"
            text="OK"
            onClick={modalClose}
          />
        </div>
      </Modal>
    )
  }
}
