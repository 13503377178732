import React from "react"
import { Field } from "redux-form"
import TextInput from "./TextInput"
import TextareaInput from "./TextareaInput"
import "./WorkInterestFields.scss"

const WorkInterestFields = ({ children }) => (
  <fieldset>
    <div className="form">
      <ul className="interests-list">{children}</ul>

      <h3>Your Work Experience</h3>
      <div className="input-line">
        <div className="input-box">
          <Field
            name="previousEmployers"
            props={{ className: "placeholder-small" }}
            type="text"
            component={TextInput}
            label="Previous Employers"
            placeholder="Previous Employers (seperated by commas)"
          />
        </div>
      </div>

      <div className="input-line">
        <Field
          name="previousExperienceDescription"
          component={TextareaInput}
          label="Experience"
          placeholder="Describe your experience in this field"
          rows="5"
          maxLength="250"
        />
      </div>
    </div>
  </fieldset>
)

export default WorkInterestFields
