import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { reduxForm, formValueSelector } from "redux-form"
import { connect } from "react-redux"
import MediaQuery from "react-responsive"
import SvgIcon from "components/SvgIcon"
import { validateEmail } from "utils/validation"

import Icon from "components/Icon"
import Modal from "components/Modal"
import ErrorMessage from "components/ErrorMessage"
import Button from "components/Form/Button"
import Section from "components/Modal/Section"
import Rating from "./Rating"
import Support from "./Support"

import Header from "./Header"
import validate from "./validate"

export const fields = [
  "startTime",
  "endTime",
  "breaks",
  "breakAcknowledgement",
  "supervisorName",
  "supervisorContact",
  "expenses",
  "mileage",
  "signature",
]
const selector = formValueSelector("timesheet")

export class TimesheetReview extends Component {
  static propTypes = {
    timesheet: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    calculateTotalTime: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
    error: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      supervisorAvailable: true,
      showModal: false,
    }
  }

  componentDidMount() {
    let anchor = document.querySelector("#review-top")
    anchor.scrollIntoView()
  }

  handleSupervisorAvailable = () => {
    this.setState({
      supervisorAvailable: true,
    })
  }

  handleSupervisorUnavailable = () => {
    this.setState({
      supervisorAvailable: false,
    })
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
      supervisorAvailable: true,
    })
  }

  openReviewModal = event => {
    event.preventDefault()
    this.setState({
      showModal: true,
    })
  }

  expensesToShow = () => {
    const { formValues, timesheet } = this.props;
    const travelPay = parseFloat(timesheet.travel.pay);
    const isTravelIncentiveVisible = (timesheet.allowMileageOption !== "none" && (this.props.calculateTotalTime('number') / 60 >= timesheet.minimumMileageHours)) || timesheet.allowMileageOption === "ppm";

    let expenses = [...formValues.expenses]

    if (isTravelIncentiveVisible) {
      let travelIncentive = 0

      if (timesheet.allowMileageOption === "range") {
        travelIncentive = travelPay
      }

      if (timesheet.allowMileageOption === "ppm") {
        const miles = parseInt(formValues.mileage, 10)
        travelIncentive =
          miles > timesheet.mileageAfterMaxMiles
            ? (miles - timesheet.mileageAfterMaxMiles) * travelPay
            : 0
      }

      expenses.push({
        label: "Travel Incentive",
        value: travelIncentive.toFixed(2),
      })
    }

    return expenses.filter(e => parseFloat(e.value) > 0)
  }

  render() {
    const {
      handleSubmit,
      formValues,
      nextStep,
      previousStep,
      submitting,
      timesheet,
      error,
    } = this.props
    const { supervisorAvailable, showModal } = this.state
    const totalTime = this.props.calculateTotalTime()
    const canEdit = timesheet.status.editable
    const takeSignature =
      timesheet.signatureRequested && window.innerWidth < 1024

    const {
      breaks,
      breakAcknowledgement,
      startTime,
      endTime,
      supervisorName,
      supervisorContact,
    } = formValues

    let workedTime,
      workedTimeRange,
      breakTime,
      breaksList,
      expenseList,
      supervisorInfo,
      contact,
      footer,
      fullAddress,
      timesheetDate

    let workedTimeMinutes = 0
    let breakTimeMinutes = 0

    if (timesheet) {
      fullAddress = timesheet.location ? timesheet.location.address.full : ""
      timesheetDate = moment(timesheet.date || timesheet.shift.start).format(
        "MMMM D, YYYY"
      )
    }

    if (startTime && endTime) {
      const momentStartTime = moment(startTime, "h:mm A")
      const momentEndTime = moment(endTime, "h:mm A")
      workedTimeMinutes = momentEndTime.diff(momentStartTime, "minutes")
      workedTimeRange = `${startTime} to ${endTime}`
    }

    workedTime = moment()
      .hour(0)
      .minute(workedTimeMinutes)
      .format("H [hours] m [minutes]")

    if (breaks) {
      breakTimeMinutes = 0
      let nonEmptyBreaks = breaks.filter(b => b.startTime && b.endTime)

      if (nonEmptyBreaks.length) {
        breakTimeMinutes = nonEmptyBreaks
          .map(br =>
            moment(br.endTime, "h:mm A").diff(
              moment(br.startTime, "h:mm A"),
              "minutes"
            )
          )
          .reduce((a, b) => a + b)
      }
      breakTime = moment()
        .hour(0)
        .minute(breakTimeMinutes)
        .format("H [hours] m [minutes]")

      breaksList = breaks.map((br, index) => {
        if (br.startTime && br.endTime) {
          return (
            <li className="timesheet-review-detail" key={index}>
              <span className="detail-title">
                Break {index + 1}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              {br.startTime} to {br.endTime}
            </li>
          )
        } else {
          return ""
        }
      })
    }

    const expenses = this.expensesToShow()

    if (expenses.length > 0) {
      expenseList = expenses.map((expense, index) => {
        return <li key={index}>{`${expense.label} - $${expense.value}`}</li>
      })
    }

    contact = validateEmail(supervisorContact)
      ? supervisorContact
      : supervisorContact

    supervisorInfo = (
      <ul>
        <li className="timesheet-review-detail">{supervisorName}</li>
        <li className="timesheet-review-detail">{contact}</li>
      </ul>
    )

    const osc = timesheet.onsiteContact
    const oscPhone =
      osc &&
      (osc.phoneNumber ||
        osc.workPhoneNumber ||
        osc.homePhoneNumber ||
        osc.cellPhoneNumber)
    const feedback = timesheet.feedback && {
      rating: timesheet.feedback.rating ? timesheet.feedback.rating : 0,
      comment: timesheet.feedback.comment ? timesheet.feedback.comment : "",
    }

    let header = canEdit ? (
      <Header timesheet={timesheet} previousStep={previousStep} review />
    ) : (
      <Header timesheet={timesheet} previousStep={previousStep} />
    )

    if (canEdit) {
      if (takeSignature) {
        footer = (
          <Button
            btnClass="btn-green btn-block"
            text={"Get Supervisor Signature"}
            submitting={submitting}
            onClick={this.openReviewModal}
          />
        )
      } else {
        footer = (
          <Button
            btnClass="btn-green btn-block"
            text={"Submit Timesheet"}
            submitting={submitting}
          />
        )
      }
    }

    const submitOrNext =
      supervisorAvailable && takeSignature ? nextStep : handleSubmit

    return (
      <React.Fragment>
        <a className="anchor" id="review-top" aria-hidden>
          Top
        </a>
        <form onSubmit={submitOrNext} className="timesheet-form">
          {header}
          <div className="timesheet-review">
            <div className="timesheet-body-info">
              <Icon name="calendar" width="16" height="16" />
              <span>{timesheetDate}</span>
            </div>
            <div className="timesheet-body-info">
              <Icon name="pinpoint" width="16" height="16" />
              <span>{fullAddress}</span>
            </div>
            {error && <ErrorMessage errorText={error} />}
            <Section>
              <div className="section-header">
                <h3>Work Time</h3>
                <span>{workedTime}</span>
              </div>
              <p>{workedTimeRange}</p>
              {canEdit && !breakAcknowledgement && (
                <ErrorMessage
                  errorText="We see you did not take the state required breaks for this shift.
                  Please contact us immediately to let us know the reason so we can seek a resolution."
                />
              )}
            </Section>

            <Section>
              <div className="section-header">
                <h3>Unpaid Breaks</h3>
                <span>{breakTime}</span>
              </div>
              <ul>{breaksList}</ul>
            </Section>

            <Section>
              <div className="timesheet-paid-time">
                <h3>Total Paid Time</h3>
                <h4>{totalTime || "0 hours 0 minutes"}</h4>
              </div>
            </Section>

            {expenses.length > 0 && (
              <Section>
                <div className="expenses">
                  <h3>Incentives</h3>
                  <ul>{expenseList}</ul>
                </div>
              </Section>
            )}

            <Section>
              <div className="section-header">
                <h3>Supervisor</h3>
              </div>
              {supervisorInfo}
            </Section>

            {osc && (
              <React.Fragment>
                <Section>
                  <div className="section-header">
                    <h3>Onsite Contact</h3>
                  </div>
                  <ul>
                    <li className="timesheet-review-detail">{osc.fullName}</li>
                    <li className="timesheet-review-detail">{oscPhone}</li>
                  </ul>
                </Section>

                {timesheet.status.name === "approved" && (
                  <React.Fragment>
                    <Section>
                      <Rating timesheet={timesheet} feedback={feedback} />
                    </Section>

                    <Section>
                      <Support timesheet={timesheet} />
                    </Section>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <MediaQuery maxWidth={1023}>
            <div className="timesheet-footer fixed">{footer}</div>
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            <div className="timesheet-footer">{footer}</div>
          </MediaQuery>

          {showModal && supervisorAvailable && (
            <Modal
              className="lofted"
              id="review-modal"
              modalClose={this.handleModalClose}
            >
              <div className="review-modal">
                <a className="modal-close" onClick={this.handleModalClose}>
                  <SvgIcon title="close" name="close-gray" />
                </a>
                <div className="modal-header">Find Your Supervisor</div>
                <div className="modal-body">
                  <p className="main">
                    Please hand your phone to your supervisor to sign and
                    approve your request.
                  </p>
                  <Button
                    btnClass="btn-green continue-button"
                    text="Continue"
                    onClick={nextStep}
                  />
                  <a onClick={this.handleSupervisorUnavailable}>
                    My supervisor is not available
                  </a>
                </div>
              </div>
            </Modal>
          )}

          {showModal && !supervisorAvailable && (
            <Modal
              className="lofted"
              id="review-modal"
              modalClose={this.handleModalClose}
            >
              <div className="review-modal">
                <a className="modal-close" onClick={this.handleModalClose}>
                  <SvgIcon title="close" name="close-gray" />
                </a>
                <div className="modal-header">Submit Timesheet</div>
                <div className="modal-body">
                  <p className="main">
                    Without your supervisor’s signature, your timesheet needs to
                    be manually approved.
                  </p>
                  <Button
                    btnClass="btn-green continue-button"
                    text="Submit for Manual Approval"
                  />
                  <a onClick={this.handleSupervisorAvailable}>
                    Find My supervisor
                  </a>
                </div>
              </div>
            </Modal>
          )}
        </form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.data.timesheets.data.find(
      timesheet => timesheet.id === parseInt(state.data.timesheets.detailId, 0)
    ),
    formValues: selector(
      state,
      "startTime",
      "endTime",
      "breakAcknowledgement",
      "breaks",
      "expenses",
      "mileage",
      "supervisorName",
      "supervisorContact"
    ),
    associate: state.data.associate,
  }
}

TimesheetReview = reduxForm({
  form: "timesheet",
  fields,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(TimesheetReview)

TimesheetReview = connect(mapStateToProps)(TimesheetReview)

export default TimesheetReview
