import React, { Component } from "react"
import PropTypes from "prop-types"
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps"
import { withScriptjs } from "utils/googleMaps"

class StaticMap extends Component {
  static propTypes = {
    position: PropTypes.object.isRequired,
  }

  render() {
    const { position } = this.props
    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={position}
        defaultOptions={{
          disableDefaultUI: true,
        }}
      >
        <Marker position={position} />
      </GoogleMap>
    )
  }
}

export default withScriptjs(withGoogleMap(StaticMap))
