import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { giveFeedback } from "actions"
import Icon from "components/Icon"
import PropTypes from "prop-types"
import Modal from "components/Modal"
import PortalWrapper from "components/PortalWrapper"
import Button from "components/Form/Button"
import TextareaInput from "components/Form/TextareaInput"
import SvgIcon from "components/SvgIcon"
import "./Rating.scss"

class Rating extends Component {
  static propTypes = {
    timesheet: PropTypes.object.isRequired,
    feedback: PropTypes.object.isRequired,
  }

  static defaultValue = {
    feedback: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      rating: props.feedback.rating,
      comment: props.feedback.comment,
      modalOpen: false,
      submitting: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.feedback.rating !== prevProps.feedback.rating) {
      this.setState({ rating: this.props.feedback.rating })
    }

    if (this.props.feedback.comment !== prevProps.feedback.comment) {
      this.setState({ comment: this.props.feedback.comment })
    }
  }

  handleTouchStart = e => {
    this.handleTouch(e, true)
  }

  handleTouchMove = e => {
    this.handleTouch(e, false)
  }

  handleTouch = (event, touchStart) => {
    const coord = event.currentTarget.getBoundingClientRect()
    const clientX = event.touches[0].clientX
    const clientY = event.touches[0].clientY
    if (
      coord.x < clientX &&
      coord.x + coord.width > clientX &&
      coord.y < clientY &&
      coord.y + coord.height > clientY
    ) {
      let rating
      if (touchStart) {
        rating = Math.ceil((clientX - coord.left) / (coord.width / 5))
      } else {
        rating = Math.round((clientX - coord.left) / (coord.width / 5))
      }
      this.changeRating(rating)
    }
  }

  handleClick = (event, index, inModal) => {
    this.changeRating(index)
    if (!inModal) {
      this.openModal(event)
    }
  }

  changeRating = index => {
    this.setState({ rating: index })
  }

  changeComment = value => {
    this.setState({ comment: value })
  }

  onSubmit = event => {
    event.preventDefault()
    this.setState({ submitting: true })

    const { rating, comment } = this.state
    const { timesheet } = this.props
    const params = { rating, comment }

    this.props
      .giveFeedback(params, timesheet)
      .then(() => {
        this.setState({ submitting: false })
        this.closeModal(true)
      })
      .catch(() => {})
  }

  openModal = e => {
    if (e.cancelable) e.preventDefault()
    this.setState({
      modalOpen: true,
      comment: this.props.feedback.comment,
    })
  }

  closeModal = success => {
    if (!success) {
      this.setState({
        rating: this.props.feedback.rating,
        modalOpen: false,
      })
    } else {
      this.setState({ modalOpen: false })
    }
  }

  renderStars = inModal => {
    const { rating } = this.state
    return Array.from(Array(5).keys()).map(i => {
      const name = rating > i ? "star" : "star-border"
      return (
        <div key={i} onClick={e => this.handleClick(e, i + 1, inModal)}>
          <Icon key={name} name={name} />
        </div>
      )
    })
  }

  render() {
    const { modalOpen, comment, submitting } = this.state
    const stars = this.renderStars()
    const starsInModal = this.renderStars(true)

    return (
      <div className="rating">
        <p>Rate your experience of this shift</p>
        <div className="rating-stars">{stars}</div>
        {modalOpen && (
          <PortalWrapper>
            <Modal className="lofted" modalClose={() => this.closeModal(false)}>
              <div className="rating-modal">
                <div className="modal-header">Give Feedback</div>
                <div className="modal-body">
                  <a
                    className="modal-close"
                    onClick={() => this.closeModal(false)}
                  >
                    <SvgIcon title="close" name="close-gray" />
                  </a>
                  <div
                    className="rating-stars"
                    onTouchStart={this.handleTouchStart}
                    onTouchMove={this.handleTouchMove}
                  >
                    {starsInModal}
                  </div>
                  <p className="main">Why did you give it that rating?</p>
                  <div className="input-line">
                    <TextareaInput
                      input={{
                        name: "comment",
                        value: comment,
                        onChange: this.changeComment,
                      }}
                      rows="6"
                      label="Feedback"
                      maxLength={255}
                    />
                  </div>
                  <Button
                    btnClass="btn-block btn-green"
                    text="Send Feeback"
                    onClick={this.onSubmit}
                    submitting={submitting}
                  />
                </div>
              </div>
            </Modal>
          </PortalWrapper>
        )}
      </div>
    )
  }
}

export default connect(null, dispatch => ({
  ...bindActionCreators({ giveFeedback }, dispatch),
}))(Rating)
