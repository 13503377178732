import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "actions"
import Referrals from "components/Referrals"

const mapStateToProps = state => {
  return {
    referrals: state.data.referrals.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readReferrals: bindActionCreators(actionCreators.readReferrals, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Referrals)
