import * as types from "./types"
import axios from "axios"

export function getVersion() {
  return dispatch => {
    if (process.env.REACT_APP_BUGSNAG_ENV !== "development") {
      const url = `https://${window.location.host}?req=${[...Array(8)]
        .map(i => (~~(Math.random() * 36)).toString(36))
        .join("")}`
      return axios
        .get(url)
        .then(response => {
          const regex = /js\/main\.(.*?)\.js/g
          const fingerprint = regex.exec(response.data)[1]
          if (fingerprint) {
            dispatch({ type: types.UPDATE_APP_VERSION, fingerprint })
          }
        })
        .catch(() => {})
    }
  }
}
