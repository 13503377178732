import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getSchedule, getScheduleLazy } from "actions"
import TasksList from "components/Tasks/List"
import ScheduleList from "components/Schedule/List"
import { deserializeSchedule } from "deserializers"
import SvgIcon from "components/SvgIcon"
import EmploymentAlert from "components/Modals/EmploymentAlert"
import { Redirect } from "react-router-dom"
import { checkWrongStatus } from "utils/associateStatus"
import "./Assignments.scss"


class Assignments extends Component {
  daysPerRequest = 15

  constructor(props) {
    super(props)

    this.assignmentId = parseInt(props.match.params.id, 0)
    if (this.assignmentId) {
      props.history.push(`/app/assignments`)
    }

    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    if (!checkWrongStatus(this.props.associate)) {
      this.getSchedule()
    }
    window.addEventListener("scroll", this.handleLazyLoading)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleLazyLoading)
  }

  handleLazyLoading = () => {
    const { schedule } = this.props

    if (schedule.isLazyLoading || schedule.scheduleIsLoaded) return

    const height = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    )

    const viewport = window.pageYOffset + window.innerHeight
    const bottom = height - 150

    if (viewport > bottom) {
      this.getScheduleLazy()
    }
  }

  getSchedule = () => {
    this.props
      .getSchedule()
      .then(() => {
        this.setState({ isLoading: false })
      })
      .catch(() => {})
  }

  getScheduleLazy = () => {
    const { schedule } = this.props
    let assignmentCountReceived = 0

    if (schedule && schedule.data && schedule.data.length > 0) {
      schedule.data.forEach(day => {
        if (day && day.slots && day.slots.length > 0) {
          assignmentCountReceived += day.slots.length
        }
      })
    }

    const params = {
      perPage: this.daysPerRequest,
      page: Math.floor(assignmentCountReceived / this.daysPerRequest) + 1,
    }

    this.props.getScheduleLazy(params).catch(() => {})
  }


  render() {
    const { history, schedule, tasks, associate } = this.props
    const isLoading =
      this.state.isLoading || schedule.isLoading || tasks.isLoading

    const associateStatus =
      associate.adminAssociateStatusDescription === "Inactive" ? "Inactive"
      :
      associate.adminAssociateStatusDescription === "Prequalified"
      && associate.onBoardingStatus === 0 ? "PrequalifiedNoOnboarding"
      :
      "Prequalified"
    
    const noAlert = !(associate.adminAssociateStatusDescription === "Ineligible" || associate.adminAssociateStatusDescription === "Accepted")

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <div className="assignments-content content">
        {isLoading ? (
          <div className="loading">
            <SvgIcon title="loading" name="loading" />
            <p>Loading...</p>
          </div>
        ) : (
          <div className="container">
            {noAlert && (
                <EmploymentAlert
                  associateStatus = {associateStatus}
                />
            )}
            {tasks.data && tasks.data.length > 0 && (
              <div className="assignments-tasks">
                <h2>My Tasks</h2>
                <TasksList history={history} tasks={tasks.data} />
              </div>
            )}
            {schedule && (
              <div className="assignments-schedule">
                <h2>My Schedule</h2>
                <ScheduleList
                  schedule={schedule}
                  assignmentId={this.assignmentId}
                />
                {!schedule.scheduleIsLoaded && (
                  <div className="loading small">
                    <SvgIcon title="loading" name="loading" />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    jobs: state.data.jobs,
    schedule: {
      ...state.data.schedule,
      data: deserializeSchedule(state.data.schedule.data),
    },
    tasks: state.data.tasks,
    associate: state.data.associate.attributes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getSchedule, getScheduleLazy }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignments)
