import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { listTeammates } from "actions/teammates"
import React, { Component } from "react"
import TeammatesList from "components/Profile/Teammates/TeammatesList"
import TeammatesInvite from "components/Profile/Teammates/TeammatesInvite"
import SvgIcon from "components/SvgIcon"
import { checkWrongStatus } from "utils/associateStatus"
import { Redirect } from "react-router-dom"

import "./Teammates.scss"

class Teammates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "my-teammates",
      isDataLoaded: false,
    }
  }

  componentDidMount() {
    this.listTeammates()
  }

  listTeammates = () => {
    this.props
      .listTeammates()
      .then(response => {
        this.setState({
          teammatesList: response.data,
          isDataLoaded: true,
        })
      })
      .catch(() => {})
  }

  handleMenuClick = event => {
    this.setState({ activeTab: event.target.id })
  }

  render() {
    const { associate } = this.props
    const { activeTab, teammatesList, isDataLoaded } = this.state

    const teammates = teammatesList && teammatesList.teammates
    const invitations = teammatesList && teammatesList.invitationReceived
    const invitationSent = teammatesList && teammatesList.invitationSent

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <div className="teammates">
        <div>
          <h2>Teammates</h2>
          <ul className="nav-tabbed">
            <li
              id="my-teammates"
              className={activeTab === "my-teammates" ? "active" : ""}
              onClick={this.handleMenuClick}
            >
              My Teammates
            </li>
            <li
              id="send-invitations"
              className={activeTab === "send-invitations" ? "active" : ""}
              onClick={this.handleMenuClick}
            >
              Send Invitations
            </li>
          </ul>
          {activeTab === "my-teammates" &&
            (isDataLoaded ? (
              <TeammatesList
                teammates={teammates}
                invitations={invitations}
                listTeammates={this.listTeammates}
              />
            ) : (
              <div className="loading">
                <SvgIcon title="loading" name="loading" />
                <p>Loading...</p>
              </div>
            ))}
          {activeTab === "send-invitations" &&
            (isDataLoaded ? (
              <TeammatesInvite
                invitations={invitationSent}
                listTeammates={this.listTeammates}
              />
            ) : (
              <div className="loading">
                <SvgIcon title="loading" name="loading" />
                <p>Loading...</p>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        listTeammates,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teammates)
