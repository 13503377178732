import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import { Grid, Row, Col } from "react-flexbox-grid"
import { deserializeEmploymentInfo } from "serializers/employment"

import ErrorMessage from "components/ErrorMessage"
import SideModal from "components/SideModal"
import Section from "components/Modal/Section"
import Button from "components/Form/Button"
import TextInput from "components/Form/TextInput"
import StateSelect from "components/Form/StateSelectRedux"
import { formatDob, formatExistingDob } from "components/Form/formatDob"
import formatSsn from "components/Form/formatSsn"
import normalizeSsn from "components/Form/normalizeSsn"
import parseDob from "components/Form/parseDob"

import Header from "./Header"

import "./forms.scss"

const fields = [
  "fullName",
  "firstName",
  "middleName",
  "lastName",
  "socialSecurityNumber",
  "dateOfBirth",
  "driversLicenseNumber",
  "driversLicenseState",
]

const validate = ({
  fullName,
  firstName,
  middleName,
  lastName,
  socialSecurityNumber,
  repeatSocialSecurityNumber,
  dateOfBirth,
  driversLicenseNumber,
  driversLicenseState,
}) => {
  const errors = {}

  if (fullName) {
    if (!fullName.id && !firstName) {
      errors.firstName = "Please enter your First Name"
    } else if (!/^[a-zA-Z\.\-\']+(\s{1}[a-zA-Z\.\-\']+)*$/g.test(firstName)) {
      errors.firstName = "Field contains forbidden symbols"
    }
    if (!fullName.id && middleName) {
      if (!/^[a-zA-Z\.\-\']+(\s{1}[a-zA-Z\.\-\']+)*$/g.test(middleName)) {
        errors.middleName = "Field contains forbidden symbols"
      }
    }
    if (!fullName.id && !lastName) {
      errors.lastName = "Please enter your Last Name"
    } else if (!/^[a-zA-Z\.\-\']+(\s{1}[a-zA-Z\.\-\']+)*$/g.test(lastName)) {
      errors.lastName =  "Field contains forbidden symbols"
    }
  }

  if (socialSecurityNumber) {
    if (!socialSecurityNumber.id) {
      if (
        (socialSecurityNumber.value && socialSecurityNumber.value.length < 9) ||
        !socialSecurityNumber.value
      ) {
        errors.socialSecurityNumber = {
          value: "Please enter your Social Security Number",
        }
      }
    }

    if (!repeatSocialSecurityNumber) {
      errors.repeatSocialSecurityNumber = {
        value: "Social Security Number values must match",
      }
    }
  }

  if (repeatSocialSecurityNumber) {
    if (repeatSocialSecurityNumber.value !== socialSecurityNumber.value) {
      errors.repeatSocialSecurityNumber = {
        value: "Social Security Number values must match",
      }
    }
  }

  if (dateOfBirth) {
    if (!dateOfBirth.id) {
      if (
        (dateOfBirth.value && dateOfBirth.value.length < 10) ||
        !dateOfBirth.value
      ) {
        errors.dateOfBirth = {
          value: "Please enter your Date of Birth (mm/dd/yyyy)",
        }
      }
    }
  }

  if (driversLicenseNumber) {
    if (!driversLicenseNumber.id && !driversLicenseNumber.value) {
      errors.driversLicenseNumber = {
        value: "Please enter your License Number",
      }
    }
  }

  if (!driversLicenseState) {
    errors.driversLicenseState = "Please enter your State"
  }

  return errors
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class Identification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canEdit: props.status !== "complete",
      firstName: '',
      middleName: '',
      lastName:  ''
    }
  }

  onFieldChangeFirstName = (e) => {
    e.preventDefault();
    const value = capitalizeFirstLetter(e.target.value)
    this.setState({ firstName: value })
    this.props.change('firstName', value)
  }

  onFieldChangeMiddleName = (e) => {
    e.preventDefault();
    const value = capitalizeFirstLetter(e.target.value)
    this.setState({ middleName: value })
    this.props.change('middleName', value)
  }

  onFieldChangeLastName = (e) => {
    e.preventDefault();
    const value = capitalizeFirstLetter(e.target.value)
    this.setState({ lastName: value })
    this.props.change('lastName', value)
  }

  render() {
    const { canEdit } = this.state
    const { initialValues, error, handleSubmit, submitting } = this.props

    const subtitle = canEdit
      ? "Please enter the following information to verify your identity."
      : `Please email us at ${process.env.REACT_APP_SUPPORT_EMAIL} if you need to update this information.`
    return (
      <form onSubmit={handleSubmit} className="government-id-form">
        <SideModal
          closeModal={this.props.closeModal}
          header={
            <Header title="Government Identification" subtitle={subtitle} />
          }
          body={
            <React.Fragment>
              {error && <ErrorMessage errorText={error} />}
              {initialValues && (
                <div className="government-id-fields">
                  <Section>
                    <Grid fluid>
                      <Row>
                        <Col xs={12} sm={12}>
                          <h3>Name and number as shown on social security card</h3>
                        </Col>
                        <Col xs={12} sm={8}>
                          <div className="input-line">
                            <div className="input-box">
                              <Field
                                type="text"
                                name='firstName'
                                component={TextInput}
                                placeholder="First Name"
                                label="First Name"
                                disabled={!!initialValues.fullName?.id}
                                onChange={this.onFieldChangeFirstName}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <div className="input-line">
                            <div className="input-box">
                              <Field
                                type="text"
                                name='middleName'
                                component={TextInput}
                                placeholder="Middle Name"
                                label="Middle Name"
                                disabled={!!initialValues.fullName?.id}
                                onChange={this.onFieldChangeMiddleName}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12}>
                          <div className="input-line">
                            <div className="input-box">
                              <Field
                                type="text"
                                name='lastName'
                                component={TextInput}
                                placeholder="Last Name"
                                label="Last Name"
                                disabled={!!initialValues.fullName?.id}
                                onChange={this.onFieldChangeLastName}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Grid>
                    <div className="input-line">
                      <div className="input-box">
                        <Field
                          type="text"
                          name="socialSecurityNumber.value"
                          component={TextInput}
                          placeholder="Social Security #"
                          label="Social Security #"
                          disabled={!!initialValues.socialSecurityNumber?.id}
                          format={formatSsn}
                          normalize={normalizeSsn}
                        />
                      </div>
                    </div>
                    {initialValues.socialSecurityNumber &&
                      !initialValues.socialSecurityNumber.id && (
                        <div className="input-line">
                          <div className="input-box">
                            <Field
                              type="text"
                              name="repeatSocialSecurityNumber.value"
                              component={TextInput}
                              placeholder="Repeat Social Security #"
                              label="Repeat Social Security #"
                              format={formatSsn}
                              normalize={normalizeSsn}
                            />
                          </div>
                        </div>
                      )}
                  </Section>
                  <Section>
                    <h3>License or State ID</h3>
                    <div className="input-line">
                      <div className="input-box">
                        <Field
                          type="text"
                          name="dateOfBirth.value"
                          component={TextInput}
                          placeholder="Date of Birth (mm/dd/yyyy)"
                          label="Date of Birth"
                          disabled={!!initialValues.dateOfBirth?.id}
                          format={
                            !initialValues.dateOfBirth.value
                              ? formatDob
                              : formatExistingDob
                          }
                          parse={parseDob}
                        />
                      </div>
                    </div>
                    <div className="input-line">
                      <Grid fluid>
                        <Row>
                          <Col xs={12} sm={8}>
                            <div className="input-box input-box-grid">
                              <Field
                                type="text"
                                name="driversLicenseNumber.value"
                                component={TextInput}
                                placeholder="License #"
                                label="License #"
                                disabled={
                                  !!initialValues.driversLicenseNumber?.id
                                }
                              />
                            </div>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div className="input-box input-box-grid">
                              <StateSelect
                                name="driversLicenseState"
                                placeholder="State"
                                label="State"
                                menuPlacement="top"
                                disabled={
                                  !!initialValues.driversLicenseState?.id
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                  </Section>
                </div>
              )}
            </React.Fragment>
          }
          footer={
            <React.Fragment>
              <div className="government-id-footer">
                {canEdit ? (
                  <React.Fragment>
                    <Button
                      btnClass="btn-green btn-block"
                      text="Submit"
                      submitting={submitting}
                    />
                    <Button
                      btnClass="btn-cancel btn-block"
                      text="Cancel"
                      onClick={this.props.closeModal}
                    />
                  </React.Fragment>
                ) : (
                  <Button
                    btnClass="btn-cancel btn-block"
                    text="Close"
                    onClick={this.props.closeModal}
                  />
                )}
              </div>
            </React.Fragment>
          }
        />
      </form>
    )
  }
}

const mapStateToProps = state => {
  let initialValues = deserializeEmploymentInfo(state.data.employment)
    .identification

  if (state.lists.states) {
    const stateObj = state.lists.states.find(state => {
      return state.initials === initialValues.driversLicenseState.value
    })
    initialValues.driversLicenseState = stateObj
  }
  let fullName = {
    firstName: initialValues.fullName.givenName || '',
    middleName: initialValues.fullName.id ? initialValues.fullName.middleName || ' ' : '',
    lastName: initialValues.fullName.familyName || ''
  }
  return {
    initialValues: {
      ...initialValues,
      ...fullName,
    },
  }
}

Identification = reduxForm({
  form: "identification",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(Identification)

export default connect(mapStateToProps)(Identification)
