import moment from "moment"
import _ from "lodash"

const format = "h:mm A"

export function deserializeTimesheet(params) {
  let deserializedParams = { ...params }

  deserializedParams["breaks"] = getBreaksFromClocks(params.clocks)

  // if breaks are empty create an initial empty break
  if (deserializedParams["breaks"].length < 1) {
    deserializedParams["breaks"] = [{ startTime: "", endTime: "" }]
  }

  const work = getWorkTimeFromClocks(params.clocks)
  if (work) {
    deserializedParams["startTime"] = work.startTime
    deserializedParams["endTime"] = work.endTime
  }

  deserializedParams["supervisorName"] = params.supervisor.name
  deserializedParams["supervisorContact"] =
    params.supervisor.email || params.supervisor.phone

  return deserializedParams
}

export function serializeTimesheet(
  timesheetId,
  params,
  timesheetDate,
  geoCoordinates
) {
  const startDate = moment(timesheetDate).format("YYYY-MM-DD")
  const startTime = moment(params.startTime, format).format("HH:mm:ss")
  const endTime = moment(params.endTime, format).format("HH:mm:ss")

  let workClocks = [
    {
      dateTime: `${startDate} ${startTime}`,
      type: "in-day",
    },
    {
      dateTime: `${findEndDate(
        timesheetDate,
        params.startTime,
        params.endTime
      )} ${endTime}`,
      type: "out-day",
    },
  ]

  const breakClocks = getClocksFromBreaks(
    params.breaks,
    timesheetDate,
    startTime
  )

  let clocks = workClocks.concat(breakClocks)

  let newParams = {
    id: timesheetId,
    clocks: clocks,
    supervisor: {
      name: params.supervisorName,
      contact: params.supervisorContact,
    },
    breakAcknowledgement: params.breakAcknowledgement,
    expenses: params.expenses,
    mileage: params.mileage,
    latitude: geoCoordinates.latitude,
    longitude: geoCoordinates.longitude,
  }

  newParams.expenses = newParams.expenses.map(e => {
    if (!e.value) return { ...e, value: "0" }
    return e
  })

  if (params.signature) {
    newParams.signature = params.signature
  }

  return newParams
}

function getWorkTimeFromClocks(clocks) {
  if (clocks.length) {
    let clockStart = _.filter(clocks, { type: "in-day" })
    let clockEnd = _.filter(clocks, { type: "out-day" })

    let newData = {}

    if (clockStart.length) {
      let startTime = moment(
        clockStart[0].dateTime,
        "YYYY-MM-DD HH:mm:ss"
      ).format(format)
      newData.startTime = startTime
    }

    if (clockEnd.length) {
      let endTime = moment(clockEnd[0].dateTime, "YYYY-MM-DD HH:mm:ss").format(
        format
      )
      newData.endTime = endTime
    }

    return newData
  }
}

function getBreaksFromClocks(clocks) {
  if (clocks.length) {
    // skip the in-day and out-day clocks
    const omit = ["in-day", "out-day"]
    let breakPairs = clocks.filter(clock => {
      return omit.indexOf(clock.type) === -1
    })

    if (!breakPairs.length) return []

    let breaks = breakPairs.map((clock, index) => {
      if (index % 2) return null

      const startTime = moment(clock.dateTime, "YYYY-MM-DD HH:mm:ss").format(
        format
      )
      const endTime = moment(
        breakPairs[index + 1].dateTime,
        "YYYY-MM-DD HH:mm:ss"
      ).format(format)
      return {
        id: index + 1,
        startTime: startTime,
        endTime: endTime,
      }
    })

    // filter out the nulls
    breaks = breaks.filter(pair => {
      return pair !== null
    })

    return breaks
  } else {
    return clocks
  }
}

function getClocksFromBreaks(breaks, timesheetDate, timesheetStartTime) {
  let breakClocks = []

  breaks.forEach(b => {
    if (b.startTime && b.endTime) {
      const startTime = moment(b.startTime, format).format("HH:mm:ss")
      const endTime = moment(b.endTime, format).format("HH:mm:ss")

      // if the break start is before the timesheetDate, then it's the next day
      let startDate
      if (startTime > timesheetStartTime) {
        startDate = moment(timesheetDate)
      } else {
        startDate = moment(timesheetDate).add(1, "days")
      }

      const breakStateDate = startDate.format("YYYY-MM-DD")

      const startBreak = {
        dateTime: `${breakStateDate} ${startTime}`,
        type: "out-break",
      }
      breakClocks.push(startBreak)

      const endBreak = {
        dateTime: `${findEndDate(
          startDate,
          b.startTime,
          b.endTime
        )} ${endTime}`,
        type: "in-break",
      }
      breakClocks.push(endBreak)
    }
  })
  return breakClocks
}

// handle case when endDate is the next day
function findEndDate(breakStartDate, start, end) {
  let date = moment(breakStartDate)
  start = moment(start, format)
  end = moment(end, format)

  if (end < start) {
    date = date.add(1, "days")
  }

  return date.format("YYYY-MM-DD")
}
