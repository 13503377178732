import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import ErrorMessage from "components/ErrorMessage"
import { handleErrors } from "utils/errors"
import CheckboxInput from "components/Form/CheckboxInput"
// import LocationSelectRedux from "components/Form/LocationSelectRedux"
// import StateSelect from "components/Form/StateSelectRedux"
import Button from "components/Form/Button"
import LocationInterest from "components/Form/LocationInterest"
import {
  checkWrongStatus,
  isOnboardingAndTimesheetsOnly,
} from "utils/associateStatus"
import { Redirect } from "react-router-dom"

import "./Notifications.scss"

export const fields = [
  "radius",
  "searchLocation",
  // "searchStates",
  "jobEmailNotificationType",
  "smsConfirmJobs",
  "smsCheckInJobs",
  "jobsAvailableForImmediateAssignment",
  "willingTravelAndStayOvernightsOnWork",
]

const validate = () => {
  const errors = {}
  return errors
}

class Notifications extends Component {
  componentDidMount() {
    this.props.readList("radius").catch(() => {})
  }

  updateCurrentAssociate(params) {
    return this.props.updateCurrentAssociate(params).catch(error => {
      handleErrors(error)
    })
  }

  render() {
    const {
      error,
      radiusOptions,
      handleSubmit,
      pristine,
      submitting,
      submitSucceeded,
      addresses,
      associate,
    } = this.props

    const location = addresses.find(address => {
      return address.label === "Physical"
    })

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <React.Fragment>
        <h2>Notifications</h2>
        <form
          onSubmit={handleSubmit(params => this.updateCurrentAssociate(params))}
        >
          <div className="form">
            {error && <ErrorMessage errorText={error} />}
            {!isOnboardingAndTimesheetsOnly(associate) && (
              <>
                <h4>Notify Me of Local Jobs</h4>
                <div className="location-interest notification">
                  <LocationInterest
                    radiusOptions={radiusOptions}
                    location={location}
                  />
                </div>
              </>
            )}
            <div className="email-preferences">
              <h4>Travel job interest</h4>
              <div className="input-line checkbox-line">
                <div className="input-box">
                  <Field
                    name="willingTravelAndStayOvernightsOnWork"
                    label="I am willing to travel and stay overnights for work"
                    component={CheckboxInput}
                  />
                </div>
              </div>
            </div>
            {!isOnboardingAndTimesheetsOnly(associate) && (
              <div className="email-preferences">
                <h4>Email Notifications</h4>
                {/*<div className="radio-line">
                  <div className="input-box">
                    <Field
                      name="jobEmailNotificationType"
                      id="radio_mobile_0"
                      component="input"
                      type="radio"
                      value="Immediate"
                    />
                    <label htmlFor="radio_mobile_0">
                      <span>
                        Email me immediately (as soon as they&apos;re posted)
                      </span>
                    </label>
                  </div>
                </div>*/}

                <div className="radio-line">
                  <div className="input-box">
                    <Field
                      name="jobEmailNotificationType"
                      id="radio_mobile_1"
                      component="input"
                      type="radio"
                      value="Digest"
                    />
                    <label htmlFor="radio_mobile_1">
                      <span>Email me weekly about new job opportunities</span>
                    </label>
                  </div>
                </div>

                <div className="radio-line">
                  <div className="input-box">
                    <Field
                      name="jobEmailNotificationType"
                      id="radio_mobile_2"
                      component="input"
                      type="radio"
                      value="None"
                    />
                    <label htmlFor="radio_mobile_2">
                      <span>
                        Do not email, I will check my dashboard for jobs
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            <h4>Send me text messages</h4>
            <div className="input-line checkbox-line">
              <div className="input-box">
                <Field
                  name="smsConfirmJobs"
                  label="To confirm job acceptance"
                  component={CheckboxInput}
                />
              </div>
            </div>

            <div className="input-line checkbox-line">
              <div className="input-box">
                <Field
                  name="smsCheckInJobs"
                  label="To check in to jobs"
                  component={CheckboxInput}
                />
              </div>
            </div>
            {!isOnboardingAndTimesheetsOnly(associate) && (
              <>
                <div className="input-line checkbox-line">
                  <div className="input-box">
                    <Field
                      name="jobsAvailableForImmediateAssignment"
                      label="About jobs available for immediate assignment"
                      component={CheckboxInput}
                    />
                  </div>
                </div>
                <div className="input-line checkbox-line">
                  <div className="input-box">
                    <Field
                      name="dailyDigestOfOpenJobs"
                      label="SASR job updates"
                      component={CheckboxInput}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="form-action">
            <Button
              btnClass="btn-green btn-save"
              text="Save"
              disabled={pristine || submitting}
              submitting={submitting}
              succeeded={submitSucceeded}
            />
          </div>
        </form>
      </React.Fragment>
    )
  }
}

Notifications = reduxForm({
  form: "notifications",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(Notifications)

export default connect(state => ({
  initialValues: {
    radius: state.data.associate.attributes.radius || 50,
    searchLocation: state.data.associate.attributes.searchLocation,
    // searchStates: state.data.associate.attributes.searchStates,
    jobEmailNotificationType:
      state.data.associate.attributes.jobEmailNotificationType,
    smsConfirmJobs: state.data.associate.attributes.smsConfirmJobs,
    smsCheckInJobs: state.data.associate.attributes.smsCheckInJobs,
    ...(!isOnboardingAndTimesheetsOnly(state.data.associate.attributes) && {
      dailyDigestOfOpenJobs:
        state.data.associate.attributes.dailyDigestOfOpenJobs,
      jobsAvailableForImmediateAssignment:
        state.data.associate.attributes.jobsAvailableForImmediateAssignment,
    }),
    willingTravelAndStayOvernightsOnWork:
      state.data.associate.attributes.willingTravelAndStayOvernightsOnWork,
  },
  addresses: state.data.associate.attributes.addresses,
  associate: state.data.associate.attributes,
}))(Notifications)
