import React from "react"
import Icon from "components/Icon"
import "./Loading.scss"

const Loading = () => (
  <div className="app-loading">
    <div className="app-loading-indicator">
      <Icon name="sasr" width="126" height="32" />
      <p>Loading...</p>
    </div>
  </div>
)

export default Loading
