import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { submit } from "redux-form"
import { reduxForm, formValueSelector } from "redux-form"

import { readTimesheet, createTimesheet, readTasks } from "actions"
import { handleErrors } from "utils/errors"
import { checkWrongStatus } from "utils/associateStatus"
import SideModal from "components/SideModal"
import TimesheetForm from "./Form"
import Modal from "components/Modal"
import Button from "components/Form/Button"
import Icon from "components/Icon"
import validate from "./validate"

import "./Modal.scss"

export const fields = [
  "startTime",
  "endTime",
  "breaks",
  "breakAcknowledgement",
  "supervisorName",
  "supervisorContact",
  "expenses",
  "mileage",
  "signature",
]

export class TimesheetModal extends Component {
  static propTypes = {
    timesheetId: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired,
    readTimesheet: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      areYouSure: false,
      completed: false,
      isLoading: true,
      active: false,
      geoCoordinates: {},
      step: 1,
      reviewed: false,
      signed: false,
      showReviewModal: false,
      showSignatureRemovalModal: true,
    }
  }

  componentDidMount() {
    const timesheetId = this.props.timesheetId
    this.props
      .readTimesheet(timesheetId)
      .then(response => {
        this.setState({ isLoading: false })
      })
      .catch(() => {
        //this.setState({ isLoading: false })
      })
  }

  handleCoordinates = (latitude, longitude) => {
    const coords = { latitude: latitude, longitude: longitude }
    this.setState({ geoCoordinates: coords })
  }

  submitTimesheet = params => {
    const timesheetDate = this.props.timesheet.shift.start
    const timesheetId = this.props.timesheetId
    const geoCoordinates = this.state.geoCoordinates
    return this.props
      .createTimesheet(timesheetId, params, timesheetDate, geoCoordinates)
      .then(response => {
        this.props.closeModal()
        if (!checkWrongStatus(this.props.associate.attributes)) {
          this.props.readTasks()
        }
      })
      .catch(err => {
        if (err.status === 404) {
          setTimeout(() => {
            document.location.reload(true);
          }, 3000);
        }
        
        handleErrors(err)
      })
  }

  confirmSignatureRemoval = e => {
    e.preventDefault()
    this.setState({
      showSignatureRemovalModal: false,
    })
  }

  closeModal = e => {
    e.preventDefault()
    this.props.closeModal()
  }

  handleSubmit = () => {
    this.props.dispatch(submit("timesheet"))
  }

  render() {
    const {
      associate,
      closeModal,
      error,
      timesheet,
      formValues,
      change,
      pristine,
    } = this.props
    const { isLoading, showSignatureRemovalModal } = this.state

    return (
      <div className="timesheet-modal">
        <SideModal
          closeModal={closeModal}
          body={
            <React.Fragment>
              {isLoading ? (
                <h4 className="loading">Loading...</h4>
              ) : (
                <TimesheetForm
                  associate={associate}
                  submitTimesheet={this.submitTimesheet}
                  closeModal={closeModal}
                  timesheet={timesheet}
                  formValues={formValues}
                  change={change}
                  error={error}
                  handleCoordinates={this.handleCoordinates}
                  pristine={pristine}
                />
              )}
            </React.Fragment>
          }
        />
        {!isLoading &&
          timesheet &&
          timesheet.signatureRequested &&
          timesheet.status &&
          timesheet.status.name &&
          timesheet.status.name === "submitted" &&
          timesheet.status.editable &&
          !timesheet.onsiteContact &&
          showSignatureRemovalModal && (
            <Modal
              className="signature-removal-modal lofted"
              modalClose={closeModal}
            >
              <div className="modal-header">
                <h3>Attention</h3>
                <a className="close" onClick={closeModal}>
                  <Icon name="x" />
                </a>
              </div>
              <div className="modal-body">
                <p>
                  Making changes to this timesheet after a supervisor’s
                  signature is obtained, will result in the removal of signature
                  and may cause a delay in processing your payroll.
                </p>
                <div className="confirm-actions">
                  <Button
                    btnClass="btn-block btn-green continue-button"
                    text="Continue"
                    onClick={this.confirmSignatureRemoval}
                  />
                  <Button
                    btnClass="btn-block btn-cancel"
                    text="Cancel"
                    onClick={this.closeModal}
                  />
                </div>
              </div>
            </Modal>
          )}
      </div>
    )
  }
}

const selector = formValueSelector("timesheet")

TimesheetModal = reduxForm({
  form: "timesheet",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(TimesheetModal)

export default connect(
  (state, ownProps) => {
    const timesheetId = parseInt(ownProps.timesheetId, 0)
    const timesheet = state.data.timesheets.data.length
      ? state.data.timesheets.data.find(
          timesheet => timesheet.id === timesheetId
        )
      : { expenses: [] }
    return {
      initialValues: state.data.timesheets.data.find(
        timesheet =>
          timesheet.id === parseInt(state.data.timesheets.detailId, 0)
      ),
      formValues: selector(
        state,
        "startTime",
        "endTime",
        "breaks",
        "mileage",
        "supervisorName",
        "supervisorContact"
      ),
      associate: state.data.associate,
      timesheet: timesheet,
    }
  },
  dispatch => {
    return {
      ...bindActionCreators(
        {
          readTimesheet,
          createTimesheet,
          readTasks,
        },
        dispatch
      ),
    }
  }
)(TimesheetModal)
