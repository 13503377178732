import * as types from "./types"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"

export function listTeammates() {
  return dispatch => {
    dispatch({ type: types.LIST_TEAMMATES_REQUEST })
    return apiCall("get", `associate/${currentAssociateId()}/teammates`)
      .then(response => {
        dispatch({ type: types.LIST_TEAMMATES_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.LIST_TEAMMATES_FAILURE, error })
        throw error
      })
  }
}

export function removeTeammate(teammateRelationId) {
  return dispatch => {
    dispatch({ type: types.REMOVE_TEAMMATE_REQUEST })
    return apiCall(
      "post",
      `associate/${currentAssociateId()}/teammate/${teammateRelationId}?action=removeTeammate`
    )
      .then(response => {
        dispatch({ type: types.REMOVE_TEAMMATE_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.REMOVE_TEAMMATE_FAILURE, error })
        throw error
      })
  }
}

export function confirmInvitation(teammateRelationId) {
  return dispatch => {
    dispatch({ type: types.CONFIRM_INVITATION_REQUEST })
    return apiCall(
      "post",
      `associate/${currentAssociateId()}/teammate/${teammateRelationId}?action=confirmInvitation`
    )
      .then(response => {
        dispatch({ type: types.CONFIRM_INVITATION_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.CONFIRM_INVITATION_FAILURE, error })
        throw error
      })
  }
}

export function rejectInvitation(teammateRelationId) {
  return dispatch => {
    dispatch({ type: types.REJECT_INVITATION_REQUEST })
    return apiCall(
      "post",
      `associate/${currentAssociateId()}/teammate/${teammateRelationId}?action=rejectInvitation`
    )
      .then(response => {
        dispatch({ type: types.REJECT_INVITATION_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.REJECT_INVITATION_FAILURE, error })
        throw error
      })
  }
}

export function cancelInvitation(teammateRelationId) {
  return dispatch => {
    dispatch({ type: types.CANCEL_INVITATION_REQUEST })
    return apiCall(
      "post",
      `associate/${currentAssociateId()}/teammate/${teammateRelationId}?action=cancelInvitation`
    )
      .then(response => {
        dispatch({ type: types.CANCEL_INVITATION_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.CANCEL_INVITATION_FAILURE, error })
        throw error
      })
  }
}

export function teammatesInvite(params) {
  return dispatch => {
    dispatch({ type: types.TEAMMATES_INVITE_REQUEST })
    return apiCall(
      "post",
      `associate/${currentAssociateId()}/teammates-invite`,
      {
        data: params,
      }
    )
      .then(response => {
        dispatch({ type: types.TEAMMATES_INVITE_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.TEAMMATES_INVITE_FAILURE, error })
        throw error
      })
  }
}
