import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readList, updateCurrentAssociate } from "actions"
import Notifications from "components/Profile/Notifications"

const mapStateToProps = state => {
  return {
    radiusOptions: state.lists.radius,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ readList, updateCurrentAssociate }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
