import * as types from "./types"
import { currentAssociateId } from "utils/auth"
import { apiCall } from "utils/api"

export function readAssignment(applicationId, params) {
  let includes =
    "job.store,job.project.workType,job.project.requirements,job.project.customer"

  return dispatch => {
    dispatch({ type: types.READ_ASSIGNMENT_REQUEST })
    return apiCall(
      "get",
      `associate/${currentAssociateId()}/applications/${applicationId}?include=${includes}`,
      { data: params }
    )
      .then(response => {
        dispatch({ type: types.READ_ASSIGNMENT_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_ASSIGNMENT_FAILURE, error })
        throw error
      })
  }
}
