import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import "./VerticalDate.scss"

const VerticalDate = props => {
  const month = props.date ? moment(props.date).format("MMM") : "..."
  const day = props.date ? moment(props.date).format("D") : "."
  return (
    <div className="vertical-date">
      <span className="month">{month}</span>
      <span className="day">{day}</span>
    </div>
  )
}

VerticalDate.propTypes = {
  date: PropTypes.string,
}

export default VerticalDate
