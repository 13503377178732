import * as types from "./types"
import { currentAssociateId } from "utils/auth"
import { apiCall } from "utils/api"
import { serializeJobSearch } from "serializers/jobs"

export function listApplications(params) {
  return dispatch => {
    dispatch({ type: types.LIST_APPLICATIONS_REQUEST })
    return apiCall(
      "get",
      `associate/${currentAssociateId()}/applications?include=job.store,job.project.workType`,
      { params: serializeJobSearch(params) }
    )
      .then(response => {
        dispatch({ type: types.LIST_APPLICATIONS_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.LIST_APPLICATIONS_FAILURE, error })
        throw error
      })
  }
}

export function confirmApplication(
  applicationId,
  agreementsExists,
  hasLodging = false,
  lodging = {}
) {
  if (hasLodging)
    return updateApplication(
      applicationId,
      "confirm",
      agreementsExists,
      hasLodging,
      lodging
    )

  return updateApplication(applicationId, "confirm", agreementsExists)
}

export function createApplication(jobId, params, agreementsExists) {
  const agreementConfirmed = { agreementsConfirmed: agreementsExists }
  let requestParams = params

  if (agreementsExists) {
    requestParams = { ...requestParams, ...agreementConfirmed }
  }

  return dispatch => {
    dispatch({ type: types.CREATE_APPLICATION_REQUEST, params })
    return apiCall(
      "post",
      `opportunities/${jobId}/apply?include=associate.skills,slots`,
      { data: requestParams },
      true,
      null,
      null,
      { skip404: true }
    )
      .then(response => {
        dispatch({ type: types.CREATE_APPLICATION_SUCCESS, response })
        if (params.submit) {
          dispatch({ type: types.REMOVE_OPEN_JOB, jobId })
          dispatch({
            type: types.UPDATE_ASSOCIATE_SUCCESS,
            associate: response.data.associate,
          })
        }
        return response
      })
      .catch(error => {
        dispatch({ type: types.CREATE_APPLICATION_FAILURE, error })
        throw error
      })
  }
}

export function declineApplication(applicationId) {
  return updateApplication(applicationId, "decline")
}

export function readApplication(applicationId, params) {
  let includes = "job.store,job.project.workType,job.project.requirements"

  return dispatch => {
    dispatch({ type: types.READ_APPLICATION_REQUEST })
    return apiCall(
      "get",
      `associate/${currentAssociateId()}/applications/${applicationId}?include=${includes}`,
      { data: params },
      true,
      null,
      { property: "data.deniedActions.canCancel", message: "data.denialReason" }
    )
      .then(response => {
        dispatch({ type: types.READ_APPLICATION_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_APPLICATION_FAILURE, error })
        throw error
      })
  }
}

export function updateApplication(
  applicationId,
  status,
  agreementsExists,
  hasLodging = false,
  lodging = {}
) {
  const agreementConfirmed = { agreementsConfirmed: agreementsExists }
  let params = { status: status }

  if (status === "confirm" && agreementsExists) {
    params = { ...params, ...agreementConfirmed }
  }

  if (hasLodging) {
    params = { ...params, lodging }
  }

  return dispatch => {
    dispatch({ type: types.UPDATE_APPLICATION_REQUEST })
    return apiCall(
      "put",
      `associate/${currentAssociateId()}/applications/${applicationId}`,
      { data: params },
      true,
      null,
      { property: "data.denialReason" },
      { skip404: true }
    )
      .then(response => {
        dispatch({ type: types.UPDATE_APPLICATION_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_APPLICATION_FAILURE, error })
        throw error
      })
  }
}

export function clearApplication() {
  return dispatch => {
    dispatch({ type: types.CLEAR_APPLICATION })
  }
}
