import React from "react"
import "./SupervisorInfo.scss"

const SupervisorInfo = ({ supervisor }) => (
  <div className="supervisor-info">
    <h5>Supervisor</h5>
    <p>{supervisor.name}</p>
    <p>{supervisor.phone}</p>
  </div>
)

export default SupervisorInfo
