import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./Select.scss"
import CustomSelect from "components/CustomSelect"

// https://github.com/erikras/redux-form/issues/82#issuecomment-274047138
export default class Select extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
  }

  handleChange = value => {
    if (!this.props.limit || value.length <= this.props.limit) {
      this.props.input.onChange(value)
    }
  }

  hasErrors() {
    return this.props.meta.error && this.props.meta.touched
  }

  render() {
    const labelText =
      this.props.meta.error && this.props.meta.touched
        ? this.props.meta.error
        : this.props.labelText

    const inputClass = classNames({
      "has-errors": this.hasErrors(),
    })

    const value = this.props.input.value;
    const selected = this.props.options?.find(option => option.id === value) || value;

    return (
      <React.Fragment>
        <CustomSelect
          name={this.props.input.name}
          value={selected}
          options={this.props.options}
          placeholder={this.props.placeholder}
          className={inputClass}
          onChange={value => this.handleChange(value)}
          onBlur={() => this.props.input.onBlur(this.props.input.value)}
          isClearable={false}
          isSearchable={true}
          labelKey={this.props.labelKey}
          valueKey={this.props.valueKey}
          isMulti={this.props.isMulti}
          isDisabled={this.props.disabled}
          menuPlacement={this.props.menuPlacement}
        />
        <label>{labelText}</label>
      </React.Fragment>
    )
  }
}
