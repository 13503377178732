import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./Modal.scss"

export default class Modal extends Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    modalClose: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
  }

  static defaultProps = {
    loaded: true,
  }

  handleOverlayClick = event => {
    event.stopPropagation()
    if (!event.target.closest(".modal-content")) {
      this.props.modalClose(this.props.id)
    }
  }

  render() {
    const modalClass = classnames("modal", this.props.className)
    const contentClass = classnames("modal-content", {
      loaded: this.props.loaded,
    })

    return (
      <div className={modalClass}>
        <div className="modal-backdrop" onClick={this.handleOverlayClick}>
          {this.props.loaded ? (
            <div className={contentClass}>{this.props.children}</div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    )
  }
}
