import React from "react"
import SvgIcon from "components/SvgIcon"
import "./NotSupported.scss"

const NotSupported = () => (
  <div className="browser-not-supported">
    <div className="logo">
      <SvgIcon name="logo" title="SASR" />
    </div>
    <h2>You are using an unsupported browser.</h2>

    <div className="upgrade">
      <h3>Please upgrade to one of the following:</h3>
      <ul>
        <li>
          <a href="https://www.google.com/chrome/">
            <SvgIcon name="chrome" title="Chrome" />
            <span>Google Chrome</span>
          </a>
        </li>
        <li>
          <a href="https://www.mozilla.org/en-US/firefox/">
            <SvgIcon name="firefox" title="Firefox" />
            <span>Mozilla Firefox</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default NotSupported
