import * as types from "./types"
import { serializeExperiences } from "serializers/experiences"
import { deserializeExperiences } from "deserializers"
import { updateCurrentAssociate } from "./associate"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"

export function readExperiences() {
  return dispatch => {
    dispatch({ type: types.READ_EXPERIENCES_REQUEST })
    return apiCall("get", `associate/${currentAssociateId()}/experience`)
      .then(response => {
        response.data = deserializeExperiences(response.data)
        dispatch({ type: types.READ_EXPERIENCES_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.READ_EXPERIENCES_FAILURE, error })
        throw error
      })
  }
}

export function updateExperiences(params) {
  params = serializeExperiences(params)
  return dispatch => {
    dispatch({ type: types.UPDATE_EXPERIENCES_REQUEST, params })
    return apiCall("put", `associate/${currentAssociateId()}/experience`, {
      data: params,
    })
      .then(response => {
        // response.data = deserializeExperiences(response.data)
        dispatch({ type: types.UPDATE_EXPERIENCES_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_EXPERIENCES_FAILURE, error })
        throw error
      })
  }
}

export function clearExperiences() {
  return dispatch => {
    dispatch({ type: types.CLEAR_EXPERIENCES })
  }
}

export function updateExperiencesAndUpdateAssociate(params) {
  return dispatch => {
    return dispatch(updateExperiences(params)).then(() => {
      const { radius, willingTravelAndStayOvernightsOnWork } = params
      return dispatch(
        updateCurrentAssociate(
          { radius, willingTravelAndStayOvernightsOnWork },
          false
        )
      ).then(() => {
        return true
      })
    })
  }
}
