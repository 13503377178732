import React, { Component } from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import Icon from "components/Icon"
import { connect } from "react-redux"
import { readApiVersion } from "actions/apiVersion"
import { bindActionCreators } from "redux"
import "./Footer.scss"

class Footer extends Component {
  componentDidMount() {
    this.props
      .readApiVersion()
      .then(response => {
        console.log("API Version: " + response.data)
      })
      .catch(() => {})
  }

  render() {
    return (
      <footer className="app-footer">
        <div className="inner">
          <Grid>
            <Row>
              <Col xs={12} md={6}>
                <div className="logo">
                  <Icon name="sasr" width="63" height="16" />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="links">
                  <ul>
                    <li className="separator">
                      <a className="bold-link" href="https://survey.zohopublic.com/zs/cCD7wR">Take a Survey</a>
                    </li>
                    <li>
                      <a href="/app/terms">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="/app/privacy">Privacy</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="copyright">
                  <p>
                    Hireflex &copy; {new Date().getFullYear()}. All rights
                    reserved.
                  </p>
                </div>
              </Col>
              <Col xs={12}>
                <div className="app-version">
                  <p>App version: {process.env.REACT_APP_VERSION}</p>
                </div>
                <div className="app-version">
                  <p>API version: {this.props.apiVersion}</p>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = state => {
  return {
    apiVersion: state.data.apiVersion.contents,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        readApiVersion,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
