export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"°#&]+(\.[^<>()\[\]\\.,;:\s@"°#&]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9][^-_]{0,1})([a-zA-Z\-0-9]*[^-_.]\.)+[a-zA-Z]{2,20}))$/g;
  return re.test(String(email).toLowerCase())
}

export function validatePhoneNumber(phone) {
  var re = /^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/
  return re.test(String(phone))
}
