import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Icon from "components/Icon"
import "./SideModal.scss"

export default class SideModal extends Component {
  static propTypes = {
    body: PropTypes.node.isRequired,
    closeModal: PropTypes.func.isRequired,
    footer: PropTypes.node,
    header: PropTypes.node,
  }

  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
  }

  componentDidMount() {
    this.timeoutID = setTimeout(() => {
      this.setState({ active: true })
    }, 1)
    document.body.classList.add("modal-open")
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID)
    document.body.classList.remove("modal-open")
  }

  handleClickOutside = event => {
    event.stopPropagation()
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ active: false })
    setTimeout(() => {
      this.props.closeModal()
    }, 300)
  }

  render() {
    const wrapperClass = classNames("side-modal-content", {
      open: this.state.active,
    })
    const modalClass = classNames("side-modal-backdrop", {
      open: this.state.active,
      closing: !this.state.active,
    })

    const { header, body, footer } = this.props

    const headerWithClose =
      header !== undefined
        ? React.cloneElement(header, { handleClose: this.handleClose })
        : undefined
    const bodyWithClose =
      body !== undefined
        ? React.cloneElement(body, { handleClose: this.handleClose })
        : undefined
    const footerWithClose =
      footer !== undefined
        ? React.cloneElement(footer, { handleClose: this.handleClose })
        : undefined

    return (
      <div className="side-modal">
        <div className={modalClass} onClick={this.handleClickOutside} />
        <div className={wrapperClass}>
          <div className="side-modal-header">
            {headerWithClose}
            <a className="side-modal-close" onClick={this.handleClose}>
              <Icon name="x" height="20" width="20" />
            </a>
          </div>
          <div className="side-modal-body">{bodyWithClose}</div>
          {footer !== undefined && (
            <div className="side-modal-footer">{footerWithClose}</div>
          )}
        </div>
      </div>
    )
  }
}
