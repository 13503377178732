import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { markEmploymentComplete } from "actions"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"
import "./ExternalModal.scss"

class Orientation extends Component {
  linkOpened = () => {
    this.props.markEmploymentComplete("orientation").catch(() => {})
  }

  render() {
    const { closeModal, orientation, status } = this.props

    let header, text, button, completeHeader, completeText

    switch (status) {
      case "incomplete":
        header = "Complete Your Online Orientation"
        text = "Please complete your online orientation."
        button = "Complete Orientation"
        break
      case "pending":
        header = "Online Orientation"
        text = `We're confirming your online orientation was completed successfully. If you have questions, email us at ${process.env.REACT_APP_SUPPORT_EMAIL}.`
        button = "Revisit Orientation"
        break
      case "complete":
        header = "Online Orientation"
        completeHeader = "Thank you for completing your online orientation."
        completeText = `If you have questions, email us at ${process.env.REACT_APP_SUPPORT_EMAIL}.`
        button = "Revisit Orientation"
        break
      default:
        header = "Oops"
        text =
          "There was a problem getting your orientation status. Please contact support."
        break
    }

    const showButton =
      status === "incomplete" || status === "pending" || status === "complete"

    return (
      <Modal className="lofted modal external-link" modalClose={closeModal}>
        <div className="modal-header">
          <a className="modal-close" onClick={closeModal}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <h3>{header}</h3>
        </div>
        <div className="modal-body">
          {status !== "complete" ? (
            <p>{text}</p>
          ) : (
            <p>
              {completeHeader}
              <br />
              {completeText}
            </p>
          )}
        </div>
        <div className="modal-footer">
          {showButton && (
            <a
              className="btn btn-green btn-apply"
              href={orientation.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.linkOpened}
            >
              {button}
            </a>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    orientation: state.data.employment.attributes.orientation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        markEmploymentComplete,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orientation)
