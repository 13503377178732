import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export default class TextareaInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      remainingCharacters: this.props.maxLength - this.props.input.value.length,
    }
  }

  handleChange = e => {
    const { maxLength } = this.props
    this.props.input.onChange(
      maxLength ? e.target.value.slice(0, maxLength) : e.target.value
    )
  }

  componentWillReceiveProps(nextProps) {
    let remaining = this.props.maxLength - nextProps.input.value.length
    this.setState({ remainingCharacters: remaining })
  }

  render() {
    let inputClass = classNames(this.props.className, {
      "has-text": this.props.input.value.length,
    })

    const props = {}

    if (this.props.maxLength) {
      props.maxLength = this.props.maxLength
    }

    return (
      <div className="input-box">
        <textarea
          className={inputClass}
          name={this.props.input.name}
          value={this.props.input.value}
          id={this.props.input.name}
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          onKeyUp={this.handleChange}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          {...props}
        />
        <label htmlFor={this.props.input.name}>{this.props.label}</label>
        {this.props.maxLength && (
          <span className="textarea-counter info-text">
            {this.state.remainingCharacters} characters remaining
          </span>
        )}
      </div>
    )
  }
}
