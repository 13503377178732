import moment from "moment"

export const formatDob = value => {
  if (!value) {
    return ""
  }

  if (value.length === 10) {
    const dateString = moment(value).format("MM/DD/YYYY")
    return dateString
  }

  const onlyNums = value.replace(/[^\d]/g, "")

  if (onlyNums.length <= 2) {
    return onlyNums
  }

  if (onlyNums.length <= 4) {
    return onlyNums.slice(0, 2) + "/" + onlyNums.slice(2)
  }

  return (
    onlyNums.slice(0, 2) +
    "/" +
    onlyNums.slice(2, 4) +
    "/" +
    onlyNums.slice(4, 8)
  )
}

export const formatExistingDob = value => {
  const dateString = moment(value, "****-MM-DD").format("MM/DD/****")
  return dateString
}
