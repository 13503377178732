import React, { Component, Fragment } from "react"
import TeammatesInviteForm from "./TeammatesInviteForm"

class TeammatesInvite extends Component {
  render() {
    const { isLoading } = this.props

    return (
      <Fragment>
        <div className="invite-wrapper no-margin">
          <h3>Invite New Teammate</h3>
          <p>
            Know someone you want to work with? Invite your preferred teammates
            so you can request them when you apply to jobs. You may invite more
            than one.
          </p>
          <TeammatesInviteForm
            onSubmit={this.props.teammatesInvite}
            submitting={isLoading}
          />
        </div>
      </Fragment>
    )
  }
}

export default TeammatesInvite
