import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import moment from "moment"
import { createPunchTime, readTasks } from "actions"
import { successAlert } from "utils/alerts"
import SvgIcon from "components/SvgIcon"
import Button from "components/Form/Button"
import Modal from "components/Modal"
import ConfirmTask from "./Confirm"
import CheckoutTask from "./Checkout"
import CheckinTask from "./Checkin"
import SubmitTimesheetTask from "./SubmitTimesheet"
import InfoMessage from "components/InfoMessage"
import { confirmInvitation } from "actions/teammates"

import "./Item.scss"

class TaskItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      longitude: null,
      latitude: null,
      instructionsHeaderText: "",
      mountChildComponent: false,
      showInstructionModal: false,
    }
  }

  mountTaskComponent = () => {
    // const { task } = this.props
    // const submitting = task.type === "check-in" || task.type === "check-out"
    this.setState({ mountChildComponent: true, submitting: false })
  }

  closeGeoChildren = () => {
    this.setState({
      mountChildComponent: false,
      submitting: false,
    })
  }

  closeSideModal = () => {
    this.setState({ mountChildComponent: false })
  }

  handlePunchSuccess = () => {
    const { task } = this.props
    // this.closeGeoChildren()
    if (task.type === "check-in" || task.type === "check-out") {
      if (task.instructions || this.isEarly()) {
        this.mountInstructionModal(task.type)
      } else {
        this.props.readTasks().catch(() => {})
      }
    } else {
      this.props.readTasks().catch(() => {})
    }
  }

  mountInstructionModal = type => {
    this.setState({
      instructionsHeaderText: this.setModalHeaderText(type),
      showInstructionModal: true,
      // submitting: true,
    })
  }

  instructionModalContinue = () => {
    // should only be for check in and check out
    this.setState({ showInstructionModal: false, submitting: true })
    this.props
      .readTasks()
      .then(response => {
        this.setState({ submitting: false })
      })
      .catch(() => {})
  }

  setModalHeaderText = type => {
    switch (type) {
      case "assignment-confirmation":
        return "Confirming a Job?"
      case "check-out":
        return "Thanks for Checking Out"
      case "check-in":
        return "Thanks for Checking In"
      case "submit-timesheet":
        return "Completing a Timesheet?"
      case "teammate-invitation":
        return "Confirming a Teammate?"
      default:
        return "Instructions"
    }
  }

  isEarly = () => {
    const { task } = this.props
    if (task.type === "check-in") {
      const job = task.source
      return moment() < moment(job.shift.start)
    } else {
      return false
    }
  }

  handleCoordinatesChange = (latitude = null, longitude = null) => {
    const { task } = this.props
    const params = {
      assignmentId: task.source.id,
      type: "day",
      latitude: latitude,
      longitude: longitude,
    }
    const location = task.source.location
      ? "at " + task.source.location.address.full
      : "to your job"

    this.props
      .createPunchTime(params)
      .then(response => {
        if (task.type === "check-in") {
          successAlert(`Checked in ${location}`)
        } else if (task.type === "check-out") {
          successAlert(`Checked out ${location}`)
        }
        this.handlePunchSuccess()
      })
      .catch(error => {
        this.setState({ submitting: false })
      })
  }

  handleModalClose = () => {
    this.setState({ showInstructionModal: false, mountChildComponent: false })
    
    const tasks = this.props.readTasks();

    if (tasks) {
      tasks
        .then(response => {
          this.setState({ submitting: false })
        })
        .catch(() => {});
    }
  }

  confirmInvitation = () => {
    this.props.history.push("/app/profile/teammates")
  }

  render() {
    const { history, task } = this.props
    const job = task.source
    const { submitting, showInstructionModal } = this.state
    let childComponent, earlyWarning
    let btnText = ""
    let teammate_id = task && task.id && task.id.split("_").pop()
    let destination = ""
    let visible = false

    switch (task.type) {
      case "assignment-confirmation":
        visible = true
        btnText = "View Details"
        childComponent = (
          <ConfirmTask
            applicationId={task.target.id}
            handleClose={this.closeSideModal}
            history={history}
          />
        )
        break
      case "check-out":
        visible = true
        btnText = "Check Out"
        childComponent = (
          <CheckoutTask
            onCoordinatesChange={this.handleCoordinatesChange}
            onCoordinatesBlocked={this.handleCoordinatesChange}
            onAcknowledgeGeoBlocked={this.handleCoordinatesChange}
            handleModalClose={this.handleModalClose}
            handleModalContinue={this.handleCoordinatesChange}
          />
        )
        break
      case "check-in":
        visible = true
        btnText = "Check In"
        childComponent = (
          <CheckinTask
            onCoordinatesChange={this.handleCoordinatesChange}
            onCoordinatesBlocked={this.handleCoordinatesChange}
            onAcknowledgeGeoBlocked={this.handleCoordinatesChange}
            handleModalClose={this.handleModalClose}
            handleModalContinue={this.handleCoordinatesChange}
          />
        )
        earlyWarning = this.isEarly()
        break
      case "submit-timesheet":
        visible = true
        btnText = "Complete Timesheet"
        childComponent = (
          <SubmitTimesheetTask
            closeModal={this.closeSideModal}
            timesheetId={task.target.id}
          />
        )
        break
      case "onboarding":
        visible = true
        btnText = "Complete Onboarding"
        destination = "/app/profile/employment"
        break
      case "teammate-invitation":
        visible = true
        btnText = "View Details"
        break
      default:
        break
    }

    const instructionModal = (
      <Modal
        className="lofted"
        id="task-instructions-modal"
        modalClose={this.instructionModalContinue}
      >
        <div className="task-instructions-modal">
          <a className="modal-close" onClick={this.instructionModalContinue}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <div className="modal-header">
            <h3>{this.state.instructionsHeaderText}</h3>
          </div>
          <div className="modal-body">
            {earlyWarning && (
              <InfoMessage
                text={`You're a bit early. Please report to your team lead at ${moment(
                  job.shift.start
                ).format("h:mm A")} for assignment.`}
              />
            )}
            <p
              className="main"
              dangerouslySetInnerHTML={{ __html: task.instructions }}
            />
            <button
              className="btn btn-green continue-button"
              onClick={this.instructionModalContinue}
            >
              <span>Continue</span>
            </button>
          </div>
        </div>
      </Modal>
    )

    return (
      <React.Fragment>
        {visible && (
          <React.Fragment>
            <div className="task-item">
              <div className="task-item-info">
                <h3>{task.title}</h3>
                <p>{task.message}</p>
              </div>

              {!(
                (task.type === "check-in" || task.type === "check-out") &&
                task.onsite_contact
              ) && (
                <div className="task-item-action">
                  {task.type === "onboarding" ? (
                    <Link to={destination} className="btn btn-green">
                      {btnText}
                    </Link>
                  ) : task.type === "teammate-invitation" ? (
                    <Button
                      btnClass="btn-green"
                      text={btnText}
                      onClick={() => {
                        this.confirmInvitation(teammate_id)
                      }}
                    />
                  ) : (
                    <Button
                      btnClass="btn-green"
                      text={btnText}
                      onClick={this.mountTaskComponent}
                      submitting={submitting}
                    />
                  )}
                </div>
              )}
            </div>
            {this.state.mountChildComponent && childComponent}
          </React.Fragment>
        )}

        {showInstructionModal && (
          <div className="task-instructions">{instructionModal}</div>
        )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { confirmInvitation, createPunchTime, readTasks },
      dispatch
    ),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(TaskItem)
