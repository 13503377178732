import React, { Component } from "react"
import MediaQuery from "react-responsive"
import MobileNavbar from "components/App/MobileNavbar"
import DesktopNavbar from "components/App/DesktopNavbar"
import Privacy from "components/Terms/Privacy"

import "./TermsAndPrivacy.scss"

export default class PrivacyRoute extends Component {
  render() {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={1023}>
          <MobileNavbar path={this.props.location.pathname} />
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <DesktopNavbar path={this.props.location.pathname} />
        </MediaQuery>
        <div>
          <Privacy />
        </div>
      </React.Fragment>
    )
  }
}
