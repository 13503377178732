import React, { useState } from "react"
import DropdownMenu from "./DropdownMenu"
import "./HelpDropdown.scss"

const HelpDropdown = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const close = () => setMenuOpen(false);
  const toggle = () => setMenuOpen(isOpen => !isOpen);

  return (
    <DropdownMenu 
      close={close}
      toggle={() => (
        <div className="help-center-icon" onClick={toggle}>
          ?
        </div>
      )}
      className="help-center-menu" 
      isOpen={isMenuOpen}
    >
      <div className="help-center-links" onClick={() => setMenuOpen(false)}>
        <ul>
          <li>
            <a
              href="https://desk.zoho.com/portal/setandserviceresources/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Help Center
            </a>
          </li>
          <li>
            <a
              href="https://desk.zoho.com/portal/setandserviceresources/signin"
              target="_blank"
              rel="noreferrer noopener"
            >
              Support Inbox
            </a>
          </li>
        </ul>
      </div>
    </DropdownMenu>
  );
}

export default HelpDropdown;
