import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import { Redirect } from "react-router-dom"

import { successAlert } from "utils/alerts"
import { handleErrors } from "utils/errors"
import ErrorMessage from "components/ErrorMessage"
import WorkInterestFields from "components/Form/WorkInterestFields"
import WorkInterest from "components/Form/WorkInterest"
import Button from "components/Form/Button"
import { checkWrongStatus } from "utils/associateStatus"
const fields = [
  "previousExperiences",
  "previousEmployers",
  "previousExperienceDescription",
]

const validate = ({
  previousExperiences,
  previousExperienceDescription,
  previousEmployers,
}) => {
  const errors = {}
  if (previousExperiences && previousExperiences.length) {
    const previousExperiencesErrors = []
    previousExperiences.forEach((interest, interestIndex) => {
      const interestErrors = {}
      if (interest.workType !== false) {
        if (!interest || !interest.yearsExperience) {
          interestErrors.yearsExperience = "Please select your experience"
          previousExperiencesErrors[interestIndex] = interestErrors
        }
      }
    })
    if (previousExperiencesErrors.length) {
      errors.previousExperiences = previousExperiencesErrors
    }
  }
  return errors
}

export class WorkInterests extends Component {
  static defaultProps = {
    interestOptions: [],
    experienceOptions: [],
  }

  componentWillUnmount() {
    this.props.actions.clearExperiences()
  }

  componentDidMount() {
    this.props.actions.readList("worktypes").catch(() => {})
    this.props.actions.readList("yearsexperience").catch(() => {})
    this.props.actions.readList("radius").catch(() => {})
    this.props.actions.readExperiences().catch(() => {})
  }

  updateExperiences(params) {
    return this.props.actions
      .updateExperiences(params)
      .then(response => {
        successAlert("Your interests have been updated.")
        this.props.initialize(this.props.initialValues)
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  render() {
    const {
      associate,
      change,
      error,
      experienceOptions,
      handleSubmit,
      initialValues,
      interestOptions,
      pristine,
      submitFailed,
      submitting,
      submitSucceeded,
    } = this.props

    const { isThirdPartyLabor } = this.props.associate

    if (isThirdPartyLabor === "Y") {
      return <Redirect to="/app/assignments" />
    }

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    const previousInterests = initialValues.previousExperiences

    const interestItems =
      previousInterests &&
      interestOptions.map((interest, index) => {
        const foundSetInterest = previousInterests.findIndex(
          i => i.workType.id === interest.id
        )

        const activated = foundSetInterest >= 0

        const computedIndex = activated ? foundSetInterest : 30 - index

        return (
          <WorkInterest
            key={computedIndex}
            onClear={change}
            checked={activated}
            index={computedIndex}
            interest={interest}
            experienceOptions={experienceOptions}
          />
        )
      })

    return (
      <div className="work-interests">
        <h2>Work Interests</h2>
        <p>Select the types of jobs you’re interested in applying to.</p>

        <form onSubmit={handleSubmit(params => this.updateExperiences(params))}>
          {submitFailed && error && <ErrorMessage errorText={error} />}

          {previousInterests && experienceOptions.length > 0 && (
            <WorkInterestFields>{interestItems}</WorkInterestFields>
          )}

          <div className="form-action">
            <Button
              btnClass="btn-green btn-save"
              text="Save"
              disabled={pristine || submitting}
              submitting={submitting}
              succeeded={submitSucceeded}
            />
          </div>
        </form>
      </div>
    )
  }
}

WorkInterests = reduxForm({
  form: "profile-interests",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(WorkInterests)

export default connect(state => ({
  initialValues: state.data.experiences.data,
  associate: state.data.associate.attributes,
}))(WorkInterests)
