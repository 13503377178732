import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import iconSprite from "assets/ui-kit-svg-sprite.svg"
import "./SortIcon.scss"

export default class SortIcon extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    active: PropTypes.bool,
    sortable: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sortable: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      sortDirection: "asc",
    }
  }

  handleClick = () => {
    if (this.props.active) {
      let newDirection = this.state.sortDirection === "asc" ? "desc" : "asc"
      this.props.handleClick(this.props.sortColumn, newDirection)
      this.setState({
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      })
    } else {
      this.props.handleClick(this.props.sortColumn, this.state.sortDirection)
    }
  }

  getRightOffset = () => {
    return this.props.width / 2 - 90
  }

  render() {
    const sortable = this.props.sortable === false ? false : true

    const tooltipStyle = {
      marginLeft: this.getRightOffset() + "px",
    }

    const iconClass = classNames("sort-icon", {
      active: this.props.active,
      disabled: !this.props.sortable,
    })
    const caretClass = classNames("sort-caret", {
      up: this.state.sortDirection === "asc",
    })

    return (
      <div className={iconClass} onClick={this.handleClick}>
        <svg
          className={this.props.name}
          width={this.props.width}
          height={this.props.height}
        >
          <use xlinkHref={`${iconSprite}#svg-icon-${this.props.name}`} />
        </svg>

        {sortable && (
          <svg className={caretClass} width="12" height="6">
            <use xlinkHref={`${iconSprite}#svg-icon-caret-down`} />
          </svg>
        )}

        {this.props.tooltipHeader && (
          <span className="tooltip" style={tooltipStyle}>
            <strong>{this.props.tooltipHeader}</strong>
            <p>{this.props.tooltipBody}</p>
          </span>
        )}
      </div>
    )
  }
}
