import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readPoints } from "actions"

import Points from "components/Profile/Points"

const mapStateToProps = state => {
  return {
    points: state.data.points,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readPoints: bindActionCreators(readPoints, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Points)
