import React from "react"
import CustomSelect from "components/CustomSelect"

const LodgingTypes = ({ value, lodgingTypes, setPreferredLodgingType }) => {
  return (
    <React.Fragment>
      <div className="lodging-types">
        <CustomSelect
          name="lodging.types"
          onChange={e => setPreferredLodgingType(e)}
          options={lodgingTypes}
          isMulti={true}
          value={value}
          labelKey="text"
          valueKey="value"
          placeholder="Select preferred lodging types"
        />
      </div>
    </React.Fragment>
  )
}

export default LodgingTypes
