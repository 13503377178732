import MailSupport from "components/MailSupport"
import { Link } from "react-router-dom"
import ErrorMessage from "components/ErrorMessage"

const EmploymentAlert = ({ associateStatus }) => {

  return (
      associateStatus ===
      "Inactive" ? (
      <ErrorMessage
        key="inactive-denial-reason-error"
        errorText="You are currently inactive, you cannot apply to work."
        secondaryText={[
          "Please, visit the ",
          <Link
            to="/app/profile/account"
            className="redirect-link"
            key="account-redirect-link"
          >
            account settings
          </Link>,
          " page or email our support team at ",
          <MailSupport
            key="support-email-link"
            className="redirect-link"
          />,
          " to resolve the issue.",
        ]}
      />
    ) : (
      <ErrorMessage
        key="prequalified-denial-reason-error"
        errorText={[
          "You are currently prequalified. Please, visit the ",
          <a
            href="https://jobs.sasrlink.com/us/en"
            target="_blank"
            rel="noopener noreferrer"
            className="redirect-link"
          >
            CareerSite
          </a>,
          " to search for jobs, apply, and complete Pre-Employment Steps.",
        ]}
        secondaryText={[
          "If you have any issues, please email our support team at ",
          <MailSupport
            key="support-email-link"
            className="redirect-link"
          />,
          ".",
        ]}
      />
    )
  )
}

export default EmploymentAlert
