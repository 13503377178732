import * as types from "actions/types"
import { apiCall } from "utils/api"
import { readCurrentAssociate } from "./associate"

export function createSession(params) {
  return dispatch => {
    dispatch({ type: types.CREATE_SESSION_REQUEST, params })
    let queryParams = Object.assign({}, params)
    for (const param of Object.keys(queryParams)) {
      if (param === "parsedParams") {
        delete queryParams[param]
      }
    }

    return apiCall(
      "post",
      `associate/login?include=skills,trainings`,
      { data: queryParams },
      false
    )
      .then(response => {
        /*eslint no-throw-literal: "off"*/
        if (!response) throw { name: "Network Error", message: "Offline" }

        localStorage.setItem("sessionToken", response.data.token)
        dispatch({ type: types.CREATE_SESSION_SUCCESS, response })
        return response
      })
      .catch(error => {
        clearLocalStorage()
        const errorMessage = "Invalid Credentials"
        dispatch({
          type: types.CREATE_SESSION_FAILURE,
          errorMessage:
            error && error.name === "Network Error" ? "" : errorMessage,
        })
      })
  }
}

export function deleteSession(message = "Logged out.") {
  return dispatch => {
    clearLocalStorage()
    dispatch({ type: types.DELETE_SESSION_SUCCESS, message: message })
  }
}

export function toggleNetworkErrorModal(state = false) {
  return dispatch => {
    dispatch({ type: types.TOGGLE_NETWORK_ERROR_MODAL, state })
  }
}

function restoreSession() {
  return dispatch => {
    dispatch({ type: types.RESTORE_SESSION_REQUEST })
    dispatch({ type: types.RESTORE_SESSION_SUCCESS })
  }
}

export function restoreSessionAndReadCurrentAssociate() {
  return dispatch => {
    dispatch(restoreSession())
    return dispatch(readCurrentAssociate())
      .then(response => {
        return response
      })
      .catch(error => {
        //document.location.reload()
      })
  }
}

export function clearLocalStorage() {
  localStorage.removeItem("sessionToken")
  localStorage.removeItem("showDrugScreenAlert")
}
