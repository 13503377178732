import React from "react"
import { Field } from "redux-form"
import TextInput from "components/Form/TextInput"
import Icon from "components/Icon"

const Breaks = ({
  handleTimeParsing,
  fields,
  canEdit,
  meta: { error, submitFailed },
}) => (
  <div className="breaks-list">
    {fields.map((b, index) => (
      <div key={index} className="input-line">
        <div className="input-box">
          <Field
            type="text"
            name={`${b}.startTime`}
            component={TextInput}
            onBlur={handleTimeParsing}
            placeholder="Start Time"
            label="Break Start Time"
            disabled={!canEdit}
          />
        </div>
        <div className="input-box">
          <Field
            type="text"
            name={`${b}.endTime`}
            component={TextInput}
            onBlur={handleTimeParsing}
            placeholder="End Time"
            label="Break End Time"
            disabled={!canEdit}
          />
        </div>
        {canEdit && (
          <React.Fragment>
            {index === 0 ? (
              <span className="add-break" onClick={() => fields.push({})}>
                <Icon name="plus" height="16" width="16" />
              </span>
            ) : (
              <span
                className="delete-break"
                onClick={() => fields.remove(index)}
              >
                <Icon name="x" height="14" width="14" />
              </span>
            )}
          </React.Fragment>
        )}
      </div>
    ))}
  </div>
)

export default Breaks
