import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Button from "components/Form/Button"
import Modal from "components/Modal"
import Icon from "components/Icon"

import "./ConfirmApply.scss"

export default class ConfirmAgreementsModal extends Component {
  static propTypes = {
    agreements: PropTypes.string.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    modalClose: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
    }
  }

  confirmAgreements = () => {
    this.setState(
      {
        submitting: true,
      },
      this.props.handleConfirm
    )
  }

  render() {
    const { agreements, modalClose } = this.props

    const { submitting } = this.state

    return (
      <Modal
        className="confirm-agreements-modal lofted"
        modalClose={modalClose}
      >
        <div className="modal-header">
          <a className="close-modal" onClick={!submitting ? modalClose : null}>
            <Icon name="x" />
          </a>
          <h3>Application Agreements</h3>
        </div>
        <div className="modal-body">
          <Fragment>
            <p className="agreements">{agreements}</p>
            <div className="confirm-actions">
              <Button
                btnClass="btn-block btn-apply btn-green"
                text="Accept"
                submitting={submitting}
                onClick={this.confirmAgreements}
              />
              <Button
                btnClass="btn-block btn-cancel"
                text="Cancel"
                disabled={submitting}
                onClick={modalClose}
              />
            </div>
          </Fragment>
        </div>
      </Modal>
    )
  }
}
