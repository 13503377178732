import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { successAlert } from "utils/alerts"
import { declineApplication, getSchedule, readTasks } from "actions"
import Button from "components/Form/Button"

import "./Footer.scss"

class ModalFooter extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    handleClose: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      confirming: false,
      declining: false,
    }
  }

  declineApplication = event => {
    event.preventDefault()
    this.props
      .declineApplication(this.props.application.id)
      .then(response => {
        this.setState({
          declining: false,
        })
        this.props.handleClose()
        successAlert("Job declined.")
        this.props.readTasks()
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            declining: false,
          })
        }, 1000)
      })
  }

  decline = event => {
    this.setState(
      {
        declining: true,
      },
      this.declineApplication(event)
    )
  }

  viewSchedule = event => {
    event.preventDefault()
    this.props.handleClose()
    this.props.history.push("/app/assignments")
  }

  goBack = event => {
    event.preventDefault()
    this.props.handleClose()
  }

  render() {
    const {
      application,
      isAuthenticated,
      confirming,
      handleConfirm,
    } = this.props
    const { declining } = this.state

    // Temporary, since this component is not used in submitting applications yet
    // TODO: Refactor against Job/Detail/Modal Footer
    const applicationSubmitted = false
    const selectedDates = []
    const availableActions = application.availableActions

    let forward = {
      text: "Apply",
      onClick: () => {
        return
      },
      disabled: selectedDates.length === 0,
    }
    let backward = {
      text: "Not Interested",
      onClick: declineApplication,
      disabled: false,
    }

    if (window.screen.width > 768) {
      forward.text = "Apply to this Job"
    }

    if (isAuthenticated) {
      if (applicationSubmitted) {
        forward = { text: "Go Back", onClick: this.goBack, disabled: false }
        backward = {
          text: "View Assignments",
          onClick: this.viewSchedule,
          disabled: false,
        }
      }

      if (availableActions) {
        if (availableActions.applyAsBackup) {
          forward.text = "Apply as Backup"
        } else if (availableActions.canSelfAssign) {
          forward.text = "Self Assign"
        } else if (availableActions.canConfirm) {
          forward = {
            text: "Confirm Job",
            onClick: handleConfirm,
            disabled: confirming || declining,
          }
          backward = {
            text: "Decline Job",
            onClick: this.decline,
            disabled: confirming || declining,
          }
        }
      }
    } else {
      forward.text = "Sign in to Apply"
      backward.text = "View Other Jobs"
    }

    return (
      <React.Fragment>
        <div className="application-modal-footer">
          <Button
            btnClass="btn-green btn-apply btn-block"
            submitting={confirming}
            {...forward}
          />
          <Button
            btnClass="btn-cancel btn-block"
            submitting={declining}
            {...backward}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.session.isAuthenticated === true,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        declineApplication,
        getSchedule,
        readTasks,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFooter)
