import * as types from "./types"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"
import { serializeEmploymentInfo } from "serializers/employment"
import { errorAlert } from 'utils/alerts';
import { Link } from "react-router-dom";

const showAddressError = (response) => {
  if (response?.data?.associateAddressError) {
    errorAlert(<>
      <div>Your address is needed to complete "Work Disclosures" step.</div>
      <div>
        Please update your address in the <Link to="/app/profile/personal">personal info</Link> section 
        of your dashboard.
      </div>
    </>);
  }
};

export function readEmploymentInfo() {
  return dispatch => {
    dispatch({ type: types.READ_EMPLOYMENT_INFO_REQUEST })
    return apiCall("get", `associate/${currentAssociateId()}/employment/`)
      .then(response => {
        dispatch({ type: types.READ_EMPLOYMENT_INFO_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_EMPLOYMENT_INFO_FAILURE, error })
        throw error
      })
  }
}

export function updateEmploymentInfo(params, type) {
  params = serializeEmploymentInfo(params, type)
  return dispatch => {
    dispatch({ type: types.UPDATE_EMPLOYMENT_INFO_REQUEST })
    return apiCall("patch", `associate/${currentAssociateId()}/employment/`, {
      data: params,
    })
      .then(response => {
        dispatch({ type: types.UPDATE_EMPLOYMENT_INFO_SUCCESS, response })
        return response
      })
      .catch(error => {
        showAddressError(error);
        dispatch({ type: types.UPDATE_EMPLOYMENT_INFO_FAILURE, error })
        throw error
      })
  }
}

export function markEmploymentPending(type) {
  return dispatch => {
    dispatch({ type: types.MARK_EMPLOYMENT_PENDING_REQUEST })
    return apiCall("patch", `associate/${currentAssociateId()}/employment/`, {
      data: [{ type: type }],
    })
      .then(response => {
        dispatch({ type: types.MARK_EMPLOYMENT_PENDING_SUCCESS, response })
        return response
      })
      .catch(error => {
        showAddressError(error);
        dispatch({ type: types.MARK_EMPLOYMENT_PENDING_FAILURE })
        throw error
      })
  }
}

export function markEmploymentComplete(type) {
  return dispatch => {
    dispatch({ type: types.MARK_EMPLOYMENT_COMPLETE_REQUEST })
    return apiCall("patch", `associate/${currentAssociateId()}/employment/`, {
      data: [{ type: type }],
    })
      .then(response => {
        dispatch({ type: types.MARK_EMPLOYMENT_COMPLETE_SUCCESS, response })
        return response
      })
      .catch(error => {
        showAddressError(error);
        dispatch({ type: types.MARK_EMPLOYMENT_COMPLETE_FAILURE })
        throw error
      })
  }
}
