import React, { Component } from "react"

import Icon from "components/Icon"

export default class EmploymentInfoItem extends Component {
  statusIcon = status => {
    let icon = "info"
    if (status === "complete") {
      icon = "tick"
    } else if (status === "incomplete") {
      icon = "alert"
    }
    return <Icon name={icon} height="24" width="24" />
  }

  statusLabel = status => {
    let klass = status ? `status-label ${status.toLowerCase()}` : "status-label"
    if (status === "pending") {
      status = "In-Progress"
    }
    return <span className={klass}>{status}</span>
  }

  render() {
    const { externalSystem, name, onClick, status } = this.props
    return (
      <li>
        <a onClick={onClick}>
          {this.statusIcon(status)}
          <h4>
            {name}
            {externalSystem && <Icon name="external" width="15" height="15" />}
          </h4>
          {this.statusLabel(status)}
        </a>
      </li>
    )
  }
}
