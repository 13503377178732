const ASSOCIATE_ID_FIELD_ID = "496634"

function prefillForm(associateName, associateEmail, associateId) {
  if (window.zE) {
    let fields = []
    if (associateId !== undefined) {
      fields.push({ id: ASSOCIATE_ID_FIELD_ID, prefill: { "*": associateId } })
    }

    window.zESettings = {
      webWidget: {
        contactForm: {
          fields: fields,
          tags: ["associate-portal"],
        },
      },
    }

    window.zE("webWidget:on", "close", function() {
      window.zE("webWidget", "updateSettings", {
        webWidget: {
          helpCenter: {
            suppress: false,
          },
          talk: {
            suppress: false,
          },
        },
      })
    })

    if (associateName !== undefined || associateEmail !== undefined) {
      window.zE(function() {
        window.zE.identify({
          name: associateName,
          email: associateEmail,
        })
      })
    }
  }
  return true
}

function openWebWidget() {
  if (window.zE) {
    window.zE("webWidget", "updateSettings", {
      webWidget: {
        helpCenter: {
          suppress: true,
        },
        talk: {
          suppress: true,
        },
      },
    })
    window.zE("webWidget", "open")
  }
}

function setPageTags(url) {
  if (window.zE) {
    console.debug(`zendesk suggestions set to: ${url}`)
    window.zE("webWidget", "helpCenter:setSuggestions", { labels: [url] })
  }
}

const Zendesk = { 
  prefillForm, 
  openWebWidget, 
  setPageTags 
};

export default Zendesk;