export function deserializeSchedule(schedule) {
  // grouping by date
  return schedule.reduce((schedule, day) => {
    const index = schedule.findIndex(s => s.date === day.date)

    if (index > -1) {
      schedule[index].slots.push(day)
    } else {
      schedule.push({
        date: day.date,
        slots: [day],
      })
    }
    return schedule
  }, [])
}
