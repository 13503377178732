import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import TimesheetTable from "containers/Timesheets/Table"

export default class TimesheetRoute extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/:id?`}
          component={TimesheetTable}
        />
      </Switch>
    )
  }
}
