const normalizeSsn = (value, previousValue) => {
  if (!value) {
    return ""
  }

  const onlyNums = value.replace(/[^\d]/g, "")

  // typing forward
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 3) {
      return onlyNums + "-"
    }

    if (onlyNums.length === 5) {
      return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3) + "-"
    }

    if (onlyNums.length <= 3) {
      return onlyNums
    }

    if (onlyNums.length <= 5) {
      return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3)
    }
  }

  return onlyNums
}

export default normalizeSsn
