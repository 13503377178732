import React from "react"
import ExpandableSection from "components/ExpandableSection"
import NoFilesFound from "./NoFilesFound"
import FileList from "./FileList"
import "./Item.scss"

const StateDisclosuresItem = ({ name, initials, files }) => {
  const title = `${initials.toUpperCase()}, ${name}`
  let content = <NoFilesFound />

  if (files && files.length > 0) {
    content = <FileList files={files} state={initials} />
  }

  return (
    <div className="disclosures-item">
      <ExpandableSection title={title} content={content} />
    </div>
  )
}

export default StateDisclosuresItem
