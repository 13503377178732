import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { markEmploymentPending } from "actions"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"
import "./ExternalModal.scss"

class Eligibility extends Component {
  linkOpened = () => {
    this.props.markEmploymentPending("eligibility").catch(() => {})
  }

  render() {
    const { closeModal, eligibility, status } = this.props
    return (
      <Modal className="lofted modal external-link" modalClose={closeModal}>
        <div className="modal-header">
          <a className="modal-close" onClick={closeModal}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <h3>I-9 Federal Employment Eligibility</h3>
        </div>
        <div className="modal-body">
          {status === "incomplete" && (
            <React.Fragment>
              <p>Check your email for a PIN from I-9 Advantage</p>
              <p>
                If you have questions, email us at&nbsp;
                <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </a>.
              </p>
            </React.Fragment>
          )}

          {status === "complete" && (
            <p>
              Thank you for completing the Federal I-9 form verifying your
              employment eligibility. If you’d like to access these onboarding
              documents, click the button below to access your account on I-9
              Advantage.
            </p>
          )}
          {status === "pending" && (
            <React.Fragment>
              <p>
                Check your email for an invitation to fill out an I9 Eligibility
                Form.
              </p>
              <p>
                If you have questions, email us at
                {process.env.REACT_APP_SUPPORT_EMAIL}.
              </p>
            </React.Fragment>
          )}
        </div>
        <div className="modal-footer">
          {eligibility && eligibility.link && (
            <a
              className="btn btn-green btn-apply"
              href={eligibility.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.linkOpened}
            >
              Open I-9 Advantage System
            </a>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    eligibility: state.data.employment.attributes.eligibility,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        markEmploymentPending,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Eligibility)
