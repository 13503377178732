import React, { Component, Fragment } from "react"
import Button from "components/Form/Button"
import moment from "moment"
import { teammatesInvite, cancelInvitation } from "actions/teammates"
import { connect } from "react-redux"
import { SubmissionError } from "redux-form"
import { bindActionCreators } from "redux"
import TeammatesInviteForm from "components/Profile/Teammates/TeammatesInviteForm"
import { successAlert, errorAlert } from "utils/alerts"
import _ from "lodash"

class TeammatesInvite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      submitSucceeded: false,
    }
  }

  cancelInvitation = id => {
    this.setState({ isLoading: true })

    return this.props
      .cancelInvitation(id)
      .then(() => {
        successAlert("Invitation was cancelled successfully.")
        return this.props.listTeammates().then(() => {
          this.setState({ isLoading: false })
        })
      })
      .catch(error => {
        if (error) {
          this.showError(error)
        }
        this.setState({ isLoading: false })
      })
  }

  teammatesInvite = params => {
    this.setState({ isLoading: true })

    return this.props
      .teammatesInvite(params)
      .then(() => {
        successAlert("Invitation was sent successfully.")
        return this.props.listTeammates().then(() => {
          this.setState({ isLoading: false, submitSucceeded: true })
        })
      })
      .catch(error => {
        if (error && error.data && error.data.error) {
          const errorMessage = error.data.error
          const fieldError = _.set({}, "emailAddresses", errorMessage)

          throw new SubmissionError({
            ...fieldError,
            _error: errorMessage,
          })
        }
        this.setState({ isLoading: false, submitSucceeded: false })
      })
  }

  showError = error => {
    if (error.data && error.data.error) {
      errorAlert(error.data.error)
    }
  }

  render() {
    const { invitations } = this.props
    const { isLoading, submitSucceeded } = this.state

    return (
      <Fragment>
        <div className="invite-wrapper">
          <h3>Invite New Teammate</h3>
          <p>
            Know someone you want to work with? Invite your preferred teammates
            so you can request them when you apply to jobs. You may invite more
            than one.
          </p>
          <TeammatesInviteForm
            onSubmit={this.teammatesInvite}
            submitting={isLoading}
            submitSucceeded={submitSucceeded}
          />
        </div>
        <ul className="pending-list">
          {invitations &&
            invitations.map((invite, index) => (
              <li key={index} className="team-item">
                <div className="teammate-item-container">
                  <div className="pending-info">
                    <div className="invite-date">
                      <div className="invite-month">
                        {moment(invite.createdAt, "YYYY-MM-DD").format("MMM")}
                      </div>
                      <div className="invite-day">
                        {moment(invite.createdAt, "YYYY-MM-DD").format("DD")}
                      </div>
                    </div>
                    <span className="teammate-email">{invite.email}</span>
                  </div>

                  <div className="controls">
                    <span className="teammate-status">Pending</span>
                    <Button
                      btnClass="btn btn-edit btn-save"
                      text="Remove"
                      onClick={() => this.cancelInvitation(invite.id)}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        teammatesInvite,
        cancelInvitation,
      },
      dispatch
    ),
  }
}

export default connect(null, mapDispatchToProps)(TeammatesInvite)
