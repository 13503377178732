import React from "react"
import Modal from "components/Modal"
import Button from "components/Form/Button"
import SvgIcon from "components/SvgIcon"
import "./Geolocation.scss"

const GeolocationBlocked = props => {
  function closeModal() {
    props.closeModal()
    if (props.handleModalClose) {
      props.handleModalClose()
    }
  }

  function continueModal() {
    props.closeModal()
    if (props.handleModalContinue) {
      props.handleModalContinue()
    }
  }

  return (
    <Modal className="lofted" id="gelocation-modal" modalClose={closeModal}>
      <div className="geolocation-modal">
        <a className="modal-close" onClick={closeModal}>
          <SvgIcon title="close" name="close-gray" />
        </a>
        <div className="modal-header">We Still Need Your Location</div>
        <div className="modal-body">
          <p className="main">
            You’ve blocked location access, but we still need your location to
            process payroll and for checking in.
          </p>
          <Button
            btnClass="btn-green continue-button"
            text="Continue"
            onClick={continueModal}
          />
          <p className="muted">
            You'll earn 5 points for allowing location access.
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default GeolocationBlocked
