import moment from "moment"
import * as chrono from "chrono-node"

const timeFormat = "h:mm A";

const validate = ({
  startTime,
  endTime,
  breakAcknowledgement,
  breaks,
  supervisorName,
}) => {
  //const { touch } = props;

  const errors = {};
  const containsNumber = /\d+/;

  if (chrono.parse(startTime).length < 1) {
    errors.startTime = "Must be a valid time";
    //touch(`startTime`);
  }

  if (chrono.parse(endTime).length < 1) {
    errors.endTime = "Must be a valid time";
    //touch(`endTime`);
  }

  if (breaks && breaks.length) {
    const breaksArrayErrors = [];

    breaks.forEach((b, breakIndex) => {
      const breakErrors = {};

      if ((b.startTime || b.endTime) && chrono.parse(b.startTime).length < 1) {
        breakErrors.startTime = "Must be a valid time";
        breaksArrayErrors[breakIndex] = breakErrors;
        //touch(`breaks[${breakIndex}].startTime`);
      }
      
      if ((b.startTime || b.endTime) && chrono.parse(b.endTime).length < 1) {
        breakErrors.endTime = "Must be a valid time";
        breaksArrayErrors[breakIndex] = breakErrors;
        //touch(`breaks[${breakIndex}].endTime`);
      }

      let mBreakStart = moment(b.startTime, timeFormat);
      let mBreakEnd = moment(b.endTime, timeFormat);
      let mStartTime = moment(startTime, timeFormat);
      let mEndTime = moment(endTime, timeFormat);

      if (mStartTime.isBefore(mEndTime)) {
        if (mBreakStart.isBefore(mStartTime) || mStartTime.isSame(mBreakStart, 'time')) {
          breakErrors.startTime = "Must be after shift start";
          breaksArrayErrors[breakIndex] = breakErrors;
          //touch(`breaks[${breakIndex}].startTime`);
        }

        if (mBreakStart.isAfter(mEndTime)) {
          breakErrors.startTime = 'Must be before shift end';
          breaksArrayErrors[breakIndex] = breakErrors;
          //touch(`breaks[${breakIndex}].startTime`);
        }
        
        if (mBreakEnd.isAfter(mEndTime) || mEndTime.isBefore(mBreakEnd)) {
          breakErrors.endTime = 'Must be before shift end';
          breaksArrayErrors[breakIndex] = breakErrors;
          //touch(`breaks[${breakIndex}].endTime`);
        }
      } else {
        if ((mBreakStart.isBefore(mStartTime) && !mBreakStart.isBefore(mEndTime)) || mStartTime.isSame(mBreakStart, 'time')) {
          breakErrors.startTime = "Must be after shift start";
          breaksArrayErrors[breakIndex] = breakErrors;
          //touch(`breaks[${breakIndex}].startTime`);
        }

        if (mBreakEnd.isAfter(mEndTime) && mBreakEnd.isBefore(mStartTime)) {
          breakErrors.endTime = 'Must be before shift end';
          breaksArrayErrors[breakIndex] = breakErrors;
          //touch(`breaks[${breakIndex}].endTime`);
        }
      }

      if (mBreakEnd.isBefore(mBreakStart)) {
        breakErrors.endTime = "Must be after break start"
        breaksArrayErrors[breakIndex] = breakErrors;
        //touch(`breaks[${breakIndex}].endTime`);
      }
    })

    if (breaksArrayErrors.length) {
      errors.breaks = breaksArrayErrors
    }
  }

  if (!supervisorName) {
    errors.supervisorName = 'This field is required';
  }

  if (supervisorName) {
    if (containsNumber.test(supervisorName)) {
      errors.supervisorName = "Field should not contain numbers"
    }

    let firstName, lastName;

    const splitedNames = supervisorName.trim().split(' ');

    if (splitedNames.length === 2) {
      [firstName, lastName] = splitedNames;
    }
    
    if (splitedNames.length >= 3) {
      [firstName] = splitedNames;
      [lastName] = splitedNames.reverse();
    }

    if (!firstName) {
      errors.supervisorName = 'First Name should be at least one character long';
    }

    if (!lastName || (lastName && lastName.length < 2)) {
      errors.supervisorName = 'Last Name should be at least two characters long'
    }
  }

  return errors
}

export default validate
