export const getManagerNotes = ({ teammatesNames = [], types = [] }) => {
  const hasTypes = types && types.length > 0
  const hasTeammatesNames = teammatesNames && teammatesNames.length > 0

  if (hasTeammatesNames && hasTypes) {
    return `Associate has opted for lodging and has indicated they do have a roommate and lodging type preferences. The preferred lodging types is ${types?.join(", ")}. The preferred roommates they have requested is ${teammatesNames?.join(", ")}.`
  }

  if (hasTeammatesNames) {
    return `Associate has opted for lodging and has indicated they do have a roommate preference. The preferred roommates they have requested is ${teammatesNames?.join(", ")}.`
  }

  if (hasTypes) {
    return `Associate has opted for lodging and has indicated they do have a lodging type preference. The preferred lodging types is ${types?.join(", ")}.`
  }

  return `Associate has opted for lodging.`
}