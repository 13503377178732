import axios from "axios"
import { deleteSession, toggleNetworkErrorModal } from "actions/session"
import store from "store"
import { errorAlert } from "utils/alerts"
import { fetchFromObject } from "utils/object"
import { USER_STATUS_NOT_ALLOW_MESSAGE } from "consts"

export const API_URL = process.env.REACT_APP_API_URL

const isNetworkError = err => {
  return !!err.isAxiosError && !err.response;
}

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (isNetworkError(error)) {
      store.dispatch(toggleNetworkErrorModal(true))
      return Promise.reject();
    }

    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(
          deleteSession("Session expired. Please log back in to continue.")
        )
      }
    }
    return Promise.reject(error)
  }
)

export function apiHeaders(authenticated = true) {
  let headers = { "Content-Type": "application/json" }
  if (authenticated) {
    let jwt = localStorage.getItem("sessionToken")
    if (jwt !== undefined) {
      headers["Authorization"] = `Bearer ${jwt}`
    }
  }
  return headers
}

export function apiCall(
  method,
  path,
  options = {},
  authenticated = true,
  cancelToken = null,
  errorRef = null,
  errorOptions = {}
) {
  function displayApiError(status = "UNK", errorInfo = undefined) {
    const { skip404 = false, skip500 = false } = errorOptions;
    if (status === 403) return;
    if (status === 404 && skip404) return;
    if (status === 500 && skip500) return;

    if (errorInfo && errorInfo.code === 512) {
      errorAlert(`${errorInfo.message}`);
    } else {
      errorAlert(`Server Error #${status}.`)
    }
  }

  return axios
    .request({
      method: method,
      url: `${API_URL}/${path}`,
      headers: apiHeaders(authenticated),
      params: options.params,
      data: JSON.stringify(options.data),
      cancelToken: cancelToken,
      errorRef: errorRef,
    })
    .catch(error => {
      if (error?.response) {
        if (error.response.status === 403 && error.response.data === USER_STATUS_NOT_ALLOW_MESSAGE) {
          return Promise.reject(error.response);
        }
        if (error.response.status !== 401 && error.response.status !== 400) {
          if (errorRef) {
            let errMessage = errorRef.property
              ? fetchFromObject(error.response, errorRef.property)
              : errorRef.message
            if (error.response.data && error.response.data.error) {
              errMessage = error.response.data.error
            }
            if (errMessage) {
              errorAlert(errMessage)
            } else {
              displayApiError(error.response.status)
            }
          } else {
            displayApiError(error.response.status, error.response.data)
          }
        }
      } else if (error?.request) {
        displayApiError("NOR")
      } else if (error?.message !== "CANCEL") {
        displayApiError("GEN")
      } else if (error?.message === "CANCEL") {
        return
      } else {
        displayApiError()
      }

      return Promise.reject(error?.response)
    })
}