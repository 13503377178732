import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  data: [],
}

export default function tasks(state = defaultState, action) {
  switch (action.type) {
    case types.READ_TASKS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.response?.data || [],
      }
    case types.READ_TASKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
