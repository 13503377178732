import React from "react"
import PropTypes from "prop-types"
import Icon from "components/Icon"

import "./InfoMessage.scss"

const InfoMessage = ({ text }) => (
  <div className="info-message">
    <Icon name="info" height="24" width="24" />
    <p>{text}</p>
  </div>
)

InfoMessage.propTypes = {
  text: PropTypes.string.isRequired,
}

export default InfoMessage
