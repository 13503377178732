import React, { Component } from "react"
import { connect } from "react-redux"
import "./TalentAgentInfo.scss"

class TalentAgentInfo extends Component {
  render() {
    const info =
      this.props.associate && this.props.associate.attributes.talentAgentInfo

    return (
      <div>
        {info && (
          <div className="talentAgentInfo">
            <h5 className="talentAgentInfo__title">Your Talent Agent</h5>
            <div className="talentAgentInfo__data">
              <p className="talentAgentInfo__data--item name">{info.name}</p>
              <p className="talentAgentInfo__data--item">{info.email}</p>
              <p className="talentAgentInfo__data--item">{info.phone}</p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate,
  }
}

export default connect(mapStateToProps)(TalentAgentInfo)
