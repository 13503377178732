import React from "react";
import SVG from 'react-inlinesvg';

const SvgIcon = ({ name, title, onClick, className = '' }) => {
  return (
    <SVG
      onClick={onClick}
      cacheRequests={true}
      className={`svg-icon ${className}`.trim()}
      src={require(`assets/${name}.svg`).default}
      uniqueHash="dJ8E)srF$dA"
      title={title || name}
      uniquifyIDs={true}
    />
  );
};

export default SvgIcon;
