import * as types from "actions/types"
import { deserializeEmploymentInfo } from "serializers/employment"

const defaultState = {
  steps: [],
  attributes: {},
}

export default function employment(state = defaultState, action) {
  switch (action.type) {
    case types.READ_EMPLOYMENT_INFO_REQUEST:
      return {
        ...state,
      }
    case types.READ_EMPLOYMENT_INFO_SUCCESS:
      return {
        ...state,
        ...(action.response?.data || {}),
        attributes: deserializeEmploymentInfo(action.response?.data || {}),
      }
    case types.READ_EMPLOYMENT_INFO_FAILURE:
      return {
        ...state,
      }
    case types.UPDATE_EMPLOYMENT_INFO_REQUEST:
      return {
        ...state,
      }
    case types.UPDATE_EMPLOYMENT_INFO_SUCCESS:
      return {
        ...state,
        ...(action.response?.data || {}),
        attributes: deserializeEmploymentInfo(action.response?.data || {}),
      }
    case types.UPDATE_EMPLOYMENT_INFO_FAILURE:
      return {
        ...state,
      }
    case types.MARK_EMPLOYMENT_PENDING_SUCCESS:
      return {
        ...state,
        ...(action.response?.data || {}),
        attributes: deserializeEmploymentInfo(action.response?.data || {}),
      }
    case types.MARK_EMPLOYMENT_COMPLETE_SUCCESS:
      return {
        ...state,
        ...(action.response?.data || {}),
        attributes: deserializeEmploymentInfo(action.response?.data || {}),
      }
    default:
      return state
  }
}
