import React, { Component } from "react"
import moment from "moment"
import isEmpty from "lodash/isEmpty"
import * as chrono from "chrono-node"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"

import Geolocation from "components/Geolocation"
import TimesheetFields from "./Fields"
import TimesheetReview from "./Review"
import TimesheetSignature from "./Signature"

import "./Form.scss"

export const fields = [
  "startTime",
  "endTime",
  "breaks",
  "breakAcknowledgement",
  "supervisorName",
  "supervisorContact",
  "expenses",
  "mileage",
  "signature",
]

const timeFormat = "h:mm A"

export default class TimesheetForm extends Component {
  static propTypes = {
    associate: PropTypes.object.isRequired,
    timesheet: PropTypes.object,
    submitTimesheet: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    error: PropTypes.array,
  }

  static defaultProps = {
    timesheet: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      areYouSure: false,
      completed: false,
      isLoading: false,
      active: false,
      geoCoordinates: {},
      step: 1,
      reviewed: false,
      signed: false,
      showReviewModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.timesheet.status) {
      if (
        prevProps.timesheet.status.editable !==
        this.props.timesheet.status.editable
      ) {
        const currentStep = this.props.timesheet.status.editable ? 1 : 2
        this.setState({ step: currentStep })
      }
    }
  }

  calculateTotalTime = (returnType = 'string') => {
    const pristine = this.props.pristine

    if (isEmpty(this.props.formValues)) return

    if (pristine) {
      const duration = moment.duration(this.props.timesheet.hours, "hours")
      const hours = Math.floor(duration.asHours())
      // needs to be rounded due to rounding on the server
      const minutes = Math.round(
        (duration.minutes() * 60 + duration.seconds()) / 60
      );

      return returnType === 'number' ?
        (hours * 60 + minutes) :
        `${hours} hours ${minutes} minutes`;
    }

    const startTime = this.props.formValues.startTime
    const endTime = this.props.formValues.endTime

    if (startTime && endTime) {
      const momentStartTime = moment(startTime, "h:mm A")
      const momentEndTime = moment(endTime, "h:mm A")
      const breaks = this.props.formValues.breaks

      let workedTime = momentEndTime.diff(momentStartTime, "minutes")

      let breakTime = 0
      if (breaks && breaks.length) {
        let nonEmptyBreaks = breaks.filter(b => b.startTime && b.endTime)

        if (nonEmptyBreaks.length) {
          breakTime = nonEmptyBreaks
            .map(br =>
              moment(br.endTime, "h:mm A").diff(
                moment(br.startTime, "h:mm A"),
                "minutes"
              )
            )
            .reduce((a, b) => a + b)
        }
      }
      let total = moment()
        .hour(0)
        .minute(workedTime - breakTime);

      return returnType === 'number' ? 
        Math.abs(workedTime - breakTime) :
        total.format("H [hours] m [minutes]");
    }
  }

  handleTimeParsing = (event, newValue, prevValue, name) => {
    event.preventDefault()
    let newTime
    const results = chrono.parse(newValue)
    if (results.length) {
      newTime = moment(results[0].start.date()).format(timeFormat)
    } else {
      newTime = prevValue
    }

    this.props.change(name, newTime)
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    })
  }

  previousStep = () => {
    this.setState({
      step: this.state.step - 1,
    })
  }

  goToStep = step => {
    this.setState({
      step: step,
    })
  }

  render() {
    const {
      associate,
      timesheet,
      submitTimesheet,
      handleCoordinates,
      error,
    } = this.props
    const { step } = this.state
    const takeSignature =
      timesheet.signatureRequested && window.innerWidth < 1024

    return (
      <React.Fragment>
        {step === 1 && (
          <TimesheetFields
            onSubmit={this.nextStep}
            timesheet={timesheet}
            calculateTotalTime={this.calculateTotalTime}
            handleTimeParsing={this.handleTimeParsing}
          />
        )}
        {step === 2 && (
          <TimesheetReview
            previousStep={this.previousStep}
            nextStep={this.nextStep}
            onSubmit={submitTimesheet}
            timesheet={timesheet}
            calculateTotalTime={this.calculateTotalTime}
            error={error}
          />
        )}
        {step === 3 && takeSignature && (
          <React.Fragment>
            <MediaQuery maxWidth={1023}>
              <Geolocation
                onSuccess={handleCoordinates}
                onFailure={handleCoordinates}
                onAcknowledgeGeoBlocked={null}
              />
            </MediaQuery>
            <TimesheetSignature
              associate={associate}
              previousStep={this.previousStep}
              onSubmit={submitTimesheet}
              timesheet={timesheet}
              totalTime={this.calculateTotalTime()}
              error={error}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
