import * as types from "actions/types"
import _ from "lodash"
import update from "immutability-helper"

const defaultState = {
  isLoading: false,
  isSearching: true,
  axiosSources: [],
  contents: [],
}

export default function jobs(state = defaultState, action) {
  switch (action.type) {
    case types.READ_JOB_REQUEST:
      cancelExistingRequests(state)
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_JOB_SUCCESS:
      let index = _.findIndex(state.contents, { id: action.response.data.id })
      if (index >= 0) {
        // the job is in the array
        //console.log("replacing existing job")
        action.response.data["detail"] = true
        let newContents = updateJob(state.contents, action)
        state = update(state, {
          contents: { $set: newContents },
          isLoading: { $set: false },
        })
      } else {
        // not there so we should push it on
        //console.log("pushing onto state")
        state = update(state, {
          contents: { $push: [action.response.data] },
          isLoading: { $set: false },
        })
      }
      return state
    case types.REMOVE_OPEN_JOB:
      let jobs = state.contents
      let jobIndex = _.findIndex(jobs, { id: action.jobId })

      if (jobIndex < 0) return state

      const job = state.contents[jobIndex]

      const updatedJob = {
        ...job,
        applied: true,
      }

      return {
        ...state,
        contents: [
          ...jobs.slice(0, jobIndex),
          updatedJob,
          ...jobs.slice(jobIndex + 1),
        ],
      }
    case types.READ_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.SEARCH_JOBS_REQUEST:
      cancelExistingRequests(state)
      return {
        ...state,
        isSearching: true,
        contents: [],
        axiosSources: [...state.axiosSources, action.axiosSource],
      }
    case types.SEARCH_JOBS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        contents: action.response.data,
        axiosSources: [],
      }
    case types.SEARCH_JOBS_FAILURE:
      return {
        ...state,
        axiosSource: [],
      }
    case types.CONFIRM_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.CONFIRM_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case types.CONFIRM_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.DECLINE_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.DECLINE_JOB_SUCCESS:
      return {
        ...state,
        contents: state.contents.filter(
          job => job.id !== action.response.data.id
        ),
        isLoading: false,
      }
    case types.DECLINE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CLEAR_JOBS:
      return {
        ...state,
        contents: [],
      }
    default:
      return state
  }
}

function cancelExistingRequests(state) {
  if (state.axiosSources.length > 0) {
    state.axiosSources.forEach(source => {
      source.cancel("CANCEL")
    })
  }
}

function updateJob(jobsArray, action) {
  return jobsArray.map((job, index) => {
    if (job.id !== action.response.data.id) {
      return job
    }
    return {
      ...job,
      ...action.response.data,
    }
  })
}
