import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCurrentAssociate } from "actions"
import PersonalInfo from "components/Profile/PersonalInfo"

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentAssociate: bindActionCreators(
      updateCurrentAssociate,
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfo)
