import React from "react"
import PropTypes from "prop-types"

import TaskItem from "./Item"
import "./List.scss"

const TasksList = ({ tasks, history }) => (
  <section className="tasks-list">
    {tasks &&
      tasks.map(task => {
        return (
          <TaskItem
            history={history}
            key={task.target ? task.target.id : task.id}
            task={task}
          />
        )
      })}
  </section>
)

TasksList.propTypes = {
  tasks: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
}

export default TasksList
