import MailSupport from "components/MailSupport"

const APP_SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export function formatCurrency(number, cents = true) {
  const decimals = cents ? 2 : 0
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
  })
}

export function formatPercent(number) {
  return `${Math.round(number * 100)}%`
}

export function formatPhoneNumber(string) {
  var s2 = ("" + string).replace(/\D/g, "")
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3]
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function formatHours(hours) {
  // round to two places if necessary
  return hours ? +hours.toFixed(2) : 0
}

export const replaceSupportEmail = (message) => {
  const newMessage = [];
  const parts = message.split(' ');
  const space = index => index !== 0 ? ' ' : '';

  parts.forEach((part, index) => {
    newMessage.push(<>{space(index)}
      {part.match(new RegExp(APP_SUPPORT_EMAIL)) ? <MailSupport
        className="redirect-link"
      /> : <>{part}</>}
    </>);
  });

  return newMessage;
};
