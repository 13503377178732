import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Icon from "components/Icon"
import "./Pagination.scss"

const PaginationNext = ({ handleNextPage, disabled }) => (
  <div
    onClick={handleNextPage}
    className={classNames("pagination-next", { disabled: disabled })}
  >
    <Icon name="caret-right" height="20" width="8" />
  </div>
)

PaginationNext.propTypes = {
  handleNextPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default PaginationNext
