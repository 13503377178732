import moment from "moment"

const parseDob = value => {
  if (value.length === 10) {
    const date = new Date(value)

    return moment(date, "MM-DD-YYYY").format("YYYY-MM-DD")
  }

  return value
}

export default parseDob
