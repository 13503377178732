import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Button from "components/Form/Button"
import Modal from "components/Modal"
import Icon from "components/Icon"

import "./ConfirmRemove.scss"

export default class ConfirmRemove extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleDecline: PropTypes.func.isRequired,
    isDeclining: PropTypes.bool.isRequired,
    modalClose: PropTypes.func,
  }

  render() {
    const {
      handleConfirm,
      modalClose,
      teammateId,
      teammateFullName,
      submitting,
    } = this.props

    return (
      <Modal className="confirm-apply-modal lofted" modalClose={modalClose}>
        <div className="modal-header">
          <a className="close" onClick={modalClose}>
            <Icon name="x" />
          </a>
        </div>
        <div className="modal-body">
          <Fragment>
            <p>
              Are you sure you want to remove {teammateFullName} from your
              Teammates? This action cannot be undone.
            </p>
            <div className="confirm-actions">
              <Button
                btnClass="btn-block btn-apply btn-green"
                text="Remove"
                onClick={() => handleConfirm(teammateId)}
                submitting={submitting}
              />
              <Button
                btnClass="btn-block btn-cancel"
                text="Cancel"
                onClick={modalClose}
              />
            </div>
          </Fragment>
        </div>
      </Modal>
    )
  }
}
