import * as types from "./types"
import { apiCall } from "utils/api"
import { serializeTimesheet } from "serializers/timesheets"
import { successAlert, errorAlert } from "utils/alerts"

export function listTimesheets(params, sortBy) {
  if (sortBy.length > 0) {
    const column = sortBy[0].id
    const direction = sortBy[0].desc ? "desc" : "asc"

    params[`sort[${column}]`] = direction
  }

  return dispatch => {
    dispatch({ type: types.LIST_TIMESHEETS_REQUEST })
    return apiCall("get", `associate/timesheets`, { params: params })
      .then(response => {
        dispatch({ type: types.LIST_TIMESHEETS_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.LIST_TIMESHEETS_FAILURE, error })
        throw error
      })
  }
}

export function readTimesheet(timesheetId) {
  return dispatch => {
    dispatch({ type: types.READ_TIMESHEET_REQUEST, timesheetId })
    return apiCall("get", `associate/timesheets/${timesheetId}`)
      .then(response => {
        dispatch({ type: types.READ_TIMESHEET_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_TIMESHEET_FAILURE, error })
        throw error
      })
  }
}

export function giveFeedback(params, timesheet) {
  return dispatch => {
    return apiCall("put", `associate/timesheets/${timesheet.id}/feedback`, {
      data: params,
    }).then(() => {
      const response = {
        data: { ...timesheet, feedback: params },
      }
      dispatch({ type: types.READ_TIMESHEET_SUCCESS, response })
      return response
    })
  }
}

export function clearTimesheet() {
  return dispatch => {
    dispatch({ type: types.CLEAR_TIMESHEET })
  }
}

export function createTimesheet(
  timesheetId,
  params,
  timesheetDate,
  geoCoordinates
) {
  const serializedParams = serializeTimesheet(
    timesheetId,
    params,
    timesheetDate,
    geoCoordinates
  )

  return dispatch => {
    dispatch({ type: types.CREATE_TIMESHEET_REQUEST })
    return apiCall("post", `associate/timesheets`, { data: serializedParams }, true, null, null, { skip404: true })
      .then(response => {
        dispatch({ type: types.CREATE_TIMESHEET_SUCCESS, response })
        successAlert(`Timesheet ${response?.data?.status?.name ?? 'submitted'}.`)
        return response
      })
      .catch(error => {
        if (error.status === 404) {
          errorAlert(error.data);
        }
        dispatch({ type: types.CREATE_TIMESHEET_FAILURE, error })
        throw error
      })
  }
}
