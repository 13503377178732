import * as types from "actions/types"
import { deserializeAssociate } from "serializers/associate"
import Zendesk from "utils/zendesk"

const defaultState = {
  isLoading: false,
  errorMessage: "",
  attributes: {
    addresses: [],
    adminAssociateStatusDescription: "",
    onboardingAndTimesheetsOnly: "",
    firstName: "",
    lastName: "",
    level: {
      maxPoints: 0,
    },
    score: 0,
    skills: [],
    searchLocation: {},
    trainings: [],
  },
}

function setupZendeskForm(attributes) {
  Zendesk.prefillForm(
    `${attributes.firstName} ${attributes.lastName}`,
    attributes.emailAddress,
    attributes.id
  )
}

export default function associate(state = defaultState, action) {
  let attributes
  switch (action.type) {
    case types.CREATE_SESSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.CREATE_SESSION_SUCCESS:
      attributes = deserializeAssociate(action.response?.data?.associate || {})
      setupZendeskForm(attributes)

      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        attributes,
      }
    case types.READ_ASSOCIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_ASSOCIATE_SUCCESS:
      attributes = deserializeAssociate(action.response?.data || {})
      setupZendeskForm(attributes)

      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        attributes,
      }
    case types.READ_ASSOCIATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
      }
    case types.UPDATE_ASSOCIATE_REQUEST:
      return state
    case types.UPDATE_ASSOCIATE_SUCCESS:
      attributes = deserializeAssociate(action.associate)
      setupZendeskForm(attributes)

      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        attributes: attributes,
      }
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      }
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        errorMessage: "",
      }
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action?.error?.data
          ? action.error.data?.errors
            ? action.error.data.errors.email[0]
            : action.error.data.email
          : "",
      }
    case types.CLEAR_ASSOCIATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      }
    case types.UPDATE_ASSOCIATE_SCORE:
      attributes = Object.assign({}, state.attributes)
      attributes.score = action.newPointsSummary

      return {
        ...state,
        attributes: attributes,
      }
    default:
      return state
  }
}
