import React from "react"
import PropTypes from "prop-types"
import Button from "components/Form/Button"

import "./ConfirmCancel.scss"

const JobConfirmCancel = ({ handleYes, handleNo, cancelMessage }) => {
  return (
    <div className="job-confirm-cancel">
      <p className="job-confirm-message">
        {cancelMessage}
        <br />
        Would you like to continue with the cancellation?
      </p>
      <div className="job-confirm-actions">
        <Button
          btnClass="btn-block btn-danger"
          text="Yes"
          onClick={handleYes}
        />
        <Button btnClass="btn-block btn-cancel" text="No" onClick={handleNo} />
      </div>
    </div>
  )
}

JobConfirmCancel.propTypes = {
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func.isRequired,
}

export default JobConfirmCancel
