import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readEmploymentInfo, updateEmploymentInfo } from "actions"
import React, { Component, Fragment } from "react"
import { handleErrors } from "utils/errors"
import { successAlert } from "utils/alerts"
import Agreements from "components/Profile/EmploymentInfo/Agreements"
import Disclosures from "components/Profile/EmploymentInfo/Disclosures"
import Eligibility from "components/Profile/EmploymentInfo/Eligibility"
import EmploymentInfoItem from "components/Profile/EmploymentInfoItem"
import Introduction from "components/Profile/EmploymentInfo/Introduction"
import Identification from "components/Profile/EmploymentInfo/Identification"
import IdentificationAlert from "components/Profile/EmploymentInfo/IdentificationAlert"
import Orientation from "components/Profile/EmploymentInfo/Orientation"
import Unavailable from "components/Profile/EmploymentInfo/Unavailable"
import { Redirect, withRouter } from "react-router-dom"
import { checkWrongStatus } from "utils/associateStatus"
import "./EmploymentInfo.scss"
import PolicyUpdates from "components/Profile/EmploymentInfo/PolicyUpdates"
import IdentificationWarning from "components/Profile/EmploymentInfo/IdentificationWarning";

class EmploymentInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showIdentification: false,
      showDisclosures: false,
      showPolicyUpdates: false,
      showOrientation: false,
      showAgreements: false,
      showEligibility: false,
      showUnavailableModal: false,
      showIdentificationAlert: false,
      clickedStep: "",
      showIdentificationWarning: false,
      isFullLegalNameValid: false,
    }
    this.isDataLoaded = false
  }

  componentDidMount() {
    const { onBoardingStatus } = this.props.associate
    const ON_BOARDING_NOT_INITIATED = 0

    if (onBoardingStatus === ON_BOARDING_NOT_INITIATED) {
      this.props.history.push("/app/assignments")
    } else {
      this.props
        .readEmploymentInfo()
        .then(() => {
          this.isDataLoaded = true
        })
        .catch(() => {})
    }
  }

  stepStatus = value => {
    const steps = this.props.employment.steps
    if (steps.length) {
      const step = steps.find(step => {
        return step.type === value
      })
      return step.status.type
    } else {
      return ""
    }
  }

  stepReason = value => {
    const steps = this.props.employment.steps
    if (steps.length) {
      const step = steps.find(step => {
        return step.label === value
      })
      return step.status.reason
    } else {
      return ""
    }
  }

  openStep = step => {
    if (!this.isDataLoaded) return
    const status = this.stepStatus(step)
    step = step.charAt(0).toUpperCase() + step.slice(1)

    this.setState({
      clickedStep: step,
    })

    if (status !== "unavailable") {
      this.setState({
        [`show${step}`]: true,
      })
    } else {
      this.setState({
        showUnavailableModal: true,
      })
    }
  }

  closeStep = step => {
    step = step.charAt(0).toUpperCase() + step.slice(1)

    this.setState({
      [`show${step}`]: false,
    })
  }

  hideUnavailableModal = () => {
    this.setState({
      showUnavailableModal: false,
    })
  }

  submitDisclosures = values => {
    return this.props
      .updateEmploymentInfo(values, "disclosures")
      .then(() => {
        this.closeStep("disclosures")
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  submitPolicyUpdates = values => {
    return this.props
      .updateEmploymentInfo(values, "policy")
      .then(() => {
        this.closeStep("policy")
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  submitIdentification = values => {
    values.fullName.value = values.firstName.trim() + '_' + values.middleName.trim() + (values.middleName.trim() ? '_' : '') + values.lastName.trim();
    const fullLegalNameFromProps = this.capitalizeFirstLetter(this.props.associate.firstName) + '_' +
      (this.props.associate.middleName ? this.capitalizeFirstLetter(this.props.associate.middleName) + '_': '') +
      this.capitalizeFirstLetter(this.props.associate.lastName);
    const isNamesMatch = fullLegalNameFromProps === values.fullName.value;
    if (isNamesMatch || this.state.isFullLegalNameValid) {
      return this.props
        .updateEmploymentInfo(values, "identification")
        .then(() => {
          successAlert("Your profile has been updated.")
          this.closeStep("identification")
        })
        .catch(error => {
          handleErrors(error)
        })
    } else {
      this.setState({ isFullLegalNameValid: true });
      return this.toggleIdentificationWarning();
    }
  }

  submitIntroduction = () => {
    this.props.updateEmploymentInfo({}, "introduction").catch(() => {})
  }

  toggleIdentificationAlert = () => {
    const isIdentificationComplete = this.props.employment.steps.find((el) => el.type === 'identification').status.type === 'complete';
    this.setState({
      showIdentificationAlert: !this.state.showIdentificationAlert && !isIdentificationComplete,
    })
  }

  toggleIdentificationWarning = () => {
    this.setState({
      showIdentificationWarning: !this.state.showIdentificationWarning,
    })
  }

  render() {
    const {
      showIdentification,
      showDisclosures,
      showPolicy,
      showOrientation,
      showAgreements,
      showEligibility,
      showUnavailableModal,
      clickedStep,
      showIdentificationAlert,
      showIdentificationWarning,
    } = this.state

    const { associate } = this.props
    const { isThirdPartyLabor } = this.props.associate

    if (isThirdPartyLabor === "Y") {
      return <Redirect to="/app/assignments" />
    }

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <Fragment>
        <div className="employment-information">
          <Introduction submitIntroduction={this.submitIntroduction} />
          <h2>Employment Information</h2>
          <p>
            Complete the following requirements to be eligible for new
            assignments.
          </p>
          <h3>Onboarding Steps</h3>
          <ul>
            <EmploymentInfoItem
              name="Government Identification"
              onClick={() => {
                this.openStep("identification")
                this.toggleIdentificationAlert()
              }}
              status={this.stepStatus("identification")}
            />

            {showIdentification && (
              <Identification
                closeModal={() => this.closeStep("identification")}
                status={this.stepStatus("identification")}
                onSubmit={this.submitIdentification}
              />
            )}

            {showIdentificationAlert && (
              <IdentificationAlert
                modalClose={() => {
                  this.toggleIdentificationAlert()
                }}
              />
            )}

            {showIdentificationWarning && (
              <IdentificationWarning
                modalClose={() => {
                  this.toggleIdentificationWarning()
                }}
              />
            )}

            <EmploymentInfoItem
              name="Work Disclosures"
              onClick={() => this.openStep("disclosures")}
              status={this.stepStatus("disclosures")}
            />

            {showDisclosures && (
              <Disclosures
                closeModal={() => this.closeStep("disclosures")}
                onSubmit={this.submitDisclosures}
                status={this.stepStatus("disclosures")}
              />
            )}

            {showEligibility && (
              <Eligibility
                closeModal={() => this.closeStep("eligibility")}
                status={this.stepStatus("eligibility")}
              />
            )}
          </ul>
          <ul>
            <EmploymentInfoItem
              name="Policy Updates"
              onClick={() => this.openStep("policy")}
              status={this.stepStatus("policy")}
            />

            {showPolicy && (
              <PolicyUpdates
                closeModal={() => this.closeStep("policy")}
                onSubmit={this.submitPolicyUpdates}
                status={this.stepStatus("policy")}
              />
            )}

            <EmploymentInfoItem
              externalSystem={true}
              name="Orientation"
              onClick={() => this.openStep("orientation")}
              status={this.stepStatus("orientation")}
            />

            {showOrientation && (
              <Orientation
                closeModal={() => this.closeStep("orientation")}
                status={this.stepStatus("orientation")}
              />
            )}

            <EmploymentInfoItem
              externalSystem={true}
              name="Payroll Forms & Agreements"
              onClick={() => this.openStep("agreements")}
              status={this.stepStatus("agreements")}
            />

            {showAgreements && (
              <Agreements
                closeModal={() => this.closeStep("agreements")}
                status={this.stepStatus("agreements")}
              />
            )}
            <EmploymentInfoItem
                externalSystem={true}
                name="I-9 Federal Employment Eligibility"
                onClick={() => this.openStep("eligibility")}
                status={this.stepStatus("eligibility")}
            />
          </ul>
          {showUnavailableModal && (
            <Unavailable
              closeModal={() => this.hideUnavailableModal()}
              reason={this.stepReason(clickedStep)}
              stepLabel={clickedStep}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
    employment: state.data.employment,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        readEmploymentInfo,
        updateEmploymentInfo,
      },
      dispatch
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmploymentInfo)
)
