import { combineReducers } from "redux"

import application from "./data/application"
import applications from "./data/applications"
import assignments from "./data/assignments"
import associate from "./data/associate"
import employment from "./data/employment"
import experiences from "./data/experiences"
import jobs from "./data/jobs"
import points from "./data/points"
import referrals from "./data/referrals"
import schedule from "./data/schedule"
import tasks from "./data/tasks"
import timesheets from "./data/timesheets"
import apiVersion from "./data/apiVersion"
import stateDisclosures from "./data/stateDisclosures"
import lodging from "./data/lodging"

const dataReducer = combineReducers({
  application,
  applications,
  assignments,
  associate,
  employment,
  experiences,
  jobs,
  points,
  referrals,
  schedule,
  tasks,
  timesheets,
  apiVersion,
  stateDisclosures,
  lodging,
})

export default dataReducer
