export function deserializeExperiences(data) {
  let serializedData = { ...data }

  let previousExperiences = []

  if (data.previousExperiences.length > 0) {
    const map = new Map()
    for (const pE of data.previousExperiences) {
      // distinguishing previous experience by work type
      // filtering previous experience by valid work type
      if (!map.has(pE.workType.id) && pE.workType.id) {
        map.set(pE.workType.id, true)
        previousExperiences.push(pE)
      }
    }

    // resetting invalid year experience
    previousExperiences = previousExperiences.map(pE =>
      pE.yearsExperience.id ? pE : { ...pE, yearsExperience: null }
    )
  }

  serializedData.previousExperiences = previousExperiences

  return serializedData
}
