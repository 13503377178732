import React, { Component } from "react"
import PropTypes from "prop-types"
import PortalWrapper from "components/PortalWrapper"
import GeolocationPreempt from "components/Modals/GeolocationPreempt"
import GeolocationBlocked from "components/Modals/GeolocationBlocked"
import { setCookie, getCookie } from "utils/cookies"

const COOKIE_NAME = "hireflex_geolocation"

export default class Geolocation extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    onAcknowledgeGeoBlocked: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      preModalOpen: false,
      blockedModalOpen: false,
    }
  }

  componentDidMount() {
    if (getCookie(COOKIE_NAME)) {
      this.getGeolocation()
    } else {
      this.openPreModal()
    }
  }

  toggleBlockedModal = () => {
    this.setState({ blockedModalOpen: !this.state.blockedModalOpen })
  }

  openBlockedModal = () => {
    this.setState({
      blockedModalOpen: true,
    })
  }

  closeBlockedModal = () => {
    if (this.props.onAcknowledgeGeoBlocked instanceof Function) {
      // this.props.onAcknowledgeGeoBlocked()
    }
    this.setState({
      blockedModalOpen: false,
    })
  }

  openPreModal = () => {
    this.setState({ preModalOpen: true })
  }

  dismissPreModal = () => {
    this.setState({ preModalOpen: false }, () => {
      this.getGeolocation()
      setCookie(COOKIE_NAME, true, 365)
    })
  }

  getGeolocation = () => {
    if (navigator.geolocation) {
      // browser will ask for permission if it hasn't before
      navigator.geolocation.getCurrentPosition(
        this.logPosition,
        this.handleErrors,
        {
          timeout: 1000 * 60 * 5, // 5 min
          maximumAge: 1000 * 60 * 60 * 24, //24 h
          enableHighAccuracy: true,
        }
      )
    } else {
      console.log("Geolocation not supported on this browser!")
    }
  }

  logPosition = position => {
    const latitude = position.coords.latitude
    const longitude = position.coords.longitude
    this.props.onSuccess(latitude, longitude)
  }

  handleErrors = error => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // User clicked Block or has previously clicked Block
        this.openBlockedModal()
        console.log("User denied the request for Geolocation.")
        break
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")
        break
      case error.TIMEOUT:
        console.log("The request to get user location timed out.")
        break
      case error.UNKNOWN_ERROR:
      default:
        console.log("An unknown error occurred.")
        break
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.preModalOpen && (
          <PortalWrapper>
            <GeolocationPreempt
              closeModal={this.dismissPreModal}
              handleModalClose={this.props.handleModalClose}
              getLocation={this.getGeolocation}
            />
          </PortalWrapper>
        )}
        {this.state.blockedModalOpen && (
          <PortalWrapper>
            <GeolocationBlocked
              closeModal={this.closeBlockedModal}
              handleModalClose={this.props.handleModalClose}
              handleModalContinue={this.props.handleModalContinue}
            />
          </PortalWrapper>
        )}
      </React.Fragment>
    )
  }
}
