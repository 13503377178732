import React from "react"
import moment from "moment"
import classNames from "classnames"
import MediaQuery from "react-responsive"
import Tooltip from "components/Tooltip"

import "./Status.scss"

const TimesheetStatus = ({ status, isPartiallyPaid, paidDate, isThirdPartyLabor, rejectionReason }) => {
  const label = isPartiallyPaid ? 'partially paid' : status.label;
  const isPaid = label.toLowerCase() === "paid" || isPartiallyPaid;
  const hasReasons = rejectionReason.reasons && rejectionReason.reasons.length > 0;

  const labelClass = classNames("timesheet-info-status", label.toLowerCase().replace(' ', '-'));

  const labelDate = isPaid ? `on ${moment(paidDate).format("MMM D, YYYY")}` : ""
  const labelDateShort = isPaid ? moment(paidDate).format("M/D/YY"): ""

  return <>
    <span className={labelClass}>
      <MediaQuery minWidth={768}>
        {label} {isThirdPartyLabor === "N" ? labelDate : ""}
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        {label} {isThirdPartyLabor === "N" ? labelDateShort : ""}
      </MediaQuery>
    </span>
    {isPartiallyPaid && (
      <Tooltip
        text={<>
        Approved hours worked after midnight will be paid on your next check
      </>}
      />
    )}
    {hasReasons && (
      <Tooltip
        text={rejectionReason.reasons[0]}
        caption={"View Details"}
      />
    )}
  </>
}

export default TimesheetStatus;
