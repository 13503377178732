import React, { Component } from "react"
import "./PointValue.scss"

export default class PointValue extends Component {
  renderSign = value => {
    let sign = value >= 0 ? "positive" : "negative"
    return <span className={sign}>{Math.abs(Math.round(value))}</span>
  }

  render() {
    return (
      <span className="point-value">{this.renderSign(this.props.value)}</span>
    )
  }
}
