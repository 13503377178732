import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  data: { pointLog: [], subtotals: {} },
  summary: [],
}

export default function points(state = defaultState, action) {
  switch (action.type) {
    case types.READ_POINTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_POINTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.response?.data || []
      }
    case types.READ_POINTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.READ_POINTS_SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_POINTS_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        summary: action.response?.data || []
      }
    case types.READ_POINTS_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
