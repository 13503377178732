import React, { useState } from "react"
import { API_URL } from "utils/api"
import FileModal from "./FileModal"
import SvgIcon from "components/SvgIcon"
import "./FileItem.scss"

const FileItem = ({ fileName, state }) => {
  const UNMODIFIED_FILENAME = fileName
  const [isOpenModal, setIsOpenModal] = useState(false)

  const TOKEN = localStorage.getItem("sessionToken")
  const URL = `${API_URL}/state-disclosures/open-file?state=${state}&fileName=${fileName}&token=${TOKEN}`
  const FILENAME_LENGTH = fileName.length

  if (FILENAME_LENGTH > 33) {
    const EXTENSION = ".pdf"
    const EXTENSION_LENGTH = 4
    const LAST_FIVE_CHARACTERS = fileName.substr(
      FILENAME_LENGTH - EXTENSION_LENGTH - 5,
      5
    )

    const FIRST_TWENTY_CHARACTERS = fileName.substr(0, 20)
    fileName =
      FIRST_TWENTY_CHARACTERS + "..." + LAST_FIVE_CHARACTERS + EXTENSION
  }

  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  return (
    <span className="file-item" title={UNMODIFIED_FILENAME}>
      <a href="#" className="badge" onClick={openModal}>
        <SvgIcon title="PDF file" name="document" />{" "}
        <span className="file-name">{fileName}</span>
      </a>

      {isOpenModal && (
        <FileModal
          closeModal={closeModal}
          source={URL}
          title={UNMODIFIED_FILENAME}
        />
      )}
    </span>
  )
}

export default FileItem
