import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Tooltip from "components/Tooltip"
import Icon from "components/Icon"

export default class CheckboxInput extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    tooltip: PropTypes.string,
  }

  hasErrors() {
    return this.props.meta.error && this.props.meta.touched
  }

  render() {
    const { icon, input, label, disabled, tooltip } = this.props

    let inputClass = classNames(this.props.className, {
      "has-errors": this.hasErrors(),
    })

    return (
      <React.Fragment>
        <input
          type="checkbox"
          id={input.name}
          name={input.name}
          checked={input.value}
          disabled={disabled}
          onChange={input.onChange}
        />
        <label htmlFor={input.name} className={inputClass}>
          {icon && <Icon height="14" width="13" name={icon} />}
          {label}
          {tooltip && <Tooltip text={tooltip} />}
        </label>
      </React.Fragment>
    )
  }
}
