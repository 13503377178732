import { SubmissionError } from "redux-form"
import { animateScroll } from "react-scroll"
import _ from "lodash"

// ReduxForm Error Handler
export function handleErrors(error) {
  if (error && error.data && error.data.errors) {
    animateScroll.scrollToTop()
    const errors = error.data.errors

    if (Object.keys(errors).length === 1) {
      Object.keys(errors).forEach(field => {
        const fieldError = _.set({}, field, errors[field])
        throw new SubmissionError({
          ...fieldError,
          _error: errors[field],
        })
      })
    } else {
      let fieldErrors = {}
      let errorMessages = []

      Object.keys(errors).forEach(field => {
        let fieldError = _.set({}, field, errors[field])
        _.assign(fieldErrors, fieldError)
        errorMessages.push(errors[field])
      })

      throw new SubmissionError({
        ...fieldErrors,
        _error: errorMessages.join(" "),
      })
    }
  }
}
