import React, { Component } from "react"
// import { Link } from "react-router-dom"
// import classNames from "classnames"
// import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { checkWrongStatus } from "utils/associateStatus"

import * as actionCreators from "actions"
// import DropdownMenu from "./DropdownMenu"
// import Icon from "components/Icon"

import "./TasksDropdown.scss"

// const TASKS_COUNT = 4

class TasksDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  componentDidMount() {
    if (!checkWrongStatus(this.props.associate)) {
      this.props.actions.readTasks().catch(() => {})
    }
  }

  toggle = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  close = () => {
    this.setState({ isMenuOpen: false })
  }

  click = e => {
    this.close(e)
  }

  render() {
    // let menuOptions = {
    //   isOpen: this.state.isMenuOpen,
    //   close: this.close,
    //   toggle: (
    //     <a className="tasks-link" onClick={this.toggle}>
    //       <Icon name="task" height="30" width="30" />
    //       {this.props.mobile && <span>Notifications</span>}
    //     </a>
    //   ),
    //   click: this.click,
    //   className: "tasks-dropdown",
    // }
    return (
      <React.Fragment>
        {/*
        <DropdownMenu {...menuOptions}>
          <div className="tasks-dropdown-list">
            <ul>
              this.props.tasks.slice(0, TASKS_COUNT).map((task, index) =>
                <li key={index} className={classNames('task', { 'expired': task.expired === 'Y' })}>
                  <span className="task-title">
                    {task.action.name}
                  </span>
                  <span className="task-date">
                    {moment(task.createdAt).fromNow()}
                  </span>
                </li>
              )
            </ul>
          </div>
          <div className="tasks-footer">
            <Link className="btn-view" to="/app/tasks">
              View All Tasks
            </Link>
          </div>
        </DropdownMenu>
        */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tasks: state.data.tasks.data,
    associate: state.data.associate.attributes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksDropdown)
