import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PortalWrapper from "components/PortalWrapper"
import Modal from "components/Modal"
import ConfirmCancel from "components/Job/ConfirmCancel"
import classNames from "classnames"
import Button from "components/Form/Button"
import DayContext from "components/Schedule/DayContext"
import { getSchedule, cancelJobDates } from "actions"

import "./Cancel.scss"

class CancelJob extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  static contextType = DayContext

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      showConfirm: false,
    }
  }

  handleCancel = () => {
    this.setState({ isLoading: true })
    return this.props
      .cancelJobDates({
        applicationId: this.props.applicationId,
        unselectedDatesList: this.props.unselectedDatesList,
      })
      .then(() => {
        this.setState({ isLoading: true })
        return this.props.getSchedule().then(() => {
          this.setState({ isLoading: false })
          this.props.handleClose()
        })
      })
      .catch(() => {})
  }

  toggleConfirm = () => {
    this.setState({ showConfirm: !this.state.showConfirm })
  }

  cancelMessage = () => {
    return (
      <div>
        <p>
          Canceling shifts less than 5 days will result in a 10 point deduction
          for each day canceled. Canceling shifts 24h or less will result in a
          100 point deduction.
        </p>
      </div>
    )
  }

  render() {
    const { job, modified } = this.props
    const { isLoading } = this.state
    const cancelMessage = this.cancelMessage()
    const isLoaded = job.title !== undefined
    const styles = classNames("job-detail-cancel", {
      center: !isLoaded,
    })

    return (
      <div className={styles}>
        {isLoaded ? (
          <React.Fragment>
            <p>
              Canceling shifts less than 5 days will result in a 10 point
              deduction for each day canceled. Canceling shifts 24h or less will
              result in a 100 point deduction.
            </p>
            <Button
              btnClass={modified ? "btn-danger" : "btn-cancel "}
              disabled={isLoading || !modified}
              text="Cancel"
              onClick={modified ? this.toggleConfirm : null}
            />
          </React.Fragment>
        ) : (
          <p>Loading...</p>
        )}
        {this.state.showConfirm && (
          <PortalWrapper>
            <Modal className="lofted" modalClose={this.toggleConfirm}>
              <ConfirmCancel
                handleYes={this.handleCancel}
                handleNo={this.toggleConfirm}
                cancelMessage={cancelMessage}
              />
            </Modal>
          </PortalWrapper>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getSchedule,
        cancelJobDates,
      },
      dispatch
    ),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(CancelJob)
