import React from "react"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"
import Button from "components/Form/Button"
import "./UnavailableRosterModal.scss"

const UnavailableRosterModal = ({ modalClose }) => {
  return (
    <Modal
      className="unavailable-roster-modal lofted modal alert"
      modalClose={modalClose}
    >
      <div className="modal-header">
        <a className="modal-close" onClick={modalClose}>
          <SvgIcon title="close" name="close-gray" />
        </a>
        <h3>Attention</h3>
      </div>
      <div className="modal-body">
        <p>Shift Roster will become available 2 hrs before Shift starts</p>
      </div>
      <div className="modal-footer">
        <Button btnClass="btn-green btn-apply" text="OK" onClick={modalClose} />
      </div>
    </Modal>
  )
}

export default UnavailableRosterModal
