import React, { Component } from "react"
import PropTypes from "prop-types"
import PortalWrapper from "components/PortalWrapper"
import classNames from "classnames"
import moment from "moment"

import UnavailableRosterModal from "./UnavailableRosterModal"
import SlotModal from "./SlotModal"

import "./Slot.scss"

export default class Slot extends Component {
  static propTypes = {
    slot: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      mountModal: props.assignmentId === props.slot.id,
      unavailableRosterModalOpen: false,
    }
  }

  closeModal = () => {
    this.setState({ mountModal: false })
  }

  openModal = () => {
    this.setState({ mountModal: true })
  }

  openUnavailableRosterModal = () =>
    this.setState({ unavailableRosterModalOpen: true })

  closeUnavailableRosterModal = () =>
    this.setState({ unavailableRosterModalOpen: false })

  render() {
    const { slot, isLoading } = this.props
    const timeFormat = "h:mmA"
    const startTime = slot.shift.start
      ? moment(slot.shift.start, "HH:mm:ss").format(timeFormat)
      : "Start"
    const endTime = slot.shift.end
      ? moment(slot.shift.end, "HH:mm:ss").format(timeFormat)
      : "End"
    const address =
      (slot.job.store
        ? slot.job.store.location
          ? slot.job.store.location.address
            ? slot.job.store.location.address.full
            : undefined
          : undefined
        : undefined) || "Unknown Location"

    const slotClass = classNames("day-slot", { active: this.state.mountModal })

    const osc = slot.fieldManagement.contact

    return (
      <div className={slotClass} onClick={this.openModal}>
        <div className="slot-address">{address}</div>
        <div className="slot-detail">
          <div className="slot-detail-left">
            <div className="slot-title">{slot.job.title}</div>
            <div className="slot-times">
              {startTime} - {endTime}
            </div>
          </div>
          {osc && (
            <div className="slot-detail-right">
              <span className="badge-osc">OSC</span>
            </div>
          )}
        </div>

        {this.state.mountModal && (
          <PortalWrapper>
            <SlotModal
              handleClose={this.closeModal}
              slot={slot}
              isLoading={isLoading}
              openUnavailableRosterModal={this.openUnavailableRosterModal}
            />
          </PortalWrapper>
        )}

        {this.state.unavailableRosterModalOpen && (
          <PortalWrapper>
            <UnavailableRosterModal
              modalClose={this.closeUnavailableRosterModal}
            />
          </PortalWrapper>
        )}
      </div>
    )
  }
}
