import * as types from "./types"
import { apiCall } from "utils/api"

export function readList(listType) {
  return dispatch => {
    dispatch({ type: types.READ_LIST_REQUEST, listType })
    return apiCall("get", `list/${listType}`, {}, false)
      .then(response => {
        dispatch({
          type: types.READ_LIST_SUCCESS,
          data: response.data,
          listType,
        })
      })
      .catch(error => {
        dispatch({ type: types.READ_LIST_FAILURE })
        throw error
      })
  }
}

// TODO: Follow Redux Success/Failure pattern for readLocation
export function readLocation(id) {
  return dispatch => {
    dispatch({ type: types.READ_LIST_LOCATION_REQUEST })
    return apiCall("get", `list/locations/${id}`, {}, false)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return {}
      })
  }
}
