import React, { Component } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { connect } from "react-redux"

import "./Navbar.scss"

class Navbar extends Component {
  constructor(props) {
    super(props)

    let pages = [
      { name: "Personal Info", path: "personal" },
      { name: "Notifications", path: "notifications" },
      { name: "Points", path: "points" },
      { name: "Account", path: "account" },
      { name: "Teammates", path: "teammates" },
      { name: "Disclosures", path: "disclosures" },
    ]

    if (props.associate.isThirdPartyLabor === "N") {
      pages.splice(1, 0, { name: "Work Interests", path: "interests" })
    }

    if (
      (props.associate.onBoardingStatus === 1 ||
        props.associate.onBoardingStatus === 2) &&
      props.associate.isThirdPartyLabor === "N"
    ) {
      pages.splice(1, 0, { name: "Employment Info", path: "employment" })
    }

    this.state = {
      pages: pages,
    }
  }

  render() {
    return (
      <div className="profile-navbar">
        <div className="container">
          <ul className="profile-navbar-menu">
            {this.state.pages.map(page => {
              const fullPath = `/app/profile/${page.path}`
              return (
                <li
                  key={page.path}
                  className={classNames({
                    active: this.props.path === fullPath,
                  })}
                >
                  <Link to={fullPath}>{page.name}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

export default connect(mapStateToProps)(Navbar)
