// Applications
export const LIST_APPLICATIONS_REQUEST = "LIST_APPLICATIONS_REQUEST"
export const LIST_APPLICATIONS_SUCCESS = "LIST_APPLICATIONS_SUCCESS"
export const LIST_APPLICATIONS_FAILURE = "LIST_APPLICATIONS_FAILURE"
export const CREATE_APPLICATION_REQUEST = "CREATE_APPLICATION_REQUEST"
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS"
export const CREATE_APPLICATION_FAILURE = "CREATE_APPLICATION_FAILURE"
export const READ_APPLICATION_REQUEST = "READ_APPLICATION_REQUEST"
export const READ_APPLICATION_SUCCESS = "READ_APPLICATION_SUCCESS"
export const READ_APPLICATION_FAILURE = "READ_APPLICATION_FAILURE"
export const UPDATE_APPLICATION_REQUEST = "UPDATE_APPLICATION_REQUEST"
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS"
export const UPDATE_APPLICATION_FAILURE = "UPDATE_APPLICATION_FAILURE"
export const CLEAR_APPLICATION = "CLEAR_APPLICATION"

// Assignments
export const READ_ASSIGNMENT_REQUEST = "READ_ASSIGNMENT_REQUEST"
export const READ_ASSIGNMENT_SUCCESS = "READ_ASSIGNMENT_SUCCESS"
export const READ_ASSIGNMENT_FAILURE = "READ_ASSIGNMENT_FAILURE"

// Associates
export const CREATE_ASSOCIATE_REQUEST = "CREATE_ASSOCIATE_REQUEST"
export const CREATE_ASSOCIATE_SUCCESS = "CREATE_ASSOCIATE_SUCCESS"
export const CREATE_ASSOCIATE_FAILURE = "CREATE_ASSOCIATE_FAILURE"
export const READ_ASSOCIATE_REQUEST = "READ_ASSOCIATE_REQUEST"
export const READ_ASSOCIATE_SUCCESS = "READ_ASSOCIATE_SUCCESS"
export const READ_ASSOCIATE_FAILURE = "READ_ASSOCIATE_FAILURE"
export const UPDATE_ASSOCIATE_REQUEST = "UPDATE_ASSOCIATE_REQUEST"
export const UPDATE_ASSOCIATE_SUCCESS = "UPDATE_ASSOCIATE_SUCCESS"
export const UPDATE_ASSOCIATE_FAILURE = "UPDATE_ASSOCIATE_FAILURE"
export const CLEAR_ASSOCIATE_ERROR_MESSAGE = "CLEAR_ASSOCIATE_ERROR_MESSAGE"
export const UPDATE_ASSOCIATE_SCORE = "UPDATE_ASSOCIATE_SCORE"

// State Disclosures
export const READ_STATE_DISCLOSURES_REQUEST = "READ_STATE_DISCLOSURES_REQUEST"
export const READ_STATE_DISCLOSURES_SUCCESS = "READ_STATE_DISCLOSURES_SUCCESS"
export const READ_STATE_DISCLOSURES_FAILURE = "READ_STATE_DISCLOSURES_FAILURE"

// Lodging Form
export const SET_LODGING_ERROR = "SET_LODGING_ERROR"
export const CLEAR_LODGING_ERRORS = "CLEAR_LODGING_ERRORS"
export const CLEAR_LODGING_FIELD_ERROR = "CLEAR_LODGING_FIELD_ERROR"

// Employment
export const READ_EMPLOYMENT_INFO_REQUEST = "READ_EMPLOYMENT_INFO_REQUEST"
export const READ_EMPLOYMENT_INFO_SUCCESS = "READ_EMPLOYMENT_INFO_SUCCESS"
export const READ_EMPLOYMENT_INFO_FAILURE = "READ_EMPLOYMENT_INFO_FAILURE"
export const UPDATE_EMPLOYMENT_INFO_REQUEST = "UPDTAE_EMPLOYMENT_INFO_REQUEST"
export const UPDATE_EMPLOYMENT_INFO_SUCCESS = "UPDTAE_EMPLOYMENT_INFO_SUCCESS"
export const UPDATE_EMPLOYMENT_INFO_FAILURE = "UPDTAE_EMPLOYMENT_INFO_FAILURE"
export const MARK_EMPLOYMENT_PENDING_REQUEST = "MARK_EMPLOYMENT_PENDING_REQUEST"
export const MARK_EMPLOYMENT_PENDING_SUCCESS = "MARK_EMPLOYMENT_PENDING_SUCCESS"
export const MARK_EMPLOYMENT_PENDING_FAILURE = "MARK_EMPLOYMENT_PENDING_FAILURE"
export const MARK_EMPLOYMENT_COMPLETE_REQUEST =
  "MARK_EMPLOYMENT_COMPLETE_REQUEST"
export const MARK_EMPLOYMENT_COMPLETE_SUCCESS =
  "MARK_EMPLOYMENT_COMPLETE_SUCCESS"
export const MARK_EMPLOYMENT_COMPLETE_FAILURE =
  "MARK_EMPLOYMENT_COMPLETE_FAILURE"

// Experience
export const READ_EXPERIENCES_REQUEST = "READ_EXPERIENCES_REQUEST"
export const READ_EXPERIENCES_SUCCESS = "READ_EXPERIENCES_SUCCESS"
export const READ_EXPERIENCES_FAILURE = "READ_EXPERIENCES_FAILURE"
export const UPDATE_EXPERIENCES_REQUEST = "UPDATE_EXPERIENCES_REQUEST"
export const UPDATE_EXPERIENCES_SUCCESS = "UPDATE_EXPERIENCES_SUCCESS"
export const UPDATE_EXPERIENCES_FAILURE = "UPDATE_EXPERIENCES_FAILURE"
export const CLEAR_EXPERIENCES = "CLEAR_EXPERIENCES"

// Jobs
export const READ_JOB_REQUEST = "READ_JOB_REQUEST"
export const READ_JOB_SUCCESS = "READ_JOB_SUCCESS"
export const READ_JOB_FAILURE = "READ_JOB_FAILURE"
export const SEARCH_JOBS_REQUEST = "SEARCH_JOBS_REQUEST"
export const SEARCH_JOBS_SUCCESS = "SEARCH_JOBS_SUCCESS"
export const SEARCH_JOBS_FAILURE = "SEARCH_JOBS_FAILURE"
export const CANCEL_JOB_REQUEST = "CANCEL_JOB_REQUEST"
export const CANCEL_JOB_SUCCESS = "CANCEL_JOB_SUCCESS"
export const CANCEL_JOB_FAILURE = "CANCEL_JOB_FAILURE"
export const CANCEL_JOB_DATES_REQUEST = "CANCEL_JOB_DATES_REQUEST"
export const CANCEL_JOB_DATES_SUCCESS = "CANCEL_JOB_DATES_SUCCESS"
export const CANCEL_JOB_DATES_FAILURE = "CANCEL_JOB_DATES_FAILURE"
export const CONFIRM_JOB_REQUEST = "CONFIRM_JOB_REQUEST"
export const CONFIRM_JOB_SUCCESS = "CONFIRM_JOB_SUCCESS"
export const CONFIRM_JOB_FAILURE = "CONFIRM_JOB_FAILURE"
export const DECLINE_JOB_REQUEST = "DECLINE_JOB_REQUEST"
export const DECLINE_JOB_SUCCESS = "DECLINE_JOB_SUCCESS"
export const DECLINE_JOB_FAILURE = "DECLINE_JOB_FAILURE"
export const REMOVE_OPEN_JOB = "REMOVE_OPEN_JOB"
export const CLEAR_JOBS = "CLEAR_JOBS"

// Lists
export const READ_LIST_REQUEST = "READ_LIST_REQUEST"
export const READ_LIST_SUCCESS = "READ_LIST_SUCCESS"
export const READ_LIST_FAILURE = "READ_LIST_FAILURE"
export const READ_LIST_LOCATION_REQUEST = "READ_LIST_LOCATION_REQUEST"
export const READ_LIST_LOCATION_SUCCESS = "READ_LIST_LOCATION_SUCCESS"
export const READ_LIST_LOCATION_FAILURE = "READ_LIST_LOCATION_FAILURE"

// Password
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE"

// Points
export const READ_POINTS_REQUEST = "READ_POINTS_REQUEST"
export const READ_POINTS_SUCCESS = "READ_POINTS_SUCCESS"
export const READ_POINTS_FAILURE = "READ_POINTS_FAILURE"
export const READ_POINTS_SUMMARY_REQUEST = "READ_POINTS_SUMMARY_REQUEST"
export const READ_POINTS_SUMMARY_SUCCESS = "READ_POINTS_SUMMARY_SUCCESS"
export const READ_POINTS_SUMMARY_FAILURE = "READ_POINTS_SUMMARY_FAILURE"

// Referrals
export const CREATE_REFERRALS_REQUEST = "CREATE_REFERRALS_REQUEST"
export const CREATE_REFERRALS_SUCCESS = "CREATE_REFERRALS_SUCCESS"
export const CREATE_REFERRALS_FAILURE = "CREATE_REFERRALS_FAILURE"
export const READ_REFERRALS_REQUEST = "READ_REFERRALS_REQUEST"
export const READ_REFERRALS_SUCCESS = "READ_REFERRALS_SUCCESS"
export const READ_REFERRALS_FAILURE = "READ_REFERRALS_FAILURE"

// Schedule
export const READ_SCHEDULE_REQUEST = "READ_SCHEDULE_REQUEST"
export const READ_SCHEDULE_SUCCESS = "READ_SCHEDULE_SUCCESS"
export const READ_SCHEDULE_FAILURE = "READ_SCHEDULE_FAILURE"
export const READ_SCHEDULE_LAZY_REQUEST = "READ_SCHEDULE_LAZY_REQUEST"
export const READ_SCHEDULE_LAZY_SUCCESS = "READ_SCHEDULE_LAZY_SUCCESS"
export const READ_SCHEDULE_LAZY_FAILURE = "READ_SCHEDULE_LAZY_FAILURE"
export const CREATE_PUNCH_TIME_REQUEST = "CREATE_PUNCH_TIME_REQUEST"
export const CREATE_PUNCH_TIME_SUCCESS = "CREATE_PUNCH_TIME_SUCCESS"
export const CREATE_PUNCH_TIME_FAILURE = "CREATE_PUNCH_TIME_FAILURE"

// Sessions
export const CREATE_SESSION_REQUEST = "CREATE_SESSION_REQUEST"
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS"
export const CREATE_SESSION_FAILURE = "CREATE_SESSION_FAILURE"
export const DELETE_SESSION_REQUEST = "DELETE_SESSION_REQUEST"
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS"
export const RESTORE_SESSION_REQUEST = "RESTORE_SESSION_REQUEST"
export const RESTORE_SESSION_SUCCESS = "RESTORE_SESSION_SUCCESS"

// Tasks
export const READ_TASKS_REQUEST = "READ_TASKS_REQUEST"
export const READ_TASKS_SUCCESS = "READ_TASKS_SUCCESS"
export const READ_TASKS_FAILURE = "READ_TASKS_FAILURE"

// Timesheets
export const LIST_TIMESHEETS_REQUEST = "LIST_TIMESHEETS_REQUEST"
export const LIST_TIMESHEETS_SUCCESS = "LIST_TIMESHEETS_SUCCESS"
export const LIST_TIMESHEETS_FAILURE = "LIST_TIMESHEETS_FAILURE"
export const READ_TIMESHEET_REQUEST = "READ_TIMESHEET_REQUEST"
export const READ_TIMESHEET_SUCCESS = "READ_TIMESHEET_SUCCESS"
export const READ_TIMESHEET_FAILURE = "READ_TIMESHEET_FAILURE"
export const CREATE_TIMESHEET_REQUEST = "CREATE_TIMESHEET_REQUEST"
export const CREATE_TIMESHEET_SUCCESS = "CREATE_TIMESHEET_SUCCESS"
export const CREATE_TIMESHEET_FAILURE = "CREATE_TIMESHEET_FAILURE"
export const CLEAR_TIMESHEET = "CLEAR_TIMESHEET"

// getVersion
export const UPDATE_APP_VERSION = "UPDATE_APP_VERSION"

// Teammates
export const LIST_TEAMMATES_REQUEST = "LIST_TEAMMATES_REQUEST"
export const LIST_TEAMMATES_SUCCESS = "LIST_TEAMMATES_SUCCESS"
export const LIST_TEAMMATES_FAILURE = "LIST_TEAMMATES_FAILURE"
export const REMOVE_TEAMMATE_REQUEST = "REMOVE_TEAMMATE_REQUEST"
export const REMOVE_TEAMMATE_SUCCESS = "REMOVE_TEAMMATE_SUCCESS"
export const REMOVE_TEAMMATE_FAILURE = "REMOVE_TEAMMATE_FAILURE"
export const CONFIRM_INVITATION_REQUEST = "CONFIRM_INVITATION_REQUEST"
export const CONFIRM_INVITATION_SUCCESS = "CONFIRM_INVITATION_SUCCESS"
export const CONFIRM_INVITATION_FAILURE = "CONFIRM_INVITATION_FAILURE"
export const REJECT_INVITATION_REQUEST = "REJECT_INVITATION_REQUEST"
export const REJECT_INVITATION_SUCCESS = "REJECT_INVITATION_SUCCESS"
export const REJECT_INVITATION_FAILURE = "REJECT_INVITATION_FAILURE"
export const CANCEL_INVITATION_REQUEST = "CANCEL_INVITATION_REQUEST"
export const CANCEL_INVITATION_SUCCESS = "CANCEL_INVITATION_SUCCESS"
export const CANCEL_INVITATION_FAILURE = "CANCEL_INVITATION_FAILURE"
export const TEAMMATES_INVITE_REQUEST = "TEAMMATES_INVITE_REQUEST"
export const TEAMMATES_INVITE_SUCCESS = "TEAMMATES_INVITE_SUCCESS"
export const TEAMMATES_INVITE_FAILURE = "TEAMMATES_INVITE_FAILURE"

// API Version
export const READ_API_VERSION_REQUEST = "READ_API_VERSION_REQUEST"
export const READ_API_VERSION_SUCCESS = "READ_API_VERSION_SUCCESS"
export const READ_API_VERSION_FAILURE = "READ_API_VERSION_FAILURE"

export const TOGGLE_NETWORK_ERROR_MODAL = "TOGGLE_NETWORK_ERROR_MODAL"
