import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field, change } from "redux-form"
import ErrorMessage from "components/ErrorMessage"
import { deserializeEmploymentInfo } from "serializers/employment"
import SideModal from "components/SideModal"
import Modal from "components/Modal"
import Section from "components/Modal/Section"
import Checkbox from "components/Form/CheckboxInput"
import Button from "components/Form/Button"
import Header from "./Header"
import Signature from "./Signature"
import SvgIcon from "components/SvgIcon"
import "./forms.scss"

export const fields = [
  "backgroundCheck",
  "electronicRecords",
  "fcraAuthorization",
  "waiveReport",
  "signature",
]

const validate = ({
  backgroundCheckDisclosureAuthorization,
  electronicSignatureNoticeAuthorization,
  fcraAcknowledgementAuthorization,
  waiveReport,
  signature,
}) => {
  const errors = {}

  if (!backgroundCheckDisclosureAuthorization) {
    errors.backgroundCheckDisclosureAuthorization = "Please acknowledge"
  }

  if (!electronicSignatureNoticeAuthorization) {
    errors.electronicSignatureNoticeAuthorization = "Please acknowledge"
  }

  if (!fcraAcknowledgementAuthorization) {
    errors.fcraAcknowledgementAuthorization = "Please acknowledge"
  }

  if (!signature || signature.length === 0) {
    errors.signature = "Please sign"
  }
  return errors
}

export class Disclosures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canEdit: props.status === "incomplete",
      showPdf: false,
    }
  }

  handleSignatureChange = data => {
    this.props.dispatch(change("disclosures", "signature", data))
  }

  openPdfModal = (url, title) => {
    this.setState({
      pdfModalTitle: title,
      showPdf: url,
    })
  }

  closePdfModal = () => {
    this.setState({
      pdfModalTitle: false,
      showPdf: false,
    })
  }

  render() {
    const { canEdit, pdfModalTitle, showPdf } = this.state
    const {
      error,
      handleSubmit,
      initialValues,
      invalid,
      pristine,
      submitting,
    } = this.props
    const californiaOption = initialValues.waiveReport !== undefined

    return (
      <form onSubmit={handleSubmit} className="work-disclosures-form">
        <SideModal
          closeModal={this.props.closeModal}
          header={
            <Header
              title="Work Disclosures"
              subtitle={
                canEdit
                  ? "Please read and acknowledge the following disclosures."
                  : this.props.status === "pending"
                  ? `We're currently performing a background check. Email us at ${process.env.REACT_APP_SUPPORT_EMAIL} if you have any questions.`
                  : "You read and acknowledged the following disclosures."
              }
            />
          }
          body={
            <React.Fragment>
              {error && <ErrorMessage errorText={error} />}
              <div className="work-disclosures-fields">
                <Section>
                  <h3>Disclosures and Agreements</h3>
                  <div className="input-line">
                    <div className="input-box">
                      <Field
                        component={Checkbox}
                        name={"backgroundCheckDisclosureAuthorization"}
                        label={
                          <span>
                            I have read the &nbsp;
                            <a
                              onClick={() =>
                                this.openPdfModal(
                                  initialValues.backgroundCheckDisclosure,
                                  "Background Check Disclosure"
                                )
                              }
                            >
                              Background Check Disclosure
                            </a>
                            .
                          </span>
                        }
                        disabled={!canEdit}
                      />
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="input-box">
                      <Field
                        component={Checkbox}
                        name={"electronicSignatureNoticeAuthorization"}
                        label={
                          <span>
                            I have read the &nbsp;
                            <a
                              onClick={() =>
                                this.openPdfModal(
                                  initialValues.electronicSignatureNotice,
                                  "Notice of Consumer Rights Regarding Electronic Records"
                                )
                              }
                            >
                              Notice of Consumer Rights Regarding Electronic
                              Records
                            </a>
                            .
                          </span>
                        }
                        disabled={!canEdit}
                      />
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="input-box">
                      <Field
                        component={Checkbox}
                        name={"fcraAcknowledgementAuthorization"}
                        label={
                          <span>
                            I authorize SASR to perform a background check in
                            accordance with the &nbsp;
                            <a
                              onClick={() =>
                                this.openPdfModal(
                                  initialValues.fcraAcknowledgement,
                                  "FCRA Authorization Terms"
                                )
                              }
                            >
                              FCRA Authorization Terms
                            </a>
                            .
                          </span>
                        }
                        disabled={!canEdit}
                      />
                    </div>
                  </div>
                </Section>
                {californiaOption && (
                  <Section>
                    <h3>California Option</h3>
                    <div className="input-line">
                      <div className="input-box">
                        <Field
                          component={Checkbox}
                          name={"waiveReport"}
                          label={
                            <span>
                              I waive my right to receive a free copy of any
                              report obtained by SASR that I have a right to
                              view.
                            </span>
                          }
                          disabled={!canEdit}
                        />
                      </div>
                    </div>
                  </Section>
                )}
                <Section>
                  <Signature
                    handleSignatureChange={this.handleSignatureChange}
                    disabled={!canEdit}
                    initialValue={initialValues.signature}
                    name={"signature"}
                  />
                  {canEdit && (
                    <p>
                      By signing this form, you authorize the above image to be
                      used as your electronic signature on all agreements.
                    </p>
                  )}
                </Section>
              </div>
            </React.Fragment>
          }
          footer={
            <React.Fragment>
              <div className="work-disclosures-footer">
                {canEdit ? (
                  <React.Fragment>
                    <Button
                      btnClass="btn-green btn-block"
                      text="Submit"
                      submitting={submitting}
                      disabled={pristine || invalid}
                    />
                    <Button
                      btnClass="btn-cancel btn-block"
                      text="Cancel"
                      onClick={this.props.closeModal}
                    />
                  </React.Fragment>
                ) : (
                  <Button
                    btnClass="btn-cancel btn-block"
                    text="Close"
                    onClick={this.props.closeModal}
                  />
                )}
              </div>
            </React.Fragment>
          }
        />
        {showPdf && (
          <Modal className="lofted modal" modalClose={this.closePdfModal}>
            <div className="modal-header">
              <h3>{pdfModalTitle}</h3>
              <a className="modal-close" onClick={this.closePdfModal}>
                <SvgIcon title="close" name="close-gray" />
              </a>
            </div>
            <div className="pdf-container">
              <iframe src={showPdf} title={pdfModalTitle} />
            </div>
          </Modal>
        )}
      </form>
    )
  }
}

Disclosures = reduxForm({
  form: "disclosures",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(Disclosures)

export default connect(state => ({
  initialValues: deserializeEmploymentInfo(state.data.employment).disclosures,
}))(Disclosures)
