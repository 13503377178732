import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  errorMessage: "",
  contents: [],
}

export default function apiVersion(state = defaultState, action) {
  switch (action.type) {
    case types.READ_API_VERSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_API_VERSION_SUCCESS:
      return {
        ...state,
        contents: action.response.data,
        isLoading: false,
      }
    case types.READ_API_VERSION_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
