import React, { Component } from "react"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"
import "./ExternalModal.scss"

class Unavailable extends Component {
  render() {
    const { closeModal, reason, stepLabel } = this.props
    return (
      <Modal className="lofted modal external-link" modalClose={closeModal}>
        <div className="modal-header">
          <a className="modal-close" onClick={closeModal}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <h3>{stepLabel} Unavailable</h3>
        </div>
        <div className="modal-body">
          <p>{reason}</p>
        </div>
        <div className="modal-footer" />
      </Modal>
    )
  }
}

export default Unavailable
