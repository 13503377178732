import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import Button from "components/Form/Button"
import Modal from "components/Modal"
import PortalWrapper from "components/PortalWrapper"
import TextareaInput from "components/Form/TextareaInput"
import classNames from "classnames"
import SvgIcon from "components/SvgIcon"
import "./Support.scss"
import moment from "moment"

class Support extends Component {
  state = {
    message: "",
    modalOpen: false,
    isSubmitting: false,
  }

  changeMessage = message => {
    this.setState({ message, error: "" })
  }

  openModal = event => {
    event.preventDefault()
    this.setState({ modalOpen: true, message: "", error: "" })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  onSubmit = event => {
    event.preventDefault()

    const { message } = this.state
    const { associate, timesheet } = this.props

    if (!message) {
      this.setState({ error: "Message is required" })
      return
    }

    this.closeModal(event)
    this.setState({ isSubmitting: true })

    const data = {
      request: {
        requester: {
          name: `${associate.attributes.firstName} ${associate.attributes.lastName}`,
          email: associate.attributes.emailAddress,
        },
        subject: `${moment().format("MM/DD/YYYY")} ${
          associate.attributes.firstName
        } ${associate.attributes.lastName} ${associate.attributes.id} Job ID ${
          timesheet.jobId
        }`,
        comment: {
          body: message,
        },
      },
    }

    axios
      .request({
        method: "post",
        url: `https://sasrlink.zendesk.com/api/v2/requests`,
        data: data,
      })
      .catch(() => {})
      .then(() => {
        this.setState({ isSubmitting: false })
      })
  }

  render() {
    const { message, error, modalOpen } = this.state

    return (
      <div className="support">
        <p>Questions or concerns about your timesheet?</p>
        <Button
          btnClass="btn-block btn-green"
          text="Contact Support"
          onClick={this.openModal}
        />
        {modalOpen && (
          <PortalWrapper>
            <Modal className="lofted" modalClose={this.closeModal}>
              <div className="support-modal">
                <div className="modal-header">Contact Support</div>
                <div className="modal-body">
                  <a className="modal-close" onClick={this.closeModal}>
                    <SvgIcon title="close" name="close-gray" />
                  </a>
                  <div className="input-line">
                    <TextareaInput
                      className={classNames({
                        "has-text": message || error,
                        "has-errors": error,
                      })}
                      input={{
                        name: "message",
                        value: message,
                        onChange: this.changeMessage,
                      }}
                      rows="6"
                      label={error || "Message"}
                    />
                  </div>
                  <Button
                    btnClass="btn-block btn-green"
                    text="Send message"
                    onClick={this.onSubmit}
                  />
                </div>
              </div>
            </Modal>
          </PortalWrapper>
        )}
      </div>
    )
  }
}

export default connect(state => ({ associate: state.data.associate }))(Support)
