import React from "react"
import "./Section.scss"

const ModalSection = props => (
  <div
    className={`modal-section ${
      props.type === "cancellation" ? "cancellation" : ""
    }`}
  >
    {props.children}
  </div>
)

export default ModalSection
