export function serializeEmploymentInfo(params, type) {
  let serializedParams = Object.assign({}, { onboarding: [] })
  let content = []

  for (var key in params) {
    if (key !== "type") {
      if (key === "driversLicenseState") {
        content.push({
          name: key,
          value: params[key].initials,
        })
      } else if (
        key === "fullName" ||
        key === "socialSecurityNumber" ||
        key === "dateOfBirth" ||
        key === "driversLicenseNumber"
      ) {
        if (!params[key].id && params[key].value) {
          content.push({
            name: key,
            value: params[key].value,
          })
        }
      } else {
        content.push({
          name: key,
          value: params[key],
        })
      }
    }
  }

  return (serializedParams.onboarding = [
    {
      type: type,
      content: content,
    },
  ])
}

export function deserializeEmploymentInfo(onboarding) {
  let deserializedParams = {}

  if (onboarding.steps) {
    onboarding.steps.forEach(function(step) {
      deserializedParams[step.type] = deserializeEmploymentStep(step)
    })
  }

  return deserializedParams
}

function deserializeEmploymentStep(step) {
  let deserializedParams = {}
  if (step.type === "identification") {
    if (step.content) {
      step.content.forEach(function(attribute) {
        deserializedParams[attribute.name] = attribute
      })
    }
  } else {
    if (step.content) {
      step.content.forEach(function(attribute) {
        deserializedParams[attribute.name] = attribute.value
      })
    }
  }
  return deserializedParams
}
