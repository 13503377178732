import React, { Component, Fragment } from "react"
import { Route, Redirect, Switch, Router } from "react-router-dom"
import ReactGA from "react-ga"
import { connect } from "react-redux"
import { createBrowserHistory } from "history"
import { currentAssociateId } from "utils/auth"
import { ToastContainer, cssTransition } from "react-toastify"
import { restoreSessionAndReadCurrentAssociate } from "actions/session"
import { getVersion } from "actions/version"
import Loading from "components/App/Loading"
import AppRoute from "routes/App"
import PrivateRoute from "routes/Private"
// import ForgotPasswordRoute from "routes/ForgotPassword"
import LoginRoute from "routes/Login"
// import JobPublicRoute from "routes/Jobs/Public"
// import PublicJobSearch from "routes/App/Jobs/PublicSearch"
// import ForcePasswordChangeRoute from "./routes/ForcePasswordChange"
import ScrollToTop from "components/ScrollToTop"
import RefreshMessage from "components/RefreshMessage"
import store from "store"
import Zendesk from "utils/zendesk"
import { toggleNetworkErrorModal } from "actions/session"
import { parse, stringify } from "utils/query"
import Modal from "components/Modal"
import Icon from "components/Icon"
import "utils/localStorage"
import "react-toastify/dist/ReactToastify.css"
import "./App.scss"

const associateId = currentAssociateId()
const history = createBrowserHistory()
const googleAnalyticsEnabled = process.env.REACT_APP_GOOGLE_ANALYTICS_ID !== undefined;

if (googleAnalyticsEnabled) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      userId: associateId,
    },
  })
}

history.listen((location, action) => {
  if (googleAnalyticsEnabled) {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }

  Zendesk.setPageTags(location.pathname)
})

const Slide = cssTransition({
  enter: "Hireflex__slide-enter",
  exit: "Hireflex__slide-exit",
  duration: 450,
})

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      tempPassword: false,
    }

    const parsedParams = parse(window.location.search)
    if (parsedParams.token !== null && parsedParams.token !== undefined) {
      localStorage.setItem("sessionToken", parsedParams.token)
      delete parsedParams.token
      window.location.search = stringify(parsedParams)
    }
  }
  componentDidMount() {
    const token = localStorage.getItem("sessionToken")
    if (token) {
      store
        .dispatch(restoreSessionAndReadCurrentAssociate())
        .then(response => {
          this.setState({ loading: false })
          if (response.data.isPasswordTemporary) {
            history.push("/forcepasswordchange")
          }
        })
        .catch(() => {})
    } else {
      this.setState({ loading: false })
    }

    this.intervalId = setInterval(() => {
      store.dispatch(getVersion())
    }, 60000)
  }

  componentDidUpdate(prevProps) {
    const { appHasBeenUpdated } = this.props

    if (appHasBeenUpdated !== prevProps.appHasBeenUpdated) {
      clearInterval(this.intervalId)
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  networkModalClose() {
    store.dispatch(toggleNetworkErrorModal(false))
    document.location.reload(true)
  }

  render() {
    const { appHasBeenUpdated, isNetworkModalOpen } = this.props

    return (
      <Fragment>
        <Router history={history}>
          <ScrollToTop>
            <div className="app">
              {!this.state.loading ? (
                <React.Fragment>
                  {appHasBeenUpdated && <RefreshMessage />}
                  <Switch>
                    {/*<Route*/}
                    {/*  exact*/}
                    {/*  path="/forcepasswordchange"*/}
                    {/*  component={() => (*/}
                    {/*    <ForcePasswordChangeRoute history={history} />*/}
                    {/*  )}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  exact*/}
                    {/*  path="/jobs/search"*/}
                    {/*  component={PublicJobSearch}*/}
                    {/*/>*/}
                    {/*<Route exact path="/work/:id" component={JobPublicRoute} />*/}
                    <Route
                      exact
                      path="/app"
                      render={() => <Redirect to="/app/assignments" />}
                    />
                    <PrivateRoute path="/app" component={AppRoute} />
                    <Route exact path="/login" component={LoginRoute} />
                    {/*<Route*/}
                    {/*  exact*/}
                    {/*  path="/forgotpassword"*/}
                    {/*  component={ForgotPasswordRoute}*/}
                    {/*/>*/}
                    <Redirect to="/app/assignments" />
                  </Switch>
                  <ToastContainer
                    autoClose={3000}
                    className="toast-alert"
                    position="top-center"
                    hideProgressBar={true}
                    transition={Slide}
                  />
                </React.Fragment>
              ) : (
                <Loading />
              )}
            </div>
          </ScrollToTop>
        </Router>
        {isNetworkModalOpen && (
          <Modal
            className="drug-screen-modal lofted"
            modalClose={this.networkModalClose}
          >
            <div className="modal-header">
              <a className="modal-close" onClick={this.networkModalClose}>
                <Icon name="x" />
              </a>
              <h3>Network Error</h3>
            </div>
            <div className="modal-body">
              <div style={{ padding: "1rem 0 1.5rem" }}>
                It looks like there was an issue with the internet connection.
                Please refresh your page
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={this.networkModalClose}>Refresh</button>
            </div>
          </Modal>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    appHasBeenUpdated: state.version && state.version.updated,
    isNetworkModalOpen: state.session.isNetworkModalOpen,
  }
}

export default connect(mapStateToProps, null)(App)
