import * as types from "actions/types"
import { deserializeTimesheet } from "serializers/timesheets"
import _ from "lodash"
import update from "immutability-helper"

const defaultState = {
  isLoading: false,
  detailId: "",
  data: [],
}

export default function timesheets(state = defaultState, action) {
  switch (action.type) {
    case types.LIST_TIMESHEETS_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: [],
      }
    case types.LIST_TIMESHEETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.detailId
          ? action.response.data.map(timesheet => {
              if (timesheet.id === state.detailId) {
                return deserializeTimesheet(timesheet)
              }
              return timesheet
            })
          : action.response.data,
      }
    case types.LIST_TIMESHEETS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.READ_TIMESHEET_REQUEST:
      return {
        ...state,
        detailId: action.timesheetId,
        isLoading: true,
      }
    case types.READ_TIMESHEET_SUCCESS:
    case types.CREATE_TIMESHEET_SUCCESS:
      let index = _.findIndex(state.data, { id: action.response.data.id })

      if (index >= 0) {
        // the timesheet is in the array
        // console.log("replacing existing timesheet")
        let newData = updateTimesheet(state.data, action)
        state = update(state, {
          data: { $set: newData },
          isLoading: { $set: false },
        })
      } else {
        // not there so we should push it on
        //console.log("pushing onto state")
        state = update(state, {
          data: { $push: [deserializeTimesheet(action.response.data)] },
          isLoading: { $set: false },
        })
      }
      return state
    case types.READ_TIMESHEET_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CLEAR_TIMESHEET:
      return {
        ...state,
        detailId: "",
      }
    default:
      return state
  }
}

function updateTimesheet(timesheetsArray, action) {
  return timesheetsArray.map((timesheet, index) => {
    if (timesheet.id !== action.response.data.id) {
      return timesheet
    }
    return {
      ...timesheet,
      ...deserializeTimesheet(action.response.data),
    }
  })
}
