import React from "react"
import CustomSelect from "components/CustomSelect"

const LodgingTeammate = ({
  value,
  teammates,
  setPreferredRoommate,
  toggleInviteTeammateModal,
}) => {
  return (
    <div className="lodging-teammate">
      <p className="disclaimer red">
        SASR Requires you to have a roommate. If no roommate is selected, SASR
        will select one for you of the same gender.
      </p>
      <div>
        <CustomSelect
          name="lodging.teammates"
          onChange={e => setPreferredRoommate(e)}
          options={teammates}
          isMulti={true}
          value={value}
          labelKey="fullName"
          valueKey="associateId"
          placeholder="Select preferred roommates (from your teammates)"
        />
        <div className="invite-lodging-teammate">
          <a href=" " onClick={e => toggleInviteTeammateModal(e)}>
            + Invite teammate
          </a>
        </div>
      </div>
    </div>
  )
}

export default LodgingTeammate
