import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { connect } from "react-redux"

import TimesheetStatus from "./Status"
import Icon from "components/Icon"
import "./Header.scss"

class Header extends Component {
  static propTypes = {
    timesheet: PropTypes.object,
    previousStep: PropTypes.func,
    review: PropTypes.bool,
    signature: PropTypes.bool,
    totalTime: PropTypes.string,
    associateName: PropTypes.string,
    fields: PropTypes.bool,
  }

  render() {
    const {
      timesheet,
      fields = false,
      review,
      signature,
      totalTime,
      associateName,
      previousStep,
    } = this.props
    let isIncomplete, title, backButton, status, paidDate

    const { isThirdPartyLabor } = this.props.associate

    if (timesheet) {
      status = timesheet.status
      paidDate = timesheet.paidDate

      isIncomplete =
        status &&
        (status.label.toLowerCase() === "incomplete" ||
          status.label.toLowerCase() === "saved")

      if (review) {
        title = "Review your Timesheet"
      } else if (signature) {
        title = ""
      } else {
        title = `Timesheet for ${timesheet.store}`
      }

      if (review) {
        backButton = (
          <React.Fragment>
            <a className="timesheet-header-back" onClick={previousStep}>
              <Icon name="caret-left" width="10" height="14" />
              &nbsp; Back to Edit
            </a>
          </React.Fragment>
        )
      } else if (signature) {
        backButton = (
          <React.Fragment>
            <div className="timesheet-signature-header">
              <a className="timesheet-header-back" onClick={previousStep}>
                <Icon name="caret-left" width="10" height="14" />
                &nbsp; Back
              </a>
              <div className="timesheet-header-signature-title">
                {associateName}
                's Timesheet
              </div>
            </div>
            <div className="timesheet-header-time">
              {totalTime || "0 hours"}
            </div>
          </React.Fragment>
        )
      }
    }

    let klass = classNames("timesheet-header", { minimal: fields })

    return (
      <div className={klass}>
        {backButton}
        {!isIncomplete && (
          <TimesheetStatus
            status={status}
            paidDate={paidDate}
            isPartiallyPaid={timesheet.isPartiallyPaid}
            rejectionReason={timesheet.rejectionReason}
            isThirdPartyLabor={isThirdPartyLabor}
          />
        )}
        {!signature && (
          <div className="timesheet-header-title">
            <h2>{title}</h2>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

export default connect(mapStateToProps, null)(Header)
