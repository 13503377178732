import * as types from "./types"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"

export function readTasks() {
  const associateId = currentAssociateId();

  return dispatch => {
    if (!associateId) {
      dispatch({ type: types.READ_TASKS_FAILURE, error: 'Associate ID is empty' });
      return;
    }
    dispatch({ type: types.READ_TASKS_REQUEST })
    return apiCall("get", `associate/${associateId}/tasks`)
      .then(response => {
        dispatch({ type: types.READ_TASKS_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.READ_TASKS_FAILURE, error })
        throw error
      })
  }
}
