import React, { Component } from "react"

export default class Terms extends Component {
  render() {
    return (
      <div className="terms-content">
        <header>
          <h1 className="marked-header">Terms of Use</h1>
          <h3 className="marked-header">Effective January 25th, 2019</h3>
          <h3>
            PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING
            THIS SITE.
          </h3>
        </header>
        <p>
          Set & Services Resources, LLC and its affiliates and related entities
          (collectively, “Company”) offers this website or any other websites or
          applications under our operation and control (collectively, the
          “Site”) to you conditioned on your acceptance without modification of
          the terms, conditions, and notices contained herein (the “Terms”).
        </p>
        <p>
          BY USING, VIEWING, TRANSMITTING, CACHING, STORING AND/OR OTHERWISE
          UTILIZING THE SITE, YOU HAVE AGREED TO THE TERMS. IF YOU DO NOT AGREE
          TO THE TERMS, CEASEUSING THE SITE IMMEDIATELY.
        </p>
        <h3 className="marked-header">MODIFICATION OF THESE TERMS OF USE</h3>
        <p>
          The Company reserves the right in its sole discretion to change,
          modify, add, or remove the terms, conditions, and notices under which
          the Site is offered at any time without notice. It is your
          responsibility to check periodically for any changes we may make to
          these Terms. Your continued use of this Site following the posting of
          changes to these terms or other policies means you accept the changes.
        </p>
        <h3 className="marked-header">LINKS TO THIRD PARTY SITES</h3>
        <p>
          The Site may contain links to other web sites ("Linked Sites"). The
          Linked Sites are not under the control of the Company and the Company
          is not responsible for the contents of any Linked Site, including
          without limitation any link contained in a Linked Site, or any changes
          or updates to a Linked Site. By providing these links, the Company
          does not endorse, sponsor or recommend such sites or the materials
          disseminated by or services provided by them, and are not responsible
          for the materials, services or other situations at or related to or
          from any other site. The Company is not responsible for webcasting or
          any other form of transmission received from any Linked Site. The
          Company is providing these links to you only as a convenience, and the
          inclusion of any link does not imply endorsement by the Company of the
          site or any association with its operators. We reserve the right to
          disable links from any third party sites to the Site.
        </p>
        <p>
          Please exercise discretion while browsing the Internet and using the
          Site. You should be aware that when you are using the Site, you could
          be directed to other sites that are beyond our control. There are
          links to other sites from the Site pages that take you outside of the
          Site. For example, if you "click" on a banner advertisement or a
          search result, the "click" may take you off the Site. This includes
          links from advertisers, sponsors, and content partners that may use
          our logo(s) as part of a co-branding agreement. These other sites may
          send their own cookies to users, collect data, solicit personal
          information, or contain information that you may find inappropriate or
          offensive. In addition, advertisers on the Site may send cookies to
          users that we do not control.
        </p>
        <h3 className="marked-header">NO UNLAWFUL OR PROHIBITED USE</h3>
        <p>
          The Site is available only to individuals and entities that can form
          legally binding contracts under applicable law. Without limiting the
          foregoing, the Site and the services offered by the Site are not
          available to minors. If you do not qualify, stop using the Site
          immediately.
        </p>
        <p>
          As a condition of your use of the Site, you warrant to the Company
          that you will not use the Site for any purpose that is unlawful or
          prohibited by these terms, conditions, and notices. You may not use
          the Site in any manner which could damage, disable, overburden, or
          impair the Site or interfere with any other party's use and enjoyment
          of the Site. You may not obtain or attempt to obtain any materials or
          information through any means not intentionally made available or
          provided for through the Site.
        </p>
        <p>
          You will not use any robot, spider, other automatic device, or manual
          process to monitor or copy the Site or the contents or information
          contained therein without our prior express written consent. You agree
          that you will not use any device, software or routine to interfere or
          attempt to interfere with the proper working of the Site or any
          transaction being conducted through the Site. You agree that you will
          not copy, reproduce, alter, modify, create derivative works, or
          publicly display any content (except for any Information in which you
          have an ownership interest) from the Site without our prior express
          written consent or the appropriate third party.
        </p>
        <p>
          The information you provide to us (i) shall not contain any viruses,
          Trojan horses, worms, time bombs, cancelbots or other computer
          programming routines that are intended to damage, detrimentally
          interfere with, surreptitiously intercept or expropriate any system,
          data or information; and (ii) shall not create liability for us or
          cause us to lose (in whole or in part) the services of our ISPs or
          other suppliers.
        </p>
        <p>
          If applicable, you may not permit anyone other than yourself to use
          your username or password to gain access to the Site. You will take
          reasonable steps to maintain the privacy of your username and password
          and to prevent unauthorized access to or disclosure of your username
          and password.
        </p>
        <h3 className="marked-header">
          MATERIALS PROVIDED TO THE COMPANY OR POSTED AT ANY COMPANY WEB SITE
        </h3>
        <p>
          Other than personal information that you may submit in order to submit
          an employment application through the Site or in connection with your
          employment with the Company or one of its clients, the Company does
          not claim ownership of the materials you provide to the Company
          (including feedback and suggestions) or post, upload, input or submit
          to any Site or its associated services (collectively "Submissions").
          However, by posting, uploading, inputting, providing or submitting
          your Submission you are granting the Company, its affiliated companies
          and necessary sub licensees permission to use your Submission in
          connection with the operation of the Site and its businesses
          including, without limitation, the rights to: utilize, copy,
          distribute, transmit, publicly display, publicly perform, reproduce,
          edit, translate and reformat your Submission; and to publish your name
          in connection with your Submission, except to the extent prohibited by
          law. None of the Submissions shall be subject to any obligation of
          confidence on our part and we shall not be liable for any use or
          disclosure of any Submissions. Without limitation of the foregoing, we
          shall be entitled to unrestricted use of the Submissions for any
          purpose without compensation to the provider of the Submissions. All
          personal information provided to this Site will be handled in
          accordance with the Site’s online Privacy Policy and the HireFlex
          Applicant Privacy Statement, the terms of which are incorporated into
          the Terms.
        </p>
        <p>
          The Company is under no obligation to post or use any Submission you
          may provide and may in the Company's sole discretion remove any
          Submission at any time, for any reason, without notice to you.
        </p>
        <p>
          By posting, uploading, inputting, providing or submitting your
          Submission you warrant and represent that you own or otherwise control
          all of the rights to your Submission as described in this section
          including, without limitation, all the rights necessary for you to
          provide, post, upload, input or submit the Submissions.
        </p>
        <h3 className="marked-header">
          DISCLAIMERS AND LIMITATION OF LIABILITY
        </h3>
        <p>
          You are entirely responsible for maintaining the confidentiality of
          your password and account and for all activities that occur under your
          account.
        </p>
        <p>
          The Site and the materials located on or through the Site are provided
          by us for informational purposes only, with the understanding that we
          are by the provision of these materials not engaged in the rendering
          of legal or other professional advice or service. The information or
          materials contained in or through the Site are based upon sources
          believed to be accurate and reliable; and we have exercised reasonable
          care to assure the accuracy of the information. However, we make no
          representation or warranty as to the accuracy, completeness or
          timeliness of the information or materials. The information and
          material on this Site should not be relied upon or used as the sole
          basis for making significant decisions without consulting primary or
          more accurate, more complete or timelier sources of information. For
          all of the above reasons, you should consult your own attorney or
          other appropriate professional for advice concerning the information
          and materials, as well as the Terms. Any reliance on the information
          or material on this Site is at your own risk. ADVICE RECEIVED VIA THE
          SITE SHOULD NOT BE RELIED UPON FOR PERSONAL, LEGAL OR FINANCIAL
          DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR
          SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
        </p>
        <p>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
          AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
          ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. THE
          COMPANY AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN
          THE SITE AT ANY TIME.
        </p>
        <p>
          THE COMPANY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
          SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
          THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
          CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES
          AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
          CONDITION OF ANY KIND. THE MAXIMUM EXTENT PERMISSIBLE PURSUANT TO
          APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, DESIGN,
          ACCURACY, CAPABILITY, SUFFICIENCY, SUITABILITY, CAPACITY,
          COMPLETENESS, AVAILABILITY, COMPATIBILITY OR ARISING FROM COURSE OF
          DEALING OR COURSE OF PERFORMANCE.{" "}
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          THE COMPANY AND/OR ITS SUPPLIERS OR THIRD-PARTY CONTENT PROVIDERS BE
          LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
          LIMITATION, DAMAGES FOR ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS,
          DELAYS, COMPUTER VIRUSES, LOSS OF USE, DATA OR PROFITS, UNAUTHORIZED
          ACCESS TO AND ALTERATION OF YOUR TRANSMISSIONS AND DATA, AND OTHER
          TANGIBLE AND INTANGIBLE LOSSES, ARISING OUT OF OR IN ANY WAY CONNECTED
          WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
          TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
          PROVIDE SERVICES, OR FROM ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A
          RESULT OF EMAIL MESSAGES YOU SEND US, OR FOR ANY INFORMATION,
          SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE
          SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED
          ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
          THE COMPANY OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
          POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
          INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
          ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
          TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
          THE SITE.
        </p>
        <p>
          ELECTRONIC COMMUNICATIONS PRIVACY ACT NOTICE (18 U.S.C. 2701-2711): WE
          MAKE NO GUARANTY OF CONFIDENTIALITY OR PRIVACY OF ANY COMMUNICATION OR
          INFORMATION TRANSMITTED ON THE SITE OR ANY WEB SITE LINKED TO THE
          SITE. WE WILL NOT BE LIABLE FOR THE PRIVACY OF THE INFORMATION, E-MAIL
          ADDRESSES, REGISTRATION AND IDENTIFICATION INFORMATION, DISK SPACE,
          COMMUNICATIONS, CONFIDENTIAL OR TRADE-SECRET INFORMATION, OR ANY OTHER
          CONTENT TRANSMITTED OVER NETWORKS ACCESSED BY THE SITE, OR OTHERWISE
          CONNECTED WITH YOUR USE OF THE SITE.
        </p>
        <p>
          In the event we are held liable for any damages related to such
          matters, your sole and exclusive remedy will be limited to
          reimbursement for services or products paid by you to the entity held
          liable which were not provided by such entity. You hereby waive any
          and all rights to bring any claim or action related to such matters in
          any forum beyond one (1) year after the first occurrence of the kind
          of act, event, condition or omission upon which the claim or action is
          based.
        </p>
        <p>
          We cannot ensure that you will be satisfied with any products or
          services that you purchase from the Site or from a third-party site
          that links to or from the Site or third party content on the Site. We
          do not endorse any of the merchandise, nor have we taken any steps to
          confirm the accuracy or reliability of, any of the information
          contained in such third-party sites or content. We do not make any
          representations or warranties as to the security of any information
          (including, without limitation, credit card and other personal
          information) you might be requested to give any third party, and you
          hereby irrevocably waive any claim against with respect to such sites
          and third party content. We strongly encourage you to make whatever
          investigation you feel necessary or appropriate before proceeding with
          any online or offline transaction with any of these third parties.{" "}
        </p>
        <p>
          We may prohibit you from participating in or utilizing the Site if in
          our sole and absolute discretion you show a disregard for the Terms or
          act in an unsportsmanlike manner, with the intent to annoy, abuse,
          threaten, or harass any other person, or in any other disruptive
          manner. We also reserve the right to refuse service, terminate
          accounts, remove or edit content or cancel orders in our sole and
          absolute discretion.
        </p>
        <p>
          If for any reason any portion of the Site is not capable of running as
          planned, including infection by computer virus, bugs, tampering,
          unauthorized intervention, fraud, technical failures, or any other
          causes beyond the reasonable control of the Company which corrupt or
          affect the administration, security, fairness, integrity, or proper
          conduct of the Site, we reserve the right (but not the obligation) in
          our sole and absolute discretion, to prohibit you and any member,
          buyer or supplier (and all of your and their information) from using
          the Site, and to cancel, terminate, modify or suspend the Site or any
          portion thereof and void such information.
        </p>
        <p>
          You also agree that the Company is not responsible or liable in any
          way for injury, loss or damage to your computer or interception or use
          of credit card information, related to or resulting from use of the
          Site or any sites, services or materials linked or related thereto or
          therefrom and also are not responsible or liable in any way for any
          injury, loss, claim or damage relating to or resulting from any part
          of the Site operating or not operating on computers or networks used
          by you or communicating with such computers or networks.
        </p>
        <p>
          To the extent we list or link to third party products or services, our
          site acts as the venue for suppliers to sell products and services
          (or, as appropriate, solicit offers to buy) and buyers to purchase
          such products and services. We are not involved in the actual
          transaction between buyers and suppliers. As a result, we have no
          control over the quality, safety or legality of the items advertised,
          the truth or accuracy of the listings, the ability of suppliers to
          sell items or the ability of buyers to buy items. We cannot ensure
          that a buyer or supplier will actually complete a transaction.
        </p>
        <p>
          We do not control the information provided by other users which is
          made available through the Site. You may find other user's information
          to be offensive, harmful, inaccurate, or deceptive. Please use caution
          and common sense when using the Site. Please note that there are also
          risks of dealing with foreign nationals, underage persons or people
          acting under false pretense.{" "}
        </p>
        <p>
          Although we intend that product descriptions contained in the Site be
          current and accurate, we make no warranty or representation that
          descriptions of products in the Site are accurate, complete, current,
          or reliable in any or all respects. In the event that a product
          described in the Site is not as described, your sole remedy is to
          return it in unused condition in accordance with the suppliers' return
          policy.{" "}
        </p>
        <p>
          The Site may contain technical inaccuracies or typographical errors or
          omissions. The Company is not responsible for any typographical,
          photographic, technical or pricing errors listed on our Site. The
          Company reserves the right to make changes, corrections and/or
          improvements to the Site, and to the products and programs described
          in such information, at any time without notice.
        </p>
        <h3 className="marked-header">INDEMNIFICATION</h3>
        <p>
          You agree to indemnify, defend and hold harmless the Company and its
          affiliates, and their owners, partners, franchisees, subsidiaries,
          officers, each of such person's or entities' directors, employees,
          contractors, agents, licensors and suppliers (collectively, the
          "Indemnified Parties"), from and against any and all allegations,
          demands, claims, liabilities, damages, fines, losses, expenses,
          penalties or costs of whatsoever nature, including reasonable
          attorneys’ fees and court costs, and whether by reason of death of or
          injury to any person or loss of or damage to any property or otherwise
          ("Claims") arising or resulting in any way from any violation of these
          Terms, the services or products provided to you by the Site or any
          related act or failure to act by you and whether or not occasioned or
          contributed to by the negligence of the Company or any agent or
          employee of the Indemnified Parties or any of them (except as and to
          the extent prohibited by applicable law) or Claims arising from your
          account, including, without limitation, any Claims related to
          infringement by you of the intellectual property rights of any person,
          including without limitation, copyright, patent, trade secret, trade
          mark, artist rights, droit moral, privacy, publicity or rights under
          other intellectual property laws. If you cause a technical disruption
          of the Site or the systems transmitting the Site to you or others, you
          agree to be responsible for any and all losses, liabilities, expenses,
          damages and costs, including reasonable attorneys’ fees and court
          costs, arising or resulting from that disruption. In the event that
          any Claim is made or any action or proceeding is brought against the
          Indemnified Parties, or any of them, arising out of or connected with
          the Terms, any such Indemnified Party may, by reasonable notice to
          you, require you, at your expense, to resist such Claim or take over
          the defense of any such action or proceeding and employ counsel for
          such purpose, such counsel to be subject to the prior written approval
          of such Indemnified Party, which approval shall be deemed to have been
          given hereby in the case of counsel acting for your insurance
          underwriters engaged in such resistance or defense. You shall
          cooperate with us in the defense of any Claim. We reserve the right,
          at our own expense, to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by you
        </p>
        <h3 className="marked-header">TERMINATION/ACCESS RESTRICTION</h3>
        <p>
          The Company reserves the right, in its sole discretion, to terminate
          your access to the Site and the related services or any portion
          thereof at any time, for any reason, without notice to you.
        </p>
        <h3 className="marked-header">GENERAL</h3>
        <p>
          To the maximum extent permitted by law, these Terms are governed by
          the laws of the State of North Carolina and you hereby consent to the
          exclusive jurisdiction and venue of courts in North Carolina in all
          disputes arising out of or relating to the use of the Site. You also
          agree that any action at law or in equity arising out of or relating
          to the Terms shall be filed only in the United States District Court
          for the District of North Carolina or, if there is no federal
          jurisdiction over the action, in the courts of the State of North
          Carolina located in <b>[insert county]</b> County, North Carolina. You
          hereby consent and submit to the personal jurisdiction of such courts
          for the purposes of litigating any such action.
        </p>
        <p>
          Use of the Site is unauthorized in any jurisdiction that does not give
          effect to all provisions of these terms and conditions, including
          without limitation this paragraph.
        </p>
        <p>
          You agree that no joint venture, partnership, employment, or agency
          relationship exists between you and the Company as a result of these
          Terms or use of the Site.{" "}
        </p>
        <p>
          The Company's performance of these Terms is subject to existing laws
          and legal process, and nothing contained in these Terms is in
          derogation of the Company's right to comply with governmental, court
          and law enforcement requests or requirements relating to your use of
          the Site or information provided to or gathered by the Company with
          respect to such use.{" "}
        </p>
        <p>
          If any part of these Terms is determined to be invalid or
          unenforceable pursuant to applicable law including, but not limited
          to, the warranty disclaimers and liability limitations set forth
          above, then the invalid or unenforceable provision will be deemed
          superseded by a valid, enforceable provision that most closely matches
          the intent of the original provision and the remainder of these Terms
          shall continue in effect.{" "}
        </p>
        <p>
          Unless otherwise specified herein or agreed to by the user, these
          Terms constitute the entire agreement between the user and the Company
          with respect to the Site and it supersedes all prior or
          contemporaneous communications and proposals, whether electronic, oral
          or written, between the user and the Company with respect to the Site.
          A printed version of these Terms and of any notice given in electronic
          form shall be admissible in judicial or administrative proceedings
          based upon or relating to these Terms to the same extent and subject
          to the same conditions as other business documents and records
          originally generated and maintained in printed form.
        </p>
        <p>
          You represent and warrant that you have the legal right, power and
          authority to agree to the Terms on behalf of yourself and the member,
          buyer or supplier participating in the Site. You further agree that
          your use constitutes an electronic signature as defined by the
          Electronic Signatures in Global and National Commerce Act ("E-Sign")
          and the Uniform Electronic Transactions Act ("UETA") and that you have
          formed, executed, entered into, acgcepted the terms of and otherwise
          authenticated the Terms and acknowledged and agreed that there Terms
          is an electronic record for purposes of E-Sign, UETA and the Uniform
          Computer Information Transactions Act and as such is completely valid,
          has legal effect, is enforceable, and is binding on, and non-refutable
          by you and the member, buyer or supplier on whose behalf you are
          acting.
        </p>

        <footer>
          <p>4820-4853-3360, v. 2</p>
        </footer>
      </div>
    )
  }
}
