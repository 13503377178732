var localStorageMock = (function() {
  var store = {}

  return {
    getItem: function(key) {
      return store[key] || null
    },
    setItem: function(key, value) {
      store[key] = value.toString()
    },
    removeItem: function(key) {
      delete store[key]
    },
    key: function(index) {
      return Object.keys(store)[index] || null
    },
    clear: function() {
      store = {}
    },
    length: (function() {
      return Object.keys(store).length
    })(),
  }
})()

try {
  const testKey = "test_key"
  localStorage.setItem(testKey, testKey)
  localStorage.removeItem(testKey)
} catch (e) {
  Object.defineProperty(window, "localStorage", {
    value: localStorageMock,
  })
}
