import React, { Component } from "react"
import PropTypes from "prop-types"

import Icon from "components/Icon"

import "./Distance.scss"

export default class Distance extends Component {
  static propTypes = {
    distanceInMiles: PropTypes.number,
    location: PropTypes.object.isRequired,
  }

  render() {
    const { distanceInMiles, location } = this.props
    const distance =
      distanceInMiles !== undefined &&
      `${Math.round(distanceInMiles)} Miles From Me`

    const addressClass = distance ? "location-muted" : "location-strong"

    return (
      <div className="job-detail-location">
        <Icon name="pinpoint" height="16" width="14" />
        <span>
          {distance && <span className="location-strong">{distance}</span>}
          <span className={addressClass}>{location.address.full}</span>
        </span>
      </div>
    )
  }
}
