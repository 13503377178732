import React from "react"
import PropTypes from "prop-types"

import TimesheetModal from "components/Timesheets/Modal"

const SubmitTimesheetTask = props => (
  <TimesheetModal
    closeModal={props.closeModal}
    timesheetId={props.timesheetId}
  />
)

SubmitTimesheetTask.propTypes = {
  closeModal: PropTypes.func.isRequired,
  timesheetId: PropTypes.number.isRequired,
}

export default SubmitTimesheetTask
