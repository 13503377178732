import { createSession } from "./session"
import * as types from "./types"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"
import { serializeAssociate } from "serializers/associate"
import { successAlert } from "utils/alerts"

export function createAssociate(params) {
  return dispatch => {
    dispatch({ type: types.CREATE_ASSOCIATE_REQUEST, params })
    return apiCall("post", "associate", { data: params })
      .then(response => {
        dispatch({ type: types.CREATE_ASSOCIATE_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.CREATE_ASSOCIATE_FAILURE, error })
        throw error
      })
  }
}

export function createAssociateAndSession(params) {
  return dispatch => {
    return dispatch(createAssociate(params)).then(() => {
      return dispatch(
        createSession({ email: params.emailAddress, password: params.password })
      ).then(response => {
        return true
      })
    })
  }
}

export function readAssociate(params) {
  return dispatch => {
    dispatch({ type: types.READ_ASSOCIATE_REQUEST, params })
    return apiCall("get", `associate/${params.id}?include=skills,trainings`)
      .then(response => {
        dispatch({ type: types.READ_ASSOCIATE_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_ASSOCIATE_FAILURE, error })
        throw error
      })
  }
}

export function readCurrentAssociate() {
  const associateId = currentAssociateId()
  return readAssociate({ id: associateId })
}

export function resetPassword(params) {
  return dispatch => {
    dispatch({ type: types.RESET_PASSWORD_REQUEST, params })
    return apiCall("post", "password/email", { data: params })
      .then(response => {
        dispatch({ type: types.RESET_PASSWORD_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.RESET_PASSWORD_FAILURE, error })
      })
  }
}

export function updateCurrentAssociate(params, showSuccessAlert = true) {
  params = serializeAssociate(params)
  return dispatch => {
    dispatch({ type: types.UPDATE_ASSOCIATE_REQUEST })
    return apiCall(
      "put",
      `associate/${currentAssociateId()}?include=skills,trainings`,
      {
        data: params,
      }
    )
      .then(response => {
        dispatch({
          type: types.UPDATE_ASSOCIATE_SUCCESS,
          associate: response.data,
        })
        if (showSuccessAlert) successAlert("Your profile has been updated.")
        return response
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_ASSOCIATE_FAILURE, error })
        throw error
      })
  }
}

export function clearErrorMessage() {
  return dispatch => dispatch({ type: types.CLEAR_ASSOCIATE_ERROR_MESSAGE })
}
