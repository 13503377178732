import React, { Component } from "react"
import Button from "components/Form/Button"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"

export default class IdentificationAlert extends Component {
  render() {
    const { modalClose } = this.props

    return (
      <Modal className="lofted modal alert" modalClose={modalClose}>
        <div className="modal-header">
          <a className="modal-close" onClick={modalClose}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <h3>Attention</h3>
        </div>
        <div className="modal-body">
          <p>
            Please ensure that you provide your full “Legal Name” including
            middle initial, correct Social Security Number (on your Social
            Security Card or Birth Certificate), and your exact Date of Birth
            (DOB). Failure to do so may result in a failed background check.
          </p>
        </div>
        <div className="modal-footer">
          <Button
            btnClass="btn-green btn-apply"
            text="OK"
            onClick={modalClose}
          />
        </div>
      </Modal>
    )
  }
}
