import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { deleteSession } from "actions/session"

class PrivateRoute extends Component {
  withRedirect(path, redirectPath) {
    if (redirectPath === undefined) {
      return path
    } else {
      return `${path}?redirect=${encodeURIComponent(redirectPath)}`
    }
  }

  pathWithoutQuery(path) {
    if (path.indexOf("?") > -1) {
      return path.split("?")[0]
    }
    return path
  }

  destinationPath() {
    const currentPath = this.props.location.pathname
    const sessionToken = localStorage.getItem("sessionToken")
    let afterAuthPath = "/app/assignments"
    const defaultPath = "/app/jobs/open"
    let authPath = "/login"
    const activeStatuses = ["Accepted", "Prequalified"]
    const signupPaths = ["/signup/interests", "/signup/personal"]
    const ignoredRedirectPaths = signupPaths
    const wurknowUserPath = "/app/timesheets"
    const wurknowRestrictedPaths = ["/app/assignments", "/app/jobs", "/app/jobs/open"];
    if (ignoredRedirectPaths.indexOf(currentPath) < 0) {
      afterAuthPath = currentPath
    }
    if (signupPaths.indexOf(currentPath) > -1) {
      authPath = "/signup/basic"
    }
    if (this.props.associate.attributes.isWurknowUser === 'Y' && wurknowRestrictedPaths.indexOf(currentPath) > -1) {
      afterAuthPath = wurknowUserPath
    }

    if (sessionToken) {
      if (this.props.isAuthenticated) {
        if (
          activeStatuses.indexOf(
            this.props.associate.attributes.adminAssociateStatusDescription
          ) > -1
        ) {
          // console.log(`valid session with active associate, continue on as planned`)
          return afterAuthPath || defaultPath
        } else if (
          this.props.associate.attributes.adminAssociateStatusDescription ===
          "Incomplete"
        ) {
          if (this.props.associate.attributes.addresses.length <= 0) {
            // console.log(`Associate still signing up without addresses, redirect to step 2`)
            return this.withRedirect(`/signup/personal`, defaultPath)
          } else {
            // console.log(`Associate still signing up with addresses, redirect to step 3`)
            return this.withRedirect(`/signup/interests`, defaultPath)
          }
        } else {
          // console.log(`Associate still loading, try again`)
          return afterAuthPath
        }
      } else {
        // console.log(`Redux still loading, try again`)
        return afterAuthPath
      }
    } else {
      // console.log(`no token in localStorage, redirect to auth`)
      return this.withRedirect(authPath, afterAuthPath)
    }
  }

  render() {
    const { component: Component } = this.props
    const currentPath = this.props.location.pathname
    const destinationPath = this.destinationPath()
    // console.log(`rendering path: ${currentPath}, destination: ${destinationPath}`)

    return (
      <Route
        render={props =>
          this.pathWithoutQuery(destinationPath) ===
          this.pathWithoutQuery(currentPath) ? (
            <Component {...this.props} />
          ) : (
            <Redirect to={destinationPath} />
          )
        }
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.session.isAuthenticated,
    associate: state.data.associate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        deleteSession,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
