import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PaginationNext from "components/PaginationNext"
import PaginationPrev from "components/PaginationPrev"
import classNames from "classnames"
import _ from "lodash"
import "./Pagination.scss"

export default class ReactTablePagination extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
    pages: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    canNext: PropTypes.bool.isRequired,
    canPrevious: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super()
    this.state = {
      page: props.page,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.page })
  }

  getSafePage = page => {
    if (Number.isNaN(page)) {
      page = this.props.page
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage = page => {
    page = this.getSafePage(page)
    this.setState({ page })
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  applyPage = e => {
    if (e) {
      e.preventDefault()
    }
    const page = this.state.page
    this.changePage(page === "" ? this.props.page : page)
  }

  render() {
    const {
      // Computed
      pages,
      total,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
    } = this.props

    const pageRange = total > pageSize ? _.range(pages) : [0]
    const pageLinks = pageRange.map(page => {
      const lowerItem = page * pageSize + 1
      const upperBound = (page + 1) * pageSize
      const upperItem = upperBound < total ? upperBound : total

      const linkClass = classNames("page-link", {
        current: page === this.state.page,
      })

      return (
        <span
          key={page}
          className={linkClass}
          onClick={e => {
            e.preventDefault()
            this.setState({ page: this.getSafePage(page) }, () => {
              this.applyPage()
            })
          }}
        >
          {lowerItem}
          {lowerItem !== upperItem && `- ${upperItem}`}
        </span>
      )
    })

    return (
      <div
        className={classnames(className, "-pagination")}
        style={this.props.style}
      >
        <div className="-previous">
          <PaginationPrev
            handlePreviousPage={() => {
              if (!canPrevious) return
              this.changePage(page - 1)
            }}
            disabled={!canPrevious}
          />
        </div>
        <div className="-center">
          <span className="-pageInfo">
            {pageLinks}
            {showPageJump ? (
              <div className="-pageJump">
                {this.props.pageText}{" "}
                <input
                  type={this.state.page === "" ? "text" : "number"}
                  onChange={e => {
                    const val = e.target.value
                    const page = val - 1
                    if (val === "") {
                      return this.setState({ page: val })
                    }
                    this.setState({ page: this.getSafePage(page) })
                  }}
                  value={this.state.page === "" ? "" : this.state.page + 1}
                  onBlur={this.applyPage}
                  onKeyPress={e => {
                    if (e.which === 13 || e.keyCode === 13) {
                      this.applyPage()
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </span>
          {showPageSizeOptions && (
            <span className="select-wrap -pageSizeOptions">
              <select
                onChange={e => onPageSizeChange(Number(e.target.value))}
                value={pageSize}
              >
                {pageSizeOptions.map((option, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={i} value={option}>
                    {`${option} ${this.props.rowsText}`}
                  </option>
                ))}
              </select>
            </span>
          )}
        </div>
        <div className="-next">
          <PaginationNext
            handleNextPage={() => {
              if (!canNext) return
              this.changePage(page + 1)
            }}
            disabled={!canNext}
          />
        </div>
      </div>
    )
  }
}
