import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SvgIcon from "components/SvgIcon"
import "./Button.scss"

export default class Button extends Component {
  static propTypes = {
    btnClass: PropTypes.string,
    disabled: PropTypes.bool,
    submitting: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      success: this.props.succeeded,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.succeeded && nextProps.succeeded) {
      this.setState({ success: true })
      setTimeout(() => {
        this.setState({ success: false })
      }, 3000)
    }
  }

  render() {
    const { btnClass, disabled, submitting, onClick, text } = this.props

    let buttonClass = classNames("btn", btnClass, {
      saved: this.state.success,
    })

    return (
      <button
        disabled={disabled || submitting}
        className={buttonClass}
        onClick={onClick}
        type="submit"
      >
        <span className="active">
          {submitting ? <SvgIcon name="submitting" title="Loading" /> : text}
        </span>
        <span className="success">
          <SvgIcon name="check" title="Saved" />
        </span>
      </button>
    )
  }
}
