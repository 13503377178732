import React from "react"
import { Field } from "redux-form"
import Select from "components/Form/Select"

import "./LocationInterest.scss"

const LocationInterest = ({ radiusOptions, location }) => (
  <div className="location-interest-box">
    <div className="within-text">Within a</div>
    <div className="input-box mile-select">
      <Field
        name="radius"
        component={Select}
        labelKey="id"
        valueKey="label"
        options={radiusOptions || []}
      />
    </div>
    <div className="mile-text">mile radius of</div>
    <div className="input-box location-input">
      <span className="address-label">
        {location
          ? `  ${location.streetAddress1}, ${location.city}, ${location.state.initials} ${location.zipCode} `
          : null}
      </span>
      <span>(your home address).</span>
    </div>
  </div>
)

export default LocationInterest
