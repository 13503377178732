export function urlify(text) {
  let urlRegex = /(ftp:|http:|https?:\/\/[^\s, <]+)/g
  return text.replace(urlRegex, url => {
    return (
      '<span class="instructions-link"><a href="' +
      url +
      '" target="_blank">' +
      url +
      "</a></span>"
    )
  })
}
