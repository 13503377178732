import React from "react"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"
import "./FileModal.scss"

const FileModal = ({ source, title, closeModal }) => {
  return (
    <Modal className="lofted file-modal" modalClose={closeModal}>
      <div className="modal-header">
        <h3>{title}</h3>
        <a className="modal-close" onClick={closeModal}>
          <SvgIcon title="close" name="close-gray" />
        </a>
      </div>
      <div className="pdf-container">
        <iframe src={source} title={title} />
      </div>
    </Modal>
  )
}

export default FileModal
