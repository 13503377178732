import {
  SET_LODGING_ERROR,
  CLEAR_LODGING_ERRORS,
  CLEAR_LODGING_FIELD_ERROR,
} from "actions/types"

export const setLodgingError = (field, message) => {
  return dispatch => {
    dispatch({ type: SET_LODGING_ERROR, payload: { field, message } })
  }
}

export const clearLodgingErrors = () => {
  return dispatch => {
    dispatch({ type: CLEAR_LODGING_ERRORS })
  }
}

export const clearLodgingFieldError = field => {
  return dispatch => {
    dispatch({ type: CLEAR_LODGING_FIELD_ERROR, payload: { field } })
  }
}
