import React, { Component } from "react"
import SignatureCanvas from "react-signature-canvas"
import ContainerDimensions from "react-container-dimensions"
import { Field } from "redux-form"

import Icon from "components/Icon"

export default class Signature extends Component {
  constructor(props) {
    super(props)
    this.state = { pristine: true }
  }

  trimSignature = () => {
    const trimmedDataURL = this.signaturePad
      .getTrimmedCanvas()
      .toDataURL("image/png")
    this.setState({
      pristine: false,
      trimmedDataURL: trimmedDataURL,
    })
    this.props.handleSignatureChange(trimmedDataURL)
  }

  clearSignature = () => {
    this.signaturePad.clear()
    this.setState({ pristine: true, trimmedDataURL: "" })
    this.props.handleSignatureChange("")
  }

  associateSignature = () => {
    return (
      <div className="associate-signature-wrapper">
        <ContainerDimensions>
          {({ width, height }) => (
            <SignatureCanvas
              penColor="black"
              onEnd={this.trimSignature}
              ref={ref => {
                this.signaturePad = ref
              }}
              canvasProps={{
                width: width - 15,
                height: height - 15,
                className: "associate-signature-canvas",
              }}
            />
          )}
        </ContainerDimensions>
      </div>
    )
  }

  render() {
    const { disabled, initialValue, name } = this.props
    const { pristine } = this.state
    return (
      <React.Fragment>
        <div className="associate-signature-header">
          <h3>Authorized Signature</h3>
          {!pristine &&
            !disabled && (
              <div className="clear-signature" onClick={this.clearSignature}>
                Clear &nbsp;
                <Icon name="x" width="10" height="12" />
              </div>
            )}
        </div>
        <div className="input-line">
          <div className="input-box">
            {!disabled ? (
              <React.Fragment>
                <Field
                  component={this.associateSignature}
                  name={name}
                  label={"Signature"}
                  {...{ handleSignatureChange: this.handleSignatureChange }}
                />
                <div className="associate-signature-label">Sign Above</div>
              </React.Fragment>
            ) : (
              <div className="associate-signature-wrapper">
                <img src={initialValue} alt="signature" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
