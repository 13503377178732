import React, { Component } from "react"

export default class Privacy extends Component {
  render() {
    return (
      <div className="terms-content">
        <header>
          <h1 className="marked-header">
            SET & SERVICE RESOURCES,LLC
            <br />
            PRIVACY POLICY
          </h1>
        </header>
        <h3 className="marked-header">
          Effective/Updated as of January 25<sup>th</sup>, 2019
        </h3>
        <p>
          Set & Services Resources, LLC and its affiliates and related entities
          (collectively, “Company”) is/are committed to protecting the privacy
          of certain data that is collected from you and that you submit when
          you access this website or websites or application under our operation
          and control (collectively, the “Site”). We recognize the sensitive
          nature of much of the information with which we are entrusted on a
          daily basis, and we are committed to the high standards of privacy and
          security. The nature of our business requires that we gather
          information that is of a personal nature and that you wish to keep
          protected. This Privacy Policy (“Policy”) is intended to familiarize
          you with our policies for the gathering, use, maintenance and
          protection of personal information on the Site.
        </p>
        <p>
          More specifically, this Policy summarizes how the Company handles the
          personal and other information users of the Site provide while
          accessing and using the Site. Please review this Policy carefully. The
          Company reserves the right to change its Policy at any time, in which
          event the Site will contain a notice of the change. Changes will be
          effective immediately upon posting to the Site. By accessing the Site,
          you are indicating your agreement to the Company’s Policy. If you have
          any questions or concerns about the Policy, please contact us using
          the information provided below.
        </p>
        <p>
          <b>Information Collected.</b> As you are probably aware, we may
          collect information from a variety of sources. We collect and use this
          information in order to carry out our business functions and provide
          the highest quality of service. In general, the Company may compile
          statistical information concerning the usage of the Site. This
          information allows the Company to monitor its utilization and
          continuously improve its quality. Examples of this information would
          include, but not be limited to, the number of visitors to the Site, or
          to sections or pages within the Site, patterns of traffic flowing
          through the Site, length of time spent on the Site, or in sections or
          pages of the Site, the other sites that refer visitors to the Site,
          the pages of the Site that visitors frequently use as entry and exit
          points, utilization of the browser and operating systems and versions
          used by visitors to the Site.
        </p>
        <p>
          In order to compile this information, the Company may collect and
          store your IP address, your operating system version, your browser
          version, the pages you visit within the Site, the length of time you
          spend on pages within the Site, the site from which you linked to
          ours, search terms you used in search engines which resulted in you
          linking to the Site, etc. While all of this information can be
          associated with the IP address your computer had while you visited the
          Site, it will not be associated with you as an individual, or
          associated with any other information you may submit through the Site,
          or that Company may store about you for any other purposes.
        </p>
        <p>
          Additionally, if you decide to provide your personal information, the
          types of personal information we may collect about you through the
          Site includes: (i) your contact information, such as name, address,
          e-mail address, phone numbers; (ii) account set-up and payment
          information when you desire to use and purchase our services, such as
          credit or debit card account numbers; (iii) employment history
          information if you seek employment with us which might include your
          work history and salary information; and (iv) when using our time
          management application, information on hours worked and geolocation.
        </p>
        <p>
          The Company may use web site “cookies” in the process of compiling the
          statistical information mentioned above and to tailor certain pages of
          the Site for your browsing convenience. A cookie file can contain
          information such as the URL you came from, your computer's IP address
          and domain type (e.g., .com or .org, etc.), your browser type, the
          country, state and telephone area code where your server is located,
          the pages of our site that were viewed during a visit, and any search
          terms that you entered on our site. This information generally is used
          for internal purposes, and we do not link your URL or IP address to
          any personal information unless you have logged into our Site with an
          established account login and password. You have the option of setting
          your browser to reject cookies, but our Site may not function
          correctly if you do. Refer to the Help feature of your browser for
          information on disabling cookies.
        </p>
        <p>
          The Site is not directed to children. The Company does not knowingly
          collect any personal information from children. If you are concerned
          about your child’s use of the Site, you may use web-filtering
          technology to supervise or limit access to the Site.
        </p>
        <p>
          <b>Use of Information.</b> In general, we will use the information we
          collect about you only for the purpose it was collected, as otherwise
          provided in this Policy, and as permitted or required by law. For
          example, we might use your information to enhance our Site, or to
          communicate with you about the Site or our products and services.
        </p>
        <p>
          <b>Disclosure of Information.</b> In general, we will not disclose
          your information for any purposes other than those in conjunction with
          the business activities requested of us unless otherwise permitted or
          required by law or by this Policy. In the course of our normal
          business activities, we may disclose collected information to both
          affiliates and non-affiliated entities, as we deem necessary. For
          example, and without limitation, the Company may need to use or
          disclose this information with its third party vendors to manage its
          website, respond to subpoenas, defend litigation, enhance its
          services, and protect the Company’s legal rights. We also may share
          applicant and time management information with our clients who use our
          services. We endeavor to choose affiliates and non-affiliates with
          similar standards to ours regarding the protection of personal
          information.{" "}
        </p>
        <p>
          In no case will the Company sell or license personal information to
          third parties, except as required or permitted by law. For example, we
          may share personal information in connection with certain business
          transactions, such as the acquisition of all or substantially all of
          the Company’s assets. In such cases, we will take appropriate steps
          under the circumstances and to the extent possible to ensure that the
          recipient agrees to provide privacy protections substantially similar
          to those established by this Policy.
        </p>
        <p>
          <b>Do Not Track.</b> “Do Not Track” is a privacy preference that you
          can set in your Internet search browser that sends a signal to a
          website that you do not want the website operator to track certain
          browsing information about you. However, because our Site is not
          configured to detect Do Not Track signals from a user’s computer, we
          are unable to respond to Do Not Track requests.{" "}
        </p>
        <p>
          <b>Modifying Your Personal Information.</b> If you would like to
          update your personal information on the Site, you may <b>login to</b>{" "}
          <a href="https://www.myhireflex.com">www.myhireflex.com</a>{" "}
          <b>
            with your email address and password and change your settings on the
            Personal Info, Employment Info, Work Interests and Account section.
          </b>{" "}
          Also, if you wish to unsubscribe from any of our newsletters or other
          communications for which you have registered, you may e-mail us at{" "}
          <a className="mailto" href="mailto:support@sasrlink.com">
            support@sasrlink.com.
          </a>
        </p>
        <p>
          <b>Safeguarding of Information.</b> No system for safeguarding
          personal or other information is 100% secure. However, we take a
          number of steps to safeguard the security of personal information
          obtained through the Site. For example, we use Secure Socket Layer
          (SSL) data encryption when data is transmitted over the Internet to
          our Site. We have installed layered firewalls and other security
          technologies to help prevent unauthorized access to our systems. Our
          data centers are maintained in a secure environment with appropriate
          security measures. Our employees are trained to understand the
          importance of confidentiality and are required to adhere to our
          privacy policies and procedures. Employees who violate these policies
          and procedures are subject to disciplinary action. We employ numerous
          practices to protect against the disclosure of information for
          purposes unrelated to the performance of our business functions or to
          individuals other than those who must use it in the course of their
          work activities. These practices include the use of password
          protection of computer files, e-mail and voice-mail, video
          surveillance, and other physical, electronic, and procedural
          safeguards.
        </p>
        <p>
          <b>Applicable law.</b> This Policy is governed by the internal
          substantive laws of Maryland, without regard to its conflict of laws
          principles. Jurisdiction for any claims arising under or out of this
          Privacy Policy shall lie exclusively with the state and federal courts
          within Maryland. If any provision of this Policy is found to be
          invalid by a court having competent jurisdiction, the invalidity of
          such provision shall not affect the validity of the remaining
          provisions of this Policy, which shall remain in full force and
          effect.
        </p>
        <p>
          <b>Contact Us.</b> If you have any questions regarding this Policy or
          our policies in the event of a compromise of your information, you may
          contact us at:
        </p>
        <p>
          Set and Service Resources; 5400 Glenwood Avenue, Suite 310, Raleigh,
          NC 27615; (919) 787-5571; support@sasrlink.com
        </p>
        <footer>
          <p>4841-6252-7856, v. 2</p>
        </footer>
      </div>
    )
  }
}
