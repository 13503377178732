import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { listTimesheets, clearTimesheet } from "actions"
import { readTasks } from "actions/tasks"

import TimesheetTable from "components/Timesheets/Table"

const mapStateToProps = state => {
  return {
    timesheets: state.data.timesheets,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        listTimesheets,
        clearTimesheet,
        readTasks,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetTable)
