import React, { Component } from "react"
import PropTypes from "prop-types"
import Month from "./Month"
import moment from "moment"
import _ from "lodash"
import "./Calendar.scss"

export default class Calendar extends Component {
  static propTypes = {
    canApply: PropTypes.bool,
    conflictDates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    handleChange: PropTypes.func,
    jobDates: PropTypes.array.isRequired,
    selectedDates: PropTypes.array,
  }

  static defaultProps = {
    canApply: false,
    conflictDates: {},
    jobDates: [],
    selectedDates: [],
  }

  buildMonthObject(date) {
    const monthIndex = moment(date).month()
    const startOfMonth = moment(date).startOf("month")
    const startDay = moment(startOfMonth).day()
    const daysInMonth = moment(startOfMonth).daysInMonth()
    const jobDatesIndices = this.parseJobDates(startOfMonth)
    const conflictDateIndices = this.parseConflictDates(startOfMonth)
    const unavailableDateIndices = this.parseUnavailableDates(startOfMonth)
    const penaltyDateIndices = this.parsePenaltyDates(startOfMonth)

    return Object.assign(
      {},
      {
        monthIndex,
        startOfMonth,
        startDay,
        daysInMonth,
        jobDatesIndices,
        conflictDateIndices,
        unavailableDateIndices,
        penaltyDateIndices,
      }
    )
  }

  getActiveMonths = () => {
    const { jobDates } = this.props

    if (!jobDates) return []

    const startDate = moment(jobDates[0])
    const endDate = moment(jobDates[jobDates.length - 1])

    let activeMonths = []

    while (
      endDate > startDate ||
      startDate.format("M") === endDate.format("M")
    ) {
      activeMonths.push(this.buildMonthObject(startDate.format("YYYY-MM")))
      startDate.add(1, "month")
    }

    return activeMonths
  }

  parseJobDates = month => {
    const { jobDates } = this.props

    let jobDatesIndices = []

    if (jobDates.length > 0) {
      for (let i = 0; i <= jobDates.length; i++) {
        let start = { ...month }
        if (jobDates[i]) {
          let diff = moment(jobDates[i]).diff(start, "days")
          jobDatesIndices.push(diff + 1)
        }
      }
    }

    return jobDatesIndices
  }

  parseConflictDates = month => {
    const { conflictDates } = this.props

    let conflictDateIndices = []

    if (conflictDates) {
      for (let job in conflictDates) {
        let start = { ...month }
        if (
          conflictDates.hasOwnProperty(job) &&
          conflictDates[job] &&
          Array.isArray(conflictDates[job]) &&
          conflictDates[job].length > 0
        ) {
          conflictDates[job].forEach(conflictDate => {
            let diff = moment(conflictDate).diff(start, "days")
            conflictDateIndices.push(diff + 1)
          })
        }
      }
    }
    return conflictDateIndices
  }

  parseUnavailableDates = month => {
    const { unavailableDates } = this.props

    let unavailableDateIndices = []

    var daysInMonth = []
    var monthDate = moment(month).startOf("month")
    _.times(monthDate.daysInMonth(), function(n) {
      daysInMonth.push(monthDate.format("YYYY-MM-DD"))
      monthDate.add(1, "day")
    })

    let checker = (arr, target) => target.every(v => arr.includes(v))

    if (unavailableDates && checker(daysInMonth, unavailableDates)) {
      // let start = { ...month }
      let start = moment(month).startOf("month")
      unavailableDates.forEach(unavailableDate => {
        let diff = moment(unavailableDate).diff(start, "days")
        unavailableDateIndices.push(Math.abs(diff + 1))
      })
    }
    return unavailableDateIndices
  }

  parsePenaltyDates = month => {
    const { penaltyDates } = this.props

    let penaltyDateIndices = []

    if (penaltyDates && penaltyDates.length > 0) {
      for (let i = 0; i <= penaltyDates.length; i++) {
        let start = { ...month }
        if (penaltyDates[i]) {
          let diff = moment(penaltyDates[i]).diff(start, "days")
          penaltyDateIndices.push(diff + 1)
        }
      }
    }
    return penaltyDateIndices
  }

  render() {
    const activeMonths = this.getActiveMonths()
    const { canApply, assignmentCalendar } = this.props
    return (
      <div className="vw-cal">
        {activeMonths.map(month => (
          <Month
            assignmentCalendar={assignmentCalendar}
            canApply={canApply}
            key={month.monthIndex}
            monthIndex={month.monthIndex}
            startDay={month.startDay}
            daysInMonth={month.daysInMonth}
            startOfMonth={month.startOfMonth}
            jobDatesIndices={month.jobDatesIndices}
            penaltyDateIndices={month.penaltyDateIndices}
            conflictDateIndices={month.conflictDateIndices}
            selectedDates={this.props.selectedDates}
            handleSelectDate={this.props.handleChange}
            handleToggleMonth={this.props.handleToggleMonth}
            unavailableDateIndices={month.unavailableDateIndices}
          />
        ))}
      </div>
    )
  }
}
