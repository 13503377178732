import React from "react"
import PropTypes from "prop-types"

import AssignmentModal from "components/Assignment/Modal"

const ConfirmTask = props => (
  <AssignmentModal
    applicationId={props.applicationId}
    handleClose={props.handleClose}
    history={props.history}
  />
)

ConfirmTask.propTypes = {
  applicationId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default ConfirmTask
