export function pickKeys(obj, ...props) {
  return Object.assign({}, ...props.map(prop => ({ [prop]: obj[prop] })))
}

export function fetchFromObject(obj, str) {
  if (!obj || !str) return false

  const i = str.indexOf(".")

  if (i > -1)
    return fetchFromObject(obj[str.substring(0, i)], str.substr(i + 1))

  return obj[str]
}

export const removePropertyFromObject = (propertyToRemove, source) => {
  return Object.keys(source).reduce((destination, property) => {
    if (property !== propertyToRemove) {
      destination[property] = source[property]
    }

    return destination
  }, {})
}
