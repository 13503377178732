import { toast } from "react-toastify"

export function successAlert(message) {
  toast.success(message)
}

export function errorAlert(message) {
  toast.error(message)
}

export const alertEmail = status => {
  if (status === "Denied") {
    return "compliance@sasrlink.com"
  }

  return "employeerelations@sasrlink.com"
}
