import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import Cancel from "components/Job/Cancel"
import Distance from "components/Job/Distance"
import MapLocation from "components/Job/MapLocation"
import SideModal from "components/SideModal"
import Summary from "components/Job/Summary"
import Header from "components/Job/Header"
import SupervisorInfo from "components/Job/SupervisorInfo"
import Section from "components/Modal/Section"
import ExpandableSection from "components/ExpandableSection"
import Icon from "components/Icon"
import { urlify } from "utils/urlify"
import Calendar from "components/Calendar/Calendar"
import moment from "moment"
import momentTimezone from "moment-timezone"
import DayContext from "components/Schedule/DayContext"
import { MIN_HOURS_TO_CANCEL_JOB } from "consts.js"
import "./SlotModal.scss"

const cancellationType = "cancellation"

class SlotModal extends Component {
  static contextType = DayContext

  constructor(props) {
    super(props)
    this.state = {
      selectedDate: "",
      selectedDates: [],
      selectedDatesOrigin: [],
      unavailableDates: [],
      penaltyScore: 0,
      modified: false,
    }
  }

  componentDidMount() {
    const time = momentTimezone.tz(
      moment(),
      this.props.slot.job.location.timezone.name
    )

    const currentTime = moment(time).utcOffset(
      this.props.slot.job.location.timezone.name
    )

    const isCheckedIn = this.props.slot.checkedIn === "Y"
    const now = currentTime.format("YYYY-MM-DDTHH:mm:ss")

    currentTime.utcOffset(time.utcOffset())

    let availableDates = this.props.slot.assignment.slotDates.accepted.filter(
      date => {
        const diff = moment(date + "T" + this.props.slot.shift.start).diff(now)

        if (diff <= 0) return false

        return !isCheckedIn
      }
    )

    let unavailableDates = this.props.slot.assignment.slotDates.accepted.filter(
      date => {
        const diff = moment(date + "T" + this.props.slot.shift.start).diff(now)

        if (diff <= 0) return true

        return isCheckedIn
      }
    )

    let penaltyDates = this.props.slot.assignment.slotDates.accepted.filter(
      date =>
        moment(date + "T" + this.props.slot.shift.start).diff(
          currentTime.format("YYYY-MM-DDTHH:mm:ss"),
          "H"
        ) >= MIN_HOURS_TO_CANCEL_JOB &&
        moment(date + "T" + this.props.slot.shift.start).diff(
          currentTime.format("YYYY-MM-DDTHH:mm:ss"),
          "H"
        ) <= 120
    )

    this.setState(
      {
        availableDates: availableDates,
        selectedDate: this.context,
        selectedDatesOrigin: availableDates,
        unavailableDates: unavailableDates,
        penaltyDates: penaltyDates,
      },
      () => {
        if (availableDates.includes(this.state.selectedDate)) {
          this.setState(
            {
              selectedDates: [
                ...this.state.selectedDates,
                this.state.selectedDate,
              ],
            },
            () => {
              if (this.state.selectedDates.length > 0) {
                this.setState({ modified: true })
              } else {
                this.setState({ modified: false })
              }
            }
          )
        } else {
          this.setState(
            {
              selectedDates: [],
            },
            () => {
              if (this.state.selectedDates.length > 0) {
                this.setState({ modified: true })
              } else {
                this.setState({ modified: false })
              }
            }
          )
        }
      }
    )

    let difference = moment(
      this.context + "T" + this.props.slot.shift.start
    ).diff(currentTime.format("YYYY-MM-DDTHH:mm:ss"), "H")

    if (difference > 24 && difference <= 120) {
      this.setState({
        penaltyScore: this.state.penaltyScore - 10,
      })
    } else if (difference <= 24) {
      this.setState({
        penaltyScore: this.state.penaltyScore - 100,
      })
    }
  }

  updateDates = (clickedDate, checked) => {
    const time = momentTimezone.tz(
      moment(),
      this.props.slot.job.location.timezone.name
    )
    const currentTime = moment(time).utcOffset(
      this.props.slot.job.location.timezone.name
    )

    let difference = moment(
      clickedDate + "T" + this.props.slot.shift.start
    ).diff(currentTime.format("YYYY-MM-DDTHH:mm:ss"), "H")
    let updatedDates = []
    if (
      checked &&
      this.props.slot.job.slotDates.assigned.includes(clickedDate)
    ) {
      updatedDates = [...this.state.selectedDates, clickedDate]
      if (difference > 24 && difference <= 120) {
        this.setState({
          penaltyScore: this.state.penaltyScore - 10,
        })
      } else if (difference <= 24) {
        this.setState({
          penaltyScore: this.state.penaltyScore - 100,
        })
      }
    } else {
      if (difference > 24 && difference <= 120) {
        this.setState({
          penaltyScore: this.state.penaltyScore + 10,
        })
      } else if (difference <= 24) {
        this.setState({
          penaltyScore: this.state.penaltyScore + 100,
        })
      }

      updatedDates = this.state.selectedDates.filter(
        date => date !== clickedDate
      )
    }

    this.setState({ selectedDates: updatedDates }, () => {
      if (this.state.selectedDates.length > 0) {
        this.setState({ modified: true })
      } else {
        this.setState({ modified: false })
      }
    })
  }

  render() {
    const { handleClose, slot, associateId, openUnavailableRosterModal } =
      this.props

    const {
      selectedDates,
      unavailableDates,
      availableDates,
      penaltyScore,
      penaltyDates,
      modified,
    } = this.state

    const job = slot.job
    const instructions = urlify(job.instructions)
    const assignment = slot.assignment
    const assignmentInstructions = assignment.instructions
      ? urlify(assignment.instructions)
      : ""
    const supervisor = slot.supervisor
    const osc = slot.fieldManagement.contact
    const isOSCDesignated =
      osc && slot.fieldManagement.contact.associateId === associateId

    const attachments =
      slot &&
      slot.attachments &&
      slot.attachments.map(attachment => {
        return (
          <li>
            <a href={attachment.link}>{attachment.name}</a>
          </li>
        )
      })

    return (
      <SideModal
        closeModal={handleClose}
        header={<Header job={job} />}
        body={
          <Fragment>
            <Distance
              distanceInMiles={assignment.travel && assignment.travel.distance}
              location={job.store.location}
            />

            {osc && isOSCDesignated && (
              <Section>
                <div className="section-item">
                  <div className="section-item-info">
                    <h3>You are the Onsite Contact</h3>
                    <p>
                      You have been assigned as the Onsite Contact (OSC) for
                      this shift.
                    </p>
                  </div>
                  <div className="section-item-action">
                    {slot.fieldManagement?.rosterUrl ? (
                      <a
                        className="btn btn-green"
                        href={slot.fieldManagement.rosterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Open Shift Roster</span>
                        <Icon name="external" width="11" height="11" />
                      </a>
                    ) : (
                      <button
                        className="btn btn-green"
                        onClick={openUnavailableRosterModal}
                      >
                        <span>Open Shift Roster</span>
                        <Icon name="external" width="11" height="11" />
                      </button>
                    )}
                  </div>
                </div>
              </Section>
            )}

            <Section>
              <Summary
                job={job}
                payRate={assignment.wage.proposedRate}
                endDate={job.shift.date.end}
                startDate={job.shift.date.start}
                durationDays={job.shift.date.duration}
                travelIncentive={assignment.travel.proposedRate}
                application={{ contents: assignment }}
              />
            </Section>

            <Section>
              <MapLocation job={job} />
            </Section>

            {supervisor && (
              <Section>
                <SupervisorInfo supervisor={supervisor} />
              </Section>
            )}

            {attachments.length > 0 && (
              <Section>
                <ExpandableSection title="Attachments" content={attachments} />
              </Section>
            )}

            {(assignment.instructions || job.instructions) && (
              <Section>
                <ExpandableSection
                  title="Instructions"
                  text={assignmentInstructions || instructions}
                />
              </Section>
            )}
            {job.comments && (
              <Section>
                <ExpandableSection title="Comments" text={job.comments} />
              </Section>
            )}
            <Section type={cancellationType}>
              <ExpandableSection
                title="Cancel Assigned Days"
                className="cancellation"
                type={cancellationType}
                content={
                  <Fragment>
                    {availableDates && availableDates.length > 0 ? (
                      <p className="disclaimer red">
                        Please select date(s) which you would like to cancel.
                      </p>
                    ) : (
                      <p className="disclaimer red">
                        There are no date(s) which you could cancel.
                      </p>
                    )}
                    <Calendar
                      assignmentCalendar={true}
                      jobDates={availableDates}
                      selectedDates={selectedDates}
                      unavailableDates={unavailableDates}
                      penaltyDates={penaltyDates}
                      handleChange={this.updateDates}
                      canApply={true}
                    />
                    <Cancel
                      canCancel={slot.assignment.availableActions.canCancel}
                      handleClose={handleClose}
                      job={job}
                      penaltyScore={penaltyScore}
                      modified={modified}
                      applicationId={slot.assignment.id}
                      unselectedDatesList={selectedDates}
                    />
                  </Fragment>
                }
              />
            </Section>
          </Fragment>
        }
      />
    )
  }
}

export default connect(state => ({
  associateId: state.data.associate.attributes.id,
}))(SlotModal)
