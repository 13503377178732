import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createSession } from "actions"
import { Row, Col } from "react-flexbox-grid"

import "./Login.scss"
import WarningMessage from "components/WarningMessage";
import LoginFooter from "components/App/LoginFooter";

class Login extends Component {

  render() {
    return (<>
      <main className="authentication">
        <div className="container">
          <Row center="md">
            <Col md={8} xs={12} className="logo-container">
              <img alt="SASR Logo" src="https://cdn.phenompeople.com/CareerConnectResources/SROOUS/images/SASR-Workforce-Solutions-med-1626275823359.png" />
            </Col>
          </Row>
          <Row center="md">
            <Col md={8} className="login-form-warning">
              <WarningMessage
                warningText={<>
                  <p>Thank you for visiting “Hireflex.com”</p>
                  <br/>
                  <p>SASR Workforce Solutions is a Cary, NC-based market leader in the retail, warehouse & distribution, and grocery staffing space. We provide comprehensive workforce solutions that allow clients to strengthen their labor needs across various initiatives, including new store setups, store remodels, project management & managed programs, and other temporary and direct hire labor solutions. SASR provides nationwide coverage across all 50 states via a technology-enabled, centralized approach to recruiting and staffing.</p>
                  <br/>
                  <p>We provide job opportunities across the country focusing on retail and retail merchandising, such as store remodels, new store openings and department resets.</p>
                  <br/>
                  <p>We offer short-term surge work, long-term and part-time traveling jobs in various industries that allow people to <a className="redirect-link" href="https://www.sasrlink.com/jobs/">find</a> the work that works for them!</p>
                  <br/>
                  <p>Check out our current opportunities now!</p>
                </>}
                hasIcon={false}
              />
            </Col>
          </Row>
          <Row center="md">
            <Col md={5} xs={12}>
              <a href="https://www.sasrlink.com/jobs/" className="btn btn-green btn-block">Visit SASR Workforce Solutions</a>
            </Col>
          </Row>
        </div>
      </main>
      <LoginFooter />
    </>)
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate,
    session: state.session,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        createSession,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
