import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field, change } from "redux-form"
import ErrorMessage from "components/ErrorMessage"
import { deserializeEmploymentInfo } from "serializers/employment"
import SideModal from "components/SideModal"
import Modal from "components/Modal"
import Section from "components/Modal/Section"
import Checkbox from "components/Form/CheckboxInput"
import Button from "components/Form/Button"
import Header from "./Header"
import Signature from "./Signature"
import SvgIcon from "components/SvgIcon"
import "./forms.scss"

export const fields = [
  "policyUpdateDocument",
  "policySignature",
]

const validate = ({
  iHaveReadPolicyUpdates,
  policySignature,
}) => {
  const errors = {}

  if (!iHaveReadPolicyUpdates) {
    errors.iHaveReadPolicyUpdates = "Please acknowledge"
  }

  if (!policySignature || policySignature.length === 0) {
    errors.policySignature = "Please sign"
  }

  return errors
}

export class PolicyUpdates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canEdit: props.status === "incomplete",
      showPdf: false,
    }
  }

  handleSignatureChange = data => {
    this.props.dispatch(change("policy", "policySignature", data))
  }

  openPdfModal = (url, title) => {
    this.setState({
      pdfModalTitle: title,
      showPdf: url,
    })
  }

  closePdfModal = () => {
    this.setState({
      pdfModalTitle: false,
      showPdf: false,
    })
  }

  render() {
    const { canEdit, pdfModalTitle, showPdf } = this.state
    const {
      error,
      handleSubmit,
      initialValues,
      invalid,
      pristine,
      submitting,
    } = this.props

    return (
      <form onSubmit={handleSubmit} className="policy-updates-form">
        <SideModal
          closeModal={this.props.closeModal}
          header={
            <Header
              title="Policy Updates"
              subtitle={
                canEdit
                  ? "Please read and acknowledge the following Policy Updates."
                  : this.props.status === "pending"
                  ? `We're currently performing a background check. Email us at ${process.env.REACT_APP_SUPPORT_EMAIL} if you have any questions.`
                  : "You read and acknowledged the following Policy Updates."
              }
            />
          }
          body={
            <React.Fragment>
              {error && <ErrorMessage errorText={error} />}
              <div className="policy-updates-fields">
                <Section>
                  <h3>Disclosures and Agreements</h3>
                  <div className="input-line">
                    <div className="input-box">
                      <Field
                        component={Checkbox}
                        name={"iHaveReadPolicyUpdates"}
                        label={
                          <span>
                            I have read the &nbsp;
                            <a
                              onClick={() =>
                                this.openPdfModal(
                                  initialValues.policyUpdateDocument,
                                  "Discrimination, Harassment and Retaliation Prevention Policy"
                                )
                              }
                            >
                              Discrimination, Harassment and Retaliation Prevention Policy
                            </a>
                            .
                          </span>
                        }
                        disabled={!canEdit}
                      />
                    </div>
                  </div>
                </Section>
                <Section>
                  <Signature
                    handleSignatureChange={this.handleSignatureChange}
                    disabled={!canEdit}
                    initialValue={initialValues.policySignature}
                    name={"policySignature"}
                  />
                  {canEdit && (
                    <p>
                      By signing this form, you authorize the above image to be
                      used as your electronic signature on all agreements.
                    </p>
                  )}
                </Section>
              </div>
            </React.Fragment>
          }
          footer={
            <React.Fragment>
              <div className="policy-updates-footer">
                {canEdit ? (
                  <React.Fragment>
                    <Button
                      btnClass="btn-green btn-block"
                      text="Submit"
                      submitting={submitting}
                      disabled={pristine || invalid}
                    />
                    <Button
                      btnClass="btn-cancel btn-block"
                      text="Cancel"
                      onClick={this.props.closeModal}
                    />
                  </React.Fragment>
                ) : (
                  <Button
                    btnClass="btn-cancel btn-block"
                    text="Close"
                    onClick={this.props.closeModal}
                  />
                )}
              </div>
            </React.Fragment>
          }
        />
        {showPdf && (
          <Modal className="lofted modal" modalClose={this.closePdfModal}>
            <div className="modal-header">
              <h3>{pdfModalTitle}</h3>
              <a className="modal-close" onClick={this.closePdfModal}>
                <SvgIcon title="close" name="close-gray" />
              </a>
            </div>
            <div className="pdf-container">
              <iframe src={showPdf} title={pdfModalTitle} />
            </div>
          </Modal>
        )}
      </form>
    )
  }
}

PolicyUpdates = reduxForm({
  form: "policy",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(PolicyUpdates)

export default connect(state => ({
  initialValues: deserializeEmploymentInfo(state.data.employment).policy,
}))(PolicyUpdates)
