import React, { useEffect, useState } from "react"
import Day from "./Day"
import moment from "moment"

const MonthRows = props => {
  const {
    selectedDates,
    setMonthChecked,
    monthChecked,
    canApply,
    penaltyDateIndices,
    unavailableDateIndices,
    handleSelectDate,
    jobDatesIndices,
    conflictDateIndices,
    assignmentCalendar,
    monthIndex,
    startDay,
    monthDates,
    startOfMonth,
    daysInMonth,
  } = props

  let rows = []

  const today = moment()
  const todayIndex = today.diff(startOfMonth, "days")

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ]

  useEffect(() => {
    let monthMatches = 0

    if (selectedDates && selectedDates.length > 0) {
      selectedDates.forEach(date => {
        if (
          moment(date).format("YYYY-MM") ===
          moment(startOfMonth).format("YYYY-MM")
        ) {
          monthMatches++
        }
      })
    }

    if (monthMatches === 0) {
      setMonthChecked(false)
    }

    let allMonthDatesAreChecked = monthDates.every(date =>
      selectedDates.includes(date)
    )

    setMonthChecked(allMonthDatesAreChecked)
  }, [selectedDates, startOfMonth, monthChecked, monthDates, setMonthChecked])

  for (let i = 0; i < daysInMonth; i++) {
    let pointer = (i + startDay) % dayNames.length

    let year = moment().year()
    if (moment().month() > monthIndex) {
      // this month is on next year's calendar
      year = year + 1
    }

    const stringDate = moment()
      .month(monthIndex)
      .year(year)
      .date(i + 1)
      .format("YYYY-MM-DD")

    const selected = selectedDates.includes(stringDate)
    const hasError = selectedDates.length === 0

    rows.push(
      <Day
        assignmentCalendar={assignmentCalendar}
        key={i + 1}
        index={i + 1}
        label={i + 1}
        dayName={dayNames[pointer]}
        startDay={startDay}
        daysWithConflict={conflictDateIndices}
        daysWithEvent={jobDatesIndices}
        stringDate={stringDate}
        selected={selected}
        selectDate={handleSelectDate}
        unavailableDateIndices={unavailableDateIndices}
        penaltyDateIndices={penaltyDateIndices}
        hasError={hasError}
        todayIndex={todayIndex}
        canApply={canApply}
      />
    )
  }

  return rows
}

const Month = props => {
  const {
    handleToggleMonth,
    canApply,
    penaltyDateIndices,
    unavailableDateIndices,
    handleSelectDate,
    jobDatesIndices,
    conflictDateIndices,
    assignmentCalendar,
    monthIndex,
    startDay,
    daysInMonth,
    selectedDates,
    startOfMonth,
  } = props
  const [monthDates, setMonthDates] = useState([])
  const [monthChecked, setMonthChecked] = useState(true)

  useEffect(() => {
    const currentMonthDates = []

    if (selectedDates && selectedDates.length > 0) {
      selectedDates.forEach(date => {
        if (
          moment(date).format("YYYY-MM") ===
          moment(startOfMonth).format("YYYY-MM")
        ) {
          currentMonthDates.push(date)
        }
      })
    }

    if (currentMonthDates.length > 0) {
      setMonthDates(currentMonthDates)
    }
  }, [selectedDates, startOfMonth])

  const toggleMonthCheck = () => {
    const toggledMonth = startOfMonth.format("YYYY-MM")

    setMonthChecked(value => {
      handleToggleMonth(toggledMonth, !value)

      return !value
    })
  }

  const monthName = startOfMonth.format("MMMM YYYY")
  const transformedMonthName = monthName.replace(/ /g, "").toLowerCase()
  let showMonthCheck = assignmentCalendar !== true ? true : false

  return (
    <div className="vw-cal-month">
      {canApply && showMonthCheck ? (
        <div className="month-header">
          <input
            type="checkbox"
            id={transformedMonthName}
            name={transformedMonthName}
            value="selected"
            onClick={toggleMonthCheck}
            defaultChecked={monthChecked}
          />
          <label htmlFor={transformedMonthName}>
            <h6>{startOfMonth.format("MMMM YYYY")}</h6>
          </label>
        </div>
      ) : (
        <h6>{startOfMonth.format("MMMM YYYY")}</h6>
      )}
      <div className="week-days">
        <div className="week-day">Sun</div>
        <div className="week-day">Mon</div>
        <div className="week-day">Tue</div>
        <div className="week-day">Wed</div>
        <div className="week-day">Thu</div>
        <div className="week-day">Fri</div>
        <div className="week-day">Sat</div>
      </div>
      <div className="month-days">
        <MonthRows
          monthIndex={monthIndex}
          daysInMonth={daysInMonth}
          monthDates={monthDates}
          startOfMonth={startOfMonth}
          startDay={startDay}
          selectedDates={selectedDates}
          assignmentCalendar={assignmentCalendar}
          conflictDateIndices={conflictDateIndices}
          jobDatesIndices={jobDatesIndices}
          handleSelectDate={handleSelectDate}
          unavailableDateIndices={unavailableDateIndices}
          penaltyDateIndices={penaltyDateIndices}
          canApply={canApply}
          monthChecked={monthChecked}
          setMonthChecked={setMonthChecked}
        />
      </div>
    </div>
  )
}

export default Month
