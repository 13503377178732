import React, { Component } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import classNames from "classnames"
import Checkbox from "components/Form/CheckboxInput"
import Select from "components/Form/Select"
import "./WorkInterest.scss"

export default class WorkInterest extends Component {
  static defaultProps = {
    experienceOptions: [],
  }

  static propTypes = {
    index: PropTypes.number.isRequired,
    checked: PropTypes.bool.isRequired,
    interest: PropTypes.object,
    experienceOptions: PropTypes.array.isRequired,
    label: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      checked: props.checked,
      computedIndex: props.index,
    }
  }

  handleCheck = e => {
    const isChecked = e.target.checked
    this.setState({ checked: isChecked })
    if (!isChecked) {
      const experienceField = `previousExperiences[${this.state.computedIndex}].yearsExperience`
      this.props.onClear(experienceField, null)
    }
  }

  setWorkType = (value, previousValue) => {
    if (value) {
      return this.props.interest
    } else {
      return value
    }
  }

  render() {
    let itemClass = classNames({
      checked: this.state.checked,
    })
    return (
      <li className={itemClass}>
        <div className="input-line">
          <div className="input-box input-box-inline">
            <Field
              component={Checkbox}
              normalize={this.setWorkType}
              name={`previousExperiences[${this.state.computedIndex}].workType`}
              label={this.props.interest.label}
              onChange={this.handleCheck}
            />
          </div>
        </div>
        <div className="experience-select">
          <div className="input-box">
            <Field
              name={`previousExperiences[${this.state.computedIndex}].yearsExperience`}
              component={Select}
              options={this.props.experienceOptions}
              labelKey="label"
              valueKey="id"
              labelText="Years Experience"
              placeholder="Years Experience"
              disabled={!this.state.checked}
            />
          </div>
        </div>
      </li>
    )
  }
}
