import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import { connect } from "react-redux"
import "./Footer.scss"
import "./LogingFooter.scss"

class LoginFooter extends Component {

  render() {
    return (
      <footer className="app-footer app-login-footer">
        <div className="inner">
          <Row center="md">
            <Col md={8} xs={12}>
              <div className="links">
                <ul>
                  <li className="separator">
                    <div>&copy; 2023 SASR Workforce Solutions</div>
                  </li>
                  <li className="separator">
                    <a href="https://www.sasrlink.com/privacy-policy/">Privacy Policy</a>
                  </li>
                  <li className="separator">
                    <a href="https://www.sasrlink.com/terms-of-use/">Terms</a>
                  </li>
                  <li className="separator">
                    <a href="https://www.sasrlink.com/dei-statement/">DEI Statement</a>
                  </li>
                  <li>
                    <a href="https://www.sasrlink.com/retail-research-service-alert/">Research Fraud Alert</a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    )
  }
}

export default connect(null, null)(LoginFooter)
