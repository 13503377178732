import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { markEmploymentPending } from "actions"
import Modal from "components/Modal"
import SvgIcon from "components/SvgIcon"
import "./ExternalModal.scss"

class Agreements extends Component {
  linkOpened = () => {
    this.props.markEmploymentPending("agreements").catch(() => {})
  }

  render() {
    const { closeModal, agreements, status } = this.props
    return (
      <Modal className="lofted modal external-link" modalClose={closeModal}>
        <div className="modal-header">
          <a className="modal-close" onClick={closeModal}>
            <SvgIcon title="close" name="close-gray" />
          </a>
          <h3>Payroll Forms &amp; Agreements</h3>
        </div>
        <div className="modal-body">
          {status === "incomplete" && (
            <React.Fragment>
              <p>
                On the next screen, you’ll be redirected to our Payroll
                processor system. Please <strong>create an account</strong> and{" "}
                <strong>complete all forms</strong>.
              </p>
            </React.Fragment>
          )}
          {status === "complete" && (
            <p>
              Thank you for completing your agreements. You can access the
              completed agreements in your account on the Payroll System by
              clicking the button below.
            </p>
          )}
          {status === "pending" && (
            <React.Fragment>
              <p>
                Click the link below to access your payroll forms and
                agreements. It may take us a few minutes to verify the completed
                forms before you can continue.
              </p>
              <p>
                If you have questions, email us at&nbsp;
                <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </a>.
              </p>
            </React.Fragment>
          )}
        </div>
        <div className="modal-footer payroll">
          <a
            className="btn btn-green btn-apply"
            href={agreements.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.linkOpened}
          >
            Open Payroll System
          </a>
          {status === "incomplete" && (
            <p>Return here to continue the forms at any time</p>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    agreements: state.data.employment.attributes.agreements,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        markEmploymentPending,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Agreements)
