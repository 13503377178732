import React, { Component } from "react"
import Button from "components/Form/Button"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCurrentAssociate } from "actions"

import "./Notification.scss"

class NotificationMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  updateCurrentAssociate(params) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        return this.props
          .updateCurrentAssociate(params)
          .then(() => {
            this.setState({
              isLoading: false,
            })
          })
          .catch(() => {
            this.setState({
              isLoading: false,
            })
          })
      }
    )
  }

  render() {
    const { notificationText } = this.props
    const { isLoading } = this.state
    return (
      <div className="notification-message-wrapper">
        <h4>Reactivate your account</h4>
        <div className="notification-content">
          <div classNmae="notification-text">
            <p className="notification-message">{notificationText}</p>
          </div>
          <div className="controls">
            <Button
              btnClass="btn-green"
              text="Activate"
              submitting={isLoading}
              onClick={() =>
                this.updateCurrentAssociate({ reactivateUser: true })
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentAssociate: bindActionCreators(
      updateCurrentAssociate,
      dispatch
    ),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NotificationMessage)
