import React from "react"
import PropTypes from "prop-types"

import "./Header.scss"

const Header = ({ title, subtitle }) => {
  return (
    <div className="employment-info-header">
      <div className="employment-info-title">
        <h2>{title}</h2>
      </div>
      <span className="employment-info-subtitle">{subtitle}</span>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Header
