import React, { Component } from "react"
import { Field, reduxForm } from "redux-form"
import Button from "components/Form/Button"
import TextInput from "components/Form/TextInput"
import TextareaInput from "components/Form/TextareaInput"

const fields = ["emailAddress", "message"]

const validate = ({ emailAddresses, message }) => {
  const errors = {}
  if (!emailAddresses) {
    errors.emailAddresses = "Please enter at least one email address"
  }

  return errors
}

class TeammatesInviteForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting } = this.props

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-box input-box-grid">
          <Field
            name="emailAddresses"
            type="text"
            component={TextInput}
            label="Email"
            placeholder="Email (separate by commas)"
          />
        </div>
        <div className="input-box">
          <Field
            name="message"
            component={TextareaInput}
            label="Message"
            placeholder="Message (optional)"
            rows="5"
            maxLength="250"
          />
        </div>
        <div className="form-action teammates-form-actions">
          <Button
            btnClass="btn btn-save btn-green"
            text="Send"
            disabled={pristine || submitting}
            submitting={submitting}
          />
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: "teammates-modal",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(TeammatesInviteForm)
