import { isValidBrowser } from "utils/browser"
import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "App"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { currentAssociateId } from "utils/auth"
import NotSupported from "NotSupported"
import elementClosest  from "element-closest"
import store from "store"

elementClosest(window);

const bugsnagEnabled = process.env.REACT_APP_BUGSNAG_ID !== undefined;

if (bugsnagEnabled) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_ID,
    releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
    appVersion: process.env.REACT_APP_VERSION,

    onError: function (report) {
      // associateId needs to be actual
      const associateId = currentAssociateId()
      report.user = { id: associateId }
    },
    plugins: [
      new BugsnagPluginReact()
    ]
  });
}

if (!isValidBrowser()) {
  ReactDOM.render(<NotSupported />, document.getElementById("root"))
} else {
  const ErrorBoundary = bugsnagEnabled ? Bugsnag.getPlugin('react').createErrorBoundary(React) : Fragment;

  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
  )
}
