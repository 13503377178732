import _ from "lodash"

export function serializeExperiences(params) {
  let serializedParams = { ...params }
  serializedParams.previousExperiences = _.reject(
    serializedParams.previousExperiences,
    exp => {
      return (
        _.isEmpty(exp) || !exp.workType || exp === null || exp === undefined
      )
    }
  )
  return serializedParams
}
