import React from "react"
import Icon from "components/Icon"
import "./ErrorMessage.scss"
import { PropTypes } from "prop-types"

const ErrorMessage = ({ errorText, secondaryText }) => (
  <div className="error-message-wrapper">
    <Icon name="alert" height="24" width="24" />
    <p className="error-message">
      {errorText}
      <br />
      {secondaryText}
    </p>
  </div>
)

ErrorMessage.propTypes = {
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  secondaryText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
}

export default ErrorMessage
