import * as types from "actions/types"

const defaultState = {
  fingerprint: "",
  updated: false,
}

export default function version(state = defaultState, action) {
  switch (action.type) {
    case types.UPDATE_APP_VERSION:
      const hasUpdated = checkVersion(state, action.fingerprint)
      console.debug(`Checking app version... ${hasUpdated ? 'Updated' : 'Not Updated'}`)
      return {
        ...state,
        fingerprint: action.fingerprint,
        updated: hasUpdated,
      }
    default:
      return state
  }
}

function checkVersion(state, fingerprint) {
  if (state.fingerprint !== "" && fingerprint !== "") {
    return state.fingerprint !== fingerprint
  } else {
    return false
  }
}
