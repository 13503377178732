import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readAssignment, setLodgingError } from "actions"
import { listTeammates } from "actions/teammates"
import Distance from "components/Job/Distance"
import MapLocation from "components/Job/MapLocation"
import Calendar from "components/Calendar/Calendar"
import SideModal from "components/SideModal"
import Summary from "components/Job/Summary"
import ExpandableSection from "components/ExpandableSection"
import Header from "components/Job/Header"
import Section from "components/Modal/Section"
import Footer from "components/Assignment/Modal/Footer"
import ApplicationAgreementsModal from "components/Job/ConfirmAgreementsModal"
import { urlify } from "utils/urlify"
import { successAlert } from "utils/alerts"
import { confirmApplication, getSchedule, readTasks } from "actions"
import LodgingForm from "components/Lodging/Form"
import InviteTeammatesModal from "components/Job/InviteTeammatesModal"
import { getManagerNotes } from "components/Lodging/utils"

class AssignmentModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      instructions: "",
      showApplicationAgreementsModal: false,
      confirmingAgreements: false,
      confirmingModal: false,
      teammates: [],
      showInviteTeammatesModal: false,
      displayLodging: false,
    }
  }

  componentDidMount() {
    this.props
      .readAssignment(this.props.applicationId)
      .then(({ data }) => {
        this.setState({
          instructions: urlify(data.job.instructions),
          agreements: data.job.project.agreementMessage,
          displayLodging: this.shouldDisplayLodging(data),
        })
      })
      .catch(() => {})

    this.props
      .listTeammates()
      .then(response => {
        this.setState({
          teammates: response.data.teammates,
        })
      })
      .catch(() => {})
  }

  shouldDisplayLodging = (data) => {
    if (!data.travel.isTravelJob) return false
    if (data.appliedByAssociate) return false

    return true
  }

  handleLodgingChange = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  closeInviteTeammatesModal = () =>
    this.setState(prevState => ({
      ...prevState,
      showInviteTeammatesModal: false,
    }))

  toggleInviteTeammatesModal = e => {
    e.preventDefault()

    this.setState(prevState => ({
      ...prevState,
      showInviteTeammatesModal: !prevState.showInviteTeammatesModal,
    }))
  }

  hasLodging = () => this.state.displayLodging

  getLodging = () => {
    if (this.hasLodging()) {
      const { touched, teammatesNames, ...rest } = this.state.lodging
      return {
        ...rest,
        managerNotes: getManagerNotes(this.state.lodging),
      }
    }

    return {}
  }

  confirmApplication = () => {
    const agreementsExists =
      this.props.assignment &&
      this.props.assignment.job &&
      this.props.assignment.job.project &&
      this.props.assignment.job.project.agreementMessage &&
      this.props.assignment.job.project.agreementMessage.length > 0

    this.props
      .confirmApplication(
        this.props.assignment.id,
        agreementsExists,
        this.hasLodging(),
        this.getLodging()
      )
      .then(response => {
        this.setState({
          confirmingModal: false,
        })
        this.props.handleClose()
        successAlert("Job confirmed.")
        this.props.getSchedule()
        this.props.readTasks()
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            confirmingModal: false,
          })
        }, 1000)
      })
  }

  validateLodging = () => {
    const { lodging } = this.state

    if (!lodging.touched || (lodging.touched && !lodging.gender)) {
      this.props.setLodgingError("gender", "Please select your gender")
      return true
    }

    return false
  }

  hasLodgingError = () => {
    return this.validateLodging()
  }

  handleConfirm = () => {
    if (this.hasLodging() && this.hasLodgingError()) {
      document.getElementById("lodging-form").scrollIntoView(true)
    } else {
      const { agreements } = this.state

      if (agreements) {
        this.setState({
          showApplicationAgreementsModal: true,
        })
      } else {
        this.setState(
          {
            confirmingModal: true,
          },
          this.confirmApplication
        )
      }
    }
  }

  render() {
    const { assignment, handleClose, history } = this.props
    const {
      instructions,
      showApplicationAgreementsModal,
      confirmingModal,
      teammates,
      showInviteTeammatesModal,
      displayLodging,
    } = this.state

    const agreements =
      assignment &&
      assignment.job &&
      assignment.job.project &&
      assignment.job.project.agreementMessage
    const job = assignment ? assignment.job : undefined
    const startDate =
      assignment && assignment.slotDates && assignment.slotDates.offered[0]
    const endDate =
      assignment &&
      assignment.slotDates &&
      assignment.slotDates.offered[assignment.slotDates.offered.length - 1]
    const durationDays =
      assignment && assignment.slotDates && assignment.slotDates.offered.length
    const payRate =
      assignment && assignment.wage && assignment.wage.proposedRate
    const travelIncentive =
      assignment && assignment.travel && assignment.travel.proposedRate
    const attachments =
      assignment &&
      assignment.attachments &&
      assignment.attachments.map(attachment => {
        return (
          <li>
            <a href={attachment.link}>{attachment.name}</a>
          </li>
        )
      })

    let conditionalFooter

    if (job) {
      conditionalFooter = (
        <Footer
          application={assignment}
          handleClose={handleClose}
          history={history}
          handleConfirm={this.handleConfirm}
          confirming={confirmingModal}
        />
      )
    }

    return (
      <Fragment>
        <SideModal
          closeModal={handleClose}
          header={<Header job={job} />}
          body={
            job && assignment.travel ? (
              <React.Fragment>
                <Distance
                  distanceInMiles={assignment.travel.distance || 0}
                  location={job.store.location}
                />

                <Section>
                  <Summary
                    job={job}
                    payRate={payRate}
                    startDate={startDate}
                    endDate={endDate}
                    durationDays={durationDays}
                    travelIncentive={travelIncentive}
                    application={{ contents: assignment }}
                  />
                </Section>

                <Section>
                  <div className="section-header">
                    <h5>Job Schedule</h5>
                  </div>
                  <Calendar jobDates={assignment.slotDates.offered} />
                </Section>

                <Section>
                  <MapLocation job={job} isLoading={job.isLoading} />
                </Section>

                {displayLodging && (
                  <Section>
                    <LodgingForm
                      canApply
                      teammates={teammates}
                      lodgingTypes={job.loggingTypes}
                      handleChange={this.handleLodgingChange}
                      toggleInviteTeammateModal={
                        this.toggleInviteTeammatesModal
                      }
                    />
                  </Section>
                )}

                {attachments && attachments.length > 0 && (
                  <Section>
                    <ExpandableSection
                      title="Attachments"
                      content={attachments}
                    />
                  </Section>
                )}

                {instructions && (
                  <Section>
                    <ExpandableSection
                      title="Instructions"
                      text={instructions}
                    />
                  </Section>
                )}
                {job.comments && (
                  <Section>
                    <ExpandableSection title="Comments" text={job.comments} />
                  </Section>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>Loading...</React.Fragment>
            )
          }
          footer={conditionalFooter}
        />
        {showInviteTeammatesModal && (
          <InviteTeammatesModal modalClose={this.closeInviteTeammatesModal} />
        )}
        {showApplicationAgreementsModal && (
          <ApplicationAgreementsModal
            agreements={agreements}
            modalClose={() =>
              this.setState({
                showApplicationAgreementsModal: false,
              })
            }
            handleConfirm={this.confirmApplication}
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    assignment: state.data.assignments.contents,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        confirmApplication,
        readAssignment,
        listTeammates,
        getSchedule,
        readTasks,
        setLodgingError,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentModal)
