import React, { Component } from "react"
import { connect } from "react-redux"
import EditableField from "components/Form/EditableField"
import { handleErrors } from "utils/errors"
import NotificationMessage from "components/Profile/Notification"
import { checkWrongStatus } from "utils/associateStatus"
import { Redirect } from "react-router-dom"
import { validateEmail } from 'utils/validation';

const emailFields = ["emailAddress"]

const emailValidate = ({ emailAddress }) => {
  const errors = {};

  if (!emailAddress) {
    errors.emailAddress = "Please enter an email address"
  } else if (!validateEmail(emailAddress)) {
    errors.emailAddress = "Please enter a valid email address"
  }

  return errors;
}

const passwordFields = ["password"]

const passwordValidate = ({ password }) => {
  const errors = {}
  if (!password) {
    errors.password = "Please enter a password"
  } else if (password.length < 7) {
    errors.password = "Must be 7 or more characters"
  }
  return errors
}

class Account extends Component {
  updateCurrentAssociate(params) {
    return this.props.updateCurrentAssociate(params).catch(error => {
      handleErrors(error)
    })
  }

  render() {
    const { associate } = this.props
    const { emailAddress } = this.props
    const { isThirdPartyLabor } = this.props.associate

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <div>
        {associate &&
          associate.adminAssociateStatusDescription === "Inactive" && (
            <NotificationMessage notificationText="You are currently set as inactive. If you would like to reactivate your account and begin receiving jobs again, please, press the activate button." />
          )}
        <h2>Login Info</h2>
        <EditableField
          fields={emailFields}
          form="profile-account-email"
          initialValues={{ emailAddress: emailAddress }}
          label="Email"
          name="emailAddress"
          onSubmit={params => this.updateCurrentAssociate(params)}
          preview={emailAddress}
          type="text"
          validate={emailValidate}
          isThirdPartyLabor={isThirdPartyLabor === "Y"}
        />
        <EditableField
          fields={passwordFields}
          form="profile-account-password"
          initialValues={{ password: "" }}
          label="Password"
          name="password"
          onSubmit={params => this.updateCurrentAssociate(params)}
          preview="********"
          type="password"
          validate={passwordValidate}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

export default connect(
  mapStateToProps,
  null
)(Account)
