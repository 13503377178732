import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readPointsSummary } from "actions"
import Modal from "components/Modal"
import PointValue from "components/Profile/PointValue"
import ScrollbarContainer from "components/ScrollbarContainer"
import Icon from "components/Icon"
import SvgIcon from "components/SvgIcon"
import "./PointsSummary.scss"

class PointsSummary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: "my-points",
    }
  }

  componentDidMount() {
    this.props.readPointsSummary().catch(() => {})
  }

  handleMenuClick = event => {
    this.setState({ activeTab: event.target.id })
  }

  renderSign = total => {
    let sign = total >= 0 ? "positive" : "negative"
    return <span className={sign}>{total}</span>
  }

  render() {
    const { closeModal } = this.props;
    const { activeTab } = this.state;
    const pointsSummary = this.props.pointsSummary.data || [];

    const winPoints = pointsSummary.filter(point => {
      return point.value >= 0 && point.used;
    })

    const losePoints = pointsSummary.filter(point => {
      return point.value < 0
    });

    const myPoints = pointsSummary.filter(point => {
      return point.used === false && point.total > 0 ? true : point.used;  
    });

    return (
      <Modal
        className="points-modal large"
        id="eligibility-modal"
        modalClose={closeModal}
      >
        <div className="modal-header blue">
          <ul className="points-modal-menu">
            <li
              id="my-points"
              className={activeTab === "my-points" ? "active" : undefined}
              onClick={this.handleMenuClick}
            >
              My Points
            </li>
            <li
              id="compare-levels"
              className={activeTab === "compare-levels" ? "active" : undefined}
              onClick={this.handleMenuClick}
            >
              Compare Levels
            </li>
            <li
              id="win-points"
              className={activeTab === "win-points" ? "active" : undefined}
              onClick={this.handleMenuClick}
            >
              Win Points
            </li>
            <li
              id="lose-points"
              className={activeTab === "lose-points" ? "active" : undefined}
              onClick={this.handleMenuClick}
            >
              Lose Points
            </li>
          </ul>
          <a className="modal-close" onClick={closeModal}>
            <Icon name="x" />
          </a>
        </div>
        {activeTab === "my-points" && (
          <React.Fragment>
            <div className="modal-body">
              <div className="points-summary-header">
                <div className="category">My Activity</div>
                <div className="points-earned">Points</div>
              </div>
              <ScrollbarContainer>
                <div className="points-summary-body">
                  {myPoints.map((entry, index) => (
                    <div className="points-summary-row" key={index}>
                      <div className="category">
                        {entry.type}
                        <span className="value">
                          {this.renderSign(entry.value)}
                        </span>
                      </div>
                      <div className="points-earned">
                        <PointValue value={entry.total} />
                        <span className="count">x{entry.count}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollbarContainer>
            </div>
            <div className="modal-footer">
              <div className="points-summary">
                <div className="category">Total Points</div>
                <div className="points-earned">
                  {Math.round(this.props.pointsEarned)}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {activeTab === "compare-levels" && (
          <div className="modal-body">
            <ScrollbarContainer>
              <div className="compare-levels">
                <div className="compare-levels-header">
                  <h2>More Points, More Priviledges</h2>
                  <p>
                    Everyone begins at Entry Level. As you win more points, you
                    upgrade
                    <br />
                    in career levels. Work hard, get points and you will gain
                    unique
                    <br />
                    benefits within the SaSR system.
                  </p>
                </div>
                <div className="levels-grid">
                  <div className="level-column">
                    <SvgIcon name="ilus-badge-entry" title="Entry" />
                    <h3>Entry Level</h3>
                    <h4>0</h4>
                    <ul>
                      <li>Apply for work that fits your needs</li>
                    </ul>
                  </div>
                  <div className="level-column">
                    <SvgIcon name="ilus-badge-qualified" title="Qualified" />
                    <h3>Qualified</h3>
                    <h4>100</h4>
                    <ul>
                      <li>Apply for work that fits your needs</li>
                      <li>Get selected for more jobs</li>
                      <li>
                        Receive a $.25 pay bump compared to Entry Level on most
                        jobs
                      </li>
                    </ul>
                  </div>
                  <div className="level-column">
                    <SvgIcon name="ilus-badge-journeyman" title="Journeyman" />
                    <h3>Journeyman</h3>
                    <h4>300</h4>
                    <ul>
                      <li>Apply for work that fits your needs</li>
                      <li>Get selected for more jobs</li>
                      <li>
                        Receive a $.50 pay bump compared to Entry Level on most
                        jobs
                      </li>
                      <li>Ability to self assign to work</li>
                    </ul>
                  </div>
                  <div className="level-column">
                    <SvgIcon name="ilus-badge-expert" alt="Expert" />
                    <h3>Expert</h3>
                    <h4>2,000</h4>
                    <ul>
                      <li>Apply for work that fits your needs</li>
                      <li>Get selected for more jobs over other levels</li>
                      <li>Ability to self assign to work</li>
                      <li>
                        Receive a $.75 pay bump compared to Entry Level on most
                        jobs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ScrollbarContainer>
          </div>
        )}
        {activeTab === "win-points" && (
          <div className="modal-body">
            <div className="points-summary-header">
              <div className="category">How to Win Points</div>
              <div className="points-earned">Points</div>
            </div>
            <ScrollbarContainer>
              <div className="points-summary-body">
                {winPoints.map((entry, index) => (
                  <div className="points-summary-row" key={index}>
                    <div className="category">{entry.type}</div>
                    <div className="points-earned">
                      <PointValue value={entry.value} />
                    </div>
                  </div>
                ))}
              </div>
            </ScrollbarContainer>
          </div>
        )}
        {activeTab === "lose-points" && (
          <div className="modal-body">
            <div className="points-summary-header">
              <div className="category">How to Lose Points</div>
              <div className="points-earned">Points</div>
            </div>
            <ScrollbarContainer>
              <div className="points-summary-body">
                {losePoints.map((entry, index) => (
                  <div className="points-summary-row" key={index}>
                    <div className="category">{entry.type}</div>
                    <div className="points-earned">
                      <PointValue value={entry.value} />
                    </div>
                  </div>
                ))}
              </div>
            </ScrollbarContainer>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    pointsEarned: state.data.associate.attributes.score,
    pointsSummary: state.data.points.summary,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readPointsSummary: bindActionCreators(readPointsSummary, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PointsSummary)
