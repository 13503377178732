import * as types from "actions/types"

const defaultState = {
  isLoading: false,
}

export default function lists(state = defaultState, action) {
  switch (action.type) {
    case types.READ_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [action.listType]: action.data,
      }
    case types.READ_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
