import React from "react"
import PropTypes from "prop-types"
import Icon from "components/Icon"

import "./Header.scss"

const JobHeader = ({ job }) => {
  const displayJob = job ? job : { title: "Loading..." }
  const customer =
    job && job.project.customer ? job.project.customer.name : null
  return (
    <div className="job-detail-header">
      <div className="job-detail-title">
        <h1>{displayJob.title}</h1>
        {displayJob.hotJob && <Icon height="14" width="12" name="hot" />}
        {displayJob.selfAssign && (
          <Icon height="14" width="13" name="self-assign" />
        )}
        {displayJob.nightJob && <Icon height="14" width="13" name="night" />}
        {displayJob.travelJob && <Icon height="13" width="16" name="bed" />}
        {customer && <h3>{customer}</h3>}
      </div>
      <span className="job-id">{displayJob.id}</span>
    </div>
  )
}

JobHeader.propTypes = {
  job: PropTypes.object,
}

export default JobHeader
