import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import NavbarLink from "components/Navbar/Link"
import ProfileDropdown from "components/Navbar/ProfileDropdown"
import TasksDropdown from "components/Navbar/TasksDropdown"
import HelpDropdown from "components/Navbar/HelpDropdown"
import Icon from "components/Icon"
import { checkWrongStatus, isOnboardingAndTimesheetsOnly } from "utils/associateStatus"
import WrongStatusAlert from "components/Modals/WrongStatusAlert"

import "./DesktopNavbar.scss"

const REFERRAL_LINK = process.env.REACT_APP_REFERRAL_LINK;
class DesktopNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusModalVisible: false,
    }
  }

  showStatusModal = e => {
    if (checkWrongStatus(this.props.associate)) {
      e.preventDefault()
      this.setState({
        statusModalVisible: true,
      })
    }
  }

  hideStatusModal = e => {
    if (checkWrongStatus(this.props.associate)) {
      this.setState({
        statusModalVisible: false,
      })
    }
  }

  render() {
    const { isAuthenticated, path, tasks, associate } = this.props;
    const { statusModalVisible } = this.state;

    return (
      <Fragment>
        <header className="desktop-navbar">
          <div className="container">
            {isAuthenticated && associate && checkWrongStatus(associate) ? (
              <Link to="/app/timesheets" className="logo" />
            ) : (
              <Link to="/app/assignments" className="logo" />
            )}
            {!isAuthenticated && <span className="logo" />}

            {isAuthenticated && (
              <ul className="desktop-navbar-menu">
                {(associate.isWurknowUser !== 'Y') && (<>
                  <NavbarLink
                    to="/app/assignments"
                    path={path}
                    hasAlert={tasks.length > 0}
                    onClick={e => this.showStatusModal(e)}
                    disabled={checkWrongStatus(associate)}
                  >
                    Assignments
                  </NavbarLink>
                  {!isOnboardingAndTimesheetsOnly(associate) && (
                    <NavbarLink
                      to="/app/jobs/open"
                      path={path}
                      activePrefix="/app/jobs"
                      onClick={e => this.showStatusModal(e)}
                      disabled={checkWrongStatus(associate)}
                    >
                      Job Search
                    </NavbarLink>
                  )}
                </>)}
                <NavbarLink to="/app/timesheets" path={path}>
                  Timesheets
                </NavbarLink>
              </ul>
            )}
            {isAuthenticated && (
              <ul className="desktop-navbar-menu-right">
                {associate.isThirdPartyLabor === "N" && (
                  <li className="referral-link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={REFERRAL_LINK}
                      onClick={e => this.showStatusModal(e)}
                      disabled={checkWrongStatus(associate)}
                    >
                      <span
                        className={
                          "" + (checkWrongStatus(associate) ? "disabled" : "")
                        }
                      >
                        Refer a friend and earn
                      </span>
                      <em
                        className={
                          "" + (checkWrongStatus(associate) ? "disabled" : "")
                        }
                      >
                        100
                      </em>
                    </a>
                  </li>
                )}
                <TasksDropdown />
                {associate.isThirdPartyLabor === "N" && <HelpDropdown />}
                <ProfileDropdown />
              </ul>
            )}

            {!isAuthenticated && (
              <div className="desktop-navbar-login">
                <Link to="/login">
                  Login
                  <Icon name="caret-right" height="12" width="6" />
                </Link>
              </div>
            )}
          </div>
        </header>

        {statusModalVisible && (
          <WrongStatusAlert modalClose={this.hideStatusModal} />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    tasks: state.data.tasks.data,
    isAuthenticated: state.session.isAuthenticated === true,
    associate: state.data.associate.attributes,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopNavbar)
