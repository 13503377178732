import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  reduxForm,
  Field,
  FieldArray,
  arrayPush,
  formValueSelector,
  change,
} from "redux-form"
import { connect } from "react-redux"
import moment from "moment"
import _ from "lodash"
import MediaQuery from "react-responsive"

import Icon from "components/Icon"
import Button from "components/Form/Button"
import CheckboxInput from "components/Form/CheckboxInput"
import TextInput from "components/Form/TextInput"
import Section from "components/Modal/Section"

import Breaks from "./Breaks"
// import Expenses from "./Expenses"
import Header from "./Header"
import validate from "./validate"

import "./Expenses.scss"

export const fields = [
  "startTime",
  "endTime",
  "breaks",
  "breakAcknowledgement",
  "supervisorName",
  "supervisorContact",
  "expenses",
  "mileage",
  "signature",
]
const selector = formValueSelector("timesheet")

export class TimesheetFields extends Component {
  static propTypes = {
    timesheet: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      showExpenseDropdown: false,
      showExpenseFields: {},
    }
  }

  toggleExpenseDropdown = event => {
    event.preventDefault()
    this.setState({ showExpenseDropdown: !this.state.showExpenseDropdown })
  }

  showExpense = expense => {
    let showExpenseFields = { ...this.state.showExpenseFields }
    showExpenseFields[expense] = true
    this.setState({ showExpenseFields })
    this.setState({ showExpenseDropdown: false })
  }

  hideExpense = (expense, index) => {
    let showExpenseFields = { ...this.state.showExpenseFields }
    showExpenseFields[expense] = false
    this.props.dispatch(
      change(
        "timesheet",
        `expenses[${index}].value`,
        this.props.initialValues.expenses[index].value
      )
    )
    this.setState({ showExpenseFields })
  }

  handleAddExpense = () => {
    this.props.dispatch(arrayPush("timesheet", "expenses", {}))
    this.setState({ showExpenseDropdown: false })
  }

  composeExtraPayVerbiage = () => {
    const { timesheet } = this.props

    let extraPayVerbiage, travelIncentive, perDiemExpense, perDiem

    const ppmOrRangeVerbiage = () =>
      timesheet.allowMileageOption === "ppm"
        ? "Pay Per Mile Incentive"
        : "Mileage Range Incentive"

    const ppmOverXMilesVerbiage = () => {
      let verbiage = ""
      if (timesheet.allowMileageOption === "ppm") {
        verbiage += " per mile"
        if (timesheet.mileageAfterMaxMiles > 0)
          verbiage += ` after the first ${timesheet.mileageAfterMaxMiles} miles`
      }
      return verbiage
    }

    if (timesheet) {
      travelIncentive = timesheet.travel ? parseFloat(timesheet.travel.pay) : 0
      perDiemExpense = _.find(timesheet.expenses, { name: "per-diem" })
      perDiem = perDiemExpense ? parseFloat(perDiemExpense.value) : 0

      if (perDiem > 0 && travelIncentive > 0) {
        extraPayVerbiage = `This job includes a Per Diem of $${
          perDiemExpense.value
        } per day and ${ppmOrRangeVerbiage()} of $${
          timesheet.travel.pay
        } for your travel${ppmOverXMilesVerbiage()}.`
      } else if (perDiem > 0) {
        extraPayVerbiage = `This job includes a Per Diem of $${perDiemExpense.value} per day.`
      } else if (
        travelIncentive > 0 &&
        timesheet.allowMileageOption !== "none"
      ) {
        extraPayVerbiage = `This job includes a ${ppmOrRangeVerbiage()} of $${
          timesheet.travel.pay
        } for your travel${ppmOverXMilesVerbiage()}.`
      }
    }

    return extraPayVerbiage
  }

  render() {
    const {
      handleSubmit,
      handleTimeParsing,
      timesheet,
      submitting,
    } = this.props
    const takeSignature =
      timesheet.signatureRequested && window.innerWidth < 1024

    let canEdit,
      stateAbbr,
      totalTime,
      travelIncentive,
      extraPay,
      fullAddress,
      timesheetDate,
      otherExpIndex,
      isTravelIncentiveVisible

    if (timesheet) {
      canEdit = timesheet.status && timesheet.status.editable
      stateAbbr = timesheet.location && timesheet.location.address.state
      totalTime = this.props.calculateTotalTime()
      travelIncentive = timesheet.travel ? parseFloat(timesheet.travel.pay) : 0
      fullAddress = timesheet.location ? timesheet.location.address.full : ""
      timesheetDate = moment(timesheet.shift.start).format("MMMM D, YYYY")
      otherExpIndex =
        timesheet.allowMileageOption === "none"
          ? timesheet.expenses.findIndex(e => e.name === "other")
          : -1
      isTravelIncentiveVisible =
        (timesheet.allowMileageOption !== "none" &&
          this.props.calculateTotalTime("number") / 60 >=
            timesheet.minimumMileageHours) ||
        timesheet.allowMileageOption === "ppm"
    }

    const rejected = timesheet.rejectionReason.length > 0

    extraPay = this.composeExtraPayVerbiage()

    let footer = (
      <Button
        btnClass="btn-green btn-block"
        text={
          takeSignature
            ? "Review & Get Supervisor"
            : "Review Timesheet"
        }
        submitting={submitting}
      />
    )

    return (
      <form onSubmit={handleSubmit} className="timesheet-form">
        <Header timesheet={timesheet} fields />
        <div className="timesheet-fields">
          <div className="timesheet-body-info">
            <Icon name="calendar" width="16" height="16" />
            <span>{timesheetDate}</span>
          </div>
          <div className="timesheet-body-info">
            <Icon name="pinpoint" width="16" height="16" />
            <span>{fullAddress}</span>
          </div>
          <Section className="time-worked">
            <h3>Work Time</h3>
            <div className="input-line">
              <div className="input-box">
                <Field
                  type="text"
                  name="startTime"
                  component={TextInput}
                  onBlur={handleTimeParsing}
                  placeholder="Start Time"
                  label="Start Time"
                  disabled={!canEdit}
                />
              </div>
              <div className="input-box">
                <Field
                  type="text"
                  name="endTime"
                  component={TextInput}
                  onBlur={handleTimeParsing}
                  placeholder="End Time"
                  label="End Time"
                  disabled={!canEdit}
                />
              </div>
            </div>
            {canEdit && (
              <div className="breaks-acknowledge">
                <Field
                  name="breakAcknowledgement"
                  label={`I acknowledge I took all required breaks according to ${stateAbbr} state law`}
                  component={CheckboxInput}
                  disabled={!canEdit}
                />
              </div>
            )}
          </Section>

          <Section className="breaks">
            <h3>Unpaid Breaks</h3>
            <FieldArray
              name="breaks"
              component={Breaks}
              canEdit={canEdit}
              handleTimeParsing={handleTimeParsing}
            />
          </Section>

          <Section>
            <div className="timesheet-paid-time">
              <h3>Total Paid Time</h3>
              <h4>{totalTime ? totalTime : "0 hours 0 minutes"}</h4>
            </div>
          </Section>

          {extraPay && isTravelIncentiveVisible && (
            <Section>
              <div className="timesheet-incentives">
                <h3>Incentives</h3>
                <div className="timesheet-extra-pay">{extraPay}</div>
                {travelIncentive > 0 && timesheet.allowMileageOption === "ppm" && (
                  <div className="input-line mileage">
                    <div className="input-box">
                      <Field
                        type="number"
                        step={1}
                        min={0}
                        name="mileage"
                        component={TextInput}
                        disabled={!canEdit}
                        label="Mileage"
                        placeholder="Mileage"
                      />
                    </div>
                  </div>
                )}
              </div>
            </Section>
          )}

          {otherExpIndex > -1 && (
            <Section className="expenses">
              <h3>Expenses</h3>
              <div className="expenses-list">
                <div className="currency">
                  <div className="input-box">
                    <Field
                      type="number"
                      name={`expenses[${otherExpIndex}].value`}
                      component={TextInput}
                      disabled={!canEdit}
                      step={0.01}
                      min={0}
                      label="Other"
                      placeholder="Other"
                    />
                  </div>
                </div>
              </div>
            </Section>
          )}

          <Section className="team-lead">
            <h3>Supervisor</h3>
            <div className="input-line full-width">
              <div className="input-box">
                <Field
                  name="supervisorName"
                  type="text"
                  component={TextInput}
                  label="Full Name"
                  placeholder="Full Name"
                  disabled={!canEdit}
                />
              </div>
            </div>
            <div className="input-line full-width">
              <div className="input-box">
                <Field
                  name="supervisorContact"
                  type="text"
                  component={TextInput}
                  label="Phone or Email"
                  placeholder="Phone or Email"
                  disabled={!canEdit}
                />
              </div>
            </div>
          </Section>

          {rejected && (
            <Section>
              <div className="rejection-reason">
                <p>
                  <strong>Reason timesheet was rejected:</strong>
                  <ul>
                    {timesheet.rejectionReason.reasons.map(reason => {
                      return <li>{reason}</li>
                    })}
                  </ul>
                </p>
                <p>
                  <strong>Comments:</strong>
                  <ul>
                    <li>{timesheet.rejectionReason.comment}</li>
                  </ul>
                </p>
                <p>
                  Contact our Support Team if you need assistance:
                  <br />
                  <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                    {process.env.REACT_APP_SUPPORT_EMAIL}
                  </a>
                </p>
              </div>
            </Section>
          )}
        </div>
        <MediaQuery maxWidth={1023}>
          <div className="timesheet-footer fixed">{footer}</div>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className="timesheet-footer">{footer}</div>
        </MediaQuery>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.data.timesheets.data.find(
      timesheet => timesheet.id === parseInt(state.data.timesheets.detailId, 0)
    ),
    formValues: selector(
      state,
      "startTime",
      "endTime",
      "breaks",
      "expenses",
      "mileage",
      "supervisorName",
      "supervisorContact"
    ),
    associate: state.data.associate,
  }
}

const handleSubmitFail = errors => {
  let keys = errors ? Object.keys(errors) : []

  if (
    keys.includes("startTime") ||
    keys.includes("endTime") ||
    keys.includes("breakAcknowledgement")
  ) {
    document.querySelector(".timesheet-fields").scrollTop = 0
  }
}

TimesheetFields = reduxForm({
  form: "timesheet",
  fields,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
  onSubmitFail: handleSubmitFail,
})(TimesheetFields)

TimesheetFields = connect(mapStateToProps)(TimesheetFields)

export default TimesheetFields
