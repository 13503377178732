import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ReactTable from "react-table-6"
import { connect } from "react-redux"

import TimesheetModal from "components/Timesheets/Modal"
import SortIcon from "components/SortIcon"
import DollarAmount from "components/DollarAmount"
import VerticalDate from "components/VerticalDate"
import TimesheetStatus from "./Status"
import Pagination from "components/Pagination"
import Icon from "components/Icon"
import { checkWrongStatus } from "utils/associateStatus"
import ErrorMessage from "components/ErrorMessage"

import { alertEmail } from "utils/alerts"
import { formatHours } from "utils/format"
import SvgIcon from "components/SvgIcon"
import MailLink from "components/MailLink"
import "react-table-6/react-table.css"
import "./Table.scss"
import WarningMessage from "components/WarningMessage";

const PER_PAGE = 20

class TimesheetTable extends Component {
  static propTypes = {
    timesheets: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const timesheetId = parseInt(this.props.match.params.id, 0)

    if (timesheetId) this.props.history.push(`/app/timesheets`)

    this.state = {
      pages: 1,
      sortBy: "",
      showForm: timesheetId ? timesheetId : "",
      isFirstLoad: true,
    }
  }

  handleRowClick = (event, rowInfo) => {
    const timesheetId = rowInfo.original.id
    this.setState({ showForm: timesheetId })
  }

  handleSortClick = (sortColumn, sortDirectio) => {}

  handleCloseModal = event => {
    this.setState({ showForm: "" })
    this.props.clearTimesheet()
  }

  fetchData = (state, instance) => {
    let params = {
      perPage: PER_PAGE,
      page: state.page + 1,
    }

    const sortBy = state.sorted
    if (sortBy.length) {
      this.setState({ sortBy: sortBy[0].id })
    }

    this.props
      .listTimesheets(params, sortBy)
      .then(response => {
        const total = parseInt(
          response.headers.pagination.match(/total=(\d+)/)[1],
          0
        )
        const pageSize = state.pageSize
        const pages = pageSize > 0 ? Math.ceil(total / pageSize) : 1
        this.setState({
          pages: pages,
          total: total,
          isFirstLoad: false,
        })

        if (!checkWrongStatus(this.props.associate)) {
          this.props.readTasks()
        }
      })
      .catch(() => {})
  }

  render() {
    const timesheets = this.props.timesheets.data
    const isLoading = this.props.timesheets.isLoading
    const { isFirstLoad, showForm, showForm: timesheetId } = this.state
    const { associate } = this.props
    const loadingText = (
      <div className="loading">
        <SvgIcon title="loading" name="loading" />
        <p>Loading...</p>
      </div>
    )

    const noDataText = (
      <React.Fragment>
        {!isLoading ? <p>No timesheets on record</p> : ""}
      </React.Fragment>
    )

    const token = localStorage.getItem("sessionToken")
    const payrollURL = `${process.env.REACT_APP_PAYROLL_URL}?token=${token}`
    const { isThirdPartyLabor } = this.props.associate
    const associateStatus = associate.adminAssociateStatusDescription
    const mail = alertEmail(associateStatus)

    return (
      <div className="timesheets-content content">
        <div className="container">
          <div className="col-md-8 col-md-offset-2">
            {checkWrongStatus(associate) && (
              <ErrorMessage
                errorText={
                  associateStatus === "Suspended"
                    ? [
                        `Please contact your Talent Agent by
                       clicking the Blue Help Icon on the bottom right
                        to discuss your employment status.`,
                      ]
                    : [
                        "Please contact ",
                        <MailLink mail={mail} className="alert-link" />,
                        " to discuss your employment status",
                      ]
                }
              />
            )}
            {associate.isWurknowUser === 'Y' && (
              <WarningMessage
                warningText={<>
                  <p>
                    Please remember your current assignments and payroll information from any work you have completed after Dec 24th 2022 are now accessible in the SASR Workforce Solutions mobile app.
                    Find more about the mobile app at our <a className="redirect-link" href="https://setandserviceresources.zohodesk.com/portal/en/kb/sasrworkforcesolutions/mobile-app">Knowledge Base</a>.
                  </p>
                  <br/>
                  <p>
                    You may access your previous earnings statements and the year end W2's once they are released at the end of January by selecting the payroll link.
                    If you need assistance please submit a request at the blue help button below.
                  </p>
                </>}
              />
            )}
          </div>
          <div className="page-header">
            <h2>Timesheets</h2>
            {isThirdPartyLabor === "N" && (
              <a
                className="external-link"
                href={payrollURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>Payroll System</span>
                <Icon name="external" width="11" height="11" />
              </a>
            )}
          </div>
        </div>
        <div className="page-body">
          <div className="container">
            <ReactTable
              manual
              onFetchData={this.fetchData}
              data={timesheets}
              pages={this.state.pages}
              total={this.state.total || 1}
              loading={isLoading}
              loadingText={loadingText}
              defaultPageSize={20}
              minRows={0}
              multiSort={false}
              resizeable={false}
              noDataText={noDataText}
              PaginationComponent={Pagination}
              showPagination={timesheets.length > 0}
              showPageJump={false}
              showPageSizeOptions={false}
              getTrProps={(state, row, column) => ({
                className:
                  row &&
                  classNames(row.original.status.label.toLowerCase(), {
                    active:
                      this.state.showForm === row.original.id && !isLoading,
                    empty: !row.original.id,
                  }),
                onClick: e => this.handleRowClick(e, row),
              })}
              columns={[
                {
                  Header: (
                    <SortIcon
                      sortColumn="shift_start"
                      handleClick={this.handleSortClick}
                      active={this.state.sortBy === "shift_start"}
                      name="calendar"
                      height="16"
                      width="16"
                    />
                  ),
                  accessor: "shift_start",
                  Cell: row => <VerticalDate date={row.original.shift.start} />,
                  className: "job-column-date",
                  headerClassName: "job-column-date",
                  width: 80,
                },
                {
                  Header: (
                    <SortIcon
                      sortColumn="project"
                      handleClick={this.handleSortClick}
                      active={this.state.sortBy === "project"}
                      name="pinpoint"
                      height="16"
                      width="14"
                    />
                  ),
                  accessor: "project",
                  Cell: row => (
                    <div className="job-info">
                      <div className="job-info-meta">
                        <strong>{`${row.original.location.address.city}, ${row.original.location.address.state}`}</strong>
                        <span>{row.original.jobId}</span>
                      </div>
                      <div
                        className="job-info-title"
                        title={row.original.project}
                      >
                        <span className="job-info-project">
                          {row.original.project}
                        </span>
                        <TimesheetStatus
                          status={row.original.status}
                          isPartiallyPaid={row.original.isPartiallyPaid}
                          rejectionReason={row.original.rejectionReason}
                          paidDate={row.original.paidDate}
                          isThirdPartyLabor={isThirdPartyLabor}
                        />
                      </div>
                      <div className="job-info-mobile">
                        <div>
                          <strong className="value">
                            {row.original.hours}
                          </strong>
                          <span className="header">Hours</span>
                        </div>
                        <div>
                          <DollarAmount amount={row.original.rate} />
                          <span className="header">Per Hour</span>
                        </div>
                        <div>
                          <DollarAmount
                            amount={
                              row.original.status?.label?.toLowerCase() ===
                              "incomplete"
                                ? 0
                                : row.original.expenseTotal
                            }
                          />
                          <span className="header">Incentives</span>
                        </div>
                      </div>
                    </div>
                  ),
                  className: "job-column-project",
                  headerClassName: "job-column-project",
                },
                {
                  Header: (
                    <SortIcon
                      sortColumn="hours"
                      handleClick={this.handleSortClick}
                      active={this.props.sortBy === "hours"}
                      sortable={false}
                      name="clock"
                      height="16"
                      width="16"
                    />
                  ),
                  accessor: "hours",
                  Cell: row => (
                    <div className="job-info-numbers">
                      <span className="header">Hours</span>
                      <span className="value">
                        {formatHours(row.original.hours)}
                      </span>
                    </div>
                  ),
                  className: "job-column-numbers",
                  headerClassName: "job-column-numbers",
                  width: 86,
                  sortable: false,
                },
                {
                  Header: (
                    <SortIcon
                      sortColumn="rate"
                      handleClick={this.handleSortClick}
                      active={this.props.sortColumn === "rate"}
                      sortable={false}
                      name="money"
                      height="16"
                      width="8"
                    />
                  ),
                  accessor: "rate",
                  Cell: row => (
                    <div className="job-info-numbers">
                      <span className="header">Per Hour</span>
                      <DollarAmount amount={row.original.rate} />
                    </div>
                  ),
                  className: "job-column-numbers",
                  headerClassName: "job-column-numbers",
                  width: 86,
                  sortable: false,
                },
                {
                  Header: (
                    <SortIcon
                      sortColumn="expenseTotal"
                      handleClick={this.handleSortClick}
                      active={this.props.sortColumn === "expenseTotal"}
                      sortable={false}
                      name="folder"
                      height="16"
                      width="16"
                    />
                  ),
                  accessor: "expenseTotal",
                  Cell: row => (
                    <div className="job-info-numbers">
                      <span className="header">Incentives</span>
                      <DollarAmount
                        amount={
                          row.original.status?.label?.toLowerCase() ===
                          "incomplete"
                            ? 0
                            : row.original.expenseTotal
                        }
                      />
                    </div>
                  ),
                  className: "job-column-numbers",
                  headerClassName: "job-column-numbers",
                  width: 86,
                  sortable: false,
                },
              ]}
            />
          </div>
        </div>
        {!isFirstLoad && showForm && (
          <TimesheetModal
            closeModal={this.handleCloseModal}
            timesheetId={timesheetId}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

export default connect(mapStateToProps, null)(TimesheetTable)
