import React from "react"
import MailLink from "./MailLink"

const MailSupport = props => (
  <MailLink
    mail={process.env.REACT_APP_SUPPORT_EMAIL}
    text={process.env.REACT_APP_SUPPORT_EMAIL}
    {...props}
  />
)

export default MailSupport
