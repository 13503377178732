import React, { PureComponent } from "react"
import { findDOMNode } from "react-dom"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./DropdownMenu.scss"


const ALIGNMENTS = ["center", "right", "left"]
const MENU_SIZES = ["sm", "md", "lg", "xl"]

export default class DropdownMenu extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    children: PropTypes.node,
    inverse: PropTypes.bool,
    align: PropTypes.oneOf(ALIGNMENTS),
    textAlign: PropTypes.oneOf(ALIGNMENTS),
    menuAlign: PropTypes.oneOf(ALIGNMENTS),
    className: PropTypes.string,
    size: PropTypes.oneOf(MENU_SIZES),
    closeOnOutsideClick: PropTypes.bool,
  }

  static defaultProps = {
    inverse: false,
    align: "center",
    textAlign: null,
    menuAlign: null,
    className: null,
    size: null,
    closeOnOutsideClick: true,
  }

  static MENU_SIZES = MENU_SIZES
  static ALIGNMENTS = ALIGNMENTS

  componentDidUpdate(prevProps) {
    if (this.props.isOpen === prevProps.isOpen) {
      return
    }

    // const menuItems = ReactDOM.findDOMNode(this).querySelector('.dd-menu > .dd-menu-items')
    if (this.props.isOpen && !prevProps.isOpen) {
      this.lastWindowClickEvent = this.handleClickOutside
      document.addEventListener("click", this.lastWindowClickEvent, { capture: true })
    } else if (!this.props.isOpen && prevProps.isOpen) {
      document.removeEventListener("click", this.lastWindowClickEvent, { capture: true })
      this.lastWindowClickEvent = null
    }
  }

  componentWillUnmount() {
    if (this.lastWindowClickEvent) {
      document.removeEventListener("click", this.lastWindowClickEvent, { capture: true })
    }
  }

  handleClickOutside = e => {
    const target = e.target;
    const menu = findDOMNode(this).closest('.dd-menu');

    if (
      !menu?.contains(target)
    ) {
      if (this.props.closeOnOutsideClick) {
        this.props.close();
      }
    }
  }

  render() {
    const { menuAlign, align, inverse, size, className } = this.props

    const menuClassName = classnames(
      "dd-menu",
      `dd-menu-${menuAlign || align}`,
      { "dd-menu-inverse": inverse },
      size ? `dd-menu-${size}` : null
    )

    const { textAlign, toggle: Toggle, isOpen, children } = this.props

    const listClassName = `dd-menu-items dd-items-${textAlign ||
      align} ${className}`

    return (
      <li className={menuClassName}>
        <Toggle />
        {isOpen && (
          <div className={listClassName}>
            {children}
          </div>
        )}
      </li>
    )
  }
}
