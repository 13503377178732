import React from "react"
import CustomSelect from "components/CustomSelect"
import PropTypes from "prop-types"

const LodgingGender = ({ gender, setGender, hasError, errorMessage }) => {
  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ]

  const rest = {}

  if (gender) {
    let i = options.findIndex(option => option.label === gender)
    rest.defaultValue = options[i]
  }

  if (hasError) {
    rest.className = "has-errors"
  }

  return (
    <div className="lodging-gender">
      <div className="input-box">
        <CustomSelect
          name="lodging.gender"
          onChange={({ label }) => setGender(label)}
          options={options}
          placeholder="Select your gender"
          {...rest}
        />
      </div>

      {hasError && <p className="lodging-error text">{errorMessage}</p>}
    </div>
  )
}

LodgingGender.propTypes = {
  gender: PropTypes.oneOf(["Male", "Female"]),
  setGender: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
}

export default LodgingGender
