import * as types from "./types"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"

export function readPoints() {
  return dispatch => {
    dispatch({ type: types.READ_POINTS_REQUEST })
    return apiCall("get", `associate/${currentAssociateId()}/points`)
      .then(response => {
        dispatch({ type: types.READ_POINTS_SUCCESS, response })

        let newPointsSummary = response.data.subtotals.netPoints
        dispatch({ type: types.UPDATE_ASSOCIATE_SCORE, newPointsSummary })
      })
      .catch(error => {
        dispatch({ type: types.READ_POINTS_FAILURE, error })
        throw error
      })
  }
}

export function readPointsSummary() {
  return dispatch => {
    dispatch({ type: types.READ_POINTS_SUMMARY_REQUEST })
    return apiCall("get", `associate/${currentAssociateId()}/points/summary`)
      .then(response => {
        dispatch({ type: types.READ_POINTS_SUMMARY_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.READ_POINTS_SUMMARY_FAILURE, error })
        throw error
      })
  }
}
