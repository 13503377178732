import React from "react"
import Modal from "components/Modal"
import "./RefreshMessage.scss"

const RefreshMessage = () => {
  const reloadPage = () => window.location.reload()

  return (
    <Modal className="large" id="refresh-message-modal" modalClose={reloadPage}>
      <div className="refresh-message">
        <div className="modal-body">
          <p>
            <strong>A new version of the app is available.</strong>
            <br />
            Please reload the page to get the updated version.
          </p>
          <button className="btn btn-green" onClick={reloadPage}>
            <span>Refresh</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RefreshMessage
