import React, { Component } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

import "./Link.scss"

export default class NavbarLink extends Component {
  render() {
    const {
      customClass,
      path,
      to,
      activePrefix,
      onClick,
      disabled,
    } = this.props

    let active
    if (activePrefix !== undefined) {
      const activeRegex = new RegExp(activePrefix + ".*")
      active = activeRegex.test(path)
    } else {
      active = path === to
    }

    const { hasAlert } = this.props

    return (
      <li className={classNames(customClass, { active: active })}>
        <Link
          to={to}
          onClick={onClick}
          className={"" + (disabled ? "disabled" : "")}
        >
          {this.props.children}
        </Link>
        {hasAlert && <div className="menu-alert-dot" />}
      </li>
    )
  }
}
