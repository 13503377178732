import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  errorMessage: "",
  contents: [],
}

export default function applications(state = defaultState, action) {
  switch (action.type) {
    case types.LIST_APPLICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.LIST_APPLICATIONS_SUCCESS:
      return {
        ...state,
        contents: action.response?.data || [],
        isLoading: false,
      }
    case types.LIST_APPLICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
