import React from "react"
import Icon from "components/Icon"
import "./WarningMessage.scss"
import { PropTypes } from "prop-types"

const WarningMessage = ({ warningText, hasIcon = true }) => (
  <div className="warning-message-wrapper">
    {hasIcon && (
      <Icon name="info" height="24" width="24" />
    )}
    <div className="warning-message">
      {warningText}
    </div>
  </div>
)

WarningMessage.propTypes = {
  warningText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasIcon: PropTypes.bool,
}

export default WarningMessage
