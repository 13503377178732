import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Icon from "components/Icon"
import "./Day.scss"

export default class Day extends Component {
  static propTypes = {
    canApply: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    daysWithConflict: [],
    daysWithEvent: [],
  }

  toggleSelected = () => {
    if (this.props.canApply) {
      if (this.props.assignmentCalendar) {
        if (this.props.daysWithEvent.includes(this.props.index)) {
          if (this.props.todayIndex < this.props.index) {
            this.props.selectDate(this.props.stringDate, !this.props.selected)
          }
        }
      } else {
        if (this.props.todayIndex < this.props.index) {
          this.props.selectDate(this.props.stringDate, !this.props.selected)
        }
      }
    }
  }

  render() {
    const {
      label,
      index,
      daysWithConflict,
      daysWithEvent,
      dayName,
      selected,
      todayIndex,
      canApply,
      unavailableDateIndices,
      penaltyDateIndices,
      assignmentCalendar,
    } = this.props

    const hasConflict = daysWithConflict.includes(index)
    const isJobDay = daysWithEvent.includes(index)
    let isUnavailable = false
    if (unavailableDateIndices) {
      isUnavailable = unavailableDateIndices.includes(index)
    }
    let isPenalty = false
    if (penaltyDateIndices) {
      isPenalty = penaltyDateIndices.includes(index)
    }
    let startOfMonth = ""

    if (index === 1) {
      startOfMonth = "startOfMonth"
    }

    let classes = classNames("vw-cal-day", startOfMonth, dayName, {
      conflict: hasConflict,
      "is-event": isJobDay,
      past: todayIndex >= index && assignmentCalendar,
      error: this.props.hasError,
      disabled: !canApply,
      unavailable: isUnavailable,
      penalty: isPenalty,
    })

    let isInThePast = todayIndex >= index && assignmentCalendar

    return (
      <div
        className={classes}
        onClick={!isUnavailable ? this.toggleSelected : null}
      >
        {canApply && isJobDay && todayIndex < index && (
          <div>
            {selected ? (
              assignmentCalendar ? (
                <div className="checkbox checked cancelled">
                  <Icon name="x2" height="8" width="10" />
                </div>
              ) : (
                <div className="checkbox checked">
                  <Icon name="tick" height="8" width="10" />
                </div>
              )
            ) : (
              <div className="checkbox unchecked" />
            )}
          </div>
        )}
        {isInThePast ? (
          <div className="day-number past">{label}</div>
        ) : (
          <div className="day-number">{label}</div>
        )}
      </div>
    )
  }
}
