import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  contents: {},
}

export default function assignments(state = defaultState, action) {
  switch (action.type) {
    case types.READ_ASSIGNMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        contents: undefined,
      }
    case types.READ_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contents: action.response?.data || {},
      }
    case types.READ_ASSIGNMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
