import * as types from "./types"
import { apiCall } from "utils/api"

export function readApiVersion() {
  return dispatch => {
    dispatch({ type: types.READ_API_VERSION_REQUEST })
    return apiCall("get", `version`)
      .then(response => {
        dispatch({ type: types.READ_API_VERSION_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_API_VERSION_FAILURE, error })
        throw error
      })
  }
}
