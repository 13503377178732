import React, { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { listTeammates } from "actions/teammates"
import * as actionCreators from "actions"
import DropdownMenu from "./DropdownMenu"
import Icon from "components/Icon"
import ProfilePicture from "components/ProfilePicture"
import ProfileDropdownCounter from "./ProfileDropdownCounter"
import { bindActionCreators } from "redux"
import { checkWrongStatus } from "utils/associateStatus"
import WrongStatusAlert from "components/Modals/WrongStatusAlert"
import "./ProfileDropdown.scss"

const ProfileDropdown = ({ associate, deleteSession, listTeammates }) => {
  const { isThirdPartyLabor, onBoardingStatus } = associate

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [teammates, setTeammates] = useState([])
  const [statusModalVisible, setStatusModalVisible] = useState(false)

  const showStatusModal = e => {
    if (checkWrongStatus(associate)) {
      e.preventDefault()

      setStatusModalVisible(true)
    } else {
      setIsMenuOpen(false)
    }
  }

  const hideStatusModal = e => {
    if (checkWrongStatus(associate)) {
      setStatusModalVisible(false)
    }
  }

  const clickLogout = () => {
    deleteSession()
  }

  const close = e => {
    setIsMenuOpen(false)
  }

  const toggle = () => {
    setIsMenuOpen(isOpen => !isOpen)

    if (!isMenuOpen && !checkWrongStatus(associate)) {
      listTeammates()
        .then(response => {
          setTeammates(response.data.invitationReceived)
        })
        .catch(() => {})
    }
  }

  const showOnboarding =
    (onBoardingStatus === 1 || onBoardingStatus === 2) &&
    isThirdPartyLabor === "N"
  const invitationCount = teammates?.length ? teammates.length : 0
  const showInvitationCount = invitationCount > 0

  return (
    <>
      <DropdownMenu
        className="profile-menu"
        close={close}
        isOpen={isMenuOpen}
        toggle={() => <ProfilePicture associate={associate} onClick={toggle} />}
      >
        <div className="associate">
          <ProfilePicture associate={associate} />
          <div className="associate-name">
            <h6>
              {associate.firstName} {associate.lastName}
            </h6>
            <span className="associate-id">{associate.id}</span>
          </div>
        </div>
        <div className="profile-links">
          <ul>
            <li className="profile">
              <Link
                to="/app/profile/personal"
                onClick={e => showStatusModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                Personal Info
              </Link>
            </li>
            {showOnboarding && (
              <li className="profile">
                <Link
                  to="/app/profile/employment"
                  onClick={e => showStatusModal(e)}
                  className={
                    "" + (checkWrongStatus(associate) ? "disabled" : "")
                  }
                >
                  Employment Info
                </Link>
              </li>
            )}
            {isThirdPartyLabor === "N" && (
              <li className="profile">
                <Link
                  to="/app/profile/interests"
                  onClick={e => showStatusModal(e)}
                  className={
                    "" + (checkWrongStatus(associate) ? "disabled" : "")
                  }
                >
                  Work Interests
                </Link>
              </li>
            )}
            <li className="profile">
              <Link
                to="/app/profile/notifications"
                onClick={e => showStatusModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                Notifications
              </Link>
            </li>
            <li className="profile">
              <Link
                to="/app/profile/points"
                onClick={e => showStatusModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                Points
              </Link>
            </li>
            <li className="profile">
              <Link
                to="/app/profile/account"
                onClick={e => showStatusModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                Account
              </Link>
            </li>
            <li className="profile">
              <Link
                to="/app/profile/teammates"
                onClick={e => showStatusModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                Teammates
                {showInvitationCount && (
                  <ProfileDropdownCounter count={teammates.length} />
                )}
              </Link>
            </li>
            <li className="profile">
              <Link
                to="/app/profile/disclosures"
                onClick={e => showStatusModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                Disclosures
              </Link>
            </li>
          </ul>
        </div>
        <div className="logout" onClick={clickLogout}>
          <span>Logout</span>
          <Icon name="arrow-right" />
        </div>
      </DropdownMenu>
      {statusModalVisible && <WrongStatusAlert modalClose={hideStatusModal} />}
    </>
  )
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
    teammates: state.data.teammates,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        listTeammates,
      },
      dispatch
    ),

    deleteSession: () => {
      dispatch(actionCreators.deleteSession())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown)
)
