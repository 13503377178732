import React, { Component } from "react"
import PropTypes from "prop-types"
import SignatureCanvas from "react-signature-canvas"
import ContainerDimensions from "react-container-dimensions"
import { reduxForm, formValueSelector, change } from "redux-form"
import { connect } from "react-redux"

import Icon from "components/Icon"
import Button from "components/Form/Button"
import ErrorMessage from "components/ErrorMessage"
import Header from "./Header"
import validate from "./validate"

export const fields = [
  "startTime",
  "endTime",
  "breaks",
  "breakAcknowledgement",
  "supervisorName",
  "supervisorContact",
  "expenses",
  "mileage",
  "signature",
]
const selector = formValueSelector("timesheet")

export class TimesheetSignature extends Component {
  static propTypes = {
    associate: PropTypes.object.isRequired,
    previousStep: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    timesheet: PropTypes.object.isRequired,
    totalTime: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      trimmedDataURL: "",
    }
  }

  handleSignatureChange = data => {
    this.props.dispatch(change("timesheet", "signature", data))
  }

  clearSignature = () => {
    this.signaturePad.clear()
    this.setState({ trimmedDataURL: "" })
  }

  trimSignature = () => {
    const trimmedDataURL = this.signaturePad
      .getTrimmedCanvas()
      .toDataURL("image/png")
    this.setState({ trimmedDataURL: trimmedDataURL })
    this.handleSignatureChange(trimmedDataURL)
  }

  render() {
    const {
      associate,
      timesheet,
      previousStep,
      handleSubmit,
      submitting,
      totalTime,
      error,
    } = this.props

    const { trimmedDataURL } = this.state

    const footer = (
      <Button
        btnClass="btn-green btn-block"
        text={"Submit Timesheet"}
        disabled={!trimmedDataURL.length}
        submitting={submitting}
        onClick={handleSubmit}
      />
    )

    const associateName = `${associate.attributes.firstName[0]}. ${
      associate.attributes.lastName
    }`

    const signatureCanvas = (
      <div className="supervisor-signature-wrapper">
        <ContainerDimensions>
          {({ width, height }) => (
            <SignatureCanvas
              penColor="black"
              onEnd={this.trimSignature}
              ref={ref => {
                this.signaturePad = ref
              }}
              canvasProps={{
                width: width,
                height: height,
                className: "supervisor-signature-canvas",
              }}
            />
          )}
        </ContainerDimensions>
      </div>
    )

    return (
      <form onSubmit={handleSubmit} className="timesheet-form">
        <Header
          timesheet={timesheet}
          previousStep={previousStep}
          associateName={associateName}
          totalTime={totalTime}
          signature
        />
        <div className="timesheet-signature">
          {error && <ErrorMessage errorText={error} />}
          <div className="supervisor-signature-header">
            Please sign to approve the timesheet below
            <div className="clear-signature" onClick={this.clearSignature}>
              Clear &nbsp;
              <Icon name="x" width="10" height="12" />
            </div>
          </div>
          {signatureCanvas}
          <div className="sign-above">
            <Icon name="x" width="20" height="20" />
            <div className="signature-line" />
          </div>
        </div>

        <div className="timesheet-footer">{footer}</div>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.data.timesheets.data.find(
      timesheet => timesheet.id === parseInt(state.data.timesheets.detailId, 0)
    ),
    formValues: selector(
      state,
      "startTime",
      "endTime",
      "breaks",
      "mileage",
      "supervisorName",
      "supervisorContact"
    ),
    associate: state.data.associate,
  }
}

TimesheetSignature = reduxForm({
  form: "timesheet",
  fields,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(TimesheetSignature)

TimesheetSignature = connect(mapStateToProps)(TimesheetSignature)

export default TimesheetSignature
