import React, { Component, Fragment } from "react"
import Button from "components/Form/Button"
import {
  confirmInvitation,
  rejectInvitation,
  removeTeammate,
} from "actions/teammates"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { successAlert, errorAlert } from "utils/alerts"
import ConfirmRemove from "components/Teammates/ConfirmRemove"

class TeammatesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      showRemovalModal: false,
    }
  }

  confirmInvitation = id => {
    this.setState({ isLoading: true })

    return this.props
      .confirmInvitation(id)
      .then(() => {
        successAlert("Invitation was confirmed successfully.")
        return this.props.listTeammates().then(() => {
          this.setState({ isLoading: false })
        })
      })
      .catch(error => {
        if (error) {
          this.showError(error)
        }
        this.setState({ isLoading: false })
      })
  }

  rejectInvitation = id => {
    this.setState({ isLoading: true })

    return this.props
      .rejectInvitation(id)
      .then(() => {
        successAlert("Invitation was rejected successfully.")
        return this.props.listTeammates().then(() => {
          this.setState({ isLoading: false })
        })
      })
      .catch(error => {
        if (error) {
          this.showError(error)
        }
        this.setState({ isLoading: false })
      })
  }

  removeTeammate = id => {
    this.setState({ isLoading: true })
    return this.props
      .removeTeammate(id)
      .then(() => {
        successAlert("Teammate was removed successfully.")
        this.setState({ showRemovalModal: false })
        return this.props.listTeammates().then(() => {
          this.setState({ isLoading: false })
        })
      })
      .catch(error => {
        if (error) {
          this.showError(error)
        }
        this.setState({ isLoading: false })
      })
  }

  showError = error => {
    if (error.data && error.data.error) {
      errorAlert(error.data.error)
    }
  }

  showRemoveModal = (id, fullName) => {
    this.setState({
      showRemovalModal: true,
      teammateId: id,
      teammateFullName: fullName,
    })
  }

  hideRemoveModal = () => {
    this.setState({ showRemovalModal: false })
  }

  render() {
    const { invitations, teammates } = this.props
    const {
      isLoading,
      showRemovalModal,
      teammateId,
      teammateFullName,
    } = this.state

    return (
      <div className="team-wrapper">
        <h3>Respond to these invitations</h3>
        {invitations ? (
          <Fragment>
            <ul className="team-list">
              {invitations &&
                invitations.map((invite, index) => (
                  <li key={index} className="team-item">
                    <div className="teammate-item-container">
                      <div className="preview">{invite.fullName}</div>
                      <div className="controls">
                        <Button
                          btnClass="btn btn-green btn-save"
                          text="Confirm"
                          onClick={() => this.confirmInvitation(invite.id)}
                          disabled={isLoading}
                        />
                        <Button
                          btnClass="btn btn-edit btn-save"
                          text="Deny"
                          onClick={() => this.rejectInvitation(invite.id)}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    {invite.message && (
                      <div className="invite-message">
                        Message: <i>{invite.message}</i>
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </Fragment>
        ) : (
          <p className="teammates-info">
            You have no invitations at the moment.
          </p>
        )}

        <h3 className="team-list-header">My Teammates</h3>
        {teammates && teammates.length > 0 ? (
          <React.Fragment>
            <ul className="team-list">
              {teammates &&
                teammates.map((teammate, index) => (
                  <li key={index} className="team-item">
                    <div className="teammate-item-container">
                      <div className="preview">{teammate.fullName}</div>
                      <div className="controls">
                        <Button
                          btnClass="btn btn-edit btn-save"
                          text="Remove"
                          onClick={() =>
                            this.showRemoveModal(teammate.id, teammate.fullName)
                          }
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </li>
                ))}
            </ul>

            {showRemovalModal && (
              <ConfirmRemove
                handleConfirm={this.removeTeammate}
                submitting={isLoading}
                teammateId={teammateId}
                teammateFullName={teammateFullName}
                modalClose={this.hideRemoveModal}
              />
            )}
          </React.Fragment>
        ) : (
          <p className="teammates-info">You have no teammates at the moment</p>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        removeTeammate,
        confirmInvitation,
        rejectInvitation,
      },
      dispatch
    ),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(TeammatesList)
