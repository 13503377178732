import * as types from "./types"
import axios from "axios"
import { apiCall } from "utils/api"
import { pickKeys } from "utils/object"
import { serializeJobSearch } from "serializers/jobs"
import { successAlert } from "utils/alerts"

import { readTasks } from "actions/tasks"

export function clearJobs() {
  return dispatch => {
    dispatch({ type: types.CLEAR_JOBS })
  }
}

export function declineJob(params) {
  const jobId = params.jobId
  return dispatch => {
    dispatch({ type: types.DECLINE_JOB_REQUEST, params })
    return apiCall("post", `opportunities/${jobId}/decline`, {}, true, null, {
      property: "data.deniedActions.canCancel",
    }, { skip404: true})
      .then(response => {
        dispatch({ type: types.DECLINE_JOB_SUCCESS, response })
        dispatch({ type: types.REMOVE_OPEN_JOB, jobId })
        successAlert("Job declined.")
      })
      .catch(error => {
        dispatch({ type: types.DECLINE_JOB_FAILURE, error })
        throw error
      })
  }
}

export function readJob(id, params = {}) {
  let selectParams = pickKeys(params, "locationId", "radius")
  return dispatch => {
    dispatch({ type: types.READ_JOB_REQUEST, id, params })
    return apiCall(
      "get",
      `opportunities/${id}?include=project.requirements,project.workType`,
      { params: selectParams },
      true,
      null,
      { message: "The job you have accessed is no longer available." }
    )
      .then(response => {
        dispatch({ type: types.READ_JOB_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_JOB_FAILURE, error })
        throw error
      })
  }
}

export function searchJobs(params) {
  if (params.sort) {
    const sort = params.sort
    const sortColumn = sort && sort.charAt(0) === "-" ? sort.substr(1) : sort
    const sortDirection = sort && sort.charAt(0) === "-" ? "desc" : "asc"
    params[`sort[${sortColumn}]`] = sortDirection
    delete params.sort
  }

  if (params && params.radius && params.radius === 1000) {
    params.radius = "1000+"
  }

  const source = axios.CancelToken.source()
  const cancelToken = source.token

  return dispatch => {
    dispatch({ type: types.SEARCH_JOBS_REQUEST, params, axiosSource: source })
    return apiCall(
      "get",
      `opportunities?include=project.workType`,
      { params: serializeJobSearch(params) },
      true,
      cancelToken
    )
      .then(response => {
        dispatch(readTasks())
        dispatch({ type: types.SEARCH_JOBS_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.SEARCH_JOBS_FAILURE, error })
        throw error
      })
  }
}
