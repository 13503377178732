import React from "react"
import PropTypes from "prop-types"
import Icon from "components/Icon"
import "./Tooltip.scss"

const Tooltip = props => (
  <span className="tooltip-wrapper">
    <div className="tooltip-icon-caption">
      <Icon name="info" />
      <span className="caption">{props.caption}</span> 
    </div> 
    <span className="tooltip">{props.text}</span>
  </span>
)

Tooltip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired
}

export default Tooltip
