import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { Grid, Row, Col } from "react-flexbox-grid"
import MediaQuery from "react-responsive"
import ProfilePersonalRoute from "routes/App/Profile/PersonalInfo"
import ProfileEmploymentRoute from "routes/App/Profile/EmploymentInfo"
import ProfileInterestsRoute from "routes/App/Profile/WorkInterests"
import ProfileNotificationsRoute from "routes/App/Profile/Notifications"
import ProfilePointsRoute from "routes/App/Profile/Points"
import ProfileAccountRoute from "routes/App/Profile/Account"
import ProfileTeammatesRoute from "routes/App/Profile/Teammates"
import ProfileStateDisclosuresRoute from "routes/App/Profile/StateDisclosures"
import Gamification from "components/Profile/Gamification"
import TalentAgentInfo from "components/Profile/TalentAgentInfo"
import Navbar from "components/Profile/Navbar"
import "./Profile.scss"

export default class ProfileRoute extends Component {
  render() {
    return (
      <React.Fragment>
        <MediaQuery minWidth={768}>
          <Navbar path={this.props.location.pathname} />
        </MediaQuery>
        <div className="profile-content content">
          <Grid>
            <Row>
              <Col xs={12} md={4} last="md" className="gamification-column">
                <Gamification />
                <TalentAgentInfo />
              </Col>
              <Col xs={12} md={8} first="md" className="profile-form-column">
                <section className="profile-form">
                  <Switch>
                    <Route
                      exact
                      path={`${this.props.match.path}/personal`}
                      component={ProfilePersonalRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/employment`}
                      component={ProfileEmploymentRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/interests`}
                      component={ProfileInterestsRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/notifications`}
                      component={ProfileNotificationsRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/points`}
                      component={ProfilePointsRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/account`}
                      component={ProfileAccountRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/teammates`}
                      component={ProfileTeammatesRoute}
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/disclosures`}
                      component={ProfileStateDisclosuresRoute}
                    />
                    <Redirect to="/app/profile/personal" />
                  </Switch>
                </section>
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}
