import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { currentAssociateId } from "utils/auth"
import { loadJSON, saveJSON } from "utils/localStorageJSON"
import { clearLodgingErrors, clearLodgingFieldError } from "actions"
import LodgingNotes from "./LodgingNotes"
import LodgingTypes from "./LodgingTypes"
import LodgingGender from "./LodgingGender"
import LodgingTeammate from "./LodgingTeammate"
import PropTypes from "prop-types"
import "./Form.scss"

const LodgingForm = ({
  errors,
  canApply,
  teammates,
  lodgingTypes,
  handleChange,
  clearLodgingErrors,
  clearLodgingFieldError,
  toggleInviteTeammateModal,
}) => {
  const ASSOCIATE_STORAGE_KEY = `associate:${currentAssociateId()}:lodging`
  const INITIAL_LODGING = {
    notes: "",
    touched: false,
  }

  const prevData = loadJSON(ASSOCIATE_STORAGE_KEY)
  const [lodging, setLodging] = useState(prevData ?? INITIAL_LODGING)

  const updateFormValues = useCallback(() => {
    let lodgingParams = { ...lodging }

    if (lodgingParams.teammates) {
      lodgingParams.teammatesNames = lodgingParams.teammates.map(
        mate => mate.fullName
      )

      lodgingParams.teammates = lodgingParams.teammates.map(mate => mate.id)
    }

    if (lodgingParams.types) {
      lodgingParams.types = lodgingParams.types.map(type => type.value)
    }

    handleChange("lodging", lodgingParams)
  }, [lodging, handleChange])

  useEffect(() => {
    saveJSON(ASSOCIATE_STORAGE_KEY, lodging)
    updateFormValues()
  }, [lodging, updateFormValues, ASSOCIATE_STORAGE_KEY])

  useEffect(() => {
    return () => {
      clearLodgingErrors()
    }
  }, [clearLodgingErrors])

  const updateLodging = (key, value) => {
    setLodging(lodging => ({
      ...lodging,
      touched: true,
      [key]: value,
    }))

    if (errors.hasOwnProperty(key)) {
      clearLodgingFieldError(key)
    }
  }

  const setGender = gender => {
    updateLodging("gender", gender)
  }

  const setNotes = notes => {
    updateLodging("notes", notes)
  }

  const setPreferredRoommate = teammate => {
    updateLodging("teammates", teammate)
  }

  const setPreferredLodgingType = types => {
    updateLodging("types", types)
  }

  return (
    <div id="lodging-form" className="lodging-user-requests">
      <div className="user-requests-section user-requests-lodging">
        <h6>Request Lodging</h6>
        <div className="lodging-fields">
          <LodgingGender
            gender={lodging?.gender}
            setGender={setGender}
            hasError={errors.hasOwnProperty("gender")}
            errorMessage={errors?.gender ?? ""}
          />
          <LodgingTypes
            lodgingTypes={lodgingTypes}
            value={lodging?.types ?? ""}
            setPreferredLodgingType={setPreferredLodgingType}
          />
          <LodgingTeammate
            teammates={teammates}
            value={lodging?.teammates ?? ""}
            setPreferredRoommate={setPreferredRoommate}
            toggleInviteTeammateModal={toggleInviteTeammateModal}
          />
          <LodgingNotes
            notes={lodging?.notes ?? ""}
            canApply={canApply}
            setNotes={setNotes}
          />
        </div>
      </div>
    </div>
  )
}

LodgingForm.propTypes = {
  canApply: PropTypes.bool.isRequired,
  teammates: PropTypes.array,
  lodgingTypes: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  toggleInviteTeammateModal: PropTypes.func.isRequired,
}

LodgingForm.defaultProps = {
  teammates: [],
}

const mapStateToProps = state => {
  return {
    errors: state.data.lodging.errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        clearLodgingErrors,
        clearLodgingFieldError,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LodgingForm)
