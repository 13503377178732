import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-flexbox-grid"
import { Field } from "redux-form"
import _ from "lodash"
import TextInput from "components/Form/TextInput"
import StateSelect from "components/Form/StateSelectRedux"

export default class Address extends Component {
  static propTypes = {
    fieldPrefix: PropTypes.string,
  }

  capitalizedFieldPrefix() {
    return _.capitalize(this.props.fieldPrefix)
  }

  prefixedFieldTitle(title) {
    return this.props.fieldPrefix
      ? `${this.capitalizedFieldPrefix()} ${title}`
      : title
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <div className="input-box input-box-grid">
              <Field
                name="streetAddress1"
                type="text"
                component={TextInput}
                label={this.prefixedFieldTitle("Address Line 1")}
                placeholder={this.prefixedFieldTitle("Address Line 1")}
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="input-box input-box-grid">
              <Field
                name="streetAddress2"
                type="text"
                component={TextInput}
                label={this.prefixedFieldTitle("Address Line 2")}
                placeholder={this.prefixedFieldTitle("Address Line 2")}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <div className="input-box input-box-grid">
              <Field
                name="city"
                type="text"
                component={TextInput}
                label={this.prefixedFieldTitle("City")}
                placeholder={this.prefixedFieldTitle("City")}
              />
            </div>
          </Col>
          <Col xs={12} md={5}>
            <div className="input-box input-box-grid">
              <StateSelect
                name="state"
                placeholder={this.prefixedFieldTitle("State")}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="input-box input-box-grid">
              <Field
                name="zipCode"
                type="text"
                component={TextInput}
                label={this.prefixedFieldTitle("Zip")}
                placeholder={this.prefixedFieldTitle("Zip")}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
