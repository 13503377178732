import React from "react"
import { Link } from "react-router-dom"
import ProfilePicture from "components/ProfilePicture"
import Icon from "components/Icon"
import SvgIcon from "components/SvgIcon"
import { checkWrongStatus } from "utils/associateStatus"
import "./SidebarContent.scss"

const REFERRAL_LINK = process.env.REACT_APP_REFERRAL_LINK

const SidebarContent = props => {
  const { associate, modalOpen, handleClick, handleLogout, invitationCount } =
    props
  const { isThirdPartyLabor, onBoardingStatus } = associate

  const showModal = e => {
    modalOpen(e)
    handleClick()
  }

  return (
    <div className="side-menu-content">
      <div className="side-menu-header">
        <div className="associate">
          <ProfilePicture associate={associate} />
          <div className="associate-name">
            <h5>
              {associate.firstName} {associate.lastName}
            </h5>
            <h6>Associate ID</h6>
            <span className="associate-id">{associate.id}</span>
          </div>
        </div>
      </div>
      <div className="side-menu-body">
        <ul>
          <li className="profile">
            <Link
              to="/app/profile/personal"
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <Icon name="user" height="24" width="24" />
              <span>Personal Info</span>
            </Link>
          </li>
          {onBoardingStatus !== 0 && isThirdPartyLabor === "N" && (
            <li className="profile">
              <Link
                to="/app/profile/employment"
                onClick={e => showModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                <Icon name="user" height="24" width="24" />
                <span>Employment Info</span>
              </Link>
            </li>
          )}
          {isThirdPartyLabor === "N" && (
            <li className="profile">
              <Link
                to="/app/profile/interests"
                onClick={e => showModal(e)}
                className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
              >
                <Icon name="briefcase" height="24" width="24" />
                <span>Work Interests</span>
              </Link>
            </li>
          )}
          <li className="profile">
            <Link
              to="/app/profile/notifications"
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <Icon name="notification" height="26" width="22" />
              <span>Notifications</span>
            </Link>
          </li>
          <li className="profile">
            <Link
              to="/app/profile/points"
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <Icon name="trophy" height="24" width="24" />
              <span>Points</span>
            </Link>
          </li>
          <li className="profile">
            <Link
              to="/app/profile/account"
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <Icon name="gear" height="24" width="24" />
              <span>Account</span>
            </Link>
          </li>
          <li className="profile">
            <Link
              to="/app/profile/teammates"
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <Icon name="teammates" height="24" width="24" />
              <span>Teammates</span>
              {invitationCount > 0 && (
                <div class="counter-badge counter-badge-mob">
                  {invitationCount}
                </div>
              )}
            </Link>
          </li>
          <li className="profile">
            <Link
              to="/app/profile/disclosures"
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <SvgIcon title="PDF file" name="document" />
              <span>Disclosures</span>
            </Link>
          </li>
        </ul>
        {isThirdPartyLabor !== "Y" && (
          <div className="referral-link">
            <a
              target="_blank"
              rel="noreferrer"
              href={REFERRAL_LINK}
              onClick={e => showModal(e)}
              className={"" + (checkWrongStatus(associate) ? "disabled" : "")}
            >
              <span>Refer a friend and earn</span>
              <em>100</em>
            </a>
          </div>
        )}
      </div>
      {isThirdPartyLabor === "N" && (
        <div className="side-menu-help">
          <ul>
            <li>
              <a
                href="https://desk.zoho.com/portal/setandserviceresources/en/set-and-service-resources"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>Help Center</span>
              </a>
            </li>
            <li>
              <a
                href="https://desk.zoho.com/portal/setandserviceresources/en/signin"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>Support Inbox</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="side-menu-footer">
        <a className="logout" onClick={handleLogout}>
          <span>Logout</span>
          <Icon name="caret-right" height="15" width="15" />
        </a>
      </div>
    </div>
  )
}

export default SidebarContent
