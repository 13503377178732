import React from "react"
import TextareaInput from "components/Form/TextareaInput"

const LodgingNotes = ({ notes, setNotes, canApply }) => {
  return (
    <div className="lodging-note">
      <TextareaInput
        input={{
          name: "lodging-notes",
          value: notes,
          onChange: setNotes,
        }}
        placeholder="Lodging Notes"
        rows="5"
        maxLength={250}
        disabled={!canApply}
      />
    </div>
  )
}

export default LodgingNotes
