import React, { useEffect, useState } from 'react';
import ReactSelect, { components } from "react-select";
import AsyncSelect from "react-select/async";

const CustomSelect = props => {
  const { className = '', labelKey = 'label', valueKey = 'value', isMulti = false, menuPlacement = 'bottom', onBlur, classNamePrefix = 'Select', ...rest} = props;
  const [hasValue, setHasValue] = useState(false);

  useEffect(() => {
    setHasValue(!!props.value);
  }, [props.value, setHasValue]);

  const ComponentType = props.loadOptions ? AsyncSelect : ReactSelect;

  return (
    <ComponentType
      className={`${hasValue ? 'Select--has-value' : ''} ${isMulti ? 'Select--is-multi' : ''} ${classNamePrefix} ${className}`}
      getOptionLabel={option => option[labelKey]}
      getOptionValue={option => option[valueKey]}
      classNamePrefix={classNamePrefix}
      menuPlacement={menuPlacement}
      onBlur={event => {
        event.preventDefault();
      }}
      isMulti={isMulti}
      components={{
        IndicatorSeparator: () => null,
        Control: ({children, ...props}) => (
          <components.Control 
            className={`Select__position--${menuPlacement}`}
            {...props}
          >
            {children}
          </components.Control>
        )
      }}
      {...rest}
    />
  )
};

export default CustomSelect;