import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import DayContext from "./DayContext"
import Slot from "./Slot"

import "./Day.scss"

export default class Day extends Component {
  static propTypes = {
    day: PropTypes.object,
    isLoading: PropTypes.bool,
  }

  render() {
    const { day, isLoading, assignmentId } = this.props
    const dateFormat = "ddd, MMM DD"
    let daysFromNow
    if (day !== undefined) {
      daysFromNow = moment(day.date)
        .startOf("day")
        .diff(moment().startOf("day"), "days")
    } else {
      daysFromNow = 0
    }

    return (
      <div className={`schedule-day ${daysFromNow === 0 ? "today" : ""}`}>
        {daysFromNow === 0 ? (
          <div className="day-header">
            <span className="header-left">Today</span>
            <span className="header-right">{moment().format(dateFormat)}</span>
          </div>
        ) : daysFromNow === 1 ? (
          <div className="day-header">
            <span className="header-left">Tomorrow</span>
            <span className="header-right">
              {moment(day.date).format(dateFormat)}
            </span>
          </div>
        ) : (
          <div className="day-header">
            <span className="header-left">In {daysFromNow} Days</span>
            <span className="header-right">
              {moment(day.date).format(dateFormat)}
            </span>
          </div>
        )}
        {isLoading ? (
          <div className="day-message">Loading schedule...</div>
        ) : day === undefined ? (
          <div className="day-message">
            Nothing on {daysFromNow === 0 ? `today's` : ""} schedule
          </div>
        ) : (
          day.slots.map((slot, i) => (
            <DayContext.Provider value={day.date} key={i}>
              <Slot
                slot={slot}
                isLoading={isLoading}
                key={i}
                assignmentId={assignmentId}
              />
            </DayContext.Provider>
          ))
        )}
      </div>
    )
  }
}
