import {
  SET_LODGING_ERROR,
  CLEAR_LODGING_ERRORS,
  CLEAR_LODGING_FIELD_ERROR,
} from "actions/types"
import { removePropertyFromObject } from "utils/object"

const defaultState = {
  errors: {},
}

export default function lodgingErrors(state = defaultState, action) {
  switch (action.type) {
    case SET_LODGING_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      }
    case CLEAR_LODGING_FIELD_ERROR:
      return {
        ...state,
        errors: removePropertyFromObject(action.payload.field, state.errors),
      }
    case CLEAR_LODGING_ERRORS:
      return {
        ...state,
        errors: {}
      }
    default:
      return state
  }
}
