import jwtDecode from "jwt-decode"

export function currentAssociateId() {
  const token = localStorage.getItem("sessionToken")
  if (token) {
    try {
      const id = jwtDecode(token).sub
      return id
    } catch (error) {
      return
    }
  }
}
