import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { formatCurrency } from "utils/format"
import Icon from "components/Icon"
import VerticalDate from "components/VerticalDate"

import "./Summary.scss"

export default class Summary extends Component {
  static propTypes = {
    durationDays: PropTypes.number,
    endDate: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    payRate: PropTypes.string.isRequired,
    job: PropTypes.object.isRequired,
    startDate: PropTypes.string,
    travelIncentive: PropTypes.string.isRequired,
    application: PropTypes.object,
  }

  render() {
    const {
      durationDays,
      endDate,
      isAuthenticated,
      job,
      payRate,
      startDate,
      travelIncentive,
      application,
    } = this.props
    const isLoaded = job.title !== undefined
    const localAuth = isAuthenticated === undefined ? true : isAuthenticated

    const startTime = job.shift.time.start
      ? moment(job.shift.time.start, "HH:mm:ss").format("h:mma")
      : "..."
    const endTime = job.shift.time.end
      ? moment(job.shift.time.end, "HH:mm:ss").format("h:mma")
      : "..."
    const duration = job.shift.time.duration
      ? `${parseFloat(job.shift.time.duration).toString()} hours`
      : "..."
    const formattedPayRate =
      payRate !== undefined ? formatCurrency(payRate) : "$..."
    const workType = job.project.workType ? (
      job.project.workType.label
    ) : (
      <p>Loading...</p>
    )
    const multiDay = endDate > startDate

    const travelIncentiveVerbiage = application.contents.travel
      ? application.contents.travel.mileageOption === "ppm"
        ? !!application.contents.travel.mileageAfterMaxMiles
          ? `$${
              application.contents.travel.proposedRate
            }/Mile for every mile after ${
              application.contents.travel.mileageAfterMaxMiles
            } ${
              application.contents.travel.mileageAfterMaxMiles === 1
                ? "mile"
                : "miles"
            }`
          : `$${application.contents.travel.proposedRate}/Mile`
        : `$${application.contents.travel.proposedRate}`
      : `$${travelIncentive}`

    const isTravelIncentiveVisible =
      application &&
      application.contents &&
      application.contents.travel &&
      application.contents.travel.mileageOption &&
      application.contents.travel.mileageOption !== "none"

    return (
      <div className="job-detail-summary">
        {isLoaded ? (
          <React.Fragment>
            <div className="summary-top">
              <div className="job-date-range">
                <VerticalDate date={startDate} />
                {multiDay && (
                  <React.Fragment>
                    <Icon name="arrow-right" height="10" width="13" />
                    <VerticalDate date={endDate} />
                  </React.Fragment>
                )}

                <div className="icon-label work-days">
                  <Icon name="calendar" height="16" width="16" />
                  <span>
                    {durationDays}
                    <span className="mobile-hide"> Work</span> Days
                  </span>
                </div>
              </div>
              <div className="job-time-range">
                <div className="times">
                  {startTime} - {endTime}
                </div>
                <div className="total-hours">{duration}</div>
                <div className="icon-label work-hours">
                  <Icon name="clock" height="16" width="16" />
                  <span>
                    <span className="mobile-hide">Work</span> Hours
                  </span>
                </div>
              </div>

              <div className="job-payment">
                <div className="per-hour">Per Hour</div>
                <div className="per-hour-value">{formattedPayRate}</div>
                <div className="icon-label pay">
                  <Icon name="money" height="16" width="10" />
                  <span>Pay</span>
                </div>
              </div>
            </div>

            <div className="summary-bottom">
              <div className="work-type">
                <div className="section-value">{workType}</div>
                <div className="icon-label">
                  <Icon name="work-type" height="12" width="16" />
                  <span>Work Type</span>
                </div>
              </div>

              {localAuth &&
                Number(travelIncentive) > 0 &&
                isTravelIncentiveVisible && (
                  <div className="travel-incentive">
                    <div className="section-value">
                      {travelIncentiveVerbiage}
                    </div>
                    <div className="icon-label">
                      <Icon
                        title="Commute Incentive"
                        name="car"
                        height="14"
                        width="15"
                      />
                      <span>Commute Incentive</span>
                    </div>
                  </div>
                )}
            </div>
          </React.Fragment>
        ) : (
          <div className="loading">Loading Summary...</div>
        )}
      </div>
    )
  }
}
