import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Icon from "components/Icon"
import "./ExpandableSection.scss"

const cancellationType = "cancellation"

export default class ExpandableSection extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  toggleExpand = event => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { title, type, text, content } = this.props

    const expandClass = classNames("expand-section", "expandable", {
      expanded: this.state.expanded,
    })

    const buttonClass = classNames({ rotated: this.state.expanded })

    return (
      <Fragment>
        {type === cancellationType && (
          <div className="cancellation-header">
            <h5>{title}</h5>
            <a className={buttonClass} onClick={this.toggleExpand}>
              <Icon name="caret-down" height="9" width="14" />
            </a>
          </div>
        )}
        <div
          className={
            type === cancellationType
              ? "expandable-section cancellation"
              : "expandable-section"
          }
        >
          {!type && (
            <div className="expandable-header" onClick={this.toggleExpand}>
              <h5 onClick={this.toggleExpand}>{title}</h5>
              <a className={buttonClass} onClick={this.toggleExpand}>
                <Icon name="caret-down" height="9" width="14" />
              </a>
            </div>
          )}
          <div className={`${expandClass} test`}>
            <div className="inner">
              <p dangerouslySetInnerHTML={{ __html: text }} />
              {content && (
                <div className="content-container">
                  <ul>{content}</ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
