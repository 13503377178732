import * as types from "./types"
import { apiCall } from "utils/api"
import { currentAssociateId } from "utils/auth"
import { successAlert } from "utils/alerts"

export function createReferrals(params) {
  return dispatch => {
    dispatch({ type: types.CREATE_REFERRALS_REQUEST, params })
    return apiCall("post", `associate/${currentAssociateId()}/referrals`, {
      data: params,
    })
      .then(response => {
        dispatch({ type: types.CREATE_REFERRALS_SUCCESS, response })
        successAlert("Referral created.")
      })
      .catch(error => {
        dispatch({ type: types.CREATE_REFERRALS_FAILURE, error })
        throw error
      })
  }
}

export function readReferrals() {
  return dispatch => {
    dispatch({ type: types.READ_REFERRALS_REQUEST })
    return apiCall("get", `associate/${currentAssociateId()}/referrals`)
      .then(response => {
        dispatch({ type: types.READ_REFERRALS_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.READ_REFERRALS_FAILURE, error })
        throw error
      })
  }
}
