import React from "react"
import PropTypes from "prop-types"

import Geolocation from "components/Geolocation"

const CheckinTask = props => (
  <Geolocation
    onSuccess={props.onCoordinatesChange}
    onFailure={props.onCoordinatesBlocked}
    onAcknowledgeGeoBlocked={props.onAcknowledgeGeoBlocked}
    handleModalContinue={props.handleModalContinue}
    handleModalClose={props.handleModalClose}
  />
)

CheckinTask.propTypes = {
  onCoordinatesChange: PropTypes.func.isRequired,
  onCoordinatesBlocked: PropTypes.func.isRequired,
  onAcknowledgeGeoBlocked: PropTypes.func.isRequired,
}

export default CheckinTask
