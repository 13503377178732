import React, { Component } from "react"
import { slide as Menu } from "react-burger-menu"
import { connect } from "react-redux"
import { listTeammates } from "actions/teammates"
import * as actionCreators from "actions"
import NavbarLink from "components/Navbar/Link"
import Icon from "components/Icon"
import SidebarContent from "components/Navbar/SidebarContent"
import { bindActionCreators } from "redux"
import { checkWrongStatus, isOnboardingAndTimesheetsOnly } from "utils/associateStatus"
import WrongStatusAlert from "components/Modals/WrongStatusAlert"
import "./MobileNavbar.scss"

class MobileNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      statusModalVisible: false,
    }
  }

  showStatusModal = e => {
    if (checkWrongStatus(this.props.associate)) {
      e.preventDefault()
      this.setState({
        statusModalVisible: true,
      })
    }
  }

  hideStatusModal = e => {
    if (checkWrongStatus(this.props.associate)) {
      this.setState({
        statusModalVisible: false,
      })
    }
  }

  isMenuOpen = menuState => {
    this.setState({ sidebarOpen: menuState.isOpen });
  }

  toggleSideBar = () => {

    this.setState({ sidebarOpen: !this.state.sidebarOpen })

    if (!this.state.sidebarOpen && !checkWrongStatus(this.props.associate)) {
      this.props
        .listTeammates()
        .then(response => {
          this.setState({
            teammates: response.data.invitationReceived,
          })
        })
        .catch(() => {})
    }

    return false;
  }

  handleLogout = () => {
    this.props.deleteSession()
  }

  render() {
    const { path, tasks, associate } = this.props
    const { teammates, statusModalVisible } = this.state

    const sidebarStyles = {
      bmOverlay: {
        background: "rgba(0, 0, 0, 0)",
      },
    }

    const invitationCount = teammates && teammates.length ? teammates.length : 0

    const sideBarContent = (
      <SidebarContent
        associate={this.props.associate}
        handleClick={this.toggleSideBar}
        handleLogout={this.handleLogout}
        invitationCount={invitationCount}
        modalOpen={this.showStatusModal}
      />
    )

    return (
      <React.Fragment>
        <header className="mobile-navbar">
          <div className="container">
            <ul className="mobile-navbar-menu">
              {(associate.isWurknowUser !== 'Y') && (<>
                <NavbarLink
                  to="/app/assignments"
                  path={path}
                  hasAlert={tasks.length > 0}
                  onClick={e => this.showStatusModal(e)}
                  disabled={checkWrongStatus(associate)}
                >
                  <Icon name="calendar3" height="19" width="21" />
                  <span>Assignments</span>
                </NavbarLink>

                {!isOnboardingAndTimesheetsOnly(associate) && (
                  <NavbarLink
                    to="/app/jobs/open"
                    path={path}
                    activePrefix="/app/jobs"
                    onClick={e => this.showStatusModal(e)}
                    disabled={checkWrongStatus(associate)}
                  >
                    <Icon name="list-search" height="19" width="21" />
                    <span>Job Search</span>
                  </NavbarLink>
                )}
              </>)}
              <NavbarLink to="/app/timesheets" path={path}>
                <Icon name="piggy-bank" height="19" width="22" />
                <span>Timesheets</span>
              </NavbarLink>
              <li className="menu-toggle">
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  this.toggleSideBar();
                }}>
                  <div />
                  <div />
                  <div />
                  <span>Menu</span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <Menu
          bodyClassName={"menu-open"}
          className="side-menu-wrap"
          customBurgerIcon={false}
          isOpen={this.state.sidebarOpen}
          right
          onStateChange={this.isMenuOpen}
          menuClassName={"side-menu"}
          styles={sidebarStyles}
          width={350}
          openModal={this.showStatusModal}
        >
          {sideBarContent}
        </Menu>
        {statusModalVisible && (
          <WrongStatusAlert modalClose={this.hideStatusModal} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
    tasks: state.data.tasks.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        listTeammates,
      },
      dispatch
    ),

    deleteSession: () => {
      dispatch(actionCreators.deleteSession())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavbar)
