import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { readStateDisclosures } from "actions"
import LoadingSpinner from "components/LoadingSpinner"
import StateDisclosuresList from "components/StateDisclosures/List"

const StateDisclosures = ({ stateDisclosures, readStateDisclosures }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    readStateDisclosures()
      .then(() => setIsLoading(false))
      .catch(() => {})
  }, [readStateDisclosures])

  return (
    <div className="disclosures">
      <h2>Disclosures</h2>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <StateDisclosuresList disclosures={stateDisclosures} />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    stateDisclosures: state.data.stateDisclosures.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readStateDisclosures: bindActionCreators(readStateDisclosures, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateDisclosures)
