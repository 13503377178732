import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  isAuthenticated: false,
  isNetworkModalOpen: false,
  errorMessage: "",
}

export default function session(state = defaultState, action) {
  switch (action.type) {
    case types.TOGGLE_NETWORK_ERROR_MODAL:
      return {
        ...state,
        isNetworkModalOpen: action.state,
      }
    case types.CREATE_SESSION_REQUEST:
    case types.RESTORE_SESSION_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        errorMessage: "",
      }
    case types.CREATE_SESSION_SUCCESS:
    case types.RESTORE_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errorMessage: "",
      }
    case types.CREATE_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errorMessage: action.errorMessage,
      }
    case types.DELETE_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errorMessage: action.message,
      }
    default:
      return state
  }
}
