import * as types from "actions/types"
import { apiCall } from "utils/api"
import { successAlert } from "utils/alerts"

import { readTasks } from "actions/tasks"

export function getSchedule(params = { perPage: 15, page: 1 }) {
  return dispatch => {
    dispatch({ type: types.READ_SCHEDULE_REQUEST, params })
    return apiCall(
      "get",
      "associate/schedule?include=assignment,job.store,job.project.workType,job.project.customer",
      { params }
    )
      .then(response => {
        dispatch(readTasks())
        dispatch({
          type: types.READ_SCHEDULE_SUCCESS,
          response,
          scheduleIsLoaded:
            response.data.length < params.perPage ? true : false,
        })
      })
      .catch(error => {
        dispatch({ type: types.READ_SCHEDULE_FAILURE, error })
        throw error
      })
  }
}

export function getScheduleLazy(params) {
  return dispatch => {
    dispatch({ type: types.READ_SCHEDULE_LAZY_REQUEST, params })
    return apiCall(
      "get",
      "associate/schedule?include=assignment,job.store,job.project.workType,job.project.customer",
      { params }
    )
      .then(response => {
        dispatch({
          type: types.READ_SCHEDULE_LAZY_SUCCESS,
          response,
          scheduleIsLoaded:
            response.data.length < params.perPage ? true : false,
        })
      })
      .catch(error => {
        dispatch({ type: types.READ_SCHEDULE_LAZY_FAILURE, error })
        throw error
      })
  }
}

export function cancelJob(params) {
  const jobId = params.jobId
  return dispatch => {
    dispatch({ type: types.CANCEL_JOB_REQUEST, params })
    return apiCall("post", `opportunities/${jobId}/decline`)
      .then(response => {
        dispatch({ type: types.CANCEL_JOB_SUCCESS, response })
        successAlert("Job canceled.")
      })
      .catch(error => {
        dispatch({ type: types.CANCEL_JOB_FAILURE, error })
        throw error
      })
  }
}

export function cancelJobDates(params) {
  return dispatch => {
    dispatch({ type: types.CANCEL_JOB_DATES_REQUEST, params })
    return apiCall("post", `associate/assignments/cancel`, { data: params })
      .then(response => {
        dispatch({ type: types.CANCEL_JOB_DATES_SUCCESS, response })
        successAlert("Job days were canceled.")
      })
      .catch(error => {
        dispatch({ type: types.CANCEL_JOB_DATES_FAILURE, error })
        throw error
      })
  }
}

export function createPunchTime(params) {
  const assignmentId = params.assignmentId
  return dispatch => {
    dispatch({ type: types.CREATE_PUNCH_TIME_REQUEST, params })
    return apiCall(
      "post",
      `schedule/${assignmentId}/punches`,
      { data: params },
      true,
      null,
      {
        property: "data.message",
      }
    )
      .then(response => {
        dispatch({ type: types.CREATE_PUNCH_TIME_SUCCESS, response })
      })
      .catch(error => {
        dispatch({ type: types.CREATE_PUNCH_TIME_FAILURE, error })
        throw error
      })
  }
}
