import React, { Component } from "react"
import { connect } from "react-redux"
import { Row, Col } from "react-flexbox-grid"
import { Field, FormSection, reduxForm } from "redux-form"
import { formatPhoneNumber } from "utils/format"
import { handleErrors } from "utils/errors"
import classNames from "classnames"
import ErrorMessage from "components/ErrorMessage"
import Checkbox from "components/Form/CheckboxInput"
import TextInput from "components/Form/TextInput"
import Button from "components/Form/Button"
import Address from "./Address"
import { checkWrongStatus } from "utils/associateStatus"
import { Redirect } from "react-router-dom"
import Select from "../Form/Select"

export const fields = [
  "firstName",
  "lastName",
  "cellPhoneNumber",
  "homePhoneNumber",
  "preferredContactPhone",
  "smsNotifications",
  "haveWebEnabledSmartphone",
  "addresses",
  "mailingDifferent",
  "tShirtSize",
]

const validate = ({
  firstName,
  lastName,
  cellPhoneNumber,
  homePhoneNumber,
  preferredContactPhone,
  addresses,
  mailingDifferent,
}) => {
  const errors = {}

  const phoneRegExp = new RegExp(
    "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
  )

  if (!firstName) {
    errors.firstName = "Please enter a first name"
  } else if (firstName.length > 30) {
    errors.firstName = "Must be less than 30 characters"
  } else if (/&/g.test(firstName)) {
    errors.firstName = "Must not contain &"
  }

  if (!lastName) {
    errors.lastName = "Please enter a last name"
  } else if (lastName.length > 30) {
    errors.lastName = "Must be less than 30 characters"
  } else if (/&/g.test(lastName)) {
    errors.lastName = "Must not contain &"
  }

  if (!cellPhoneNumber) {
    errors.cellPhoneNumber = "Please enter a number"
  } else if (!phoneRegExp.test(cellPhoneNumber)) {
    errors.cellPhoneNumber = "Please enter a valid number"
  } else if (!preferredContactPhone) {
    errors.cellPhoneNumber = "Please select a preferred number"
    errors.homePhoneNumber = "Please select a preffered number"
  }

  if (preferredContactPhone === "Home") {
    if (!homePhoneNumber) {
      errors.homePhoneNumber = "Please enter a number"
    } else if (!phoneRegExp.test(homePhoneNumber)) {
      errors.homePhoneNumber = "Please enter a valid number"
    }
  }

  if (addresses && addresses.length) {
    const addressesArrayErrors = []
    addresses.forEach((address, addressIndex) => {
      if (addressIndex === 0 || mailingDifferent) {
        const addressErrors = {}
        if (!address || !address.streetAddress1) {
          addressErrors.streetAddress1 = "Please enter a street address"
          addressesArrayErrors[addressIndex] = addressErrors
        }
        if (!address || !address.city) {
          addressErrors.city = "Please enter a city"
          addressesArrayErrors[addressIndex] = addressErrors
        }
        if (!address || !address.state) {
          addressErrors.state = "Please enter a state"
          addressesArrayErrors[addressIndex] = addressErrors
        }
        if (!address || !address.zipCode) {
          addressErrors.zipCode = "Required"
          addressesArrayErrors[addressIndex] = addressErrors
        }
      }
    })
    if (addressesArrayErrors.length) {
      errors.addresses = addressesArrayErrors
    }
  }

  return errors
}

class PersonalInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMailingAddress: null,
    }
  }

  updateCurrentAssociate(params) {
    params.tShirtSize = params.tShirtSize?.id || params.tShirtSize;
    return this.props.updateCurrentAssociate(params).catch(err => {
      handleErrors(err)
    })
  }

  handleMailingAddressDisplay = e => {
    this.setState({ showMailingAddress: e.target.checked })
  }

  hasRequiredPhoneData({
    cellPhoneNumber,
    homePhoneNumber,
    preferredContactPhone,
  }) {
    if (!preferredContactPhone) return false
    if (!cellPhoneNumber) return false
    if (preferredContactPhone === "Home" && !homePhoneNumber) return false

    return true
  }

  isSaveButtonActive() {
    const { pristine, submitting, initialValues } = this.props

    if (submitting) return false
    if (this.hasRequiredPhoneData(initialValues) && pristine) return false

    return true
  }

  render() {
    const { error, handleSubmit, submitting, submitSucceeded, associate } =
      this.props

    const expanded =
      this.state.showMailingAddress === null
        ? this.props.associate.addresses.length > 1
        : this.state.showMailingAddress

    let mailingAddressClass = classNames("mailing-address-form", "expandable", {
      expanded: expanded,
    })

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <React.Fragment>
        <h2>Personal Information</h2>
        <form
          onSubmit={handleSubmit(params => this.updateCurrentAssociate(params))}
        >
          <div className="form">
            {error && <ErrorMessage errorText={error} />}
            <Row>
              <Col xs={12} md={6}>
                <div className="input-box input-box-grid">
                  <Field
                    name="firstName"
                    type="text"
                    component={TextInput}
                    label="First Name"
                    placeholder="First Name"
                  />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="input-box input-box-grid">
                  <Field
                    name="lastName"
                    type="text"
                    component={TextInput}
                    label="Last Name"
                    placeholder="Last Name"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div className="input-box input-box-grid">
                  <Field
                    name="cellPhoneNumber"
                    type="tel"
                    component={TextInput}
                    label="Mobile Phone"
                    placeholder="Mobile Phone"
                  />

                  <div className="input-box overlay-input">
                    <Field
                      name="preferredContactPhone"
                      id="radio_mobile"
                      component="input"
                      type="radio"
                      value="Cell"
                    />
                    <label htmlFor="radio_mobile">
                      <span>Preferred Number</span>
                    </label>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="input-box input-box-grid">
                  <Field
                    name="homePhoneNumber"
                    type="tel"
                    component={TextInput}
                    label="Home Phone"
                    placeholder="Home Phone"
                  />

                  <div className="input-box overlay-input">
                    <Field
                      name="preferredContactPhone"
                      id="radio_home"
                      component="input"
                      type="radio"
                      value="Home"
                    />
                    <label htmlFor="radio_home">
                      <span>Preferred Number</span>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="input-line checkbox-line">
              <div className="input-box input-box-inline">
                <Field
                  name="smsNotifications"
                  id="smsNotifications"
                  label="I agree to receive text messages to my mobile phone"
                  tooltip="By checking this box, you agree to allow Set and Service Resources to send job opportunities and updates via text message. Text messages may incur fees from your mobile provider."
                  component={Checkbox}
                />
              </div>
            </div>
            <div className="input-line checkbox-line">
              <div className="input-box input-box-inline">
                <Field
                  name="haveWebEnabledSmartphone"
                  id="haveWebEnabledSmartphone"
                  label="I have a web enabled smartphone (Android, iPhone, Etc)"
                  component={Checkbox}
                />
              </div>
            </div>
            <div className="mailing-address-form">
              <FormSection name="addresses[0]">
                <Address label="Physical" />
              </FormSection>
            </div>

            <div className="input-line checkbox-line">
              <div className="input-box">
                <Field
                  component="input"
                  type="checkbox"
                  id="mailingDifferent"
                  name="mailingDifferent"
                  onClick={this.handleMailingAddressDisplay}
                />
                <label htmlFor="mailingDifferent">
                  My mailing address is different
                </label>
              </div>
            </div>

            <div className={mailingAddressClass}>
              <FormSection name="addresses[1]">
                <Address label="Mailing" fieldPrefix="Mailing" />
              </FormSection>
            </div>

            <Row>
              <Col xs={12} md={6}>
                <div className="input-box input-box-grid">
                  <Field
                    name="tShirtSize"
                    component={Select}
                    options={associate.tShirtSizesList.map(size => { return { id: size, name: size }; })}
                    labelKey="name"
                    valueKey="id"
                    labelText="T-Shirt Size"
                    placeholder="T-Shirt Size"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="form-action">
            <Button
              btnClass="btn-green btn-save"
              text="Save"
              disabled={!this.isSaveButtonActive()}
              submitting={submitting}
              succeeded={submitSucceeded}
            />
          </div>
        </form>
      </React.Fragment>
    )
  }
}

PersonalInfo = reduxForm({
  form: "personal",
  fields,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
})(PersonalInfo)

export default connect(state => ({
  initialValues: {
    firstName: state.data.associate.attributes.firstName,
    lastName: state.data.associate.attributes.lastName,
    cellPhoneNumber: formatPhoneNumber(
      state.data.associate.attributes.cellPhoneNumber
    ),
    homePhoneNumber: formatPhoneNumber(
      state.data.associate.attributes.homePhoneNumber
    ),
    preferredContactPhone:
      state.data.associate.attributes.preferredContactPhone,
    smsNotifications: state.data.associate.attributes.smsNotifications,
    mailingDifferent: state.data.associate.attributes.addresses.length > 1,
    addresses: state.data.associate.attributes.addresses,
    haveWebEnabledSmartphone:
      state.data.associate.attributes.haveWebEnabledSmartphone,
    associate: state.data.associate.attributes,
    tShirtSize: state.data.associate.attributes.tShirtSize,
  },
}))(PersonalInfo)
