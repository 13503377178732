import React from "react"
import SvgIcon from "components/SvgIcon"

const LoadingSpinner = () => {
  return (
    <div className="loading">
      <SvgIcon title="loading" name="loading" />
      <p>Loading...</p>
    </div>
  )
}

export default LoadingSpinner
