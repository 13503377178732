import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Icon from "components/Icon"
import "./Pagination.scss"

const PaginationPrev = ({ handlePreviousPage, disabled }) => (
  <div
    onClick={handlePreviousPage}
    className={classNames("pagination-prev", { disabled: disabled })}
  >
    <Icon name="caret-left" height="20" width="8" />
  </div>
)

PaginationPrev.propTypes = {
  handlePreviousPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default PaginationPrev
