import React from "react"
import StateDisclosuresItem from "./Item"
import "./List.scss"

const StateDisclosuresList = ({ disclosures }) => {
  const states = Object.keys(disclosures)

  return (
    <div className="disclosures-list">
      {states.map(state => {
        const { id, ...rest } = disclosures[state]
        return <StateDisclosuresItem key={id} {...rest} />
      })}
    </div>
  )
}

export default StateDisclosuresList
