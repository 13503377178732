import React, { Component } from "react"
import PropTypes from "prop-types"
import Icon from "components/Icon"
import classNames from "classnames"

export default class TextInput extends Component {
  static propTypes = {
    type: PropTypes.oneOf(["text", "number", "email", "password", "tel"])
      .isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    classNames: PropTypes.string,
  }

  hasErrors() {
    return this.props.meta.error && this.props.meta.touched
  }

  render() {
    const {
      disabled,
      icon,
      input,
      label,
      meta,
      onClick,
      placeholder,
      type,
      ...custom
    } = this.props

    const labelText = meta.error && meta.touched ? meta.error : label

    const hasValue =
      typeof input.value !== "number" ? input.value && input.value.length : true
    const inputClass = classNames(this.props.classNames, {
      "has-text": hasValue || this.hasErrors(),
      "has-errors": this.hasErrors(),
    })

    return (
      <React.Fragment>
        <input
          {...input}
          className={inputClass}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          onClick={onClick}
          {...custom}
        />
        <label htmlFor={input.name}>{labelText}</label>
        {icon && <Icon height="16" width="16" name={icon} />}
      </React.Fragment>
    )
  }
}
