import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCurrentAssociate } from "actions"
import Account from "components/Profile/Account"

const mapStateToProps = state => {
  return {
    emailAddress: state.data.associate.attributes.emailAddress,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentAssociate: bindActionCreators(
      updateCurrentAssociate,
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)
