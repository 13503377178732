import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import AssignmentsRoute from "routes/App/Assignments"
import DesktopNavbar from "components/App/DesktopNavbar"
import MobileNavbar from "components/App/MobileNavbar"
import TimesheetsRoute from "routes/App/Timesheets"
// import JobsRoute from "routes/App/Jobs"
// import JobShowRoute from "routes/Jobs/Show"
import ProfileRoute from "routes/App/Profile"
import ReferralsRoute from "routes/App/Referrals"
import Footer from "components/App/Footer"
import TermsAndConditionsRoute from "routes/App/TermsAndConditions"
import PrivacyRoute from "routes/App/Privacy"
// import ForcePasswordChangeRoute from "routes/ForcePasswordChange"
import DrugScreenAlert from "components/Modals/DrugScreenAlert"
import "./App.scss"
import { connect } from "react-redux"

class AppRoute extends Component {
  componentDidMount() {
    if (!this.props.isAuthenticated) return;
  }
  render() {
    return (
      <React.Fragment>
        <MobileNavbar path={this.props.location.pathname} />
        <DesktopNavbar path={this.props.location.pathname} />
        <Switch>
          <Route
            exact
            path={`${this.props.path}/profile`}
            render={() => <Redirect to="/app/profile/personal" />}
          />
          <Route path={`${this.props.path}/profile`} component={ProfileRoute} />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={`${this.props.path}/jobs`}*/}
          {/*  render={() => <Redirect to="/app/jobs/open" />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={`${this.props.path}/work/:id`}*/}
          {/*  component={JobShowRoute}*/}
          {/*/>*/}
          <Route exact path="/app/terms" component={TermsAndConditionsRoute} />
          <Route exact path="/app/privacy" component={PrivacyRoute} />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path="/app/forcepasswordchange"*/}
          {/*  component={ForcePasswordChangeRoute}*/}
          {/*/>*/}
          {/*<Route path={`${this.props.path}/jobs`} component={JobsRoute} />*/}
          <Route
            path={`${this.props.path}/timesheets`}
            component={TimesheetsRoute}
          />
          <Route
            exact
            path={`${this.props.path}/assignments/:id?`}
            component={AssignmentsRoute}
          />
          <Route
            exact
            path={`${this.props.path}/referrals`}
            component={ReferralsRoute}
          />
          <Redirect to="/app/assignments" />
        </Switch>
        <Footer />
        <DrugScreenAlert />
      </React.Fragment>
    )
  }
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.session.isAuthenticated === true,
  }
}

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute)
