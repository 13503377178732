import React, { Component } from "react"
import VerticalDate from "components/VerticalDate"
import PointValue from "components/Profile/PointValue"
import ScrollbarContainer from "components/ScrollbarContainer"
import Odometer from "react-odometerjs"
import SvgIcon from "components/SvgIcon"
import { checkWrongStatus } from "utils/associateStatus"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import "odometer/themes/odometer-theme-default.css"
import "./Points.scss"

class Points extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      perPage: 10,
    }
  }

  componentDidMount() {
    this.props.readPoints().catch(() => {})
  }

  formatSubtotals = value => {
    if (!value) return 0
    return Math.abs(Math.round(value))
  }

  getSign = value => {
    if (value) {
      if (value > 0) {
        return <sup>+</sup>
      } else {
        return <sup>-</sup>
      }
    }
  }

  handleNextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 })
  }

  handlePreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 })
  }

  render() {
    const { associate } = this.props
    const isLoading = this.props.points.isLoading
    const points = this.props.points.data
    const pointLog = points.pointLog

    const pointsEarned = this.formatSubtotals(points.subtotals.pointsEarned)
    const pointsLost = this.formatSubtotals(points.subtotals.pointsLost)
    const netPoints = this.formatSubtotals(points.subtotals.netPoints)

    if (checkWrongStatus(associate)) {
      return <Redirect to="/app/timesheets" />
    }

    return (
      <div className="profile-points">
        <h2>Points</h2>
        <div className="points-totals">
          <div className="points-earned">
            <h5>Points Earned</h5>
            <span>
              {this.getSign(points.subtotals.pointsEarned)}
              <Odometer value={pointsEarned} />
            </span>
          </div>

          <div className="points-lost">
            <h5>Points Lost</h5>
            <span>
              {this.getSign(points.subtotals.pointsLost)}
              <Odometer value={pointsLost} />
            </span>
          </div>

          <div className="net-points">
            <h5>Net Points</h5>
            <span>
              {this.getSign(points.subtotals.netPoints)}
              <Odometer value={netPoints} />
            </span>
          </div>
        </div>
        <ScrollbarContainer>
          <div className="points-log">
            {pointLog.length > 0 ? (
              pointLog.map((entry, index) => (
                <div className="points-log-row" key={index}>
                  <VerticalDate date={entry.logDate} />

                  <div className="notes">
                    {entry.clientName && (
                      <div className="client">{entry.clientName}</div>
                    )}
                    {entry.note}
                    <div className="job">{entry.jobID}</div>
                  </div>
                  <div className="points">
                    <span className="title">Points</span>
                    <PointValue value={entry.value} />
                  </div>
                </div>
              ))
            ) : (
              <div className="points-log-row">
                <div className="notes">
                  {isLoading ? (
                    <div className="loading">
                      <SvgIcon title="loading" name="loading" />
                    </div>
                  ) : (
                    <p className="no-points">
                      No points entries yet.
                      <br />
                      Apply to jobs, work some jobs, get paid, and get points to
                      boost your score!
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </ScrollbarContainer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
  }
}

export default connect(
  mapStateToProps,
  null
)(Points)
