import moment from "moment"

export function serializeJobSearch(params) {
  let serializedParams = Object.assign({}, params)

  if (typeof serializedParams.radius === "object") {
    serializedParams["radius"] = serializedParams.radius.label
  }

  if (typeof serializedParams.locationId === "object") {
    serializedParams["locationId"] = serializedParams.locationId.id
  }

  if (serializedParams.startDate instanceof moment) {
    serializedParams["startDate"] = serializedParams.startDate.format(
      "YYYY-MM-DD"
    )
  }

  if (serializedParams.endDate instanceof moment) {
    serializedParams["endDate"] = serializedParams.endDate.format("YYYY-MM-DD")
  }

  // not sure what this should look like yet
  if (typeof serializedParams.searchStates === "object") {
    /* eslint no-self-assign: off */
    serializedParams["searchStates"] = serializedParams.searchStates
  }

  return serializedParams
}
