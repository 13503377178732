import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "components/Modal"
import Icon from "components/Icon"
import Button from "components/Form/Button"
import "./DrugScreenAlert.scss"

class DrugScreenAlert extends Component {
  state = {
    showAlert: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.drugScreenStatus === "Ordered" &&
      localStorage.getItem("showDrugScreenAlert") !== "false"
    ) {
      return {
        showAlert: true,
      }
    }
    return null
  }

  closeAlert = () => {
    this.setState({ showAlert: false })
    localStorage.setItem("showDrugScreenAlert", "false")
  }

  render() {
    const { showAlert } = this.state

    if (showAlert)
      return (
        <Modal
          className="lofted drug-screen-modal"
          modalClose={this.closeAlert}
        >
          <div className="modal-header">
            <a className="modal-close" onClick={this.closeAlert}>
              <Icon name="x" />
            </a>
            <h3>Drug Screen</h3>
          </div>
          <div className="modal-body">
            <p>
              A drug screen has been ordered for you. Check your inbox and spam
              folder for an email from FADVReports-NoReply@fadv.com. It must be
              completed within two business days of receiving the email. Once
              passed you will be qualified for more jobs.
            </p>
          </div>
          <div className="modal-footer">
            <Button text="OK" onClick={this.closeAlert} />
          </div>
        </Modal>
      )

    return null
  }
}

const mapStateToProps = state => {
  return {
    drugScreenStatus: state.data.associate.attributes.drugScreenStatus,
  }
}

export default connect(mapStateToProps, null)(DrugScreenAlert)
