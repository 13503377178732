import React from "react"
import PropTypes from "prop-types"

const MailLink = ({ mail, text, ...props }) => {
  return (
    <a href={`mailto:${mail}`} {...props}>
      {text ?? mail}
    </a>
  )
}

MailLink.propTypes = {
  mail: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default MailLink
