import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as actionCreators from "actions"

import WorkInterests from "components/Profile/WorkInterests"

const mapStateToProps = state => {
  return {
    experiences: state.data.experiences,
    interestOptions: state.lists.worktypes,
    experienceOptions: state.lists.yearsexperience,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkInterests)
