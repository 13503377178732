export const ASSOCIATE_STATUS_LIST = [
  "Denied",
  "Terminated",
  "Deleted",
  "Suspended",
  "UnderReview",
]

export function checkWrongStatus(associate) {
  if (!associate) return false;

  return ASSOCIATE_STATUS_LIST.includes(
    associate.adminAssociateStatusDescription
  );
}

export function isOnboardingAndTimesheetsOnly(associate) {
  return associate?.onboardingAndTimesheetsOnly === 'Y';
}
