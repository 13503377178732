import React, { Component } from "react"
import PropTypes from "prop-types"

import Icon from "components/Icon"
import StaticMap from "components/StaticMap"

import "./MapLocation.scss"

export default class MapLocation extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
  }

  render() {
    const { job, isLoading } = this.props
    const location = job.store.location
    const isLoaded = job.title !== undefined

    let directionsURI
    if (location.address.full) {
      directionsURI = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        location.address.full
      )}`
    }
    const name = !isLoading && job.store.name
    const number = !isLoading && job.store.number
    const address = !isLoading && location.address.full
    const hasCoordinates =
      location.coordinates.latitude && location.coordinates.longitude
    return (
      <div className="job-detail-map-location">
        {isLoaded ? (
          <React.Fragment>
            <div className="location-header">
              <div className="header-left">
                <Icon name="pinpoint" height="16" width="14" />
                <div className="location-name">
                  {name} {number}
                  <div className="location-address">{address}</div>
                </div>
              </div>
              {directionsURI ? (
                <a
                  href={directionsURI}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-green btn-small"
                >
                  Get Directions
                </a>
              ) : (
                <a target="_blank" className="btn btn-small btn-disabled">
                  Get Directions
                </a>
              )}
            </div>
            {hasCoordinates && (
              <StaticMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCs9PbEyZSF92yvMK-hpB3g3hWMk-h8F4w"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `230px` }} />}
                mapElement={
                  <div className="location-map" style={{ height: `100%` }} />
                }
                position={{
                  lat: location.coordinates.latitude,
                  lng: location.coordinates.longitude,
                }}
              />
            )}
          </React.Fragment>
        ) : (
          <div className="loading">Loading Location...</div>
        )}
      </div>
    )
  }
}
