import React, { Fragment } from "react"
import { alertEmail } from "utils/alerts"
import MailLink from "components/MailLink"
import PropTypes from "prop-types"

const WrongStatusAlertBody = ({ status }) => {
  const mail = alertEmail(status)

  return (
    <Fragment>
      {status === "Suspended" ? (
        <p>
          Please contact your Talent Agent by clicking the Blue Help Icon on the
          bottom right to discuss your employment status.
        </p>
      ) : (
        <p>
          Please contact <MailLink mail={mail} className="mail-link" /> to
          discuss your employment status
        </p>
      )}
    </Fragment>
  )
}

WrongStatusAlertBody.propTypes = {
  status: PropTypes.string.isRequired,
}

export default WrongStatusAlertBody
