import * as types from "./types"
import { apiCall } from "utils/api"

export function readStateDisclosures() {
  return dispatch => {
    dispatch({ type: types.READ_STATE_DISCLOSURES_REQUEST })

    return apiCall("get", "state-disclosures/")
      .then(response => {
        dispatch({ type: types.READ_STATE_DISCLOSURES_SUCCESS, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.READ_STATE_DISCLOSURES_FAILURE, error })
        throw error
      })
  }
}
