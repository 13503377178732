import React, { Component } from "react"
import moment from "moment"

import Day from "./Day"
import "./List.scss"

export default class List extends Component {
  render() {
    const { schedule, assignmentId } = this.props
    let today
    let otherDays = []

    if (schedule.data.length > 0) {
      let firstDay = schedule.data[0]
      if (moment(firstDay.date).isSame(moment(), "day")) {
        today = firstDay
        otherDays = schedule.data.slice(1)
      } else {
        otherDays = schedule.data
      }
    }

    return (
      <div className="schedule-list">
        <Day
          day={today}
          isLoading={schedule.isLoading}
          assignmentId={assignmentId}
        />
        {otherDays.map((day, i) => (
          <Day
            day={day}
            isLoading={schedule.isLoading}
            key={day.date}
            assignmentId={assignmentId}
          />
        ))}
      </div>
    )
  }
}
