import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "components/Modal"
import Icon from "components/Icon"
import Button from "components/Form/Button"
import WrongStatusAlertBody from "./WrongStatusAlertBody"
import { checkWrongStatus } from "utils/associateStatus"
import "./DrugScreenAlert.scss"

class WrongStatusAlert extends Component {
  state = {
    showAlert: true,
  }

  static getDerivedStateFromProps(props, state) {
    if (
      checkWrongStatus(props.associate)
      // || props.wrongStatus
    ) {
      return {
        showAlert: true,
      }
    }
    return null
  }

  closeAlert = () => {
    this.setState({ showAlert: false })
  }

  render() {
    const { modalClose, associate } = this.props
    const { showAlert } = this.state
    const associateStatus = associate.adminAssociateStatusDescription

    if (showAlert)
      return (
        <Modal className="lofted drug-screen-modal" modalClose={modalClose}>
          <div className="modal-header">
            <a className="modal-close" onClick={modalClose}>
              <Icon name="x" />
            </a>
            <h3>Status Alert</h3>
          </div>
          <div className="modal-body">
            <WrongStatusAlertBody status={associateStatus} />
          </div>
          <div className="modal-footer">
            <Button text="OK" onClick={modalClose} />
          </div>
        </Modal>
      )

    return null
  }
}

const mapStateToProps = state => {
  return {
    associate: state.data.associate.attributes,
    wrongStatus: state.data.wrongStatus,
  }
}

export default connect(mapStateToProps, null)(WrongStatusAlert)
