import React from "react"
import PropTypes from "prop-types"

import iconSprite from "assets/ui-kit-svg-sprite.svg"
import "./Icon.scss"

// TODO: Remove in favor of SvgIcon
// viewBoxes to make icons responsive
const viewBoxes = {
  star: "0 0 17 17",
  "star-border": "0 0 17 17",
}

const Icon = props => {
  const viewBox = viewBoxes[props.name]
    ? { viewBox: viewBoxes[props.name] }
    : {}

  return (
    <svg
      className={props.name}
      width={props.width}
      height={props.height}
      {...viewBox}
    >
      <title>{props.title}</title>
      <use xlinkHref={`${iconSprite}#svg-icon-${props.name}`} />
    </svg>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default Icon
