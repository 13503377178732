import { combineReducers } from "redux"
import { reducer as form } from "redux-form"
import { reducer as burgerMenu } from "redux-burger-menu"
import data from "./data"
import lists from "./lists"
import session from "./session"
import version from "./version"

const appReducer = combineReducers({
  burgerMenu,
  data,
  form,
  lists,
  session,
  version,
})

const rootReducer = (state, action) => {
  if (action.type === "DELETE_SESSION_SUCCESS") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
