import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  isLazyLoading: false,
  scheduleIsLoaded: false,
  isSearching: false,
  data: [],
}

export default function schedule(state = defaultState, action) {
  switch (action.type) {
    case types.CANCEL_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.CANCEL_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case types.CANCEL_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CANCEL_JOB_DATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.CANCEL_JOB_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case types.CANCEL_JOB_DATES_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.READ_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.READ_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleIsLoaded: action.scheduleIsLoaded,
        isSearching: false,
        data: action.response?.data || [],
      }
    case types.READ_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.READ_SCHEDULE_LAZY_REQUEST:
      return {
        ...state,
        isLazyLoading: true,
      }
    case types.READ_SCHEDULE_LAZY_SUCCESS:
      const arr = state.data.concat(action.response.data)

      return {
        ...state,
        isLazyLoading: false,
        scheduleIsLoaded: action.scheduleIsLoaded,
        isSearching: false,
        // filter unique days
        data: arr
          .map(e => e["id"])
          .map((e, i, final) => final.lastIndexOf(e) === i && i)
          .filter(e => arr[e])
          .map(e => arr[e]),
      }
    case types.READ_SCHEDULE_LAZY_FAILURE:
      return {
        ...state,
        isLazyLoading: false,
      }
    case types.CREATE_PUNCH_TIME_REQUEST:
      return {
        ...state,
      }
    case types.CREATE_PUNCH_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case types.CREATE_PUNCH_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
