import React from "react"
import FileItem from "./FileItem"
import "./FileList.scss"

const FileList = ({ files, state }) => {
  const filePath = state.toLowerCase() + "/"

  return (
    <div className="file-list">
      {files.map(file => {
        file = file.replace(filePath, "")
        return <FileItem key={file} fileName={file} state={state} />
      })}
    </div>
  )
}

export default FileList
