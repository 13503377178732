import React, { Component } from "react"
import Player from "@vimeo/player"

export default class Introduction extends Component {
  componentDidMount() {
    this.player = new Player(this.refs.player)
    this.player
      .addCuePoint(54)
      .then(id => {
        // cue point was added successfully
        this.player.on("cuepoint", this.props.submitIntroduction)
      })
      .catch(error => {
        // cue points are not supported with the current player or browser
        this.player.on("play", this.props.submitIntroduction)
      })
  }

  render() {
    const introVideo = "https://player.vimeo.com/video/306404905"

    return (
      <React.Fragment>
        <h2>Introduction to working with SASR</h2>
        <div className="video-container">
          <iframe
            ref="player"
            src={`${introVideo}?autoplay=0`}
            title="intro video"
          />
        </div>
      </React.Fragment>
    )
  }
}
