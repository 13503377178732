import ColorHash from "color-hash"

export function serializeAssociate(params) {
  let serializedParams = Object.assign({}, params)

  if ("addresses" in serializedParams) {
    // First address
    if (serializedParams.addresses[0]) {
      serializedParams.addresses[0]["label"] = "Physical"

      if (!serializedParams.addresses[0].streetAddress2) {
        serializedParams.addresses[0]["streetAddress2"] = null
      }
    }

    // Second address
    if (serializedParams.addresses.length > 1) {
      if (!serializedParams.mailingDifferent) {
        // remove the mailing address (which was populated with initial values) if
        // the checkbox wasn't checked
        serializedParams.addresses.pop()
      } else {
        // the address should be saved
        serializedParams.addresses[1]["label"] = "Mailing"
        if (!serializedParams.addresses[1].streetAddress2) {
          serializedParams.addresses[1]["streetAddress2"] = null
        }
      }
    }
  }

  delete serializedParams.mailingDifferent

  if (typeof serializedParams.radius === "object") {
    serializedParams["radius"] = serializedParams.radius.label
  }

  return serializedParams
}

export function deserializeAssociate(params) {
  let colorHash = new ColorHash({ lightness: 0.5 })
  params["profileColor"] = colorHash.hex(params["emailAddress"])
  let serializedParams = Object.assign({}, params)

  // Physical address must be listed before Mailing address
  if (
    serializedParams.addresses !== undefined &&
    serializedParams.addresses[0] !== undefined
  ) {
    if (serializedParams.addresses[0].label === "Mailing") {
      serializedParams.addresses.reverse()
    }
  }

  return serializedParams
}
