import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Modal from "components/Modal"
import Icon from "components/Icon"
import TeammatesInvite from "./TeammatesInvite"
import { successAlert, errorAlert } from "utils/alerts"
import { SubmissionError } from "redux-form"
import { teammatesInvite, cancelInvitation } from "actions/teammates"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"
import "./ConfirmApply.scss"

class InviteTeammatesModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      submitSucceeded: false,
    }
  }

  static propTypes = {
    modalClose: PropTypes.func,
  }

  teammatesInvite = params => {
    this.setState({ isLoading: true })

    return this.props
      .teammatesInvite(params)
      .then(() => {
        successAlert("Invitation was sent successfully.")
        this.setState({ isLoading: false, submitSucceeded: false })
        this.props.modalClose()
      })
      .catch(error => {
        if (error && error.data && error.data.error) {
          const errorMessage = error.data.error
          const fieldError = _.set({}, "emailAddresses", errorMessage)

          throw new SubmissionError({
            ...fieldError,
            _error: errorMessage,
          })
        }
        this.setState({ isLoading: false, submitSucceeded: false })
      })
  }

  showError = error => {
    if (error.data && error.data.error) {
      errorAlert(error.data.error)
    }
  }

  render() {
    const { modalClose } = this.props

    const { isLoading, submitSucceeded } = this.state

    return (
      <Modal className="confirm-apply-modal lofted" modalClose={modalClose}>
        <div className="modal-header">
          <a className="close" onClick={modalClose}>
            <Icon name="x" />
          </a>
        </div>
        <div className="modal-body">
          <Fragment>
            <TeammatesInvite
              teammatesInvite={this.teammatesInvite}
              submitting={isLoading}
              submitSucceeded={submitSucceeded}
            />
          </Fragment>
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        teammatesInvite,
        cancelInvitation,
      },
      dispatch
    ),
  }
}

export default connect(null, mapDispatchToProps)(InviteTeammatesModal)
