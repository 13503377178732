import React from "react"
import PropTypes from "prop-types"
import "./ProfilePicture.scss"

const ProfilePicture = ({ onClick, associate }) => (
  <div
    className="profile-picture"
    onClick={onClick}
    style={{ backgroundColor: associate?.profileColor || '' }}
  >
    {associate?.firstName?.charAt(0) || ''}
    {associate?.lastName?.charAt(0) || ''}
  </div>
)

ProfilePicture.propTypes = {
  associate: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default ProfilePicture
